import React, { useContext } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'

import History from './History/History'
import Me from './Me'
import RoleAdmin from './RoleAdmin'
import AuthContext from '../contexts/AuthContext'
import { Tabs } from '../components'

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
`

export default (props) => {
  const [{ user }] = useContext(AuthContext)
  const history = useHistory()
  const { resource } = useParams()
  let panes = [
    {
      key: 'me',
      title: 'Informations personnelles',
      content: <Me {...props} />,
    },
    {
      key: 'history',
      title: `Historique d'investigation`,
      content: <History {...props} />,
    },
  ]

  if (['admin', 'manager'].includes(user.type)) {
    panes.push(
      {
        key: 'role-admin',
        title: 'Gestion des utilisateurs',
        content: <RoleAdmin {...props} />,
      })
  }

  return (
    <Container>
      <Tabs
        currentTab={resource}
        panes={panes}
        onChange={(v) => history.push(`/account/${v}`)}
      />
    </Container>
  )
}
