const scenario = {
  'scenarioRealTime': 'Scenarios',
  'scenarioInSearch': 'Scenarios in search',
  'defineScenarioSimpleOrComplexe': 'Create simple or complex scenarios of abnormal or dangerous situations.',
  'notificationDescriptionNeuroCop': '(person with helmet lying on the ground, vehicle overturned, etc.), Neurocop',
  'defineScenarioSimpleOrComplexeOnBasepredefine': ' Based on preset events',
  'notificationDescriptionNeuroCopImportant': 'will notify you if the scenario is triggered.',
  'camera': 'Camera',
  'chooseCamera': 'Select the camera',
  'definitionColorShipOrange': 'Orange dots indicate the presence of a zone on the corresponding camera.',
  'definitionColorShipRed': 'Red dots indicate processing on the corresponding camera.',
  'hideCamera': 'Hide cameras',
  'showCamera': 'Show cameras',
  'show': 'Show',
  'unique': 'Only',
  'createModifyZone': 'Create / Edit zones:',
  'defineZoneDetection': 'Draw the perimeter of each detection zone',
  'defineZoneDetectionDetail': 'by precisely positioning the points with the tool.',
  'editeZone': 'Edit zones',
  'zoneName': 'Zone name',
  'defineScenario': 'Create scenario',
  'addDetailScenario': 'Enter scenario details.',
  'nothingDefineZone': 'No zone set',
  'defineZoneAboveSection': 'Please set the zones in the section above.',
  'title': 'Name',
  'Event': 'Event',
  'chooseEvent': 'Choose an event',
  'zoneEntry': 'Zone entry',
  'stopSignCrossing': 'Stop sign crossing',
  'redLightCrossing': 'Red light crossing',
  'gatheringInTheZone': 'Gathering in the zone',
  'crowdMovement': 'Crowd movement',
  'immobileLuggage': 'Immobile luggage',
  'isolatedLuggage': 'Isolated luggage',
  'assault': 'Assault',
  'personInDistress': 'Person in distress',
  'fallingPerson': 'Falling person',
  'crashedCamera': 'Crashed camera',
  'zone': 'Zone',
  'laneZoneBeforeStopSign': 'Lane zone before stop sign',
  'laneZoneAfterStopSign': 'Lane zone after stop sign',
  'redLightZone': 'Red light zone',
  'orangeLightZone': 'Orange light zone',
  'greenLightZone': 'Green light zone',
  'laneZoneBeforeTrafficLight': 'Lane zone before traffic light',
  'laneZoneAfterTraffecLight': 'Lane zone after traffic light',
  'person': 'Person',
  'car': 'Car',
  'heavyVehicleTruck': 'Heavy vehicle: Truck',
  'motocycleScooterBicycle': 'Motorcycle / Scooter / Bicycle',
  'animal': 'Animal',
  'luggage': 'Luggage',
  'allDay': 'All day',
  'From': 'From',
  'upTo': 'Up to',
  'minimumTime': 'Minimum time',
  'seconds': 'second(s)',
  'minimumNumberOfPeopleToConsiderACrowd': 'Minimum number of people to consider a crowd',
  'violenceSensitivtySettingBetweenOneAndTen': 'Violence sensitivity setting (between 1 and 10)',
  'chooseZone': 'Choose Zone',
  'activateRealTimeScenario': 'Activate scenario',
  'eventNotCorrectlyFilledIn': '(Event(s) not correctly filled in)',
  'lastestRealTimeScenarios': 'Latest scenarios',
  'date': 'Date',
  'name': 'Name',
  'description': 'Description',
  'zone': 'Zones',
  'eventType': 'Event type',
  'tags': 'Tag(s)',
  'from': 'from',
  'to': 'to',
  'camera': 'Camera',
  'status': 'Status',
  'xxxAlerts': 'alerts',
  'results': 'Results',
  'action': 'Action',
  'cancel': 'Cancel',
  'areYouSureYouWantToDoThis': 'Are you sure you want to do this?',
  'doYouReallyWantToCancelThisAlert': 'Do you really want to cancel this alert?',
  'thisActionIsIrreversible': 'This action is irreversible.',
  'cancelAlert': 'Cancel Alert',
  'close': 'Close',
  'periode': 'Periode',
  'definePeriode': 'Define periode',
  'definePeriodeOfSearch': 'of research',
  'download': 'Download',
  'cancel': 'Cancel',
  'noAlert': `Pas d'alerte`,
}

export default scenario
