import axios from 'axios'

export const fetchAllUsers = () =>
  axios.get('/api/users').then(({ data }) => data)

export const createUser = (infos) =>
  axios
    .post(`/api/users/`, infos)
    .then(({ data }) => data)

export const fetchUser = id =>
  axios
    .get(`/api/users/${id}`)
    .then(({ data }) => data)

export const updateUser = (id, updates) =>
  axios
    .put(`/api/users/${id}`, updates)
    .then(({ data }) => data)

export const updateMe = updates =>
  axios
    .put(`/api/users/me`, updates)
    .then(({ data }) => data)

export const deleteUser = userId =>
  axios
    .delete(`/api/users/${userId}`)
    .then(({ data }) => data)
