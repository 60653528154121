import axios from 'axios'

export const addRewind = params =>
  axios
    .post(`/api/rewinds`, params)
    .then(({ data }) => data)

export const fetchMyRewinds = ({ limit }) => {
  let url = `/api/rewinds`

  if (limit)
    url = `/api/rewinds?limit=${limit}`

  return axios
    .get(url)
    .then(({ data }) => data)
}

export const fetchMyRewind = ({ rewindId }) =>
  axios
    .get(`/api/rewinds/${rewindId}`)
    .then(({ data }) => data)

export const fetchVideoList = (cameraId, startDateTimeStamp, endDateTimeStamp) =>
  axios
    .post(`/api/rewinds/video`, { cameraId: cameraId, startDateTimeStamp: startDateTimeStamp, endDateTimeStamp: endDateTimeStamp })
    .then(({ data }) => data)

export const fetchVideoListLive = (cameraId, startDateTimeStamp) =>
  axios
    .post(`/api/rewinds/lastVideo`, { cameraId: cameraId, startDateTimeStamp: startDateTimeStamp })
    .then(({ data }) => data)