const clear = () => {
    return localStorage.clear();
}

const setItem = (key, value) => {
    return localStorage.setItem(key, value);
}

const removeItem = (key) => {
    return localStorage.removeItem(key);
}


const setLanguage = (lang) => {
    return localStorage.setItem("lang", lang);
}

const getLanguage = () => {
    return localStorage.getItem("lang");
}

export const localStorageServices = {
    clear,
    setItem,
    removeItem,
    setLanguage,
    getLanguage
}

export default localStorageServices