import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import styled, { keyframes } from 'styled-components'

const hintAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BottomBar = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  align-items: center;
  position: absolute;
  padding: 0 10px;
  box-sizing: border-box;
`

const PlayButton = styled.div`
  cursor: pointer;
  padding: 10px;
`

const animationDuration = 800

const Hint = styled.div`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  animation: ${hintAnimation} ${animationDuration}ms;
`

const Times = styled.span`
  color: white;
`

function VideoControls({ isPlaying, onChange, onPlayPause, time, duration }) {
  const [showHint, setShowHint] = React.useState(false)
  React.useEffect(() => {
    setShowHint(true)
    setTimeout(() => setShowHint(false), animationDuration)
  }, [isPlaying])

  const progress = (time / duration) * 100

  function format(seconds) {
    return [
      `${Math.floor(seconds / 60)}`.padStart(2, '0'),
      `${Math.floor(seconds % 60)}`.padStart(2, '0'),
    ].join(':')
  }

  return (
    <>
      <Background onClick={onPlayPause}>
        {showHint && (
          <Hint>
            <FontAwesomeIcon
              icon={isPlaying ? faPlay : faPause}
              size="2x"
              color="white"
            />
          </Hint>
        )}
      </Background>
      <BottomBar>
        <PlayButton onClick={onPlayPause}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} color="white" />
        </PlayButton>
        <div
          style={{
            flex: 1,
            padding: '0 30px',
          }}
        >
          <ProgressBar onChange={onChange} progress={progress} />
        </div>
        <Times>
          {format(time)}/{format(duration)}
        </Times>
      </BottomBar>
    </>
  )
}

export default VideoControls

function ProgressBar({ progress, onChange }) {
  const target = React.useRef(null)

  function changeProgress(e) {
    if (!target.current) return
    const { x, width } = target.current.getBoundingClientRect()
    onChange((e.pageX - x) / width)
  }

  React.useEffect(() => {
    window.addEventListener('mousemove', changeProgress)
    return () => window.removeEventListener('mousemove', changeProgress)
  }, [target])

  React.useEffect(() => {
    function mouseUp() {
      document.body.style.userSelect = 'initial'
      document.body.style.pointerEvents = 'initial'
      target.current = null
    }
    window.addEventListener('mouseup', mouseUp)
    return () => window.removeEventListener('mouseup', mouseUp)
  }, [target])

  function onMouseDown(e) {
    target.current = e.target
    document.body.style.userSelect = 'none'
    document.body.style.pointerEvents = 'none'
    changeProgress(e)
  }

  return (
    <div
      onMouseDown={onMouseDown}
      style={{
        cursor: 'pointer',
        width: '100%',
        height: 6,
        borderRadius: 5,
        position: 'relative',
        background: 'rgba(255, 255, 255, 0.4)',
      }}
    >
      <div
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          width: 5,
          height: 20,
          top: '50%',
          transform: 'translateY(-50%)',
          background: 'white',
          borderRadius: 5,
          left: `${progress}%`,
        }}
      />
      <div
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          background: '#50C5F3',
          borderRadius: 5,
          width: `${progress}%`,
        }}
      ></div>
    </div>
  )
}
