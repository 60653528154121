import React, { useRef, useState, useEffect, useContext } from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import axios from 'axios'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'

import { base64toBlob } from '../../../utils'

import * as services from '../services'

import SearchContext from '../contexts/SearchContext'

import { PageSection } from '../components/PageSection'
import { H1, P, H3, Error } from '../components/Typography'
import { Button, SmallButton } from '../components/Button'
import { Modal, ModalTitle, ModalBody } from '../components/Modal'
import { AddCircleFilledIcon, CloseIcon, Form } from '../components/design-system'
import { TextInput, Select } from '../components/Input'
import { CameraTooltipButton } from '../components/Tooltip'
import SearchCamerasSection from '../components/SearchCamerasSection'

import EditCamera from '../components/EditCamera'
import SectorCreation from '../components/SectorCreation'
import { deleteCamera } from '../services'

const Options = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Option = styled.li`
  display: inline-block;
  margin: 0 10px 0 0;
`

const StyledAddCircleFilledIcon = styled(AddCircleFilledIcon)`
  cursor: pointer;
  transition: color .2s ease-in;

  &:hover {
    color: ${({ theme }) => theme.primaryHover};
  }
`

const DeleteMultipleCameraModal = ({
  selectedCameras,
  setDeletingMultiple,
  handleDeleteMultiple,
  deletingMultiple
}) => {
  const [deleting, setDeleting] = useState(false)

  const handleCancel = () => {
    setDeletingMultiple(false)
  }

  const handleDelete = () => {
    setDeleting(true)
    handleDeleteMultiple(selectedCameras)
    setDeleting(false)
  }

  return (
    <Modal
      isOpen={deletingMultiple}
      onCloseClick={handleCancel}
      onBackgroundClick={handleCancel}
      onEscapeKeydown={handleCancel}
    >
      <ModalTitle>Êtes-vous sûr de vouloir faire ça ?</ModalTitle>
      <ModalBody>
        <P>
          Supprimer cette configuration ces caméras supprimera également toutes les videos et recherches associées.
          <br />Cette action est irréversible.
          <br />
          <b>ATTENTION:</b> Après suppression de ces caméras, l'enregistrement des vidéos sera arrêté et les modifications seront prises en compte après un court instant.
        </P>
        <Options>
          <Option>
            <Button red
              spin={deleting}
              disabled={deleting || process.env.NODE_ENV === 'demonstration'}
              onClick={handleDelete}
            >
              Supprimer
            </Button>
          </Option>
          <Option>
            <Button onClick={handleCancel}>Annuler</Button>
          </Option>
        </Options>
      </ModalBody>
    </Modal>
  )
}

const DeleteCameraModal = ({
  cameraToDelete,
  setCameraToDelete,
  deleteCamera,
  fetchCameras,
  setAllSectors
}) => {
  const [deleting, setDeleting] = useState(false)

  const handleCancel = () => setCameraToDelete(null)

  const handleDelete = () => {
    setDeleting(true)

    deleteCamera(cameraToDelete.id)
      .then(() => {
        fetchCameras()

        services
          .fetchAllSectors({})
          .then(({ requests }) => {
            setAllSectors(requests)
            handleCancel()

            setDeleting(false)
          })
      })
      .catch(error => {
        console.error(error)

        setDeleting(false)
      })
  }

  return (
    <Modal
      isOpen={cameraToDelete && cameraToDelete.id}
      onCloseClick={handleCancel}
      onBackgroundClick={handleCancel}
      onEscapeKeydown={handleCancel}>
      <ModalTitle>Êtes-vous sûr de vouloir faire ça ?</ModalTitle>
      <ModalBody>
        <P>
          Supprimer cette configuration de caméra supprimera également toutes les videos et recherches associées.
          <br />Cette action est irréversible.
          <br />
          <b>ATTENTION:</b> Après suppression de cette caméra, l'enregistrement des vidéos sera arrêté et les modifications seront prises en compte après un court instant.
        </P>
        <Options>
          <Option>
            <Button red
              spin={deleting}
              disabled={deleting || process.env.NODE_ENV === 'demonstration'}
              onClick={handleDelete}
            >
              Supprimer
            </Button>
          </Option>
          <Option>
            <Button onClick={handleCancel}>Annuler</Button>
          </Option>
        </Options>
      </ModalBody>
    </Modal>
  )
}


const DeleteSectorModal = ({
  sectorToDelete,
  setSectorToDelete,
  updateAllSectors,
  setDeletedFromSector,
  setAddedToSector
}) => {
  const [deleting, setDeleting] = useState(false)

  const handleCancel = () => {
    setDeletedFromSector(false)
    setAddedToSector(false)

    setSectorToDelete(null)
  }

  const handleDelete = () => {
    setDeleting(true)
    services.deleteSector(sectorToDelete?.id).then(() => {
      handleCancel()
      setSectorToDelete(null)
      setDeleting(false)
      updateAllSectors()
    })
  }

  return (
    <Modal
      isOpen={sectorToDelete && sectorToDelete?.id}
      onCloseClick={handleCancel}
      onBackgroundClick={handleCancel}
      onEscapeKeydown={handleCancel}
    >
      <ModalTitle>Êtes-vous sûr de vouloir faire ça ?</ModalTitle>
      <ModalBody>
        <P>
          Souhaitez-vous vraiment supprimer le secteur <b>{sectorToDelete?.name}</b> ?
          <br />Cette action est irréversible.
        </P>
        <Options>
          <Option>
            <Button red
              spin={deleting}
              disabled={deleting || process.env.NODE_ENV === 'demonstration'}
              onClick={handleDelete}
            >
              Supprimer
            </Button>
          </Option>
          <Option>
            <Button onClick={handleCancel}>Annuler</Button>
          </Option>
        </Options>
      </ModalBody>
    </Modal>
  )
}

const InputColumns = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1.5rem;

  & > *:not(:nth-child(1)) {
    width: 260px;
  }

  & > *:nth-child(1) {
    width: auto;
  }
`

const SearchCamerasSectionContainer = styled.div`
  z-index: 10;
  width: 96%;
  margin-top: 0 !important;
  position: fixed;
  height: 300px;
  background-color: #fff;
  bottom: 0;
  opacity: 1;
  height: ${({ toggle }) => toggle ? 'auto' : '0'};
  padding: ${({ toggle }) => toggle ? '2rem 2%' : '0'};
  opacity: ${({ toggle }) => toggle ? '1' : '0'};
  ${({ toggle }) => toggle && 'margin-top: 2rem;'}
  transition: opacity .6s ease-in-out, padding .6s ease-in-out;
  -moz-box-shadow: inset 0px -13px 17px -12px rgba(0,0,0,0.2), inset 0px 13px 17px -12px rgba(0,0,0,0.2);
  -webkit-box-shadow: inset 0px -13px 17px -12px rgba(0,0,0,0.2), inset 0px 13px 17px -12px rgba(0,0,0,0.2);
  box-shadow: inset 0px -13px 17px -12px rgba(0,0,0,0.2), inset 0px 13px 17px -12px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${SmallButton} {
    padding: .9rem .9rem;
  }

  ${InputColumns}, button {
    ${({ toggle }) => !toggle && 'display: none;'};
  }
`

const SectorsPageSection = styled(PageSection)`
  border-bottom none;
  ${({ toggle, bottomOffset }) => (
    toggle && `margin-bottom: ${bottomOffset}px };`
  )
  };
  transition: margin-bottom .6s ease-in-out;
`

const StyledH3 = styled(H3)`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  letter-spacing: 0;
`

const CamerasDisplay = styled.div`
  position: absolute;
  top: 1.9rem;
  right: 0;
  display: flex;
  column-gap: .8rem;
`

const DisplayOption = styled.img`
  filter: ${({ show }) => !show ? 'grayscale(1)' : 'none'};

  cursor: pointer;
`

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 1rem;
  right: 1rem;

  &:hover {
    cursor: pointer;
  }
`

const DeleteContainer = styled.div`
  display: flex;
  padding: 2rem 0 0;
  justify-content: center;
  align-items: center;
`

const PreviewContainer = styled.div`
  width: 220px;
  height: 124px;
  overflow: hidden;

  & .thumbnail {
    display: block;
  }

  &:hover {
    & .thumbnail {
      display: none;
    }
  }
`

const StyledMapContainer = styled(MapContainer)`
  position: relative;
  width: 220px;
  height: 124px;
  z-index: 1;
  background-color: #000;
`

const ThumbnailContainer = styled.div`
  position: relative;
  max-width: 220px;
  max-height: 124px;
  background-color: #000;
`

const ThumbnailImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const Info = styled.b`
  overflow-wrap: anywhere;
`

const CameraTooltipButtonColor = styled(CameraTooltipButton)`
  color: ${({ color, theme }) => {
    if (color === 'red') return theme.errorRed
    if (color === 'primary') return theme.primaryColor
    return '#fff'
  }};
`

const StyledUploadConfigForm = styled(Form)`
  margin: 1rem 0;
`

const StyledUploadConfigLabel = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #bbb;
  display: inline-block;
  color: #555;
  font-size: 13px;
  border-radius: 4px;
  font-family: Blinker;
  letter-spacing: 1px;
  padding: 10px 30px;
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    border: 1px solid #000;
    color: #000;
  }
`

const StyledUploadConfigInput = styled.input`
  display: none;
`

export default () => {
  const [{ cameras }, { fetchCameras }] = useContext(SearchContext)
  const [testing, setTesting] = useState(false)
  const [creating, setCreating] = useState(false)
  const [uriToTest, setUriToTest] = useState('rtsp://')
  const [testingError, setTestingError] = useState(null)
  const [display, setDisplay] = useState('mosaic')

  const [deletingMultiple, setDeletingMultiple] = useState(null)
  const [cameraToDelete, setCameraToDelete] = useState(null)
  const [selectedCamera, setSelectedCamera] = useState(null)
  const [selectedCameras, setSelectedCameras] = useState([])

  const [sectorToDelete, setSectorToDelete] = useState(null)
  const [selectedSector, setSelectedSector] = useState('')
  const [allSectors, setAllSectors] = useState(null)
  const [newSector, setNewSector] = useState('')
  const [onCreateSector, setOnCreateSector] = useState(false)

  const [bottomOffset, setBottomOffset] = useState(0)

  const [addedToSector, setAddedToSector] = useState(false)
  const [deletedFromSector, setDeletedFromSector] = useState(false)

  const camerasActions = useRef(null)

  useEffect(() => {
    if (camerasActions && camerasActions.current)
      setBottomOffset(camerasActions.current.offsetHeight)
  }, [selectedCameras])

  let canceller = axios.CancelToken.source()

  const updateAllSectors = () => {
    services.fetchAllSectors({})
      .then(({ sectors }) => {
        setAllSectors(sectors)
      })
  }

  useEffect(() => {
    updateAllSectors()
    fetchCameras()
  }, [])

  const onSubmitTest = async e => {
    e.preventDefault()
    let error = false
    await axios.post('/api/cameras/uri', {
      params: {
        uri: String(uriToTest)
      }
    }).then(({ data }) => {
      if (data?.id) {
        error = true
        setTestingError(`URL déjà utilisée par la caméra "${data?.name}".`)
      } else {
        error = false
        setTestingError(null)
      }
    }).catch((thrown) => {
      error = true
      if (axios.isCancel(thrown)) {
        setTestingError('URL non disponible ou problème de flux caméra.')
      }
    })

    if (!error) {
      setTesting(true)

      services
        .testCamera({ input: uriToTest }, canceller.token)
        .then(({ img: thumbnail }) => {
          setSelectedCamera({
            input: uriToTest,
            thumbnail: 'data:image/jpeg;base64,' + thumbnail,
            detect: true,
            saveVideo: true
          })
          setTesting(false)
          setTestingError(null)
          setCreating(true)

          canceller = axios.CancelToken.source()
        })
        .catch((thrown) => {
          if (!axios.isCancel(thrown)) {
            setTesting(false)
            setTestingError('Communication avec la caméra impossible.')
          }

          canceller = axios.CancelToken.source()
        })
    } else {
      setTesting(false)
    }
  }

  const handleDeleteMultiple = cameras => {
    setDeletingMultiple(true)

    services.deleteMultipleCamera(cameras)
      .then(() => {
        fetchCameras()

        services
          .fetchAllSectors({})
          .then(({ requests }) => {
            setAllSectors(requests)
          })

        setSelectedCameras([])
        setDeletingMultiple(false)
      })
      .catch(error => {
        console.error(error)

        setDeletingMultiple(false)
      })
  }

  const handleDeleteCamera = camera => setCameraToDelete(camera)

  const handleModifyCamera = camera => {
    setCreating(false)
    setSelectedCamera(camera)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleSectorName = value => {
    const regexTest = /(^[a-zA-Z0-9\_\-\ ]+$)|^$/g.test(value);

    if (regexTest) setNewSector(value)
  }

  const onSubmitSector = (e, withCameras) => {
    e.preventDefault()
    e.stopPropagation()

    if (newSector.length <= 0) {
      return
    }

    setOnCreateSector(true)

    if (newSector) {
      services.addSector({
        name: newSector,
        cameras: (
          selectedCameras && selectedCameras?.length > 0 && withCameras
            ? selectedCameras.join(',')
            : null
        )
      })
        .then(() => {
          setOnCreateSector(false)

          setSelectedCameras([])
          setNewSector('')
          setSelectedSector('')
          updateAllSectors()
        })
        .catch(err => {
          console.error(err)

          setOnCreateSector(false)

          setSelectedCameras([])
          setNewSector('')
          setSelectedSector('')
          updateAllSectors()
        })
    }
  }

  const AddButton = ({ withCameras }) => {
    return (
      <StyledAddCircleFilledIcon
        onClick={e => onSubmitSector(e, withCameras)}
      />
    )
  }

  const TooltipContent = ({ camera }) => {
    let long = -1;
    let lat = -1;

    return (
      <>
        <div>
          <b>{camera.censoredInput}</b>
          <br />
          <PreviewContainer>
            <ThumbnailContainer className={camera.coordinates && 'thumbnail'}>
              {camera.thumbnailFilename && (
                <ThumbnailImg
                  src={`${process.env.NODE_ENV != 'production' ? `${window.location.protocol}//${window.location.hostname}:${process.env.NODE_PORT}` : window.location.origin}/thumbnails/${camera.thumbnailFilename}.${camera.thumbnailExtension}`}
                />
              )}
            </ThumbnailContainer>
            {camera.coordinates &&
              camera.coordinates &&
              camera.coordinates.length > 0 &&
              camera.coordinates.includes('/') &&
              camera.coordinates[camera.coordinates.indexOf('/') + 1] &&
              (long = parseFloat(camera.coordinates.split('/')[0])) &&
              (lat = parseFloat(camera.coordinates.split('/')[1])) && (
                <StyledMapContainer
                  key={`map-${lat}-${long}`}
                  center={[lat, long]}
                  zoom={15}
                  dragging={false}
                  doubleClickZoom={false}
                  scrollWheelZoom={false}
                  attributionControl={false}
                  zoomControl={false}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[lat, long]} />
                </StyledMapContainer>
              )}
          </PreviewContainer>
          <b>Secteurs:</b>{' '}
          {camera.sectors
            .map(({ name }) => name)
            .join(', ')
            .slice(0, -2)}
          <br />
          <b>Pré-traitement VPI:</b> {camera.anpr_real_time ? 'Oui' : 'Non'}
          <br />
          <b>Pré-traitement Investigation:</b> {camera.detect_real_time ? 'Oui' : 'Non'}
          <br />
          <b>Description:</b>{' '}
          {camera.description ? (
            camera.description
          ) : (
            <i>Pas de description disponible.</i>
          )}
          <br />
        </div>
        <CameraTooltipButtonColor
          color="primary"
          onClick={() => handleModifyCamera(camera)}
        >
          Modifier
        </CameraTooltipButtonColor>
        <CameraTooltipButtonColor
          color="red"
          onClick={() => handleDeleteCamera(camera)}
        >
          Supprimer
        </CameraTooltipButtonColor>
      </>
    )
  }

  const onAddToSector = () => {
    setDeletedFromSector(false)
    setAddedToSector(false)

    services.updateSector(selectedSector, {
      addCameras: (
        selectedCameras && selectedCameras?.length > 0
          ? selectedCameras.join(',')
          : null
      )
    })
      .then(() => {
        updateAllSectors()
        setAddedToSector(true)
      })
  }

  const onDeleteFromSector = () => {
    setDeletedFromSector(false)
    setAddedToSector(false)

    services.updateSector(selectedSector, {
      removeCameras: (
        selectedCameras && selectedCameras?.length > 0
          ? selectedCameras.join(',')
          : null
      )
    })
      .then(() => {
        updateAllSectors()
        setDeletedFromSector(true)
      })
  }

  const onClickUploadConfig = upload => {
    const data = new FormData()

    data.append('file', upload)

    services
      .uploadIniCameras(data)
      .then(() => {
        fetchCameras()

        services
          .fetchAllSectors({})
          .then(({ requests }) => {
            setAllSectors(requests)
          })
      })
  }

  return (
    <>
      <H1>Caméras</H1>
      <PageSection
        title='Ajouter/Modifier une caméra'
        icon='/images/filters.svg'
        description='Ajouter une caméra via son RTSP. Modifiez via les caméras enregistrées ci-dessous.'
      >
        <EditCamera
          createCamera={services.createCamera}
          creating={creating}
          fetchCameras={fetchCameras}
          onSubmitTest={onSubmitTest}
          onCancelTest={() => canceller.cancel()}
          selectedCamera={selectedCamera}
          setCreating={setCreating}
          setSelectedCamera={setSelectedCamera}
          setUriToTest={setUriToTest}
          testing={testing}
          testingError={testingError}
          uriToTest={uriToTest}
          updateCamera={services.updateCamera}
        />
      </PageSection>
      <PageSection
        title='Caméras enregistrées'
        icon='/images/camera.png'
      >
        <CamerasDisplay>
          <DisplayOption
            src='images/cameras_list.svg'
            show={display === 'list'}
            onClick={() => setDisplay('list')}
          />
          <DisplayOption
            src='images/cameras_mosaic.svg'
            show={display === 'mosaic'}
            onClick={() => setDisplay('mosaic')}
          />
        </CamerasDisplay>
        <SearchCamerasSection
          standalone
          thumbnail={display === 'mosaic' ? true : false}
          camerasList={cameras.filter(({ source }) => source !== 'file')}
          toggle={false}
          deleteCamera={handleDeleteCamera}
          modifyCamera={handleModifyCamera}
          selectedCameras={selectedCameras}
          setSelectedCameras={setSelectedCameras}
          sectors={allSectors}
          tooltipContent={TooltipContent}
        />
        {
          selectedCameras.length > 0 && (
            <DeleteContainer>
              <SmallButton
                onClick={() => setDeletingMultiple(true)}
                color='#F61A1A'
                hoverColor='#cc1212'
              >
                Supprimer {
                  selectedCameras?.length && selectedCameras?.length > 1
                    ? 'caméras'
                    : 'caméra'
                }
              </SmallButton>
            </DeleteContainer>
          )
        }
      </PageSection>
      <SectorsPageSection
        bottomOffset={false}
        title='Gestion des secteurs'
        icon='/images/localization.svg'
        description={<>
          <strong>Les secteurs sont un regroupement de caméras prédéfini, ils facilitent vos recherches. </strong>
          Créer vos secteurs puis placez-y les caméras concernées en les sélectionnant via la section « Caméras enregistrées » ci-dessus.
        </>}
        toggle={selectedCameras && selectedCameras?.length !== 0}
      >
        <SectorCreation
          allSectors={allSectors}
          newSector={newSector}
          setNewSector={setNewSector}
          handleSectorName={handleSectorName}
          onSubmitSector={onSubmitSector}
          addButton={AddButton}
          onCreateSector={onCreateSector}
          setSectorToDelete={setSectorToDelete}
        />
      </SectorsPageSection>
      <SectorsPageSection
        bottomOffset={bottomOffset}
        title='Sauvegarde'
        icon='/images/save.svg'
        description={
          <>
            Vous pouvez télécharger et importer la configuration des caméras et des secteurs ici au format .ini (Les secteurs ou caméras possédant un nom similaires à ceux existant dans l'application sont ignorés).
          </>
        }
        toggle={selectedCameras && selectedCameras?.length !== 0}
      >
        <Button
          onClick={
            () =>
              services
                .downloadIniCameras()
                .then(() => console.log('downloaded'))
          }
        >
          Télécharger une config (.ini)
        </Button>
        <StyledUploadConfigForm>
          <StyledUploadConfigLabel htmlFor="upload-config">
            Importer une config (.ini)
          </StyledUploadConfigLabel>
          <StyledUploadConfigInput
            id="upload-config"
            disabled={false}
            label="Ajouter une config"
            primary
            accept=".ini"
            onChange={
              event => {
                event.preventDefault()

                const upload = event.target.files[0]

                onClickUploadConfig(upload)

                event.target.value = null
              }
            }
            type="file"
          />
        </StyledUploadConfigForm>
      </SectorsPageSection>
      <DeleteCameraModal
        cameraToDelete={cameraToDelete}
        setCameraToDelete={setCameraToDelete}
        deleteCamera={services.deleteCamera}
        fetchCameras={fetchCameras}
        setAllSectors={setAllSectors}
      />
      <DeleteSectorModal
        sectorToDelete={sectorToDelete}
        setSectorToDelete={setSectorToDelete}
        updateAllSectors={updateAllSectors}
        setDeletedFromSector={setDeletedFromSector}
        setAddedToSector={setAddedToSector}
      />
      <DeleteMultipleCameraModal
        selectedCameras={selectedCameras}
        setSelectedCameras={setSelectedCameras}
        handleDeleteMultiple={handleDeleteMultiple}
        setDeletingMultiple={setDeletingMultiple}
        deletingMultiple={deletingMultiple}
      />
      <SearchCamerasSectionContainer
        ref={camerasActions}
        toggle={selectedCameras && selectedCameras?.length !== 0}
      >
        <StyledCloseIcon onClick={() => {
          setDeletedFromSector(false)
          setAddedToSector(false)
          setSelectedSector('')

          setSelectedCameras([])
        }} />
        <StyledH3>
          {
            selectedCameras?.length && selectedCameras?.length > 1
              ? `${selectedCameras?.length} caméras selectionnées`
              : '1 caméra selectionnée'
          }
        </StyledH3>
        <StyledH3>
          [&nbsp;
          {
            selectedCameras.length > 0
            && (
              cameras
                .filter(camera => selectedCameras.includes(camera.id))
                .map(camera => camera.name)
                .join(', ')
            )
          }
          &nbsp;]
        </StyledH3>
        <InputColumns>
          <form onSubmit={e => onSubmitSector(e, true)}>
            <TextInput
              label='Créer un secteur avec les caméras'
              disabled={onCreateSector}
              icon={() => <AddButton withCameras={true} />}
              value={newSector}
              onChange={v => handleSectorName(v)}
            />
          </form>
        </InputColumns>
        <InputColumns>
          <Select
            value={selectedSector}
            onChange={v => setSelectedSector(v)}
          >
            <option key='base' value={null}>Sélection d'un secteur</option>
            {
              allSectors &&
              allSectors.length > 0 &&
              allSectors.map((sector, i) => (
                <option
                  key={`${sector.name}-${i}`}
                  value={sector.id}
                >
                  {sector.name}
                </option>
              ))
            }
          </Select>
          <SmallButton onClick={e => onAddToSector(e)}>
            Ajouter au secteur
          </SmallButton>
          <SmallButton
            onClick={e => onDeleteFromSector(e)}
            color='#F61A1A'
            hoverColor='#cc1212'
          >
            Supprimer du secteur
          </SmallButton>
        </InputColumns>
        {
          addedToSector && (
            <H3>Ajout effectué !</H3>
          )
        }
        {
          deletedFromSector && (
            <H3>Suppression effectuée !</H3>
          )
        }
      </SearchCamerasSectionContainer>
    </>
  )
}
