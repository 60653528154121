import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'
import SRModal, { BaseModalBackground } from 'styled-react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { H3 } from './Typography'

const StyledModal = SRModal.styled`
  position: relative;
  width: 80%;
  max-width: 960px;
  margin: 2.5% auto;
  background-color: #fff;
  border-radius: 3px;
  padding: 20px;
  z-index: 1030 !important;
`

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.primaryColor};
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  right: 5px;
  top: 5px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`

const SpecialModalBackground = styled(BaseModalBackground)`
  z-index: 1030;
`

export const Modal = ({ onCloseClick, children, isOpen }) => (
  <AnimatePresence>
    {isOpen && (
      <StyledModal
        as={motion.div}
        initial={{ opacity: 0, y: 200 }}
        exit={{ opacity: 0, y: 200, transition: { duration: 0.2 } }}
        enter={{ opacity: 1, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: 'spring', damping: 10, mass: 0.5 }}
        isOpen
      >
        <CloseButton onClick={onCloseClick}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        {children}
      </StyledModal>
    )}
  </AnimatePresence>
)

export const ModalTitle = (props) => <H3 {...props} />

export const ModalBody = (props) => <div {...props} />
