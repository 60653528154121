import axios from 'axios'

export const login = (email, password) =>
  axios
    .post('/api/auth', { email, password })
    .then(({ data }) => data)

export const logout = () =>
  axios.delete('/api/auth')

export const register = body =>
  axios.post('/api/auth/register', body).then(({ data }) => data)

export const requestPassword = body =>
  axios
    .post(`/api/auth/request_password`, body)
    .then(({ data }) => data)

export const resetPassword = body =>
  axios
    .post(`/api/auth/reset_password`, body)
    .then(({ data }) => data)
