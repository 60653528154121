export const lPR = {
    add: "Ajouter",
    all: "Périmètre entier",
    completed: "Terminé",
    date: "Date",
    description: "Description",
    defineTheSearchPeriod: "Définissez la période de recherche.",
    descriptionOfTheSearch: "Déscription de la recherche.",
    end: "Fin",
    enterThePlatesToBeSearchedIfYouOnlyHavePartOfThePlate: "Indiquez les plaques à rechercher. Si vous ne disposez que d’une partie de la plaque, veillez à positionner les numéros dont vous disposez à leur place exacte et inscrivez « * » à la place des autres. Par exemple pour une plaque française à 7 caractères, si vous ne disposez que du premier caractère « A » et des quatrième et cinquième caractères « 1 » et « 2 », renseignez « A**12** ».",
    filterTheCamerasToBeSearchedIfNecessaryGroupSeveralCamerasByAreaViaTheCamerasMenu: "Filtrer les caméras concernées par la recherche. Au besoin, regroupez plusieurs caméras par secteurs via le",
    nemuCamera: "menu Caméras",
    from: "Du :",
    hideCameras: "Masquer les caméras",
    showCameras: "Afficher les caméras",
    name: "Nom",
    nameSearch: "Nommer la recherche",
    newLPRSearch: "Nouvelle recherche VPI",
    noPlateSelectedTheSearchWillBePerformedOnAllPlates: "Aucune plaque sélectionnée, la recherche s'effectuera sur la totalité des plaques (\« ******* \»).",
    perimeter: "Périmètre",
    period: "Période",
    plates: "Plaques",
    redDotsIndicateRealTimeProcessingOnTheCorrespondingCamera: "Les pastilles rouges indiquent le traitement en temps réel sur la caméra concernée.",
    results: "Résultats",
    searchesInProgress: "Recherches en cours",
    start: "Début",
    startSearch: "Lancer la recherche",
    status: "Status",
    time: "Heure",
    to: "Au:",
    type: "Type",
    viewFullHistory: "Voir l'historique complet",
    xxxCameras: "xxx caméras",
    NoResultat: "Aucun résultat",
    delete: "Supprimer",
}
export default lPR