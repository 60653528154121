import { createGlobalStyle, ThemeProvider } from 'styled-components'

const theme = {
  primaryColor: '#50C5F3',
  primaryHover: '#47B5E1',
  black: '#000000',
  white: '#ffffff',
  gray: '#e5e5e5',
  dustyGray: '#999999',
  alto: '#d8d8d8',
  orange: '#ff9f00',
  red: '#e33131',
  dangerRed: '#d60909',
  errorRed: '#f61a1a',
  validGreen: '#23DF21',
  blue: '#00c7f8',
  pictonBlue: '#50c5f3',
  progress: {
    color: '#00c7f8',
    backgroundColor: '#e5e5e5',
  },
  checkbox: {
    borderColor: '#e5e5e5',
    label: {
      color: '#777',
    }
  },
  label: {
    color: '#777',
    backgroundColor: '#fff',
    dark: {
      color: '#e5e5e5',
      backgroundColor: '#000',
    }
  },
  input: {
    borderColor: '#d8d8d8',
    backgroundColor: 'transparent',
    disabled: {
      color: '#d8d8d8',
      backgroundColor: '#fafafa',
    },
    label: {
      backgroundColor: '#ffffff'
    },
    dark: {
      color: '#e5e5e5',
      borderColor: '#e5e5e5',
    },
  },
  navbar: {
    backgroundColor: '#000000',
    color: '#ffffff',
    subnav: {
      backgroundColor: '#000000',
      color: '#ffffff',
    },
    link: {
      hoverColor: '#47B5E1',
      activeColor: '#50C5F3',
      color: '#ffffff'
    },
  },
  ul: {
    li: {
      collapsed: {
        color: '#999999',
      }
    }
  },
  plan: {
    headColor: '#144E95',
    shadowColor: '#808080'
  },
  fileDropzone: {
    borderColor: '#d8d8d8'
  }
}

// Don't add external fonts here, use the `fonts/index.css` file instead
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Blinker;
    font-weight: 300;
    src: url("./fonts/blinker/Blinker-Light.ttf");
  }
  @font-face {
    font-family: Blinker;
    font-weight: 500;
    src: url("./fonts/blinker/Blinker-SemiBold.ttf");
  }
  @font-face {
    font-family: Blinker;
    font-weight: 500;
    src: url("./fonts/blinker/Blinker-Bold.ttf");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 400;
    src: url("./fonts/roboto/Roboto-Regular.ttf");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 500;
    src: url("./fonts/roboto/Roboto-Bold.ttf");
  }
  @font-face {
    font-family: Saira;
    font-weight: 400;
    src: url("./fonts/saira/Saira-Regular.ttf");
  }
  @font-face {
    font-family: Saira;
    font-weight: 900;
    src: url("./fonts/saira/Saira-ExtraBold.ttf");
  }

  :root, body {
    height: 100%;
    font-size: 16px;
  }
  body {
    line-height: 1.6;
    font-weight: normal;
    font-family: Roboto, "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #000;
  }
  #app {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

export {
  theme,
  ThemeProvider,
  GlobalStyle
}
