export const alertResultAlert = {
    alertsResults: "Alerts &gt; Results",
    allSequences: "All sequences",
    back: "Back",
    cameras: "Cameras",
    camera: "Camera",
    cancel: "Cancel",
    closePane: "Close pane",
    falsePositives: "False positives",
    favorites: "Favorites",
    filterByCamera: "Filter by camera",
    filterByType: "Filter by type",
    filterByView: "Filter by view",
    hidden: "Hidden",
    highestToLowestScore: "Highest to lowest score",
    launchedOn: "Launched on",
    lowestToHighestScore: "Lowest to highest score",
    markAllAsSeen: "Mark all as seen",
    newestToOldest: "Newest to oldest",
    nonViewedOnly: "Non-viewed only",
    oldestToNewest: "Oldest to newest",
    refresh: "Refresh",
    results: "Results",
    sortBy: "Sort by",
    viewedOnly: "Viewed only",
    filterBySector: "Filter by sector",
    scoreBaseOn: "Score base on",
    model: "Model",
    color: "Coulor",
    all: "All",
    search: "Search",
    result: "Result",
    treatmentVideoInProgress: "Video treatment in progress",
    noResultDoNewSearch: "No result. Do a",
    newSearch: "new search",
    removeFalsePositive: "Remove false positiv",
    wouldYouLikeToRemoveThisResultFromTheFalsePositiveList: "Would you like to remove this result from the false positive list ?",
    uniqueThisResult: "This result unique",
    allFalsePositiveAssociate: "All false positive associate",
    close: "Close",
    FilterByYype: "Filter by type",
    details: "Details",
    LPR: "LPR",
    startAt: "Start at",
    closeThsSlide: "close slide",
    alerts: "Alerts",
    noResult: "No result",
    back: 'Back',
}
export default alertResultAlert