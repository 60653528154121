import React from 'react'
import { Link } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'
import { useSubscription } from '../services/fetch'
// import { scenarioMarkRead, scenarioNotification } from '../services/scenarios'
import { SmallButton } from './Button'
import { P } from './Typography'

function NotificationsScenarios() {
  return null

  const { data, refetch } = useSubscription(scenarioNotification, 10000)
  const [loading, setLoading] = React.useState(false)

  async function reload() {
    setLoading(true)
    await scenarioMarkRead({
      alertIds: data.results.map((d) => d.requestId),
    })
    await refetch()
    setLoading(false)
  }

  return (
    <AnimatePresence>
      {data?.results?.length && (
        <motion.div
          initial={{
            opacity: 0,
            y: 300,
          }}
          exit={{
            opacity: 0,
            y: 300,
          }}
          enter={{
            opacity: 1,
            y: 0,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            type: 'spring',
            damping: 16,
          }}
          style={{
            zIndex: 9999,
            position: 'fixed',
            bottom: 20,
            left: 20,
            background: 'rgba(255, 255, 255, 0.9)',
            border: '1px solid black',
            borderRadius: 5,
            padding: 10,
            maxWidth: 400,
          }}
        >
          <P style={{ margin: 0 }}>
            {data.results.length}{' '}
            {data.results.length === 1
              ? 'nouvelle alerte'
              : 'nouvelles alertes'}{' '}
            sur les scénarios.
          </P>
          <P style={{ margin: 0 }}>
            Entrée détectée sur{' '}
            {data.results.length === 1 ? 'la zone' : 'les zones'}{' '}
            {data.results.map((d) => '"' + d.zoneName + '"').join(', ')}
          </P>
          <div>
            {data.results.map((d) => (
              <SmallButton
                as={Link}
                style={{ display: 'inline-block', marginBottom: 10 }}
                key={d.requestId}
                to={'/scenarios/' + d.requestId}
              >
                Consulter "{d.zoneName}"
              </SmallButton>
            ))}
          </div>
          <SmallButton disabled={loading} onClick={reload}>
            Marquer comme vu
          </SmallButton>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default NotificationsScenarios
