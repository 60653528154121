import axios from 'axios'

export const addScenariosAlerts = (body) =>
  axios.post(`/api/scenarios/alerts`, body).then(({ data }) => data)

export const addScenariosSearchs = (body) =>
  axios.post(`/api/scenarios/searchs`, body).then(({ data }) => data)

export const scenarioNotification = () =>
  axios.get(`/api/scenarios/new-result`).then(({ data }) => data)

export const updateScenariosAlertsSeen = ({ alertId }) =>
  axios.post(
    `/api/scenarios/alerts/${alertId}/seen`,
    { seen: true }
  ).then(({ data }) => data)

export const updateScenariosAlertsHidden = ({ alertId }) =>
  axios.post(
    `/api/scenarios/alerts/${alertId}/hidden`,
    { hidden: true }
  ).then(({ data }) => data)

export const updateScenariosAlertFavorite = ({ resultId, favorite }) =>
  axios
    .post(
      `/api/scenarios/alerts/results/${resultId}/favorite`,
      { favorite }
    )
    .then(({ data }) => data)

export const updateScenariosAlertHidden = ({ resultId, hidden }) =>
  axios
    .post(
      `/api/scenarios/alerts/results/${resultId}/hidden`,
      { hidden }
    )
    .then(({ data }) => data)

export const updateScenariosAlertFalsePositive = ({ id, action }) =>
  axios
    .put(
      `/api/scenarios/alerts/results/${id}/false-positive`,
      {},
      { params: { action } }
    ).then(({ data }) => data)

export const updateScenariosSearchFavorite = ({
  resultId,
  favorite
}) =>
  axios
    .post(
      `/api/scenarios/searchs/results/${resultId}/favorite`,
      { favorite }
    )
    .then(({ data }) => data)

export const updateScenariosSearchHidden = ({ resultId, hidden }) =>
  axios
    .post(
      `/api/scenarios/searchs/results/${resultId}/hidden`,
      { hidden }
    )
    .then(({ data }) => data)

export const getScenariosRequests = () =>
  axios.get(`/api/scenarios/requests`).then(({ data }) => data)

export const getScenariosAlerts = () =>
  axios.get(`/api/scenarios/alerts`).then(({ data }) => data)

export const getScenarioZones = () =>
  axios.get(`/api/scenarios/zones`).then(({ data }) => data)

export const getScenarioResults = (requestId, filters) =>
  axios
    .get(
      `/api/scenarios/${requestId}?${Object.entries(filters)
        .map((kv) => kv.join('='))
        .join('&')}`
    )
    .then(({ data }) => data)

export const cancelScenariosAlert = (id) =>
  axios.get(`/api/scenarios/cancel/alert/${id}`).then(({ data }) => data)

export const cancelScenariosSearch = (id) =>
  axios.get(`/api/scenarios/cancel/search/${id}`).then(({ data }) => data)

export const fetchScenarioRequest = (id) =>
  axios.get(`/api/scenarios/searchs/${id}`).then(({ data }) => data)

export const fetchScenariosAlertsCount = () =>
  axios.get(`/api/scenarios/alerts/results/count`).then(({ data }) => data)

export const updateScenarioZones = ({ zones, configId, image }) =>
  axios
    .put(
      `/api/scenarios/zones`,
      { zones, configId, image }
    )
    .then(({ data }) => data)

export const downloadScenariosStats = (scenarioId, scenarioName) =>
  axios({
    url: `/api/scenarios/download/${scenarioId}`,
    method: 'GET',
    responseType: 'blob'
  })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))

      const link = document.createElement('a')

      link.href = url

      const date = new Date().toLocaleDateString("fr-FR").replaceAll('/', '-')

      link.setAttribute(
        'download',
        `neurocop-scenarios-stats_${scenarioName}_${date}.csv`
      )

      document.body.appendChild(link)

      link.click()

      link.parentNode.removeChild(link)
    })
