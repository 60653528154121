const popUpZoneDefinitionForAScenario = {
    addZone: "Add zone",
    cancel: "Cancel",
    createEditZones: "Create / Edit zones",
    drawThePerimeterOfEachDetectionZoneByPreciselyPositioningThePointsWithTheTool: "Draw the perimeter of each detection zone by precisely positioning the points with the tool.",
    drawZone: "Draw zone",
    moveOnView: "Move on view",
    resetView: "Reset view",
    saveChanges: "Save changes",
    shortcut: "Shortcut: -",
    shortcut: "Shortcut: +",
    shortcutHoldDownCtrl: "Shortcut: hold down Ctrl",
    shortcutHome: "Shortcut: Home",
    zoneName: "Zone name",
    zoomIn: "Zoom in",
    zoomOut: "Zoom out",
    validateZone: "validate the zone",
    noZone: "No zone defined",
    noZoneDescription: "Click the button below to add a new zone.",
    validZone: "Validate your area by clicking on the button below.",
    shortcut: 'Shortcut',
}
export default popUpZoneDefinitionForAScenario