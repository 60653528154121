import React, { useState } from 'react'
import styled from 'styled-components'
import { faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CF } from '../components/Grid'
import { Modal, ModalTitle, ModalBody } from '../components/Modal'
import { H1, Link } from '../components/Typography'
import Pagination from '../components/Pagination'
import Player from '../components/Player'
import { useFetch } from '../services/fetch'
import { useParams } from 'react-router'
import { Button, SmallButton } from '../components/Button'
import CancelAlertModal from '../components/CancelAlertModal'
import SequencesFootages from '../components/SequencesFootages'
import Filters, { useFilters } from '../components/SequencesFilters'
import {
  cancelDetectionAlert,
  fetchDetectionAlert,
  fetchDetectionAlertResults,
  markAllDetectionAlertResultsAsSeen,
  putDetectionAlertFavorite,
  putDetectionAlertHide,
} from '../services'
import SequencesDetails from '../components/SequencesDetails'
import { useTranslation } from 'react-i18next'

const SequencesContainer = styled.div`
  width: 90%;
  margin: auto;
`

const MargedContainer = styled(CF)`
  margin: 15px;
  text-align: center;
  label {
    margin-right: 15px;
  }
`

const Headings = styled.div`
  margin: 30px 0;
`

function AlertResults() {
  const { t } = useTranslation()
  const { id } = useParams()

  const [canceledFalsePositiveId, setCanceledFalsePositiveId] = useState(null)
  const closeModalFalsePositive = () => setCanceledFalsePositiveId(null)

  const [activeSequence, setActiveSequence] = useState(null)
  function closeModal() {
    setActiveSequence(null)
  }

  const { filters, setFilter } = useFilters()

  const alertFetch = useFetch(() => fetchDetectionAlert(id), [id])
  const detectionsFetch = useFetch(
    () =>
      fetchDetectionAlertResults({
        id,
        offset: ((filters.page || 1) - 1) * 20,
        ...filters,
      }),
    [id, filters]
  )

  async function markAllSeen() {
    await markAllDetectionAlertResultsAsSeen(id)
    await detectionsFetch.refetch()
  }

  async function toggleHidden(footage) {
    footage.hidden = !footage.hidden
    await putDetectionAlertHide(footage)
    detectionsFetch.setData({ ...detectionsFetch.data })
  }

  async function toggleFavorite(footage) {
    footage.favorite = !footage.favorite
    await putDetectionAlertFavorite(footage)
    detectionsFetch.setData({ ...detectionsFetch.data })
  }

  async function toggleDeclareFalsePositive(footage) {
    console.log(footage)
    // See removeFalsePositive elsewhere
  }

  async function removeFalsePositive(action) {
    // See removeFalsePositive elsewhere
  }

  const sequences = detectionsFetch.data?.results || []
  const stats = detectionsFetch.data?.stats || []
  const nbResults = detectionsFetch.data?.nbResults || 0
  const filterParams = alertFetch.data?.alert || { cameras: '' }

  return (
    <>
      <SequencesDetails request={filterParams} loading={alertFetch.loading} />
      <MargedContainer>
        <Link to="/alert">{t('alertResultAlert.alerts')}</Link>
        <span>{' >'} {t('alertResultAlert.result')}</span>
      </MargedContainer>
      <Headings>
        <H1 style={{ margin: '0' }}>{t('alertResultAlert.result')}</H1>
        <Link
          as="p"
          onClick={detectionsFetch.refetch}
          style={{ textAlign: 'center', cursor: 'pointer' }}
        >
          <FontAwesomeIcon
            icon={detectionsFetch.loading ? faSpinner : faDownload}
            spin={detectionsFetch.loading}
            style={{ marginRight: '5px' }}
          />
          {!detectionsFetch.loading && t('alertResultAlert.refresh')}
        </Link>
      </Headings>
      <SequencesContainer>
        {nbResults > 0 && (
          <>
            <SmallButton
              onClick={markAllSeen}
              style={{ marginBottom: 10 }}
              primary
            >
              {t('alertResultAlert.markAllAsSeen')}
            </SmallButton>
            <Filters
              params={{ ...filterParams, has_seen: true }}
              filters={filters}
              setFilter={setFilter}
            />
          </>
        )}
        <SequencesFootages
          loading={detectionsFetch.loading}
          footages={sequences}
          pauseAtFirstDetection
          onFootageClick={setActiveSequence}
          onFootageHide={toggleHidden}
          onFootageAddToFavorite={toggleFavorite}
          onFootageDeclareFalsePositive={toggleDeclareFalsePositive}
          getSequenceUrl={(footage) =>
            `/api/detections/alert/${footage.configId}/${footage.trackId}/sequence`
          }
          renderNoResult={() => (
            <p style={{ textAlign: 'center' }}>{t('alertResultAlert.noResult')}.</p>
          )}
        />
      </SequencesContainer>
      {sequences.length > 0 && (
        <Pagination
          activePage={filters.page}
          onChange={setFilter('page')}
          itemsCountPerPage={20}
          totalItemsCount={nbResults}
          pageRangeDisplayed={5}
        />
      )}
      <div style={{ textAlign: 'center', marginBottom: 10 }}>
        <SmallButton as={Link} to="/alert" primary>
          {t('alertResultAlert.back')}
        </SmallButton>
        <CancelAlertModal
          style={{ marginLeft: 10 }}
          onCancel={() => cancelDetectionAlert(id)}
        />
      </div>
      <Modal
        isOpen={!!activeSequence}
        onCloseClick={closeModal}
        onBackgroundClick={closeModal}
        onEscapeKeydown={closeModal}
        style={{ maxHeight: '90%', overflow: 'hidden' }}
      >
        {activeSequence && (
          <Player
            videos={activeSequence.videos}
            showControls
          />
        )}
      </Modal>
      <Modal
        isOpen={canceledFalsePositiveId}
        onCloseClick={closeModalFalsePositive}
        onBackgroundClick={closeModalFalsePositive}
        onEscapeKeydown={closeModalFalsePositive}
        style={{ maxHeight: '90%', overflow: 'hidden' }}
      >
        <ModalTitle>{t('alertResultAlert.removeFalsePositive')}</ModalTitle>
        <ModalBody>
          <p>
            {t('alertResultAlert.wouldYouLikeToRemoveThisResultFromTheFalsePositiveList')} ?
          </p>
        </ModalBody>
        <ModalOptions>
          <ModalOption>
            <Button onClick={() => removeFalsePositive('remove')}>
              {t('alertResultAlert.uniqueThisResult')}
            </Button>
          </ModalOption>
          <ModalOption>
            <Button onClick={() => removeFalsePositive('removeAll')}>
              {t('alertResultAlert.allFalsePositiveAssociate')}
            </Button>
          </ModalOption>
          <ModalOption>
            <Button onClick={closeModalFalsePositive}>{t('alertResultAlert.close')}</Button>
          </ModalOption>
        </ModalOptions>
      </Modal>
    </>
  )
}

export default AlertResults
