import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const Plan = styled.div`
  margin: auto;
  box-shadow: 1px 1px 20px ${({ theme }) => theme.plan.shadowColor};
`

export const PlanHead = styled.div`
  font-family: Blinker;
  font-weight: 700;
  font-size: 1.5rem;
  text-decoration: none;
  color: #ffffff;
  background-color: ${
    ({ theme, color }) => color ? color : theme.plan.headColor
  };
  text-align: center;
  padding: 0.375rem;
`

PlanHead.propTypes = {
  children: PropTypes.string,
  headColor: PropTypes.string
}

const PlanValueContainer = styled.div`
  text-align: center;
  border-top: 1px solid #d3d3d3;
`

const PlanValueTitle = styled.h5`
  font-family: Saira;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  margin: 0.75rem 0 0.25rem 0;
  padding: 2%;
`

const PlanValueDescription = styled.div`
  font-family: Saira;
  font-weight: normal;
  font-size: 0.9rem;
  text-align: center;
  margin: 0.75rem 0 0.25rem 0;
  padding: 2%;
`

export const PlanValue = ({ title, description }) => (
  <PlanValueContainer>
    <PlanValueTitle>{title}</PlanValueTitle>
    <PlanValueDescription>{description}</PlanValueDescription>
  </PlanValueContainer>
)

PlanValue.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  hideHyphen: PropTypes.bool
}
