const personalInformations = {
    company: "Company",
    confirmChanges: "Confirm changes",
    email: "Email",
    firstName: "First name",
    lastName: "Last name",
    personalInformation: "Personal information",
    phoneNumber: "Phone number",
    searchHistory: "Search history",
    userManagement: "User management"
}
export default personalInformations