import React, { useContext } from 'react'
import EnvContext from '../../contexts/EnvContext'
import VideoControls from './VideoControls'
import VideoGroup from './videoGroup/VideoGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion/dist/framer-motion'

const Player = React.memo(
  ({
    videos = [],
    startAt,
    endAt,
    highlightPolygonStart,
    highlightPolygonEnd,
    poster,
    polygon,
    showControls = false,
    pauseAtFirstDetection,
  }) => {

    const [{ env }] = useContext(EnvContext)

    const [playerState, setPlayerState] = React.useState({
      playing: showControls,
      time: 0,
      duration: 0,
      loading: true,
    })
    const canvasRef = React.useRef()
    const polygonArray = React.useMemo(
      () => polygon?.split(';').map((xy) => xy.split(',')),
      [polygon]
    )

    function lerp(a, b, percent) {
      return (1 - percent) * a + b * percent
    }

    function lerpDetection(a, b, percent) {
      return {
        x: lerp(a.x, b.x, percent),
        y: lerp(a.y, b.y, percent),
        width: lerp(a.width, b.width, percent),
        height: lerp(a.height, b.height, percent),
        velocityX: lerp(a.velocityX, b.velocityX, percent),
        velocityY: lerp(a.velocityY, b.velocityY, percent),
      }
    }

    function findDetection(videoId, time) {
      let nextId = videoId
      let currentId = videoId

      let nextIndex = videos[nextId].detections.findIndex((d) => d.time > time)
      let currentIndex = nextIndex - 1
      let next = videos[nextId].detections[nextIndex]
      let current = videos[currentId].detections[currentIndex]

      // Next detection is in the next video
      if (!next && videos[nextId + 1]) {
        nextId += 1
        next = videos[nextId].detections[0]
        current =
          videos[currentId].detections[videos[currentId].detections.length - 1]
      }

      // Current detection is in the previous video
      if (!current && videos[currentId - 1]) {
        currentId -= 1
        next = videos[nextId].detections[0]
        current =
          videos[currentId].detections[videos[currentId].detections.length - 1]
      }

      return { next, current, nextId, currentId }
    }

    const lastVideo = videos[videos.length - 1]
    const lastDetection =
      lastVideo?.detections?.[lastVideo.detections.length - 1]

    function arrow(context, fromx, fromy, tox, toy) {
      const headlen = 10 // length of head in pixels
      const dx = tox - fromx
      const dy = toy - fromy
      const angle = Math.atan2(dy, dx)
      context.moveTo(fromx, fromy)
      context.lineTo(tox, toy)
      context.lineTo(
        tox - headlen * Math.cos(angle - Math.PI / 6),
        toy - headlen * Math.sin(angle - Math.PI / 6)
      )
      context.moveTo(tox, toy)
      context.lineTo(
        tox - headlen * Math.cos(angle + Math.PI / 6),
        toy - headlen * Math.sin(angle + Math.PI / 6)
      )
    }

    const baseUrl = `${env.NODE_ENV != 'production' ? `${env.NODE_CONTAINER_DEV_PORT_APP ? window.location.origin.replace(env.NODE_CONTAINER_DEV_PORT_APP, env.NODE_CONTAINER_DEV_PORT_API) : window.location.origin + ":" + env.NODE_CONTAINER_DEV_PORT_API}` : window.location.origin}/media/`

    const videoGroup = useVideoGroup({
      canvasRef,
      startAt: startAt || { index: 0, time: videos[0]?.detections[0]?.time - 1 },
      endAt: endAt || {
        index: videos.length - 1,
        time: lastDetection?.time + 1,
      },
      videoUrls: videos.map((video) => `${baseUrl}${video.filename}`),
      onLoading: () =>
        setPlayerState((prevState) => ({ ...prevState, loading: true })),
      onLoaded: () =>
        setPlayerState((prevState) => ({ ...prevState, loading: false })),
      onTimeUpdate({ timeGrouped, durationGrouped }) {
        setPlayerState((prevState) => ({
          ...prevState,
          time: timeGrouped,
          duration: durationGrouped,
        }))
      },
      onDraw(grp) {
        const {
          ctx,
          currentVideo,
          currentVideoId,
          pause,
          play,
          time,
          timeGrouped,
          toTimeGrouped,
        } = grp
        ctx.drawImage(
          currentVideo,
          0,
          0,
          currentVideo.videoWidth,
          currentVideo.videoHeight,
          0,
          0,
          ctx.canvas.width,
          ctx.canvas.height
        )

        let detection = null
        const detectDistance = time - videos[0].detections[0].time
        if (
          pauseAtFirstDetection &&
          detectDistance > 0.01 &&
          detectDistance < 0.05
        ) {
          // Pause at first detection
          detection = videos[0].detections[0]
          if (!grp.currentVideo.paused) {
            grp.time = videos[0].detections[0].time + 0.011
            pause()
            setTimeout(() => {
              grp.time = videos[0].detections[0].time + 0.051
              play()
            }, 2000)
          }
        } else {
          // Detection interpolation when tracking
          const { next, current, nextId, currentId } = findDetection(
            currentVideoId,
            time
          )
          if (current && next) {
            const currentT = toTimeGrouped(current.time, currentId)
            const nextT = toTimeGrouped(next.time, nextId)
            const percent = (timeGrouped - currentT) / (nextT - currentT)
            detection = lerpDetection(current, next, percent)
          }
        }

        let isIntersecting =
          currentVideoId >= highlightPolygonStart?.index &&
          currentVideoId <= highlightPolygonEnd?.index &&
          !(
            (currentVideoId == highlightPolygonStart?.index &&
              time < highlightPolygonStart?.time) ||
            (currentVideoId == highlightPolygonEnd?.index &&
              time > highlightPolygonEnd?.time)
          )

        if (detection) {
          let { x, y, width, height, velocityX = 0, velocityY = 0 } = detection
          x *= ctx.canvas.width / currentVideo.videoWidth
          y *= ctx.canvas.height / currentVideo.videoHeight
          width *= ctx.canvas.width / currentVideo.videoWidth
          height *= ctx.canvas.height / currentVideo.videoHeight
          velocityX *= ctx.canvas.width / currentVideo.videoWidth
          velocityY *= ctx.canvas.height / currentVideo.videoHeight

          ctx.globalAlpha = 0.6
          ctx.fillStyle = 'black'
          ctx.fillRect(0, 0, x, ctx.canvas.height)
          ctx.fillRect(
            x + width,
            0,
            ctx.canvas.width - (x + width),
            ctx.canvas.height
          )
          ctx.fillRect(x - 0.11, 0, width + 0.2, y)
          ctx.fillRect(
            x - 0.11,
            y + height,
            width + 0.2,
            ctx.canvas.height - (y + height)
          )
          ctx.globalAlpha = 1.0

          ctx.lineWidth = 2
          ctx.strokeStyle = 'red'
          ctx.beginPath()
          ctx.strokeRect(x, y, width, height)
          ctx.closePath()
          ctx.stroke()
          if (isIntersecting) {
            ctx.fillStyle = '#ff000066'
            ctx.fillRect(x, y, width, height)
          }

          if (velocityX && velocityY) {
            ctx.beginPath()
            arrow(
              ctx,
              x + width / 2,
              y + height / 2,
              x + width / 2 + velocityX,
              y + height / 2 + velocityY
            )
            ctx.closePath()
            ctx.stroke()
          }
        }

        if (polygon) {
          ctx.globalAlpha = 1.0

          ctx.fillStyle = isIntersecting ? '#00ff0055' : '#ef6c0055'
          ctx.strokeStyle = isIntersecting ? 'green' : '#ef6c00'
          ctx.lineWidth = 1

          ctx.beginPath()
          polygonArray.map(([x, y], j) => {
            if (j === 0)
              ctx.moveTo(
                (x * ctx.canvas.width) / currentVideo.videoWidth,
                (y * ctx.canvas.height) / currentVideo.videoHeight
              )
            else
              ctx.lineTo(
                (x * ctx.canvas.width) / currentVideo.videoWidth,
                (y * ctx.canvas.height) / currentVideo.videoHeight
              )
          })
          ctx.closePath()
          ctx.stroke()
          ctx.fill()
        }
      },
    })

    React.useEffect(() => {
      if (showControls && videoGroup.current) {
        videoGroup.current.load()
        videoGroup.current.play()
      }
    }, [showControls, videoGroup.current])

    const [displayPoster, setDisplayPoster] = React.useState(true)

    return (
      <motion.div
        onMouseOver={
          !showControls
            ? () => {
              videoGroup.current?.load()
              videoGroup.current?.play()
              setDisplayPoster(false)
            }
            : undefined
        }
        onMouseOut={
          !showControls
            ? () => {
              videoGroup.current?.stop()
              setDisplayPoster(true)
            }
            : undefined
        }
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          background: '#222',
          boxShadow: '1px 1px 20px inset black',
          overflow: 'hidden',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          width: '100%',
          height: '100%',
        }}
      >
        <canvas
          ref={canvasRef}
          style={{
            width: '100%',
          }}
        />
        {showControls && (
          <VideoControls
            isPlaying={playerState.playing}
            onPlayPause={() => {
              videoGroup.current.currentVideo.paused
                ? videoGroup.current.play()
                : videoGroup.current.pause()
              setPlayerState((prevState) => ({
                ...prevState,
                playing: !prevState.playing,
              }))
            }}
            onChange={(progress) => {
              videoGroup.current.progress = progress
              setPlayerState((prevState) => ({
                ...prevState,
                playing: false,
                time: videoGroup.current.timeGrouped,
              }))
            }}
            time={playerState.time}
            duration={playerState.duration}
          />
        )}
        {playerState.loading && (
          <FontAwesomeIcon
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
            }}
            icon={faSpinner}
            spin
          />
        )}
        {!showControls && displayPoster && <Poster src={poster} />}
      </motion.div>
    )
  }
)

export default Player

const Poster = React.memo(({ src }) => {
  const [loading, setLoading] = React.useState(true)

  if (!src) {
    return null
  }
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'black',
      }}
    >
      <img
        src={src}
        onLoad={() => setLoading(false)}
        style={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          width: '100%',
        }}
      />
      {loading && (
        <FontAwesomeIcon
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          icon={faSpinner}
          spin
        />
      )}
    </div>
  )
})

/**
 *
 */
function useVideoGroup({
  onDraw,
  onLoading,
  onLoaded,
  onTimeUpdate,
  canvasRef,
  ...options
}) {
  const videoGroup = React.useRef()

  React.useEffect(() => {
    if (options.videoUrls.length === 0) return

    videoGroup.current = new VideoGroup({
      ...options,
      canvas: canvasRef.current,
    })
    videoGroup.current.on('draw', onDraw)
    videoGroup.current.on('loading', onLoading)
    videoGroup.current.on('loaded', onLoaded)
    videoGroup.current.on('timeupdate', onTimeUpdate)
    return () => {
      videoGroup.current.off('draw', onDraw)
      videoGroup.current.off('loading', onLoading)
      videoGroup.current.off('loaded', onLoaded)
      videoGroup.current.off('timeupdate', onTimeUpdate)
      videoGroup.current.destroy()
    }
  }, [options.videoUrls.length])

  return videoGroup
}
