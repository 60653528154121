import React from 'react'
import styled from 'styled-components'
// import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts'

export const DiskUsage = () => {
  // const [{ cameras }, { fetchCameras }] = useContext(SearchContext)

  return (
    <div></div>
  )
}
