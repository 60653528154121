const header = {
  'live': 'Live',
  'replay': 'Replay',
  'exports': 'Exports',
  'investigations': 'Investigations',
  'lpr': 'LPR',
  'alerts': 'Alerts',
  'scenarios': 'Scenarios',
  'map': 'Map',
  'stat': 'Statistique',
  'uploads': 'Uploads',
  'cameras': 'Cameras',
  'resources': 'Ressources',
  'monitoring': 'Monitoring',
}

export default header
