export const investigationResult = {
    allSequences: "Toutes les séquences",
    back: "Retour",
    cameras: "Caméras",
    closePane: "Fermer le volet",
    falsePositives: "Faux positifs",
    favorites: "Favories",
    filterByCamera: "Filter par caméra",
    filterByType: "Filter par type",
    fromXxXxXxToXxXxXx: "Du xx/xx/xx au xx/xx/xx",
    hidden: "Cachées",
    highestToLowestScore: "Du score le plus haut au plus bas",
    lowestToHighestScore: "Du score le plus bas au plus haut",
    newestToOldest: "Du plus récent au plus ancien",
    noResultsFoundPerformANewSearch: "Aucun résultat. Effectuer une nouvelle recherche ?",
    oldestToNewest: "Du plus ancien au plus récent",
    refresh: "Rafraichir",
    results: "Résultas",
    searchResults: "Recherche > Résultats",
    sortBy: "Trier par",
    videoProcessingInProgress: "Traitement des vidéos en cours"
}
export default investigationResult