const searchHistory = {
    action: "Action",
    date: "Date",
    displayXxxxLines: "Display xxxx lines",
    lPRSearch: "LPR search",
    name: "Name",
    perimeter: "Perimeter",
    personalInformation: "Personal information",
    searchByName: "Search by name",
    searchHistory: "Search history",
    standardSearch: "Standard search",
    status: "Status",
    type: "Type",
    userManagement: "User management"
}

export default searchHistory