import React, { useContext } from 'react'
import EnvContext from './../contexts/EnvContext'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'

import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'
import * as L from 'leaflet'
import 'leaflet-defaulticon-compatibility'

import { Checkbox, CheckIcon } from './design-system'
import { ColoredLink, Error } from '../components/Typography'
import { CameraTooltip } from './Tooltip'
import { Container, CenterContainer } from './Grid'
import { SpinnerAnimatedIcon } from '../components/design-system/Icons'

const ExportsCameraSectionContainer = styled.div`
  height: ${({ toggle, standalone }) => (toggle || standalone ? 'auto' : '0')};
  padding: ${({ toggle, standalone }) =>
    toggle && !standalone ? '2rem 0' : '0'};
  opacity: ${({ toggle, standalone }) => (toggle || standalone ? '1' : '0')};
  ${({ toggle }) => toggle && 'margin-top: 2rem;'}
  transition: opacity .6s ease-in-out, padding .6s ease-in-out;
  -moz-box-shadow: inset 0px -13px 17px -12px rgba(0, 0, 0, 0.2),
    inset 0px 13px 17px -12px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0px -13px 17px -12px rgba(0, 0, 0, 0.2),
    inset 0px 13px 17px -12px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px -13px 17px -12px rgba(0, 0, 0, 0.2),
    inset 0px 13px 17px -12px rgba(0, 0, 0, 0.2);
  ${({ standalone }) => (standalone ? 'box-shadow: none;' : '')}
  overflow: visible !important;
`

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > *:not(:first-child) {
    margin-left: 2rem;
  }

  & > *:first-child {
    &::after {
      content: '';
      height: 100%;
      width: 1px;
      background-color: #e5e5e5;
      margin: 0 1rem;
      // border-right: 1px solid #E5E5E5;
      // padding-right: 2rem;
    }
  }
`

const StyledCheckbox = styled(Checkbox)`
  padding: 0.4rem;
`

const StyledContainer = styled(Container)`
  ${({ standalone }) => (standalone ? 'width: 100% !important;' : '')}
`

const AllCamerasContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${({ colNb }) => colNb}, 1fr);
  rows: ${({ rowNb }) => `repeat(${rowNb}, 1fr)`};
  column-gap: 1rem;
  row-gap: 1rem;
  ${({ toggle }) => !toggle && 'visibility: hidden;'}
`

const Check = styled.div`
  position: absolute;
  top: -8px;
  right: -10px;
  width: 1rem;
  height: 1rem;
  background-color: ${({ theme }) => theme.primaryColor};
  border-radius: 100%;
  border: 2px solid #fff;
  display: flex;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
`

const CameraContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  font-family: Blinker;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  text-align: center;
  border: 2px solid
    ${({ theme, selected }) => (selected ? theme.primaryColor : '#999999')};
  color: ${({ selected }) => (selected ? '#fff' : '#999999')};
  background-color: ${({ theme, selected }) =>
    selected ? theme.primaryColor : 'transparent'};
  border-radius: 5px;
  padding: 0.2rem 0;
  transition: all 200ms;
  &:hover {
    border: 2px solid ${({ theme }) => theme.primaryColor};
    color: ${({ theme, selected }) =>
    selected ? undefined : theme.primaryColor};
  }

  ${Check} {
    transform: ${({ selected }) => (selected ? 'scale(1)' : 'scale(1.5)')};
    opacity: ${({ selected }) => (selected ? '1' : '0')};
  }
`

const CameraThumbnailContainer = styled.div`
  max-width: 100%;
  padding: 0.6rem 0.8rem;
  padding-bottom: 0.6rem;
  border: 1px solid #e5e5e5;

  & > div {
    display: flex;
    column-gap: 2rem;
  }

  & > div:first-child {
    flex-direction: columns;
    margin-bottom: 0.5rem;

    label {
      display: inline-flex;
      align-items: center;

      span {
        font-family: Blinker;
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  }

  & > div:nth-child(2) {
    span {
      font-size: 11px;
    }
  }

  & > div:nth-child(3) {
    margin-top: 0.6rem;

    span:last-child {
      color: ${({ theme }) => theme.errorRed};
    }
  }
`

const ThumbnailContainer = styled.div`
  position: relative;
  max-width: 220px;
  max-height: 124px;
  background-color: #000;
`

const ThumbnailImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const Info = styled.b`
  overflow-wrap: anywhere;
`

const PreviewContainer = styled.div`
  width: 220px;
  height: 124px;
  overflow: hidden;

  & .thumbnail {
    display: block;
  }

  &:hover {
    & .thumbnail {
      display: none;
    }
  }
`

const StyledMapContainer = styled(MapContainer)`
  position: relative;
  width: 220px;
  height: 124px;
  z-index: 1;
  background-color: #000;
`

const ExportsCameraSection = ({
  // "Standard" cameras' list props
  camerasList,
  toggle, // can show/hide component with animation
  sectors,
  selectedCamera,
  limit,
  // "Advanced" cameras' list props
  setSelectedCamera,
  enableSelectAll, // display checkbox to select all cameras
  standalone, // no container
  deleteCamera,
  modifyCamera,
  loading,
  thumbnail,
  hideInfo,
  hideControls,
  tooltipContent,
}) => {
  const rowNb = camerasList.length / thumbnail ? 2 : 9

  const [{ env }] = useContext(EnvContext)
  const renderPreview = camera => {
    let lat = -1
    let long = -1

    return (
      <PreviewContainer>
        <ThumbnailContainer className={camera.coordinates && 'thumbnail'}>
          {camera.thumbnailFilename && (
            <ThumbnailImg
              src={`${env.NODE_ENV != 'production' ? `${window.location.protocol}//${window.location.hostname}:${env.NODE_CONTAINER_DEV_PORT_API}` : window.location.origin}/thumbnails/${camera.thumbnailFilename}.${camera.thumbnailExtension}`}
            />
          )}
        </ThumbnailContainer>
        {camera.coordinates &&
          camera.coordinates &&
          camera.coordinates.length > 0 &&
          camera.coordinates.includes('/') &&
          camera.coordinates[camera.coordinates.indexOf('/') + 1] &&
          (long = parseFloat(camera.coordinates.split('/')[0])) &&
          (lat = parseFloat(camera.coordinates.split('/')[1])) && (
            <StyledMapContainer
              key={`map-${lat}-${long}`}
              center={[lat, long]}
              zoom={15}
              dragging={false}
              doubleClickZoom={false}
              scrollWheelZoom={false}
              attributionControl={false}
              zoomControl={false}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={[lat, long]} />
            </StyledMapContainer>
          )}
      </PreviewContainer>
    )
  }

  return (
    <ExportsCameraSectionContainer standalone={standalone} toggle={toggle}>
      <StyledContainer standalone={standalone}>
        {camerasList.length === 0 && (
          <CenterContainer>Aucunes caméras trouvées.</CenterContainer>
        )}
        {camerasList.length !== 0 && (
          <AllCamerasContainer
            toggle={toggle || standalone}
            rowNb={rowNb}
            colNb={thumbnail ? 2 : 9}
          >
            {loading && <SpinnerAnimatedIcon />}
            {!loading &&
              !thumbnail &&
              camerasList.map((camera, i) =>
                tooltipContent ? (
                  <CameraTooltip
                    hideInfo={hideInfo}
                    hideControls={hideControls}
                    camera={camera}
                    key={`${camera.id}-${i}`}
                    content={tooltipContent}
                    renderPreview={renderPreview}
                  >
                    <CameraContainer
                      onClick={() => setSelectedCamera(camera.id)}
                      selected={selectedCamera === camera.id || (selectedCamera && Array.isArray(selectedCamera) && selectedCamera.find(selectedCameraItem => selectedCameraItem === camera.id))}
                      key={`${camera.id}-${i}`}
                    >
                      <Check>
                        <CheckIcon />
                      </Check>
                      {camera.name}
                    </CameraContainer>
                  </CameraTooltip>
                ) : (
                  <CameraContainer
                    onClick={() => setSelectedCamera(camera.id)}
                    selected={selectedCamera === camera.id || (selectedCamera && Array.isArray(selectedCamera) && selectedCamera.find(selectedCameraItem => selectedCameraItem === camera.id))}
                    key={`${camera.id}-${i}`}
                  >
                    <Check>
                      <CheckIcon />
                    </Check>
                    {camera.name}
                  </CameraContainer>
                )
              )}
          </AllCamerasContainer>
        )}
      </StyledContainer>
    </ExportsCameraSectionContainer>
  )
}

ExportsCameraSection.propTypes = {
  // "Standard" cameras' list props
  camerasList: PropTypes.array,
  toggle: PropTypes.bool,
  selectedCamera: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  setSelectedCamera: PropTypes.func,
  sectors: PropTypes.array,
  limit: PropTypes.number,
  // "Advanced" cameras' list props
  standalone: PropTypes.bool,
  enableSelectAll: PropTypes.bool,
  deleteCamera: PropTypes.func,
  modifyCamera: PropTypes.func,
  tooltipContent: PropTypes.func,
  select: PropTypes.bool,
}

ExportsCameraSection.defaultProps = {
  // "Standard" cameras' list props
  camerasList: [],
  sectors: [],
  selectedCamera: null,
  // "Advanced" cameras' list props
  setSelectedCamera: () => { },
  standalone: false,
  toggle: true,
  enableSelectAll: true,
  deleteCamera: () => { },
  modifyCamera: () => { },
  tooltipContent: null,
  select: true,
  hideInfo: false,
  hideControls: false,
}

export default ExportsCameraSection
