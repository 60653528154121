import axios from 'axios'

export const getRequestCancelToken = () => axios.CancelToken.source()

export const formatDate = (timesStampMS) => {
    const dateToString = new Date(timesStampMS)

    const addZeroOnNumber = (value, numberOfDigit) => {
        let result = value;
        if (`${value}`.length === 1 && !numberOfDigit) {
            result = `0${value}`
        }
        if (numberOfDigit && value && value.toString().length < numberOfDigit) {
            for (let i = 0; i < numberOfDigit - value.toString().length; i++) {
                result = `0${result}`
            }
        }
        return result
    }

    return `${addZeroOnNumber(dateToString.getDate())}/` +
        `${addZeroOnNumber(dateToString.getMonth() + 1)}/` +
        `${dateToString.getFullYear()} ` +
        `${addZeroOnNumber(dateToString.getHours())}:` +
        `${addZeroOnNumber(dateToString.getMinutes())}:` +
        `${addZeroOnNumber(dateToString.getSeconds())}:` +
        `${addZeroOnNumber(dateToString.getMilliseconds(), 1).toString().slice(0, 2)}`
}

// 1/12/2024 24:60:60:9999