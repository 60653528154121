const scenario = {
  'scenarioRealTime': 'Scénarios',
  'scenarioInSearch': 'Scénarios',
  'defineScenarioSimpleOrComplexe': 'Définissez des scénarios simples ou complexes de situations anormales ou dangereuses.',
  'defineScenarioSimpleOrComplexeOnBasepredefine': 'Sur base d\'événements prédéfinis',
  'notificationDescriptionNeuroCop': '(personne avec un casque couchée au sol, véhicule renversé...), Neurocop',
  'notificationDescriptionNeuroCopImportant': 'notifiera en cas de déclenchement du scénario.',
  'camera': 'Caméra',
  'chooseCamera': 'Choisissez la caméra concernée',
  'definitionColorShipOrange': 'Les pastilles oranges indiquent la présence de zone sur la caméra concernée.',
  'definitionColorShipRed': 'Les pastilles rouges indiquent que la caméra est en pré-traitement.',
  'hideCamera': 'Masquer les caméras',
  'showCamera': 'Afficher les caméras',
  'show': 'Afficher',
  'unique': 'Uniquement',
  'createModifyZone': 'Créer / Modifier les zones:',
  'defineZoneDetection': 'Définissez avec précision les périmètres',
  'defineZoneDetectionDetail': 'des zones de détection en positionnant les points à l\'aide de l\'outil.',
  'editeZone': 'Editer les zones',
  'zoneName': 'Nom de la zone',
  'defineScenario': 'Définir le scénario',
  'addDetailScenario': 'Indiquez les détails du scénario.',
  'nothingDefineZone': 'Aucune zone définie',
  'defineZoneAboveSection': 'Veuillez définir les zones dans la section ci-dessus.',
  'title': 'Titre',
  'Event': 'Événement',
  'chooseEvent': 'Choisir un évènement',
  'zoneEntry': 'Entrée dans la zone',
  'stopSignCrossing': 'Franchissement de stop',
  'redLightCrossing': 'Franchissement de feu rouge',
  'gatheringInTheZone': 'Regroupement dans la zone',
  'crowdMovement': 'Mouvement de foule',
  'immobileLuggage': 'Bagage immobile',
  'isolatedLuggage': 'Bagage isolé',
  'assault': 'Agression',
  'personInDistress': 'Personne en détresse',
  'fallingPerson': 'Personne qui chute',
  'crashedCamera': 'Caméra accidentée',
  'zone': 'Zone',
  'laneZoneBeforeStopSign': 'Zone de la voie de circulation avant le stop',
  'laneZoneAfterStopSign': 'Zone de la voie circulation après le stop',
  'redLightZone': 'Zone de lumière rouge du feu',
  'orangeLightZone': 'Zone de lumière orange du feu',
  'greenLightZone': 'Zone de lumière verte du feu',
  'laneZoneBeforeTrafficLight': 'Zone de la voie de circulation avant le feu',
  'laneZoneAfterTraffecLight': 'Zone de la voie circulation après le feu',
  'person': 'Personne',
  'car': 'Voiture',
  'heavyVehicleTruck': 'Véhicule lourd : Camionette',
  'motocycleScooterBicycle': 'Moto / Scooter / Vélo',
  'animal': 'Animal',
  'luggage': 'Bagage',
  'allDay': 'Toute la journée',
  'From': 'A partir de',
  'upTo': 'Jusqu\'a',
  'minimumTime': 'Durée minimum',
  'seconds': 'seconde(s)',
  'minimumNumberOfPeopleToConsiderACrowd': 'Nombre de personnes minimum pour considérer une foule',
  'violenceSensitivtySettingBetweenOneAndTen': 'Réglage de la sensibilité à la violence de l\'intelligence artificielle(entre 1 et 10)',
  'chooseZone': 'Choisir Zone',
  'activateRealTimeScenario': 'Activer le scénario',
  'eventNotCorrectlyFilledIn': '(Le ou les évènements ne sont pas correctement remplis)',
  'lastestRealTimeScenarios': 'Scénarios récents',
  'date': 'Date',
  'name': 'Nom',
  'description': 'Description',
  'zone': 'Zones',
  'eventType': 'Type d\'évènement',
  'tags': 'Tag(s)',
  'from': 'de',
  'to': 'à',
  'camera': 'Caméra',
  'status': 'Status',
  'xxxAlerts': 'alertes',
  'results': 'Résultats',
  'action': 'Action',
  'cancel': 'Annuler',
  'areYouSureYouWantToDoThis': 'Êtes-vous sûr de vouloir faire ça ?',
  'doYouReallyWantToCancelThisAlert': 'Souhaitez-vous vraiment annuler cette alerte ?',
  'thisActionIsIrreversible': 'Cette action est irréversible.',
  'cancelAlert': 'Annuler l\'Alerte',
  'close': 'Fermer',
  'periode': 'Pèriode',
  'definePeriode': 'Définissez la période',
  'definePeriodeOfSearch': 'de recherche',
  'download': 'Télécharger',
  'cancel': 'Annuler',
  'noAlert': `No alert`,
}

export default scenario
