import React, { Component } from "react";
import PropTypes from "prop-types";
import paginator from "paginator";
import styled from "styled-components"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'

const StyledPagination = styled.ul`
  display: flex;
  margin: 16px auto;
  padding-left: 0;
  list-style: none;
  border-radius: 4px;
  background-color: #fff;

  -webkit-box-pack: center!important;
  -ms-flex-pack: center!important;
  justify-content: center!important;
`

const Link = styled.a`
  position: relative;
  display: block;
  padding: 8px 12px;
  margin-left: -1px;
  line-height: 1.25;
  color: #74ccf8;
  border: 1px solid #e1e1e1;
  text-decoration: none;

  li:first-child & {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  li:last-child & {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  ${({ isActive }) => isActive && `
    background-color: #74ccf8;
    border-color: #74ccf8;
    color: #fff;
  `}
  ${({ isDisabled }) => isDisabled && `
    background-color: transparent;
    border: 1px solid #e1e1e1;
    color: #a4a6aa;
    cursor: auto;
    pointer-events: none;
  `}
`

class Page extends Component {
  static propTypes = {
    pageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    pageNumber: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    href: PropTypes.string
  };

  static defaultProps = {
    isActive: false,
    isDisabled: false,
    href: "#"
  };

  handleClick(e) {
    const { isDisabled, pageNumber } = this.props;
    e.preventDefault();
    if (isDisabled) {
        return;
    }
    this.props.onClick(pageNumber);
  }

  render() {
    let {
      pageText,
      pageNumber,
      isActive,
      isDisabled,
      href,
      ariaLabel
    } = this.props;

    return (
      <li onClick={this.handleClick.bind(this)}>
        <Link href={href} aria-label={ariaLabel} isActive={isActive} isDisabled={isDisabled}>
          {pageText}
        </Link>
      </li>
    );
  }
}

export default class extends Component {
  static propTypes = {
    totalItemsCount: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    activePage: PropTypes.number,
    itemsCountPerPage: PropTypes.number,
    pageRangeDisplayed: PropTypes.number,
    prevPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    nextPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    lastPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    firstPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    hideDisabled: PropTypes.bool,
    hideNavigation: PropTypes.bool,
    hideFirstLastPages: PropTypes.bool,
    getPageUrl: PropTypes.func
  }

  static defaultProps = {
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    activePage: 1,
    prevPageText: <FontAwesomeIcon icon={faAngleLeft} />,
    firstPageText: <FontAwesomeIcon icon={faAngleDoubleLeft} />,
    nextPageText: <FontAwesomeIcon icon={faAngleRight} />,
    lastPageText: <FontAwesomeIcon icon={faAngleDoubleRight} />,
    hideFirstLastPages: false,
    getPageUrl: (i) => "#"
  }

  isFirstPageVisible(has_previous_page) {
    const { hideDisabled, hideFirstLastPages } = this.props;
    if (hideFirstLastPages || (hideDisabled && !has_previous_page)) return false;
    return true;
  }

  isPrevPageVisible(has_previous_page) {
    const { hideDisabled, hideNavigation } = this.props;
    if (hideNavigation || (hideDisabled && !has_previous_page)) return false;
    return true;
  }

  isNextPageVisible(has_next_page) {
    const { hideDisabled, hideNavigation } = this.props;
    if(hideNavigation || (hideDisabled && !has_next_page)) return false;
    return true;
  }

  isLastPageVisible(has_next_page) {
    const { hideDisabled, hideFirstLastPages } = this.props;
    if (hideFirstLastPages || (hideDisabled && !has_next_page)) return false;
    return true;
  }

  buildPages() {
    const pages = [];
    const {
      itemsCountPerPage,
      pageRangeDisplayed,
      activePage,
      prevPageText,
      nextPageText,
      firstPageText,
      lastPageText,
      totalItemsCount,
      onChange,
      getPageUrl
    } = this.props;

    const paginationInfo = new paginator(
      itemsCountPerPage,
      pageRangeDisplayed
    ).build(totalItemsCount, activePage);

    for (
      let i = paginationInfo.first_page;
      i <= paginationInfo.last_page;
      i++
    ) {
      pages.push(
        <Page
          isActive={i === activePage}
          key={i}
          href={getPageUrl(i)}
          pageNumber={i}
          pageText={i + ""}
          onClick={onChange}
          ariaLabel={`Go to page number ${i}`}
        />
      )
    }

    this.isPrevPageVisible(paginationInfo.has_previous_page) &&
      pages.unshift(
        <Page
          key={"prev" + paginationInfo.previous_page}
          href={getPageUrl(paginationInfo.previous_page)}
          pageNumber={paginationInfo.previous_page}
          onClick={onChange}
          pageText={prevPageText}
          isDisabled={!paginationInfo.has_previous_page}
          ariaLabel="Go to previous page"
        />
      )

    this.isFirstPageVisible(paginationInfo.has_previous_page) &&
      pages.unshift(
        <Page
          key={"first"}
          href={getPageUrl(1)}
          pageNumber={1}
          onClick={onChange}
          pageText={firstPageText}
          isDisabled={!paginationInfo.has_previous_page}
          ariaLabel="Go to first page"
        />
      )

    this.isNextPageVisible(paginationInfo.has_next_page) &&
      pages.push(
        <Page
          key={"next" + paginationInfo.next_page}
          href={getPageUrl(paginationInfo.next_page)}
          pageNumber={paginationInfo.next_page}
          onClick={onChange}
          pageText={nextPageText}
          isDisabled={!paginationInfo.has_next_page}
          ariaLabel="Go to next page"
        />
      )

    this.isLastPageVisible(paginationInfo.has_next_page) &&
      pages.push(
        <Page
          key={"last"}
          href={getPageUrl(paginationInfo.total_pages)}
          pageNumber={paginationInfo.total_pages}
          onClick={onChange}
          pageText={lastPageText}
          isDisabled={
            paginationInfo.current_page === paginationInfo.total_pages
          }
          ariaLabel="Go to last page"
        />
      )

    return pages;
  }

  render() {
    const pages = this.buildPages();
    return <StyledPagination>{pages}</StyledPagination>;
  }
}
