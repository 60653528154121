import React from 'react'
import axios from 'axios'
import styled from 'styled-components'
import SequenceThumbnail from '../components/SequenceThumbnail'

const FootagesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

function SequenceFootages({
  loading,
  footages,
  pauseAtFirstDetection,
  showControls,
  getSequenceUrl,
  renderNoResult,
  onFootageClick,
  onFootageHide,
  onFootageAddToFavorite,
  onFootageDeclareFalsePositive
}) {
  if (loading) {
    return (
      <p style={{ textAlign: 'center' }}>
        Veuillez patienter, récupération des données en cours...
      </p>
    )
  }

  if (footages.length === 0) {
    return renderNoResult()
  }

  return (
    <>
      <FootagesContainer rowNb={footages.length / 4}>
        {footages.map((footage) => (
          <Footage
            key={footage.id || `${footage.trackId}-${footage.configId}`}
            sequenceUrl={getSequenceUrl(footage)}
            footage={footage}
            onClick={onFootageClick}
            onHide={onFootageHide}
            onAddToFavorite={onFootageAddToFavorite}
            onDeclareFalsePositive={onFootageDeclareFalsePositive}
            pauseAtFirstDetection={pauseAtFirstDetection}
            showControls={showControls}
          />
        ))}
      </FootagesContainer>
    </>
  )
}

export default SequenceFootages

function Footage({
  footage,
  sequenceUrl,
  pauseAtFirstDetection,
  showControls,
  onClick,
  onHide,
  onAddToFavorite,
  onDeclareFalsePositive
}) {
  const [sequence, setSequence] = React.useState({})

  React.useEffect(() => {
    axios
      .get(sequenceUrl)
      .then(({ data: { sequence } }) => setSequence(sequence))
  }, [sequenceUrl])

  const at = sequence.videos?.[0]?.at

  return (
    <SequenceThumbnail
      trackId={footage.trackId}
      configId={footage.configId}
      configName={footage.configName}
      at={at}
      confidence={footage.score}
      hasSeen={footage.hasSeen}
      favorite={footage.favorite}
      hidden={footage.hidden}
      falsePositive={footage.falsePositiveZoneId}
      videos={sequence.videos || []}
      poster={sequence.poster}
      showControls={showControls}
      pauseAtFirstDetection={pauseAtFirstDetection}
      onClick={() => onClick({ ...footage, ...sequence, at })}
      onHide={() => onHide(footage)}
      onAddToFavorite={() => onAddToFavorite(footage)}
      onDeclareFalsePositive={() => onDeclareFalsePositive(footage)}
    />
  )
}
