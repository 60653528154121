import React, { useContext, useEffect, useState } from 'react'
import qs from 'qs'
import styled from 'styled-components'
import SearchContext from '../contexts/SearchContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Select } from './Input'
import { useHistory, useLocation } from 'react-router-dom'
import { fetchAllSectors } from '../services'
import { useFetch } from '../services/fetch'

import { useTranslation } from 'react-i18next'

const FiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: start;
  margin-bottom: 1rem;

  & > * {
    margin: 0 0.5rem;
  }

  & > *:first-child {
    margin: 0 0.5rem 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 0.5rem;
  }
`

export function useFilters({
  sortType = 'score:desc',
  sortBy = 'score:confidence',
} = {}) {
  const history = useHistory()
  const location = useLocation()
  const query = qs.parse(location.search.replace(/^\?/, ''))

  const [filters, setFilters] = useState({
    sortType: query.sortType || sortType,
    sortBy: query.sortBy || sortBy,
    cameras: query.cameras || 'all',
    filter: query.filter || 'all',
    sector: query.sector || 'all',
    has_seen: query.has_seen || 'all',
    page: query.page,
  })

  useEffect(() => {
    history.replace({
      pathname: location.pathname,
      search:
        `?sortType=${filters.sortType}` +
        `&sortBy=${filters.sortBy}` +
        `&filter=${filters.filter}` +
        (filters.sector !== 'all' ? `&sector=${filters.sector}` : '') +
        (filters.cameras !== 'all' ? `&cameras=${filters.cameras}` : '') +
        (filters.page !== undefined ? `&page=${filters.page}` : '') +
        (filters.has_seen !== 'all' ? `&has_seen=${filters.has_seen}` : ''),
    })
  }, [filters])

  const setFilter = (filter) => (value) => {
    setFilters((prevState) => ({ ...prevState, page: 1, [filter]: value }))
  }

  return {
    filters,
    setFilter,
  }
}

const Filters = ({ params, filters, setFilter }) => {
  const { t } = useTranslation()
  return (
    <FiltersContainer>
      <Select
        label={t('alertResultAlert.sortBy')}
        value={filters.sortBy}
        onChange={setFilter('sortBy')}
      >
        <option value="score:desc">{t('alertResultAlert.highestToLowestScore')}</option>
        <option value="score:asc">{t('alertResultAlert.lowestToHighestScore')}</option>
        <option value="at:desc">{t('alertResultAlert.newestToOldest')}</option>
        <option value="at:asc">{t('alertResultAlert.oldestToNewest')}</option>
      </Select>
      {params.tag === 'car' && (params.make || params.color) && (
        <Select
          label={t('alertResultAlert.scoreBaseOn')}
          value={filters.sortType}
          onChange={setFilter('sortType')}
        >
          {params.make && params.color && (
            <option value="score:quality">{t('alertResultAlert.model')} / {t('alertResultAlert.color')}</option>
          )}
          {params.make && <option value="score:make">{t('alertResultAlert.sortBy')}{t('alertResultAlert.model')}</option>}
          {params.color && <option value="score:color">{t('alertResultAlert.sortBy')}{t('alertResultAlert.color')}</option>}
        </Select>
      )}
      {params.cameras && (
        <FilterCameras
          params={params}
          value={filters.cameras}
          onChange={setFilter('cameras')}
        />
      )}
      {params.sectors && (
        <FilterSectors
          params={params}
          value={filters.sector}
          onChange={setFilter('sector')}
        />
      )}
      <Select
        label={t('alertResultAlert.FilterByYype')}
        labelOverflow={false}
        value={filters.filter}
        onChange={setFilter('filter')}
      >
        <option key="all" value="all">
          {t('alertResultAlert.allSequences')}
        </option>
        <option key="favorite" value="favorite">
          {t('alertResultAlert.favorites')}
        </option>
        <option key="hidden" value="hidden">
          {t('alertResultAlert.hidden')}
        </option>
        <option key="false-positive" value="false-positive">
          {t('alertResultAlert.falsePositives')}
        </option>
      </Select>
      {params.has_seen && (
        <Select
          label={t('alertResultAlert.filterByView')}
          labelOverflow={false}
          value={filters.has_seen + ''}
          onChange={setFilter('has_seen')}
        >
          <option key="all" value="all">
            {t('alertResultAlert.allSequences')}
          </option>
          <option key="true" value="true">
            {t('alertResultAlert.viewedOnly')}
          </option>
          <option key="false" value="false">
            {t('alertResultAlert.nonViewedOnly')}
          </option>
        </Select>
      )}
    </FiltersContainer>
  )
}

export default Filters

function FilterCameras({ params, value, onChange }) {
  const { t } = useTranslation()
  const [{ cameras }, { fetchCameras }] = useContext(SearchContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchCameras().then(() => setLoading(false))
  }, [])

  const filterCameras = cameras.filter((camera) =>
    params.cameras.split(',').includes(camera.id.toString())
  )

  if (loading) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <FontAwesomeIcon icon={faSpinner} style={{ marginRight: '5px' }} spin />
      </div>
    )
  }

  return (
    <Select
      label={t('alertResultAlert.filterByCamera')}
      labelOverflow={false}
      value={value}
      onChange={onChange}
    >
      <option key="all" value="all">
        {t('alertResultAlert.all')}
      </option>
      {filterCameras.map((camera) => (
        <option key={camera.id} value={camera.id}>
          {camera.name}
        </option>
      ))}
    </Select>
  )
}

function FilterSectors({ params, value, onChange }) {
  const { loading, data } = useFetch(fetchAllSectors, [])
  const { t } = useTranslation()

  if (loading) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '5px' }} />
      </div>
    )
  }

  return (
    <Select
      label={t('alertResultAlert.filterBySector')}
      labelOverflow={false}
      value={value}
      onChange={onChange}
    >
      <option key="all" value="all">
        {t('alertResultAlert.all')}
      </option>
      {data?.sectors?.map((sector) => (
        <option key={sector.id} value={sector.id}>
          {sector.name}
        </option>
      ))}
    </Select>
  )
}
