import React, { useState } from 'react'
import styled from 'styled-components'
import { faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  cancelAnprAlert,
  fetchAnprAlert,
  fetchAnprAlertResults,
  markAllAnprAlertResultsAsSeen,
  putAnprAlertFavorite,
  putAnprAlertHide,
  putAnprAlertResultFalsePositive
} from '../services/anpr-alerts'
import { CF, Row, Hr } from '../components/Grid'
import { H1, P, Link } from '../components/Typography'
import Pagination from '../components/Pagination'
import Player from '../components/Player'
import { Button, SmallButton } from '../components/Button'
import { useFetch, useSubscription } from '../services/fetch'
import { useParams } from 'react-router'
import { Modal, ModalTitle, ModalBody } from '../components/Modal'
import CancelAlertModal from '../components/CancelAlertModal'
import SequencesFootages from '../components/SequencesFootages'
import Filters, { useFilters } from '../components/SequencesFilters'
import SequencesDetails from '../components/SequencesDetails'

const ModalOptions = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ModalOption = styled.li`
  display: inline-block;
  margin: 0 10px 0 0;
`

const MargedContainer = styled(CF)`
  margin: 15px;
  text-align: center;
  label {
    margin-right: 15px;
  }
`

const Headings = styled.div`
  margin: 30px 0;
`

const SequencesContainer = styled.div`
  width: 90%;
  margin: auto;
`

function VpiAlertResults() {
  const { id } = useParams()
  const { filters, setFilter } = useFilters()

  const [
    canceledFalsePositiveId,
    setCanceledFalsePositiveId
  ] = useState(null)
  const closeModalFalsePositive = () => setCanceledFalsePositiveId(null)

  const alertFetch = useFetch(() => fetchAnprAlert(id), [id])
  const [activePlateId, setActivePlateId] = React.useState(null)
  const detectionFetch = useSubscription(
    () => fetchAnprAlertResults({ id, ...filters }),
    3000
  )
  const plates = detectionFetch.data?.plates || {}

  const activePlate = plates[activePlateId]
  
  const searchPlates = alertFetch?.data?.alert?.plates?.split(',') || []

  const [activeSequence, setActiveSequence] = useState(null)
  function closeModal() {
    setActiveSequence(null)
  }

  async function toggleHidden(footage) {
    footage.hidden = !footage.hidden
    await putAnprAlertHide(footage)
    detectionFetch.setData({ ...detectionFetch.data })
  }

  async function toggleFavorite(footage) {
    footage.favorite = !footage.favorite
    await putAnprAlertFavorite(footage)
    detectionFetch.setData({ ...detectionFetch.data })
  }

  async function toggleDeclareFalsePositive(footage) {
    if (!footage.falsePositiveZoneId) {
      footage.falsePositiveZoneId = !footage.falsePositiveZoneId
      await putAnprAlertResultFalsePositive({
        id: footage.id,
        action: 'add'
      })
      detectionFetch.setData({ plates: { ...plates } })
    } else {
      setCanceledFalsePositiveId(footage.id)
    }
  }

  async function removeFalsePositive(action) {
    activePlate.detections = activePlate.detections.map(plate => {
      if (plate.trackId === canceledFalsePositiveId) {
        return {
          ...plate,
          falsePositiveZoneId: false
        }
      }

      return plate
    })
    await putAnprAlertResultFalsePositive({
      id: canceledFalsePositiveId,
      action
    })
    detectionFetch.setData({ plates: { ...plates } })
    closeModalFalsePositive()
  }

  async function markAllSeen() {
    await markAllAnprAlertResultsAsSeen(id)
    await detectionFetch.refetch()
  }

  React.useEffect(() => {
    if (!activePlateId) {
      setActivePlateId(Object.keys(plates)[0])
    }
  }, [activePlateId, plates])

  React.useEffect(() => {
    if (!activePlateId && searchPlates.length > 0) {
      setActivePlateId(searchPlates[0])
    }
  }, [searchPlates])

  const filterParams = alertFetch.data?.alert || { cameras: '' }

  return (
    <>
      <SequencesDetails request={filterParams} loading={alertFetch.loading} />
      <MargedContainer>
        <Link to="/alert">Alertes</Link>
        <span>{' >'} Résultats</span>
      </MargedContainer>
      <Headings>
        <H1 style={{ margin: '0' }}>Résultats</H1>
        <PlatesTabs
          value={activePlateId}
          onChange={setActivePlateId}
          searchPlates={searchPlates}
        />
        <Link
          as="p"
          onClick={detectionFetch.refetch}
          style={{ textAlign: 'center', cursor: 'pointer' }}
        >
          <FontAwesomeIcon
            icon={detectionFetch.loading ? faSpinner : faDownload}
            spin={detectionFetch.loading}
            style={{ marginRight: '5px' }}
          />
          {!detectionFetch.loading && 'Rafraichir'}
        </Link>
      </Headings>
      <SequencesContainer>
        <SmallButton onClick={markAllSeen} style={{ marginBottom: 10 }} primary>
          Tout marquer comme vu
        </SmallButton>
        <Filters
          params={{ ...filterParams, has_seen: true }}
          filters={filters}
          setFilter={setFilter}
        />
        {activePlate?.detections.length === 0 && (
          <P style={{ textAlign: 'center', margin: '25px 0' }}>
            Aucun résultat.
          </P>
        )}
        <PaginatedPlateDetections
          plate={activePlateId}
          detections={activePlate?.detections || []}
          onClickFavorite={toggleFavorite}
          onClickHide={toggleHidden}
          onClickFalsePositive={toggleDeclareFalsePositive}
          onClickSequence={setActiveSequence}
        />
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          <SmallButton as={Link} to="/alert" primary>
            Retour
          </SmallButton>
          <CancelAlertModal
            style={{ marginLeft: 10 }}
            onCancel={() => cancelAnprAlert(id)}
          />
        </div>
      </SequencesContainer>
      <Modal
        isOpen={!!activeSequence}
        onCloseClick={closeModal}
        onBackgroundClick={closeModal}
        onEscapeKeydown={closeModal}
        style={{ maxHeight: '90%', overflow: 'hidden' }}
      >
        {activeSequence && (
          <Player
            videos={activeSequence.videos}
            pauseAtFirstDetection
            showControls
          />
        )}
      </Modal>
      <Modal
        isOpen={!!canceledFalsePositiveId}
        onCloseClick={closeModalFalsePositive}
        onBackgroundClick={closeModalFalsePositive}
        onEscapeKeydown={closeModalFalsePositive}
        style={{ maxHeight: '90%', overflow: 'hidden' }}
      >
        <ModalTitle>Retirer des faux positifs</ModalTitle>
        <ModalBody>
          <p>
            Souhaitez vous retirer ce résultat de la liste des faux positifs?
          </p>
        </ModalBody>
        <ModalOptions>
          <ModalOption>
            <Button onClick={() => removeFalsePositive('remove')}>
              Uniquement ce résultat
            </Button>
          </ModalOption>
          <ModalOption>
            <Button onClick={() => removeFalsePositive('removeAll')}>
              Tout les faux positifs associés
            </Button>
          </ModalOption>
          <ModalOption>
            <Button onClick={closeModalFalsePositive}>Fermer</Button>
          </ModalOption>
        </ModalOptions>
      </Modal>
    </>
  )
}

export default VpiAlertResults

function PaginatedPlateDetections({
  plate,
  detections,
  onClickSequence,
  onClickFavorite,
  onClickHide,
  onClickFalsePositive
}) {
  const [page, setPage] = useState(1)
  const countPerPage = 16
  const offset = (page - 1) * countPerPage

  function changePage(page) {
    setPage(page)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  if (detections.length === 0) {
    return null
  }

  const filteredFootages = detections.slice(offset, offset + countPerPage)

  return (
    <CF>
      <Row>
        <SequencesFootages
          footages={filteredFootages}
          pauseAtFirstDetection
          getSequenceUrl={(footage) =>
            `/api/plates/alert/${footage.id}/sequence`
          }
          onFootageClick={onClickSequence}
          onFootageAddToFavorite={onClickFavorite}
          onFootageHide={onClickHide}
          onFootageDeclareFalsePositive={onClickFalsePositive}
          renderNoResult={() => null}
        />
      </Row>
      {detections.length > countPerPage && (
        <Pagination
          activePage={page}
          totalItemsCount={detections.length}
          itemsCountPerPage={countPerPage}
          pageRangeDisplayed={5}
          onChange={changePage}
        />
      )}
    </CF>
  )
}

const PlateList = styled.div`
  display: flex;
  justify-content: center;
`

const Plate = styled.button`
  margin: 10px;
  padding: 8px 10px;
  border: 1px solid ${({ active }) => (active ? 'red' : 'black')};
  border-radius: 4px;
  color: ${({ active }) => (active ? 'red' : 'black')};
`

function PlatesTabs({ value, onChange, searchPlates }) {
  return (
    <PlateList>
      {searchPlates.map((plate) => (
        <Plate
          key={plate}
          onClick={() => onChange(plate)}
          active={value === plate}
        >
          {plate}
        </Plate>
      ))}
    </PlateList>
  )
}
