import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { BlankButton } from './CTAs'
import {
  Icon,
  AngleUpIcon,
  AngleDownIcon,
  CloseIcon,
  CheckIcon,
  EditIcon,
 } from './Icons'

export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
`

export const THead = styled.thead``

export const TBody = styled.tbody``

export const Tr = styled.tr``

const TdStyle = css`
  position: relative;
  padding: .375rem .75rem;
  font-size: .875rem;
  line-height: 1;
  color: ${({ empty, theme }) => empty ? theme.alto : 'inherit'};
  border: 1px solid ${({ theme }) => theme.gray};
  white-space: nowrap;

  ${({ canOrder }) => canOrder && `padding-right: 1.75rem`};

  ${Icon} {
    vertical-align: middle;
  }
`

const StyledTh = styled.th`
  ${TdStyle}
  font-family: Roboto;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
`

StyledTh.propTypes = {
  ...TdStyle.propTypes,
  canOrder: PropTypes.bool
}

const OrderButton = styled(BlankButton)`
  position: absolute;
  top: 50%;
  right: .5rem;
  margin-top: -.5rem;
  color: ${({ ordered, theme }) => ordered && theme.primaryColor || '#e5e5e5'};
`

OrderButton.propTypes = {
  ordered: PropTypes.bool,
  theme: PropTypes.shape({
    primaryColor: PropTypes.string
  })
}

export const Th = ({ children, order, onClickOrder, ...props }) =>
  <StyledTh canOrder={!!onClickOrder} {...props}>
    {children}
    {onClickOrder &&
      <OrderButton onClick={onClickOrder} ordered={!!order}>
        {order === 'desc' ? <AngleUpIcon /> : <AngleDownIcon />}
      </OrderButton>
    }
  </StyledTh>

Th.propTypes = {
  children: PropTypes.node,
  order: PropTypes.string,
  onClickOrder: PropTypes.func
}

export const Td = styled.td`
  ${TdStyle}
`

Td.propTypes = TdStyle.propTypes

const StyledTdForm = styled(Td)`
  > form, > div {
    display: flex;
  }
  input, label {
    line-height: 1rem;
    flex: 1;
    margin-right: .25rem;
    padding: 3px;
    border: 1px solid transparent;
  }
  input {
    border-bottom: 1px dashed ${({ theme }) => theme.gray };
  }
  label {
    border-bottom: 1px solid transparent;
  }
  input:focus { outline: 0; }
`

const StyledTdButton = styled(BlankButton)`
  color: ${({ theme, primary, danger }) =>
  (primary && theme.primaryColor) ||
  (danger && theme.dangerRed)
  };

  ${Icon} {
    width: 1.25rem;
    height: 1.25rem;
  }
`

export const TdForm = ({ onSubmit, ...props }) => {
  const [value, setValue] = useState(props.value)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const handleCancel = e => {
    setValue(props.value)
    setEditing(false)
  }

  const handleChange = e =>
    setValue(e.target.value)

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(value)
    setEditing(false)
  }

  return (
    <StyledTdForm {...props}>
      {
        editing
        ? <form onSubmit={handleSubmit}>
            <input type="text" value={value} onChange={handleChange} autoFocus />
            <StyledTdButton primary type="submit">
              <CheckIcon />
            </StyledTdButton>
            <StyledTdButton danger onClick={handleCancel} type="button">
              <CloseIcon />
            </StyledTdButton>
          </form>
        : <div>
            <label>{value}</label>
            <StyledTdButton primary onClick={() => setEditing(true)}>
              <EditIcon />
            </StyledTdButton>
          </div>
      }
    </StyledTdForm>
  )
}

TdForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}
