const investigation = {
    description: 'Description',
    name: 'Name',
    all: "All",
    animals: "Animals",
    cars: "Cars",
    color: "Color",
    completed: "Completed",
    criteria: "Criteria",
    date: "Date",
    defineTheSearchPeriod: "Define the search period.",
    end: "End",
    filterTheCamerasToBeSearchedIfNecessaryGroupSeveralCamerasByAreasViaTheCamerasMenu: "Filter the cameras to be searched. If necessary, group several cameras by areas via the ",
    menuCamera: "Camera menu",
    from: "From",
    heavyVehiclesTrucks: "Heavy vehicles / Trucks",
    hideCameras: "Hide cameras",
    showCameras: "Display cameras",
    latestSearches: "Latest searches",
    luggage: "Luggage",
    make: "Make",
    model: "Model",
    motorcyclesScootersBicycles: "Motorcycles / Scooters / Bicycles",
    nameSearch: "Name search",
    descriptionSearch: "Description search",
    newSearch: "New Search",
    people: "People",
    perimeter: "Perimeter",
    period: "Period",
    redDotsIndicateRealTimeProcessingOnTheCorrespondingCamera: "Red dots indicate processing on the corresponding camera.",
    results: "Results",
    selectTheSearchCriteria: "Select the search criteria.",
    start: "Start",
    startSearch: "Start search",
    status: "Status",
    time: "Time",
    to: "To",
    type: "Type",
    unknown: "Unknown",
    viewFullHistory: "View full history",
    xxxCameras: "xxx cameras",
    camera: "camera",
    noResult: "no results",
}
export default investigation