import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

export const H = css`
  color: ${({ color }) => color};
  font-family: Blinker;
  font-weight: bold;
  letter-spacing: .0625rem;
  margin: 0;
`

H.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node
}

export const H1 = styled.h1`
  ${H}
  font-size: 3.75rem;
  text-align: center;
  font-family: Saira;
  font-weight: 800;
  line-height: 1;
`

H1.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node
}

export const H2 = styled.h2`
  ${H}
  font-size: 1.875rem;
  font-family: Saira;
  font-weight: 300;
  line-height: 1.25;
`

H2.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node
}

export const H3 = styled.h3`
  ${H}
  font-size: 1.75rem;
  font-weight: 500;
`

H3.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node
}

export const H4 = styled.h4`
  ${H}
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.042;
`

H4.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node
}

export const H5 = styled.h5`
  ${H}
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.222;
`

H5.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node
}

export const Text = css`
  color: ${({ color }) => color};
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.429;
`

Text.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node
}

export const P = styled.p.attrs(props => ({
  color: props.theme.textColor || 'inherit',
  textAlign: props.textAlign || 'inherit',
}))`
  ${Text}
  margin: 0;
  text-align: ${({ textAlign }) => textAlign};
  line-height: 1.429;
`

P.propTypes = {
  color: PropTypes.string,
  textAlign: PropTypes.oneOf(['inherit', 'left', 'center', 'right']),
  children: PropTypes.node
}
