const lPR = {
    add: "Add",
    all: "All",
    completed: "Completed",
    date: "Date",
    description: "Description",
    defineTheSearchPeriod: "Define the search period.",
    descriptionOfTheSearch: "Description of the search.",
    end: "End",
    enterThePlatesToBeSearchedIfYouOnlyHavePartOfThePlate: `Enter the plates to be searched.If you only have part of the plate, make sure you put the numbers you have in their exact position, and put "*" in place of the others.For example, for a 7-character French plate, if you only have the first character "A" and the fourth and fifth characters "1" and "2", enter "A**12**".`,
    filterTheCamerasToBeSearchedIfNecessaryGroupSeveralCamerasByAreaViaTheCamerasMenu: "Filter the cameras to be searched. If necessary, group several cameras by area via the",
    nemuCamera: " Camera menu",
    from: "From :",
    hideCameras: "Hide cameras",
    showCameras: "Display cameras",
    name: "Name",
    nameSearch: "Name search",
    newLPRSearch: "New LPR search",
    noPlateSelectedTheSearchWillBePerformedOnAllPlates: "No plate selected, the search will be performed on all plates (\"*******\").",
    perimeter: "Perimeter",
    period: "Period",
    plates: "Plates",
    redDotsIndicateRealTimeProcessingOnTheCorrespondingCamera: " Red dots indicate processing on the corresponding camera",
    results: "Results",
    searchesInProgress: "Searches in progress",
    start: "Start",
    startSearch: "Start search",
    status: "Status",
    time: "Time",
    to: "To:",
    type: "Type",
    viewFullHistory: "View full history",
    xxxCameras: "xxx cameras",
    NoResultat: "No result",
    delete: "Delete",
}
export default lPR