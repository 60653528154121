import React, { useContext } from 'react'
import EnvContext from '../../contexts/EnvContext'
import { motion } from 'framer-motion/dist/framer-motion'
import { TextInput } from '../Input'
import { PageSection } from '../PageSection'
import { SmallButton, Button } from '../Button'
import { Checkbox, EditIcon } from '../design-system'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select } from '../Input'
import styled from 'styled-components'
import { eventTypes, subjects } from '../../pages/Scenarios'

const CheckboxCustom = styled(Checkbox)`
  margin: 0.5rem 0;
`

const TagImg = styled.img`
  margin: 0 1rem;
`

const SubjectContainer = styled.div`
  width: 100%;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
`

const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;

  .svg-inline--fa {
    margin: 0.5rem;
    font-size: 2rem;
  }
`

const Line = styled.div`
  width: 100%;
  border: 1px dashed #808080;
  margin: 1rem 0;
`

const eventTypeIds = [
  'entry',
  'run-stop',
  'run-red-light',
  'squat',
  'stampede',
  'fight',
  'static-luggage',
  'forgotten-luggage',
  'person-down',
  'person-fall',
  'broken-camera'
]

const timeTypes = [{ id: 'time', name: 'Heure' }]

const DefaultOption = (props) => (
  <option {...props} style={{ display: 'none' }} />
)

export default function EventEditorSection({
  t,
  events,
  zones,
  dispatch,
  setCanSendForm,
  hasTimeRange = true
}) {
  function addEvent() {
    dispatch({ type: 'addEvent' })
  }
  return (
    <PageSection
      title={t('scenario.defineScenario')}
      icon="/images/pen.svg"
      description={
        <>
          <strong>
            {t('scenario.addDetailScenario')}
          </strong>
        </>
      }
    >
      {zones.length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              background: 'rgba(0, 0, 0, 0.1)',
              borderRadius: 5,
              width: 350,
              padding: 30,
            }}
          >
            <strong> {t('scenario.nothingDefineZone')}</strong>
            <br />
            {t('scenario.defineZoneAboveSection')}
          </div>
        </div>
      ) : (
        <>
          {/*<SmallButton
            type="button"
            onClick={addEvent}
            style={{ marginBottom: 10 }}
          >
            Ajouter un événement
          </SmallButton>*/}
          {events.map((event, index) => (
            <div key={index}>
              {/*<Line />*/}
              <Event
                t={t}
                key={event.id}
                event={event}
                zones={zones}
                dispatch={dispatch}
                setCanSendForm={setCanSendForm}
                hasTimeRange={hasTimeRange}
              />
            </div>
          ))}
        </>
      )}
    </PageSection>
  )
}

export function eventReducer(prevEvent, action) {
  switch (action.type) {
    case 'setName': {
      return { ...prevEvent, name: action.value }
    }
    case 'setDescription': {
      return { ...prevEvent, description: action.value }
    }
    case 'setEventType': {
      return {
        ...prevEvent,
        eventType: action.value,
        zoneIds: {},
        subjects: {},
        everytime: true,
        timeValueStart: '00:00',
        timeValueEnd: '23:59',
        duration: 1
      }
    }
    case 'setZoneId': {
      return {
        ...prevEvent,
        zoneIds: {
          ...prevEvent.zoneIds,
          [action.value.zoneName]: action.value.zoneId
        }
      }
    }
    case 'setSubjectItems': {
      const subjects = {
        ...prevEvent.subjects,
        [action.value.subjectName]: action.value.items
      }

      return {
        ...prevEvent,
        subjects: Object.keys(subjects).reduce((accumulator, value) => {
          if (subjects[value] !== '0') {
            return {
              ...accumulator,
              [value]: subjects[value]
            }
          }

          return accumulator
        }, {})
      }
    }
    case 'setEverytime': {
      return {
        ...prevEvent,
        everytime: action.value
      }
    }
    case 'setTimeValueStart': {
      return { ...prevEvent, timeValueStart: action.value }
    }
    case 'setTimeValueEnd': {
      return { ...prevEvent, timeValueEnd: action.value }
    }
    case 'setDuration': {
      return { ...prevEvent, duration: action.value }
    }
    case 'setSensibility': {
      return { ...prevEvent, sensibility: action.value }
    }
  }
}

const NumTextInput = styled(TextInput)`
  margin: 0 1rem 0 0;
  input {
    margin: 0;
  }
`

function Event({ t, event, zones, dispatch, setCanSendForm, hasTimeRange }) {
  const titleRef = React.useRef()
  const eventTypeRef = React.useRef()

  const [{ env }] = useContext(EnvContext)

  React.useEffect(() => {
    if (event.eventType && eventTypeRef.current) {
      eventTypeRef.current.focus()
    }
  }, [event.eventType, eventTypeRef.current])

  React.useEffect(() => {
    canSendForm()
  }, [event])

  const canSendForm = () => {
    setCanSendForm(
      event.name !== ''
      && event.eventType
      && eventTypes()[event.eventType]
      && (
        (
          eventTypes()[event.eventType].zones
          && eventTypes()[event.eventType].zones.length > 0
          && Object.keys(event.zoneIds).length === eventTypes()[event.eventType].zones.length
        )
        || (eventTypes()[event.eventType].allScreen)
      )
      && (
        eventTypes()[event.eventType]?.subjects === undefined
          ? true
          : Object.keys(event.subjects).some(key => {
            return event.subjects[key] > 0
          })
      )
    )
  }

  function setName(event, value) {
    dispatch({ type: 'setName', event, value })
  }
  function setDescription(event, value) {
    dispatch({ type: 'setDescription', event, value })
  }
  function setZoneId(event, value) {
    dispatch({ type: 'setZoneId', event, value })
  }
  function setSubjectItems(event, value) {
    dispatch({ type: 'setSubjectItems', event, value })
  }
  function setEventType(event, value) {
    dispatch({ type: 'setEventType', event, value })
    if (value === 'fight') {
      setSubjectItems(event, { items: eventTypes()[value].minItems + '', subjectName: 'person' })
    } else if (value === 'person-down') {
      setSubjectItems(event, { items: eventTypes()[value].minItems + '', subjectName: 'person' })
    } else if (value === 'person-fall') {
      setSubjectItems(event, { items: eventTypes()[value].minItems + '', subjectName: 'person' })
    } else if (value === 'stampede') {
      setSubjectItems(event, { items: eventTypes()[value].minItems + '', subjectName: 'person' })
    } else if (value === 'forgotten-luggage') {
      setSubjectItems(event, { items: eventTypes()[value].minItems + '', subjectName: 'luggage' })
      setDuration(event, eventTypes()[value].minDuration)
    } else if (value === 'static-luggage') {
      setSubjectItems(event, { items: eventTypes()[value].minItems + '', subjectName: 'luggage' })
      setDuration(event, eventTypes()[value].minDuration)
    }
  }
  function setEverytime(event, value) {
    dispatch({ type: 'setEverytime', event, value })
  }
  function setSensibility(event, value) {
    dispatch({ type: 'setSensibility', event, value })
  }
  function setTimeValueStart(event, value) {
    dispatch({ type: 'setTimeValueStart', event, value })
  }
  function setTimeValueEnd(event, value) {
    dispatch({ type: 'setTimeValueEnd', event, value })
  }
  function setDuration(event, value) {
    dispatch({ type: 'setDuration', event, value })
  }
  function deleteEvent(event) {
    dispatch({ type: 'deleteEvent', event })
  }

  return (
    <div
      style={{
        display: 'block',
        alignItems: 'center',
        marginBottom: 10,
      }}
    >
      <motion.div
        style={{ display: 'flex', width: '100%' }}
        layout
        initial={{ x: 0, y: -20, opacity: 0 }}
        animate={{ x: 0, y: 0, opacity: 1 }}
        enter={{ x: 0, y: 0, opacity: 1 }}
        exit={{ x: 0, y: 20, opacity: 0 }}
        transition={{
          type: 'tween',
          ease: 'easeOut',
          duration: 0.25,
        }}
      >
        <NumTextInput
          ref={titleRef}
          label={t('scenario.title')}
          value={event.name}
          onChange={(value) => setName(event, value)}
        />

        <NumTextInput
          label={t('scenario.description')}
          value={event.description}
          onChange={(value) => setDescription(event, value)}
        />
      </motion.div>
      {
        (event.name !== '' && (
          <motion.div
            layout
            initial={{ x: 0, y: -20, opacity: 0 }}
            animate={{ x: 0, y: 0, opacity: 1 }}
            enter={{ x: 0, y: 0, opacity: 1 }}
            exit={{ x: 0, y: 20, opacity: 0 }}
            transition={{
              type: 'tween',
              ease: 'easeOut',
              duration: 0.25,
            }}
            style={{ margin: '0.5rem 0' }}
          >
            <Select
              value={event.eventType}
              onChange={(value) => setEventType(event, value)}
              label={t('scenario.Event')}
            >
              <DefaultOption>{t('scenario.chooseEvent')}</DefaultOption>
              {
                eventTypeIds
                  .filter(
                    id => (
                      env.SCENARIOS_ACTIVATED.split(',').includes(id)
                    )
                  )
                  .map(id => (
                    <option key={id} value={id}>
                      {eventTypes()[id].name}
                    </option>
                  ))
              }
            </Select>
          </motion.div>
        ))
      }
      {
        (
          event.name !== ''
          && event.eventType
          && eventTypes()[event.eventType]
          && eventTypes()[event.eventType].zones
          && eventTypes()[event.eventType].zones.length > 0
          && (eventTypes()[event.eventType].zones.map(
            (zone, index) => (
              <motion.div
                key={index}
                layout
                initial={{ x: 0, y: -20, opacity: 0 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                enter={{ x: 0, y: 0, opacity: 1 }}
                exit={{ x: 0, y: 20, opacity: 0 }}
                transition={{
                  type: 'tween',
                  ease: 'easeOut',
                  duration: 0.25,
                }}
                style={{ margin: '0.5rem 0' }}
              >
                <Select
                  value={event.zoneIds[zone.name]}
                  onChange={value => setZoneId(event, {
                    zoneName: zone.name,
                    zoneId: value
                  })}
                  label={zone.option}
                >
                  <DefaultOption>{t('scenario.chooseZone')}</DefaultOption>
                  {
                    zones.map(z => (
                      <option key={z.id} value={z.id}>
                        {z.name}
                      </option>
                    ))
                  }
                </Select>
              </motion.div>
            )
          )
          ))
      }
      {
        (
          event.name !== ''
          && event.eventType
          && eventTypes()[event.eventType]
          && eventTypes()[event.eventType].zones
          && eventTypes()[event.eventType].zones.length > 0
          && Object.keys(event.zoneIds).length === eventTypes()[event.eventType].zones.length
          && (
            <motion.div
              layout
              initial={{ x: 0, y: -20, opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              enter={{ x: 0, y: 0, opacity: 1 }}
              exit={{ x: 0, y: 20, opacity: 0 }}
              transition={{
                type: 'tween',
                ease: 'easeOut',
                duration: 0.25,
              }}
              style={{
                margin: '0.5rem 0',
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'column'
              }}
            >
              {
                eventTypes()[event.eventType]?.subjects?.map(
                  (subject, index) => (
                    <TagContainer key={index}>
                      {
                        (eventTypes()[event.eventType].selectType === 'checkbox')
                          ? (
                            <Checkbox
                              checked={event.subjects[subject.name] > 0}
                              onChange={() => setSubjectItems(event, {
                                items: !(event.subjects[subject.name] > 0) ? '1' : '0',
                                subjectName: subject.name
                              })}
                              label=""
                            />
                          ) : (
                            <TextInput
                              style={{
                                width: 'auto',
                                marginRight: 0,
                                padding: 0
                              }}
                              label=""
                              type="number"
                              min={eventTypes()[event.eventType].minItems || 0}
                              max={eventTypes()[event.eventType].maxItems || 99}
                              value={event.subjects[subject.name] || eventTypes()[event.eventType].minItems || 0}
                              onChange={value =>
                                setSubjectItems(event, {
                                  items: value,
                                  subjectName: subject.name
                                })
                              }
                            />
                          )
                      }
                      <SubjectContainer>
                        <FontAwesomeIcon icon={subject.icon ? subject.icon : faTimes} />
                        {t('investigation.' + subject.option)}
                      </SubjectContainer>
                    </TagContainer>
                  )
                )
              }
            </motion.div>
          )
        )
      }
      {
        event.name !== ''
        && event.eventType
        && eventTypes()[event.eventType]
        && eventTypes()[event.eventType].zones
        && eventTypes()[event.eventType].zones.length > 0
        && Object.keys(event.zoneIds).length === eventTypes()[event.eventType].zones.length
        && (eventTypes()[event.eventType].minSensibility || eventTypes()[event.eventType].maxSensibility)
        && (
          <motion.div
            style={{ display: 'flex', width: '100%', margin: 10, justifyContent: 'center' }}
            layout
            initial={{ x: 0, y: -20, opacity: 0 }}
            animate={{ x: 0, y: 0, opacity: 1 }}
            enter={{ x: 0, y: 0, opacity: 1 }}
            exit={{ x: 0, y: 20, opacity: 0 }}
            transition={{
              type: 'tween',
              ease: 'easeOut',
              duration: 0.25,
            }}
          >
            <TextInput
              style={{
                width: 'auto',
                marginRight: 0,
                padding: 0
              }}
              label=""
              type="number"
              min={eventTypes()[event.eventType].minSensibility || 0}
              max={eventTypes()[event.eventType].maxSensibility || 10}
              value={event.sensibility || 0}
              onChange={value =>
                setSensibility(event, value)
              }
            />
            <SubjectContainer>
              {t('scenario.violenceSensitivtySettingBetweenOneAndTen')}
            </SubjectContainer>
          </motion.div>
        )
      }
      {
        (
          hasTimeRange
          && event.name !== ''
          && event.eventType
          && eventTypes()[event.eventType]
          && eventTypes()[event.eventType].zones
          && eventTypes()[event.eventType].zones.length > 0
          && Object.keys(event.zoneIds).length === eventTypes()[event.eventType].zones.length
          && !eventTypes()[event.eventType].everytime
          && (
            <motion.div
              layout
              initial={{ x: 0, y: -20, opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              enter={{ x: 0, y: 0, opacity: 1 }}
              exit={{ x: 0, y: 20, opacity: 0 }}
              transition={{
                type: 'tween',
                ease: 'easeOut',
                duration: 0.25,
              }}
              style={{
                margin: '0.5rem 0',
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'column'
              }}
            >
              <CheckboxCustom
                checked={event.everytime}
                onChange={() => setEverytime(event, !event.everytime)}
                label={t('scenario.allDay')}
              />
            </motion.div>
          )
        )
      }
      {
        (
          hasTimeRange
          && event.name !== ''
          && event.eventType
          && eventTypes()[event.eventType]
          && eventTypes()[event.eventType].zones
          && eventTypes()[event.eventType].zones.length > 0
          && Object.keys(event.zoneIds).length === eventTypes()[event.eventType].zones.length
          && !event.everytime
          && (
            <motion.div
              key={event.timeType}
              ref={eventTypeRef}
              layout
              initial={{ x: 0, y: -20, opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              enter={{ x: 0, y: 0, opacity: 1 }}
              exit={{ x: 0, y: 20, opacity: 0 }}
              style={{ width: 120, display: 'flex' }}
              transition={{
                type: 'tween',
                ease: 'easeOut',
                duration: 0.25,
              }}
            >
              <NumTextInput
                type='time'
                value={event.timeValueStart}
                onChange={(value) => setTimeValueStart(event, value)}
                label={t('scenario.From')}
              />
              <NumTextInput
                type='time'
                value={event.timeValueEnd}
                onChange={(value) => setTimeValueEnd(event, value)}
                label={t('scenario.upTo')}
              />
            </motion.div>
          )
        )
      }
      {
        (
          event.name !== ''
          && event.eventType
          && eventTypes()[event.eventType]
          && eventTypes()[event.eventType].zones
          && eventTypes()[event.eventType].zones.length > 0
          && Object.keys(event.zoneIds).length === eventTypes()[event.eventType].zones.length
          && !eventTypes()[event.eventType].noDuration
          && (
            <TagContainer style={{ justifyContent: 'flex-start' }}>
              <NumTextInput
                style={{ width: 'auto' }}
                label={t('scenario.minimumTime')}
                min={eventTypes()[event.eventType].minDuration || 1}
                value={event.duration}
                onChange={value => {
                  setDuration(event, value)
                }}
                onBlur={() => {
                  let v = event.duration

                  if (isNaN(parseInt(v, 10)) || v <= 0) {
                    v = 1
                  }

                  if (v < eventTypes()[event.eventType].minDuration)
                    v = eventTypes()[event.eventType].minDuration

                  setDuration(event, v)
                }}
              />
              &nbsp;{t('scenario.seconds')}
            </TagContainer>
          )
        )
      }
    </div>
  )
}
