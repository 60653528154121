import axios from 'axios'

export const fetchAllStandardSearch = params =>
  axios
    .get(`/api/search/requests`, { params })
    .then(({ data }) => data)

export const fetchStandardSearch = id =>
  axios
    .get(`/api/search/requests/${id}`)
    .then(({ data }) => data)

export const putStandardSearchFavorite = ({ id, favorite }) =>
  axios
    .put(`/api/search/requests/detections/${id}/favorite`, { favorite })
    .then(({ data }) => data)

export const putStandardSearchHide = ({ id, hidden }) =>
  axios
    .put(`/api/search/requests/detections/${id}/hide`, { hidden })
    .then(({ data }) => data)

export const putStandardSearchFalsePositive = ({ id, action }) =>
  axios
    .put(
      `/api/search/detector/results/${id}/false-positive`,
      {},
      { params: { action } }
    ).then(({ data }) => data)

export const addStandardSearch = params =>
  axios
    .post(`/api/search/requests`, params)
    .then(({ data }) => data)

export const fetchCameras = () =>
  axios.get('/api/cameras').then(({ data }) => data)

export const fetchCarMakes = () =>
  axios.get('/api/cars/makes').then(({ data }) => data)

export const fetchCarModels = params =>
  axios.get(`/api/cars/makes/${make}/models`, params).then(({ data }) => data)
