export const resultScenario = {
    addToFavorites: "Ajouter aux favoris",
    allSequences: "Toutes les séquences",
    camera: "Caméra",
    eventAt: "Evènement à ",
    falsePositives: "Faux positifs",
    favorites: "Favories",
    filterByCamera: "Filter par caméra",
    filterByType: "Filter par type",
    filterByView: "Filtrer par vues",
    hidden: "Cachées",
    hideAll: "Tout cacher",
    hideThisResult: "Cacher ce résultat",
    highestToLowestScore: "Du score le plus haut au plus bas",
    lowestToHighestScore: "Du score le plus bas au plus haut",
    markAllAsSeen: "Tout marquer comme vu",
    newestToOldest: "Du plus récent au plus ancien",
    newResult: "Nouveau résultat",
    nonViewsOnly: "Uniquement les non-vues",
    oldestToNewest: "Du plus ancien au plus récent",
    onlyViews: "Uniquement les vues",
    reportAsFalsePositive: "Déclarer comme faux positif",
    results: "Résultats",
    scenariosResults: "Scénarios&gt;Résultats",
    sortBy: "Trier par",
    uniqueThisResult: "Uniquement ce résultat",
    scenario: 'Scénarios',
    removeFalsePositive: "Retirer des faux positifs",
    wouldYouLikeToRemoveThisResultFromTheFalsePositiveList: "Souhaitez vous retirer ce résultat de la liste des faux positifs ?",
    allFalsePositiveAssociate: "Tout les faux positifs associés",
    close: "Fermer",
    makeThisResultVisible: "Rendre ce résultat visible",
    removeFromFavorites: "Retirer des favoris",
    addToFavorites: "Ajouter aux favoris",
}
export default resultScenario