import React, { createContext, useReducer, useEffect } from 'react'

import { fetchEnv } from '../services/env'

const initialState = {
  loading: true,
  env: {
    NODE_ENV: 'development',
    NODE_CONTAINER_DEV_PORT_API: 5000,
    NODE_CONTAINER_DEV_PORT_APP: 80,
    ACTIVE_WATCH: false,
    ACTIVE_REWIND: false,
    ACTIVE_EXPORTS: false,
    ACTIVE_MAP: false,
    aCTIVE_UPLOADS: false,
    ACTIVE_DETECTOR: false,
    ACTIVE_DETECTOR_ALERTS: false,
    ACTIVE_VPI: false,
    ACTIVE_VPI_ALERTS: false,
    ACTIVE_RESSOURCES: false,
    ACTIVE_CAMERAS: false,
    ACTIVE_SCENARIOS_SEARCH: false,
    ACTIVE_SCENARIOS_ALERTS: false,
    ACTIVE_STATS: false,
    ACTIVE_MONITORING: false,
    SCENARIOS_ACTIVATED: ''
  },
  error: null
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'LOADING':
      return { ...state, loading: true, error: null }
    case 'SET_ENV':
      let { env, error } = payload

      return {
        ...state,
        env,
        loading: false,
        error
      }
    default:
      throw new Error()
  }
}

const errorFromResponse = res => {
  return ({
    status: res?.status || null,
    message: (res?.data && res?.data.message) || null
  })
}

const context = createContext(initialState);
const { Provider } = context;

export const EnvProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: 'LOADING' })
    fetchEnv()
      .then(({ env }) => dispatch({
        type: 'SET_ENV',
        payload: { env, error: null }
      }))
      .catch(err => {
        const error = errorFromResponse(err.response)

        dispatch({
          type: 'SET_ENV', payload: { env: initialState.env, error }
        })
      })
  }, [])

  const actions = {}

  return <Provider value={[state, actions]}>{children}</Provider>
}

export default context
