import axios from 'axios'

export const fetchAllDetectionAlerts = () =>
  axios.get(`/api/detect-alerts/requests`).then(({ data }) => data)

export const fetchDetectionAlertsCount = () =>
  axios.get(`/api/detect-alerts/requests/count`).then(({ data }) => data)

export const fetchDetectionAlert = (id) =>
  axios.get(`/api/detect-alerts/requests/${id}`).then(({ data }) => data)

export const fetchDetectionAlertResults = ({
  id,
  offset,
  sortBy,
  sortType,
  cameras,
  sector,
  filter,
  has_seen = 'all',
}) =>
  axios
    .get(
      `/api/detect-alerts/requests/${id}/detections` +
        `?offset=${offset}` +
        `&sortBy=${sortBy}` +
        `&sortType=${sortType}` +
        (sector ? `&sector=${sector}` : '') +
        (cameras ? `&cameras=${cameras}` : '') +
        (has_seen !== 'all' ? `&has_seen=${has_seen}` : '') +
        `&filter=${filter}`
    )
    .then(({ data }) => data)

export const createDetectionAlert = (body) =>
  axios.post(`/api/detect-alerts/requests`, body).then(({ data }) => data)

export const markAllDetectionAlertResultsAsSeen = (id) =>
  axios.put(`/api/detect-alerts/requests/${id}/seen`).then(({ data }) => data)

export const putDetectionAlertFavorite = ({ id, favorite }) =>
  axios
    .put(`/api/detect-alerts/requests/${id}/favorite`, { favorite })
    .then(({ data }) => data)

export const putDetectionAlertHide = ({ id, hidden }) =>
  axios.put(`/api/detect-alerts/requests/${id}/hide`, { hidden }).then(({ data }) => data)

export const cancelDetectionAlert = (id) =>
  axios.put(`/api/detect-alerts/requests/${id}/cancel`).then(({ data }) => data)
