import React from 'react'
import { useLocation } from 'react-router-dom'
import PlayerGrid from '../components/PlayerGridCam'


export default () => {

    const [cameraList, setCameraList] = React.useState([])
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    React.useEffect(() => {
        if (params.get("cameras_order")) {
            setCameraList(params.get("cameras_order").split(",").map((cameraIdString) => { return cameraIdString * 1 }))
        } else {
            setCameraList(params.get("cameras").split(",").map((cameraIdString) => { return cameraIdString * 1 }))
        }
    }, [])

    return <>
        {
            cameraList.length >= 1 && <PlayerGrid
                cameraList={cameraList}
                startDateTimeStamp={null}
                endDateTimeStamp={null}
                mode={'LIVE'}
                style={{
                    overflow: cameraList.length === 1 ? 'hidden' : 'auto'
                }}
            />
        }
    </>;
}