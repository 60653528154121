import React from 'react'
import styled from 'styled-components'

import { H3 } from '../components/Typography'
import { DateTimePicker } from '../components/Input'
import { AngleRightIcon } from '../components/design-system'
import { CriteriaWrapper } from '../components/PageSection'
import { useTranslation } from 'react-i18next'

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: min-content;
  margin-top: 0;
  align-self: self-end;
`

const StyledAngleRightIcon = styled(AngleRightIcon)`
  height: 3rem;
  width: auto;
  justify-self: center;
`

const SearchDateSection = ({ until, since, setDate }) => {
  const { t } = useTranslation()
  return <CriteriaWrapper columns='1fr auto 1fr' style={{ textAlign: 'center' }}>
    <div>
      <H3>{t('investigation.from')} :</H3>
      <DateTimePicker
        max={until}
        value={since}
        onChange={v => setDate('since', v)}
      />
    </div>
    <IconContainer>
      <StyledAngleRightIcon />
    </IconContainer>
    <div>
      <H3>{t('investigation.to')} :</H3>
      <DateTimePicker
        min={since}
        value={until}
        onChange={v => setDate('until', v)}
      />
    </div>
  </CriteriaWrapper>
}

export default SearchDateSection
