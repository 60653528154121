import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Player from './PlayerCam';
import {
  faPlay,
  faPause,
  faForward,
  faBackward,
  faArrowLeft,
  faArrowsAlt,
  faSearchPlus,
  faSearchMinus,
  faDesktop,
} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

const StyledButtonPlayer = styled.button`
  background-color: transparent;
  color: white;
  padding: 0;
  height: 2rem; 
  width: 2rem;
  border: none;

  &:hover {
    color: #333;
  }
`

const PlayerGrid = ({ cameraList, startDateTimeStamp, endDateTimeStamp, mode = 'REPLAY', style /* 'REPLAY', 'LIVE' */ }) => {

  const [hoverCamera, setHoverCamera] = React.useState(null);
  const [playBackRate, setPlayBackRate] = React.useState(1);
  const [playPause, setPlayPause] = React.useState(true);
  const [fullLittleScreen, setFullLittleScreen] = React.useState(false);
  const [timeStamp, setTimeStamp] = React.useState({
    seekParam: null
  });
  const [zoom, setZoom] = React.useState({
    zoom: '1' // '1', '+', '-'
  });

  const getWidthAndHeightOfScreen = (cameraList) => {
    let result = {
      column: 0,
      row: 0,
    };
    let cameraListVariable = cameraList;
    if (cameraListVariable.length === 1) {
      result = {
        column: 1,
        row: 1,
      }
    } else {
      let arrayOfMultiple = []
      cameraListVariable = cameraListVariable.length % 2 === 0 ? cameraListVariable : [...cameraListVariable, null]
      for (let i = cameraListVariable.length - 1; i >= 1; i--) {
        if ((cameraListVariable.length / i) % 1 === 0) {
          arrayOfMultiple.push(i)
        }
      }
      arrayOfMultiple = arrayOfMultiple.sort(function (a, b) { return b - a })
      var middle_index = parseInt((arrayOfMultiple.length / 2).toFixed(), 10) - 1;
      if (middle_index != -1)
        result = {
          column: cameraListVariable.length / arrayOfMultiple[middle_index],
          row: arrayOfMultiple[middle_index]
        }
    }
    return result
  }

  return <div style={{
    ...style,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
  }}>
    <div className='camera-view' style={{
      width: '100%',
      height: cameraList.length > 1 ? '90%' : '100%',
      backgroundColor: 'black',
    }}>
      {
        cameraList.map((cameraId, index) => {
          return <div
            key={`${index}-${cameraId}`}
            onMouseEnter={() => setHoverCamera(index)}
            onMouseLeave={() => setHoverCamera(null)}
            className='cam'
            style={{
              width: `${100 / getWidthAndHeightOfScreen(cameraList).column}%`,
              height: `${100 / getWidthAndHeightOfScreen(cameraList).row}%`,
              backgroundColor: 'black',
              display: 'inline-block',
              position: 'relative',
              marginBottom: '-0.4rem',
            }}>
            {
              cameraId && <Player
                cameraId={cameraId}
                displayControl={hoverCamera === index || cameraList.length === 1}
                playBackRateCommon={playBackRate}
                playPauseCommon={playPause}
                fullLittleScreenCommon={fullLittleScreen}
                timeStampCommon={timeStamp}
                startDateTimeStamp={mode === 'LIVE' ? new Date().getTime() - 120000 : startDateTimeStamp}
                endDateTimeStamp={endDateTimeStamp}
                zoomCommon={zoom}
                mode={mode}
              />
            }
          </div>
        })
      }
    </div>
    {cameraList.length > 1 && <div className='panel-control' style={{
      width: '100%',
      backgroundColor: 'black',
    }}>
      <div style={{
        margin: 'auto',
        marginTop: '2.5%',
        width: 'fit-content',
        backgroundColor: 'transparent',
      }}
      >
        {mode === 'REPLAY' && <><StyledButtonPlayer title="Retour au début" onClick={() => { setTimeStamp({ seekParam: 0 }) }}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </StyledButtonPlayer>
          <StyledButtonPlayer title="Play/Pause" onClick={() => { setPlayPause(!playPause) }}>
            <FontAwesomeIcon icon={playPause ? faPause : faPlay} />
          </StyledButtonPlayer>
          <StyledButtonPlayer title="Rallentissement" onClick={() => {
            if (playBackRate > 0.125) { setPlayBackRate(playBackRate / 2) }
          }}>
            <FontAwesomeIcon icon={faBackward} />
          </StyledButtonPlayer>
          <StyledButtonPlayer title="Vitesse de lecture">
            x{playBackRate}
          </StyledButtonPlayer>
          <StyledButtonPlayer title="Accélération" onClick={() => {
            if (playBackRate < 16) { setPlayBackRate(playBackRate * 2) }
          }}>
            <FontAwesomeIcon icon={faForward} />
          </StyledButtonPlayer>
        </>}
        <StyledButtonPlayer title="Zoom" onClick={() => {
          setFullLittleScreen(!fullLittleScreen)
        }}>
          <FontAwesomeIcon icon={faArrowsAlt} />
        </StyledButtonPlayer>
        <StyledButtonPlayer title="Dézoomer" onClick={() => {
          setZoom({ zoom: '-' })
        }}>
          <FontAwesomeIcon icon={faSearchMinus} />
        </StyledButtonPlayer>
        <StyledButtonPlayer title="Reset zoom" onClick={() => {
          setZoom({ zoom: '1' })
        }}>
          <FontAwesomeIcon icon={faDesktop} />
        </StyledButtonPlayer>
        <StyledButtonPlayer title="Zoomer" onClick={() => {
          setZoom({ zoom: '+' })
        }}>
          <FontAwesomeIcon icon={faSearchPlus} />
        </StyledButtonPlayer>
      </div>
    </div>}
  </div>
}

export default PlayerGrid;