export const login = {
    'welcome': 'Welcome',
    'email': 'Email',
    'password': 'Password',
    'login': 'Login',
    'loginIncorrect': 'Email and/or password incorrect',
    'signup': 'Sign up',
    'passwordForgotten': 'Password forgotten',
    'passwordRequired': 'Password required',
    'emailRequired': 'Email required',
    'emailBadFormat': 'Email bad format',
    'accountNotAvailable': 'Your account is created but not activate. Contact your administrator with this ',
    'formularErrorLink': 'contact formular',
    'error': 'An error occured, try again later or contact an administrator with this',
    'errorMaintenance': 'Application is in maintenance, we do our maximum to restore this service as quickly as possible.',
};

export default login;