import React, { useContext } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import { faCar, faTruckMoving, faMotorcycle, faUser, faWalking, faPaw, faSuitcase } from '@fortawesome/free-solid-svg-icons';

import EnvContext from '../contexts/EnvContext'

import ScenariosAlerts from './ScenariosAlerts'
import ScenariosSearch from './ScenariosSearch'
import { Tabs } from '../components'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next';

const Container = styled.div`
  width: 100%;
`

export const subjects = () => {
  return {
    person: { name: 'person', subject: 'people', icon: faUser, option: 'people' },
    car: { name: 'car', subject: 'cars', icon: faCar, option: 'cars' },
    heavyVehicles: { name: 'heavyVehicles', subject: 'heavyVehiclesTrucks', icon: faTruckMoving, option: 'heavyVehiclesTrucks' },
    twoWheeled: { name: 'twoWheeled', subject: 'motorcyclesScootersBicycles', icon: faMotorcycle, option: 'motorcyclesScootersBicycles' },
    pet: { name: 'pet', subject: 'animals', icon: faPaw, option: 'animals' },
    luggage: { name: 'luggage', subject: 'luggage', icon: faSuitcase, option: 'luggage' }
  }
}

export const eventTypes = () => {
  const t = (keyWord) => i18next.store.data[i18next.language].translation[keyWord.split('.')[0]][keyWord.split('.')[1]]
  return {
    entry: {
      name: t('scenario.zoneEntry'),
      noDuration: true,
      everytime: false,
      selectType: 'checkbox',
      zones: [{
        name: 'zone',
        option: 'Zone'
      }],
      subjects: [
        subjects()['person'],
        subjects()['car'],
        subjects()['heavyVehicles'],
        subjects()['twoWheeled'],
        subjects()['pet'],
        subjects()['luggage']
      ]
    },
    squat: {
      name: t('scenario.gatheringInTheZone'),
      noDuration: false,
      selectType: 'default',
      everytime: false,
      zones: [{
        name: 'zone',
        option: 'Zone'
      }],
      subjects: [
        subjects()['person'],
        subjects()['car'],
        subjects()['heavyVehicles'],
        subjects()['twoWheeled'],
        subjects()['pet'],
        subjects()['luggage']
      ]
    },
    'run-stop': {
      name: t('scenario.stopSignCrossing'),
      maxItems: 1,
      noDuration: true,
      selectType: 'checkbox',
      zones: [{
        name: 'beforeZone',
        option: t('scenario.laneZoneBeforeStopSign')
      }, {
        name: 'afterZone',
        option: t('scenario.laneZoneAfterStopSign')
      }],
      subjects: [
        subjects()['car'],
        subjects()['heavyVehicles'],
        subjects()['twoWheeled']
      ]
    },
    'run-red-light': {
      name: t('scenario.redLightCrossing'),
      maxItems: 1,
      noDuration: true,
      selectType: 'checkbox',
      zones: [{
        name: 'redLightZone',
        option: t('scenario.redLightZone')
      }, {
        name: 'orangeLightZone',
        option: t('scenario.orangeLightZone')
      }, {
        name: 'greenLightZone',
        option: t('scenario.greenLightZone')
      }, {
        name: 'beforeZone',
        option: t('scenario.laneZoneBeforeTrafficLight')
      }, {
        name: 'afterZone',
        option: t('scenario.laneZoneAfterTraffecLight')
      }],
      subjects: [
        subjects()['car'],
        subjects()['heavyVehicles'],
        subjects()['twoWheeled']
      ]
    },
    stampede: {
      name: t('scenario.crowdMovement'),
      noDuration: true,
      everytime: false,
      minItems: 2,
      selectType: 'default',
      zones: [{
        name: 'zone',
        option: t('scenario.zone')
      }],
      subjects: [{
        ...subjects()['person'],
        option: t('scenario.minimumNumberOfPeopleToConsiderACrowd'),
      }]
    },
    fight: {
      name: t('scenario.assault'),
      noDuration: true,
      minItems: 1,
      everytime: true,
      selectType: 'default',
      minSensibility: 1,
      maxSensibility: 10,
      zones: [{
        name: 'zone',
        option: 'Zone'
      }]
    },
    'static-luggage': {
      name: t('scenario.immobileLuggage'),
      noDuration: false,
      minDuration: 1,
      everytime: false,
      minItems: 1,
      hasTimeRange: true,
      selectType: 'default',
      zones: [{
        name: 'zone',
        option: 'Zone'
      }]
    },
    'forgotten-luggage': {
      name: t('scenario.isolatedLuggage'),
      noDuration: false,
      minDuration: 1,
      everytime: false,
      minItems: 1,
      hasTimeRange: true,
      selectType: 'default',
      zones: [{
        name: 'zone',
        option: 'Zone'
      }]
    },
    'person-down': {
      name: t('scenario.personInDistress'),
      maxItems: 1,
      minItems: 1,
      everytime: true,
      noDuration: false,
      hasTimeRange: true,
      selectType: 'default',
      zones: [{
        name: 'zone',
        option: 'Zone'
      }]
    },
    'person-fall': {
      name: t('scenario.fallingPerson'),
      maxItems: 1,
      minItems: 1,
      everytime: true,
      noDuration: true,
      hasTimeRange: true,
      selectType: 'default',
      zones: [{
        name: 'zone',
        option: 'Zone'
      }]
    },
    'broken-camera': {
      name: t('scenario.crashedCamera'),
      noDuration: true,
      hasTimeRange: false,
      selectType: 'default',
      allScreen: true
    }
  }
}

export default (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { resource } = useParams()

  const [{ env }] = useContext(EnvContext)

  const panes = [
    {
      key: 'alerts',
      title: t('scenario.scenarioRealTime'),
      content: <ScenariosAlerts {...props} />,
    },
    {
      key: 'search',
      title: t('scenario.scenarioInSearch'),
      content: <ScenariosSearch {...props} />,
    }
  ]

  let Page = null

  if (env.ACTIVE_SCENARIOS_SEARCH && env.ACTIVE_SCENARIOS_ALERTS) {
    Page = (
      <Tabs
        currentTab={resource}
        panes={panes}
        onChange={(v) => history.push(`/scenarios/${v}`)}
      />
    )
  } else if (env.ACTIVE_SCENARIOS_SEARCH) {
    Page = (
      <ScenariosSearch {...props} />
    )
  } else if (env.ACTIVE_SCENARIOS_ALERTS) {
    Page = (
      <ScenariosAlerts {...props} />
    )
  }

  return (
    <Container>
      {Page}
    </Container>
  )
}
