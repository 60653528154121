import React from 'react'
import * as ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import {
  faPlay,
  faPause,
  faForward,
  faBackward,
  faStepForward,
  faStepBackward,
  faExpand,
  faCompress,
  faVolumeOff,
  faVolumeUp,
  faVolumeDown,
  faSpinner,
  faTimes,
  faArrowLeft,
  faArrowsAlt,
  faSearchPlus,
  faSearchMinus,
  faDesktop,
} from '@fortawesome/free-solid-svg-icons'
import { fetchVideoList, fetchVideoListLive } from '../services/rewind';
import { formatDate } from '../services/utils';


const StyledButtonPlayer = styled.button`
  background-color: transparent;
  color: white;
  padding: 0;
  height: 2rem; 
  width: 2rem;
  border: none;

  &:hover {
    color: #333;
  }
`

const Player = ({
  cameraId,
  displayControl = true,
  playBackRateCommon,
  playPauseCommon,
  fullLittleScreenCommon,
  timeStampCommon,
  zoomCommon,
  startDateTimeStamp,
  endDateTimeStamp,
  mode = 'REPLAY' /* 'REPLAY', 'LIVE' */
}) => {
  const playerRefFirst = React.useRef(null);
  const playerRefSecond = React.useRef(null);
  const [currentPlayer, setCurrentPlayer] = React.useState(playerRefFirst);
  const [playbackRate, setPlaybackRate] = React.useState(1);
  const [playList, setPlayList] = React.useState();
  const [playerLoading, setPlayerLoading] = React.useState(true);
  const [seekSetting, setSeekSetting] = React.useState(false);
  const [currentTimeStampPlaying, setCurrentTimeStampPlaying] = React.useState(0);
  const [firstPlayerUrlVideo, setFirstPlayerUrlVideo] = React.useState(null);
  const [secondPlayerUrlVideo, setSecondPlayerUrlVideo] = React.useState(null);
  const [playerTakeAllLittleScreen, setPlayerTakeAllLittleScreen] = React.useState(false);
  const [zoom, setZoom] = React.useState(1);
  const [positionX, setPositionX] = React.useState(0)
  const [positionY, setPositionY] = React.useState(0)
  React.useEffect(() => {
    if (zoomCommon && zoomCommon.zoom) {
      switch (zoomCommon.zoom) {
        case '-':
          if (zoom > 1) {
            setZoom(zoom - 0.1)
            setPositionX(0.5)
            setPositionY(0.5)
          }
          break;
        case '1':
          setZoom(1)
          break;
        case '+':
          setZoom(zoom + 0.1)
          setPositionX(0.5)
          setPositionY(0.5)
          break;
        default:
          break;
      }
    }
  }, [
    zoomCommon,
  ])

  React.useEffect(() => {
    setPlaybackRate(playBackRateCommon || 1)
  }, [
    playBackRateCommon,
  ])

  React.useEffect(() => {
    if (playPauseCommon) {
      currentPlayer && currentPlayer.current && currentPlayer.current.play()
    } else {
      currentPlayer && currentPlayer.current && currentPlayer.current.pause()
    }
  }, [
    playPauseCommon,
  ])

  React.useEffect(() => {
    setPlayerTakeAllLittleScreen(fullLittleScreenCommon)
  }, [
    fullLittleScreenCommon
  ])


  React.useEffect(() => {
    playList && setSeek(timeStampCommon.seekParam, playList, {
      continueToPlay: true
    }, false)
  }, [
    timeStampCommon
  ])

  async function preloadVideo(src) {
    if (src) {
      const res = await fetch(src);
      const blob = await res.blob();
      return URL.createObjectURL(blob);
    } else {
      return null
    }
  }

  const playerRefFirstTimeUpdateEvent = (playListParam) => (event) => {
    if (playerRefSecond.current) playerRefFirst.current.playbackRate = playbackRate
    if (playListParam && playListParam[playerRefFirst.current.srcIndexUrl] && event.currentTarget.currentTime != 0 && playerRefFirst.current.readyState === 4) {
      setCurrentTimeStampPlaying(playListParam[playerRefFirst.current.srcIndexUrl].startDate.timestampMS + Math.round(event.currentTarget.currentTime * 1000))
    }
    if (
      event.currentTarget.buffered.length > 0 &&
      event.currentTarget.buffered.end(0) === event.currentTarget.duration
      && playerRefSecond.current.srcIndexUrl != playerRefFirst.current.srcIndexUrl + 1
      && (playerRefSecond.current.srcIndexUrl != null || (playerRefSecond.current.srcIndexUrl === null && playListParam.length >= playerRefFirst.current.srcIndexUrl + 1))
    ) {
      if (playListParam && playListParam[playerRefFirst.current.srcIndexUrl]?.url === playListParam[playerRefFirst.current.srcIndexUrl + 1]?.url) {
        playerRefSecond.current.src = playerRefSecond.current.src
        playerRefSecond.current.currentTime = 0
        playerRefSecond.current.pause()
      } else {
        if (playListParam && playListParam[playerRefFirst.current.srcIndexUrl + 1]) {
          setPlayerLoading(true)
          if (mode === 'LIVE') {
            playerRefSecond.current.src = playListParam[playerRefFirst.current.srcIndexUrl + 1].url
            setPlayerLoading(false)
            if (playerRefFirst.current.paused) {
              setCurrentPlayer(playerRefSecond)
              playerRefSecond.current.play()
            }
          } else {
            preloadVideo(playListParam[playerRefFirst.current.srcIndexUrl + 1].url || null).then((videoSrc) => {
              playerRefSecond.current.src = videoSrc
              setPlayerLoading(false)
              if (playerRefFirst.current.paused) {
                setCurrentPlayer(playerRefSecond)
                playerRefSecond.current.play()
              }
            })
          }
          preloadVideo(playListParam[playerRefFirst.current.srcIndexUrl + 1].url || null).then((videoSrc) => {
            playerRefSecond.current.src = videoSrc
            setPlayerLoading(false)
            if (playerRefFirst.current.paused) {
              setCurrentPlayer(playerRefSecond)
              playerRefSecond.current.play()
            }
          })
          setSecondPlayerUrlVideo(playListParam[playerRefFirst.current.srcIndexUrl + 1].url)
        } else {
          setSecondPlayerUrlVideo(null)
          playerRefSecond.current.src = null
          playerRefSecond.current.load()
        }
      }
      playerRefSecond.current.srcIndexUrl = playerRefFirst.current.srcIndexUrl + 1
      if (playerRefSecond.current.srcIndexUrl && (playListParam && playerRefSecond.current.srcIndexUrl > playListParam.length - 1)) {
        playerRefSecond.current.srcIndexUrl = null
        playerRefSecond.current.src = null
        playerRefSecond.current.load()
        setFirstPlayerUrlVideo(null)
      }
    }
  }

  const playerRefFirstEndedEvent = (playListParam) => () => {
    playerRefSecond.current.playbackRate = playbackRate
    if (playListParam[playerRefSecond.current.srcIndexUrl] != null) {
      if (playList && playList.length >= playListParam.length) playerRefSecond.current.addEventListenerNeurocop('timeupdate', playerRefSecondTimeUpdateEvent(playList));
      if (playListParam && (!playList || playList.length < playListParam.length)) playerRefSecond.current.addEventListenerNeurocop('timeupdate', playerRefSecondTimeUpdateEvent(playListParam));
      setCurrentPlayer(playerRefSecond)
      if (playerRefSecond.current.src.search('/null') === -1) {
        playerRefSecond.current.play()
        setPlayerLoading(false)
      } else {
        setPlayerLoading(true)
      }
    }
    playerRefSecond.current.playbackRate = playbackRate
    if (playListParam[playerRefFirst.current.srcIndexUrl + 2] === null || playListParam[playerRefFirst.current.srcIndexUrl]?.url != playListParam[playerRefFirst.current.srcIndexUrl + 2]?.url) {
      setFirstPlayerUrlVideo(null)
      playerRefFirst.current.src = null
      playerRefFirst.current.load()
    }
    playerRefFirst.current.removeEventListenerNeurocop('timeupdate', playerRefFirstTimeUpdateEvent(playListParam));
    fetchShortVideoListLive(false, playListParam)
  }

  const playerRefSecondTimeUpdateEvent = (playListParam) => (event) => {
    if (playerRefSecond.current) playerRefSecond.current.playbackRate = playbackRate
    if (playListParam && playListParam[playerRefSecond.current.srcIndexUrl] && event.currentTarget.currentTime != 0 && playerRefSecond.current.readyState === 4) {
      setCurrentTimeStampPlaying(playListParam[playerRefSecond.current.srcIndexUrl].startDate.timestampMS + Math.round(event.currentTarget.currentTime * 1000))
    }
    if (
      event.currentTarget.buffered.length > 0 &&
      event.currentTarget.buffered.end(0) === event.currentTarget.duration
      && playerRefFirst.current.srcIndexUrl != playerRefSecond.current.srcIndexUrl + 1
      && (playerRefFirst.current.srcIndexUrl != null || (playerRefFirst.current.srcIndexUrl === null && playListParam.length >= playerRefSecond.current.srcIndexUrl + 1))
    ) {
      if (playListParam && playListParam[playerRefSecond.current.srcIndexUrl]?.url === playListParam[playerRefSecond.current.srcIndexUrl + 1]?.url) {
        playerRefFirst.current.src = playerRefSecond.current.src
        playerRefFirst.current.currentTime = 0
        playerRefFirst.current.pause()
      } else {
        if (playListParam && playListParam[playerRefSecond.current.srcIndexUrl + 1]) {
          setPlayerLoading(true)
          if (mode === 'LIVE') {
            playerRefFirst.current.src = playListParam[playerRefSecond.current.srcIndexUrl + 1].url
            setPlayerLoading(false)
            if (playerRefSecond.current.paused) {
              setCurrentPlayer(playerRefFirst)
              playerRefFirst.current.play()
            }
          } else {
            preloadVideo(playListParam[playerRefSecond.current.srcIndexUrl + 1].url || null).then((videoSrc) => {
              playerRefFirst.current.src = videoSrc;
              setPlayerLoading(false)
              if (playerRefSecond.current.paused) {
                setCurrentPlayer(playerRefFirst)
                playerRefFirst.current.play()
              }
            })
          }
          setFirstPlayerUrlVideo(playListParam[playerRefSecond.current.srcIndexUrl + 1].url)
        } else {
          setFirstPlayerUrlVideo(null)
          playerRefFirst.current.src = null
          playerRefFirst.current.load()
        }
      }
      playerRefFirst.current.srcIndexUrl = playerRefSecond.current.srcIndexUrl + 1
      if (playerRefFirst.current.srcIndexUrl && playListParam && playerRefFirst.current.srcIndexUrl > playListParam.length - 1) {
        playerRefFirst.current.srcIndexUrl = null
        playerRefFirst.current.src = null
        playerRefFirst.current.load()
        setFirstPlayerUrlVideo(null)
      }
    }
  }

  const playerRefSecondEndedEvent = (playListParam) => () => {
    playerRefFirst.current.playbackRate = playbackRate
    if (playListParam[playerRefFirst.current.srcIndexUrl] != null) {
      if (playList && playList.length >= playListParam.length) playerRefFirst.current.addEventListenerNeurocop('timeupdate', playerRefFirstTimeUpdateEvent(playList));
      if (playListParam && (!playList || playList.length < playListParam.length)) playerRefFirst.current.addEventListenerNeurocop('timeupdate', playerRefFirstTimeUpdateEvent(playListParam));
      setCurrentPlayer(playerRefFirst)
      if (playerRefFirst.current.src.toString().search('/null') === -1) {
        playerRefFirst.current.play()
        setPlayerLoading(false)
      } else {
        setPlayerLoading(true)
      }
    }
    if (playListParam[playerRefSecond.current.srcIndexUrl + 2] === null || playListParam[playerRefSecond.current.srcIndexUrl]?.url != playListParam[playerRefSecond.current.srcIndexUrl + 2]?.url) {
      setSecondPlayerUrlVideo(null)
      playerRefSecond.current.src = null
      playerRefSecond.current.load()
    }
    playerRefSecond.current.removeEventListenerNeurocop('timeupdate', playerRefSecondTimeUpdateEvent(playListParam));
    fetchShortVideoListLive(false, playListParam)
  }

  const getIndexShortVideoFromSeek = (seek, playListParam) => {
    let result = playListParam.findIndex((shortVideo) => {
      return shortVideo.startDate.timestampMS <= seek && shortVideo.endDate.timestampMS > seek
    })
    return result
  }

  const setSeek = (seekParam, playListParam = playList, option = {
    continueToPlay: true
  }, firstStarting = false) => {
    fetchShortVideoListLive(false, playListParam)
    playerRefFirst.current.pause()
    playerRefSecond.current.pause()
    if (!seekSetting) {
      setSeekSetting(true)
      setPlayerLoading(true)
      playerRefFirst.current.removeEventListenerNeurocop('timeupdate', playerRefFirstTimeUpdateEvent(playListParam));
      playerRefFirst.current.removeEventListenerNeurocop('ended', playerRefFirstEndedEvent(playListParam));
      playerRefSecond.current.removeEventListenerNeurocop('timeupdate', playerRefSecondTimeUpdateEvent(playListParam));
      playerRefSecond.current.removeEventListenerNeurocop('ended', playerRefSecondEndedEvent(playListParam));
      let startVideo = {
        indexVideo: 0,
        seekAt: 0
      }

      if (seekParam) {
        startVideo.indexVideo = getIndexShortVideoFromSeek(seekParam, playListParam)
        startVideo.seekAt = seekParam - playListParam[startVideo.indexVideo].startDate.timestampMS
      } else {
        startVideo = {
          indexVideo: 0,
          seekAt: 1
        }
      }

      setCurrentTimeStampPlaying(playListParam[startVideo.indexVideo].startDate.timestampMS + startVideo.seekAt)
      if (startVideo) {
        if (playerRefFirst.current.srcIndexUrl === startVideo.indexVideo && !firstStarting) {
          playerRefFirst.current.currentTime = (startVideo.seekAt / 1000)
          if (option.continueToPlay) {
            playerRefFirst.current.play()
          } else {
            playerRefFirst.current.pause()
          }
          setPlayerLoading(false)
        } else {
          playerRefFirst.current.srcIndexUrl = startVideo.indexVideo
          if (mode === 'LIVE') {
            playerRefFirst.current.src = playListParam[startVideo.indexVideo].url
            setFirstPlayerUrlVideo(playListParam[startVideo.indexVideo].url)
            setPlayerLoading(false)
            playerRefFirst.current.currentTime = (startVideo.seekAt / 1000)
            option.continueToPlay && playerRefFirst.current.play()
          } else {
            preloadVideo(playListParam[startVideo.indexVideo].url).then((videoSrc) => {
              playerRefFirst.current.src = videoSrc;
              setFirstPlayerUrlVideo(playListParam[startVideo.indexVideo].url)
              setPlayerLoading(false)
              playerRefFirst.current.currentTime = (startVideo.seekAt / 1000)
              option.continueToPlay && playerRefFirst.current.play()
            })
          }
          if (startVideo.indexVideo + 1 <= playListParam.length - 1) {
            playerRefSecond.current.srcIndexUrl = startVideo.indexVideo + 1
            if (mode === 'LIVE') {
              playerRefSecond.current.src = playListParam[startVideo.indexVideo + 1].url;
              setSecondPlayerUrlVideo(playListParam[startVideo.indexVideo + 1].url)
              playerRefSecond.current.load()
            } else {
              preloadVideo(playListParam[startVideo.indexVideo + 1].url).then((videoSrc) => {
                playerRefSecond.current.src = videoSrc;
                setSecondPlayerUrlVideo(playListParam[startVideo.indexVideo + 1].url)
                playerRefSecond.current.load()
              })
            }

          } else {
            playerRefSecond.current.srcIndexUrl = null
            playerRefSecond.current.src = null;
            playerRefSecond.current.src = null;
          }
        }
      }
      playerRefFirst.current.addEventListenerNeurocop("timeupdate", playerRefFirstTimeUpdateEvent(playListParam));
      playerRefFirst.current.addEventListenerNeurocop("ended", playerRefFirstEndedEvent(playListParam));
      playerRefSecond.current.addEventListenerNeurocop("timeupdate", playerRefSecondTimeUpdateEvent(playListParam));
      playerRefSecond.current.addEventListenerNeurocop("ended", playerRefSecondEndedEvent(playListParam));
      setSeekSetting(false)
    }
  }

  const fetchShortVideoListReplay = () => {
    if (mode === 'REPLAY') {
      fetchVideoList(cameraId, startDateTimeStamp, endDateTimeStamp).then((response) => {
        const listOfVideo = response;
        setPlayList(listOfVideo)
        setSeek(null, listOfVideo, {
          continueToPlay: true
        }, true)
      })
    }
  }
  const fetchShortVideoListLive = (firstStart = true, playListParam) => {
    if (mode === 'LIVE') {
      fetchVideoListLive(cameraId, firstStart ? startDateTimeStamp : playListParam.filter((playlist) => playlist.type != 'HOLE')[playListParam.filter((playlist) => playlist.type != 'HOLE').length - 1].endDate.timestampMS).then((response) => {
        const listOfVideo = response;
        if (!firstStart) {
          let result = [...playListParam, ...listOfVideo].filter((value, index, self) =>
            index === self.findIndex((t) => (
              (t.startDate.timestampMS === value.startDate.timestampMS)
              || (t.endDate.timestampMS > new Date().getTime())
              || (t.endDate.timestampMS > new Date().getTime() - 30000 && t.type === 'HOLE')
              || (t.startDate.timestampMS >= value.startDate.timestampMS && t.endDate.timestampMS <= value.endDate.timestampMS && t.type === 'HOLE')
            ))
          )
          setPlayList(result)
        } else {
          setPlayList(listOfVideo)
          setSeek(null, listOfVideo, {
            continueToPlay: true
          }, true)
        }
      })
    }

  }

  React.useEffect(() => {
    fetchShortVideoListLive(true);
    fetchShortVideoListReplay();
  }, [])

  React.useEffect(() => {
    if (playList && playList.length > 0) {
      if (!(playerRefFirst.current.addEventListenerNeurocop)) {
        playerRefFirst.current.addEventListenerNeurocop = (eventListener, functionParam) => {
          function functionParamEvent(e) {
            functionParam(e)
          }
          playerRefFirst.current.functionParamEvent = playerRefFirst.current.functionParamEvent ? playerRefFirst.current.functionParamEvent : {}
          playerRefFirst.current.functionParamEvent[eventListener] = functionParamEvent
          playerRefFirst.current.addEventListener(eventListener, functionParamEvent);
        }
        playerRefFirst.current.removeEventListenerNeurocop = (eventListener) => {
          if (playerRefFirst.current.functionParamEvent && playerRefFirst.current.functionParamEvent[eventListener]) {
            playerRefFirst.current.removeEventListener(eventListener, playerRefFirst.current.functionParamEvent[eventListener]);
          }
        }
        playerRefSecond.current.addEventListenerNeurocop = (eventListener, functionParam) => {
          function functionParamEvent(e) {
            functionParam(e)
          }
          playerRefSecond.current.functionParamEvent = playerRefSecond.current.functionParamEvent ? playerRefSecond.current.functionParamEvent : {}
          playerRefSecond.current.functionParamEvent[eventListener] = functionParamEvent
          playerRefSecond.current.addEventListener(eventListener, functionParamEvent);
        }

        playerRefSecond.current.removeEventListenerNeurocop = (eventListener) => {
          if (playerRefSecond.current.functionParamEvent && playerRefSecond.current.functionParamEvent[eventListener]) {
            playerRefSecond.current.removeEventListener(eventListener, playerRefSecond.current.functionParamEvent[eventListener]);
          }
        }
      }
      playerRefFirst.current.playbackRate = playbackRate
      playerRefSecond.current.playbackRate = playbackRate
      playerRefFirst.current.removeEventListenerNeurocop('timeupdate', playerRefFirstTimeUpdateEvent(playList));
      playerRefFirst.current.removeEventListenerNeurocop('ended', playerRefFirstEndedEvent(playList));
      playerRefSecond.current.removeEventListenerNeurocop('timeupdate', playerRefSecondTimeUpdateEvent(playList));
      playerRefSecond.current.removeEventListenerNeurocop('ended', playerRefSecondEndedEvent(playList));
      playerRefFirst.current.addEventListenerNeurocop("timeupdate", playerRefFirstTimeUpdateEvent(playList));
      playerRefFirst.current.addEventListenerNeurocop("ended", playerRefFirstEndedEvent(playList));
      playerRefSecond.current.addEventListenerNeurocop("timeupdate", playerRefSecondTimeUpdateEvent(playList));
      playerRefSecond.current.addEventListenerNeurocop("ended", playerRefSecondEndedEvent(playList));
    }
  }, [playList, playbackRate])

  const player = (id, src, thumbnail, style, refParam) => {
    return <video
      key={id}
      poster={thumbnail}
      style={style}
      ref={refParam}
      width={'100%'}
      height={'100%'}
      playsInline
      type="video/"
      muted
    >
      <source src={src} />
    </video>
  };

  return <>{
    playList && <LayerPlayer {...{
      currentPlayer,
      nextPlayer: currentPlayer != playerRefFirst ? playerRefFirst : playerRefSecond,
      playList,
      currentTimeStampPlaying,
      setSeek,
      upSpeed: () => {
        if (playbackRate < 16) {
          setPlaybackRate(playbackRate * 2)
          currentPlayer.current.playbackRate = playbackRate * 2
        }
      },
      lowSpeed: () => {
        if (playbackRate > 0.125) {
          setPlaybackRate(playbackRate / 2)
          currentPlayer.current.playbackRate = playbackRate / 2
        }
      },
      playbackRate,
      setPlaybackRate,
      displayControl,
      playerLoading,
      setPlayerLoading,
      playerTakeAllLittleScreen,
      setPlayerTakeAllLittleScreen,
      zoom,
      setZoom,
      positionX,
      setPositionX,
      positionY,
      setPositionY,
      mode,
    }}>
      {player(
        1,
        firstPlayerUrlVideo,
        null,
        {
          zIndex: currentPlayer != playerRefFirst ? '1' : '2',
          visibility: currentPlayer != playerRefFirst ? 'hidden' : 'inherit',
          position: 'absolute',
          objectFit: playerTakeAllLittleScreen ? 'fill' : ''
        }
        , playerRefFirst)}

      {player(
        2,
        secondPlayerUrlVideo,
        null,
        {
          zIndex: currentPlayer != playerRefSecond ? '1' : '2',
          visibility: currentPlayer != playerRefSecond ? 'hidden' : 'inherit',
          position: 'absolute',
          objectFit: playerTakeAllLittleScreen ? 'fill' : ''
        }
        , playerRefSecond)}
    </LayerPlayer>

  }</>
}

const LayerPlayer = ({
  children,
  currentPlayer = null,
  nextPlayer = null,
  playList = null,
  currentTimeStampPlaying = 0,
  setSeek,
  upSpeed,
  lowSpeed,
  playbackRate,
  setPlaybackRate,
  displayControl,
  playerLoading,
  setPlayerLoading,
  playerTakeAllLittleScreen,
  setPlayerTakeAllLittleScreen,
  zoom,
  setZoom,
  positionX,
  setPositionX,
  positionY,
  setPositionY,
  mode = 'REPLAY' /* 'REPLAY', 'LIVE' */
}) => {
  const playerScreenRef = React.useRef(null);
  const playerScreenContentRef = React.useRef(null);
  const zoomDrag = React.useRef(null);
  const zoomDrop = React.useRef(null);

  const [displayInterface, setDisplayInterface] = React.useState(false)
  const [playerFullScreen, setPlayerFullScreen] = React.useState(false)
  const [keyPress, setKeyPress] = React.useState(null)
  const [dragZoomZone, setDragZoomZone] = React.useState(false)

  function keyPressFunctionEvent(event) {
    setKeyPress(event)
  }

  React.useEffect(() => {
    if (playerScreenRef.current) {
      playerScreenRef.current.addEventListener('keydown', keyPressFunctionEvent)
    } else {
      playerScreenRef.current.removeEventListener('keydown', keyPressFunctionEvent)
    }
  }, [playerFullScreen, playerScreenRef])

  let img = new Image();
  img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  const getPercentValueLeftByOtherItem = (coordonate, refStatic) => {
    let result = ((
      (coordonate - refStatic.current.getBoundingClientRect().left) / refStatic.current.getBoundingClientRect().width
    ))
    if (result < 0.00) {
      result = 0
    }
    if (result > 1) {
      result = 1
    }
    return result
  }


  const getPercentValueTopByOtherItem = (coordonate, refStatic) => {
    let result = ((
      (coordonate - refStatic.current.getBoundingClientRect().top) / refStatic.current.getBoundingClientRect().height
    ))
    if (result < 0.00) {
      result = 0
    }
    if (result > 1) {
      result = 1
    }
    return result
  }

  return <div
    ref={playerScreenRef}
    tabIndex="0"
    style={{
      zIndex: '3',
      position: 'relative',
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      cursor: dragZoomZone ? 'grabbing' : 'inherit',
    }}
    onClick={() => { playerScreenRef.current.focus() }}
    onMouseMove={() => { setDisplayInterface(true); playerScreenRef.current.focus() }}
    onMouseLeave={() => setDisplayInterface(false)}
    onWheel={(e) => {
      if (playerFullScreen || displayInterface) {
        if (e.nativeEvent.wheelDelta > 0) {
          setZoom(zoom + 0.1)
        } else {
          if (zoom > 1) {
            setZoom(zoom - 0.1)
          }
        }
        if (e.clientX > 0) {
          setPositionX(getPercentValueLeftByOtherItem(positionX + e.clientX, playerScreenRef))
        }
        if (e.clientY > 0) {
          setPositionY(getPercentValueTopByOtherItem(positionY + e.clientY, playerScreenRef))
        }
      }
    }}
  >
    <div
      ref={playerScreenContentRef}
      onClick={() => { keyPressFunctionEvent({ keyCode: 32 }) }}
      style={{
        zIndex: '3',
        position: 'absolute',
        backgroundColor: 'transparent',
        width: '100%',
        height: '100%',
        transform: `scale(${zoom})`,
        overflow: 'hidden',
        left: playerScreenRef.current && positionX != null ? (positionX * (playerScreenRef.current.getBoundingClientRect().width - playerScreenContentRef.current.getBoundingClientRect().width)) - (playerScreenRef.current.getBoundingClientRect().width - playerScreenContentRef.current.getBoundingClientRect().width) / 2 : 'auto',
        top: playerScreenRef.current && positionY != null ? (positionY * (playerScreenRef.current.getBoundingClientRect().height - playerScreenContentRef.current.getBoundingClientRect().height)) - (playerScreenRef.current.getBoundingClientRect().height - playerScreenContentRef.current.getBoundingClientRect().height) / 2 : 'auto'
      }}
    >
      {children}
    </div>
    {(displayInterface) && zoom != 1 && <>
      <span
        style={{
          position: 'absolute',
          width: '20%',
          height: '10%',
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          left: '1%',
          top: '1%',
          zIndex: '3',
        }}>
        <>
          zoom : {Math.round(zoom * 100) / 100}<br />
          position X : {Math.round(positionX * 100) / 100}<br />
          position Y : {Math.round(positionY * 100) / 100}<br />
        </>
      </span>
      <div
        ref={zoomDrop}
        style={{
          position: 'absolute',
          width: '10%',
          height: '10%',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          left: '50%',
          top: '75%',
          zIndex: '3',
        }}>
        <div
          droppable="true"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            border: 'solid black 1px',
            zIndex: '3',
          }}
        />
        <div
          ref={zoomDrag}
          draggable="true"
          onDragStart={(e) => {
            e.dataTransfer.setDragImage(img, 0, 0);
            setDragZoomZone(true)
          }}
          onDragEnd={(e) => {
            setDragZoomZone(false)
          }}
          onDrag={(e) => {
            if (e.clientX > 0) {
              setPositionX(getPercentValueLeftByOtherItem(e.clientX, zoomDrop))
            }
            if (e.clientY > 0) {
              setPositionY(getPercentValueTopByOtherItem(e.clientY, zoomDrop))
            }
          }}
          style={{
            cursor: dragZoomZone ? 'grabbing' : 'grab',
            position: 'absolute',
            width: '100%',
            height: '100%',
            transform: `scale(${1 / zoom})`,
            backgroundColor: 'transparent',
            border: 'solid yellow 2px',
            zIndex: '3',
            left: zoomDrop.current && positionX != null ? (positionX * (zoomDrop.current.getBoundingClientRect().width - zoomDrag.current.getBoundingClientRect().width)) - (zoomDrop.current.getBoundingClientRect().width - zoomDrag.current.getBoundingClientRect().width) / 2 : 'auto',
            top: zoomDrop.current && positionY != null ? (positionY * (zoomDrop.current.getBoundingClientRect().height - zoomDrag.current.getBoundingClientRect().height)) - (zoomDrop.current.getBoundingClientRect().height - zoomDrag.current.getBoundingClientRect().height) / 2 : 'auto'
          }}
        />
      </div>
    </>}
    {
      <InterfacePlayer {...{
        style: {
          visibility: displayInterface && displayControl ? 'inherit' : 'hidden'
        },
        currentPlayer,
        nextPlayer,
        playList,
        currentTimeStampPlaying,
        setSeek,
        upSpeed,
        lowSpeed,
        playerScreenRef,
        playbackRate,
        setPlaybackRate,
        playerLoading,
        setPlayerLoading,
        playerTakeAllLittleScreen,
        setPlayerTakeAllLittleScreen,
        zoom,
        setZoom,
        playerFullScreen,
        setPlayerFullScreen,
        positionX,
        setPositionX,
        positionY,
        setPositionY,
        keyPress,
        mode,
        displayInterface
      }} />
    }
  </div >
}

const InterfacePlayer = ({
  style,
  currentPlayer = null,
  nextPlayer = null,
  playList = null,
  currentTimeStampPlaying = 0,
  setSeek,
  upSpeed,
  lowSpeed,
  playerScreenRef,
  playbackRate,
  setPlaybackRate,
  playerLoading,
  setPlayerLoading,
  playerTakeAllLittleScreen,
  setPlayerTakeAllLittleScreen,
  zoom,
  setZoom,
  playerFullScreen,
  setPlayerFullScreen,
  positionX,
  setPositionX,
  positionY,
  setPositionY,
  keyPress,
  mode = 'REPLAY' /* 'REPLAY', 'LIVE' */,
  displayInterface
}) => {
  const [playerState, setPlayerState] = React.useState('PAUSE') // PLAY / PAUSE / ENDED / LOAD / ERROR
  const [seekState, setSeekState] = React.useState(false)
  const [positionSeek, setPositionSeek] = React.useState(0)
  const [positionSeekHover, setPositionSeekHover] = React.useState(null)
  const [positionThumbnail, setPositionThumbnail] = React.useState(0)
  const [srcThumbnail, setSrcThumbnail] = React.useState(null)
  const rangeRef = React.useRef(null)
  const rangeSpanRef = React.useRef(null)
  const rangeThumbnailRef = React.useRef(null)

  React.useEffect(() => {
    if (keyPress) {
      switch (keyPress.keyCode) {
        case 38:
          if (zoom > 1 && positionY > 0) setPositionY(positionY - 0.05)
          break;
        case 37:
          if (zoom > 1 && positionX > 0) setPositionX(positionX - 0.05)
          if (mode === 'REPLAY' && zoom === 1) setSeek(positionSeek - (playList[playList.length - 1].endDate.timestampMS - playList[0].startDate.timestampMS) / 100, playList, {
            continueToPlay: false
          })
          if (mode === 'LIVE' && zoom === 1 && currentTimeStampPlaying - 5000 > playList[0].startDate.timestampMS + 6000) setSeek(currentTimeStampPlaying - 5000, playList, {
            continueToPlay: true
          })
          break;
        case 40:
          if (zoom > 1 && positionY < 1) setPositionY(positionY + 0.05)
          break;
        case 39:
          if (zoom > 1 && positionX < 1) setPositionX(positionX + 0.05)
          if (mode === 'REPLAY' && zoom === 1) setSeek(currentTimeStampPlaying + (playList[playList.length - 1].endDate.timestampMS - playList[0].startDate.timestampMS) / 100, playList, {
            continueToPlay: false
          })
          if (mode === 'LIVE' && zoom === 1 && currentTimeStampPlaying + 5000 < new Date().getTime() - 120000) setSeek(currentTimeStampPlaying + 5000, playList, {
            continueToPlay: true
          })
          break;
        case 32:
          playPausePlayer(playerState, playList)
          break;
        default:
          return
      }
    }
  }, [keyPress])


  React.useEffect(() => {
    let statePlayer = playerState
    if (!currentPlayer.current.paused || !nextPlayer.current.paused) {
      statePlayer = 'PLAY'
    }
    if (currentPlayer.current.paused && !nextPlayer.current.paused) {
      statePlayer = 'PAUSE'
    }
    if (playList && (currentPlayer.current.paused || nextPlayer.current.paused) && Math.round(currentTimeStampPlaying / 1000) === Math.round(playList[playList.length - 1].endDate.timestampMS / 1000)) {
      statePlayer = 'ENDED'
    }
    if (playerLoading && currentPlayer.current.paused && nextPlayer.current.paused) {
      statePlayer = 'LOAD'
    }
    if (currentPlayer.current.error && !(Math.round(currentTimeStampPlaying / 1000) === Math.round(playList[playList.length - 1].endDate.timestampMS / 1000))) {
      console.log('error : ', currentPlayer.current.error)
      statePlayer = 'ERROR'
      currentPlayer.current.src = null
      nextPlayer.current.src = null
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        setSeek(positionSeek + 10, playList, {
          continueToPlay: true
        })
      })
    }
    setPlayerState(statePlayer)
    if (!seekState) {
      setPositionSeek(currentTimeStampPlaying)
    }
  }, [currentTimeStampPlaying, playerLoading])

  React.useEffect(() => {
    setPositionThumbnail(getPositionRangeRef(rangeThumbnailRef))
    setSrcThumbnail(positionSeekHover ? playList.find((video) => video.startDate.timestampMS < positionSeekHover && video.endDate.timestampMS > positionSeekHover) : null)
  }, [seekState])

  const playPausePlayer = (playerStateParam, playList) => {
    if (mode === 'REPLAY' || true) {
      switch (playerStateParam) {
        case 'PLAY':
          currentPlayer.current.pause()
          setPlayerState('PAUSE')
          break;
        case 'PAUSE':
          currentPlayer.current.play()
          setPlayerState('PLAY')
          break;
        case 'ENDED':
          setSeek(null, playList, {
            continueToPlay: true
          }, false)
          break;
        case 'LOAD':
          if (currentPlayer.current.src.search('/null') === -1) {
            currentPlayer.current.play()
            setPlayerState('PLAY')
            setPlayerLoading(false)
          }
          break;
        case 'ERROR':
          setSeek(currentTimeStampPlaying + 1000, playList, {
            continueToPlay: false
          })
          break;
        default:
          return
      }
    }
  }

  const getIconStatePlayer = () => {
    let result = <><FontAwesomeIcon icon={faTimes} /></>
    switch (playerState) {
      case 'PLAY':
        result = <><FontAwesomeIcon icon={faPause} /></>;
        break;
      case 'PAUSE':
        result = <><FontAwesomeIcon icon={faPlay} /></>;
        break;
      case 'LOAD':
        result = <><FontAwesomeIcon icon={faSpinner} spin /></>;
        break;
      case 'ENDED':
        result = <><FontAwesomeIcon icon={faArrowLeft} /></>;
        break;
      case 'ERROR':
        result = <><FontAwesomeIcon icon={faTimes} /></>;
        break;
      default:
        result = <></>;
        break;
    }
    return result;
  }

  const fullScreen = () => {
    setPlayerFullScreen(!playerFullScreen)
    if (!playerFullScreen) {
      playerScreenRef.current.requestFullscreen()
    } else {
      window.document.exitFullscreen()
    }
  }

  const getPositionRangeRef = (refItemToPlace) => {
    let result = 0;
    if (!seekState && refItemToPlace.current && rangeRef.current && positionSeek) {
      let rangeRefWidth = Number(window.getComputedStyle(ReactDOM.findDOMNode(rangeRef.current)).width.replace('px', ''));
      let rangeRefMinValue = Number(ReactDOM.findDOMNode(rangeRef.current).min);
      let rangeRefMaxValue = Number(ReactDOM.findDOMNode(rangeRef.current).max) - rangeRefMinValue;
      let positionToDisplay = positionSeek - rangeRefMinValue;

      let rangeRefSpanWidth = Number(window.getComputedStyle(ReactDOM.findDOMNode(refItemToPlace.current)).width.replace('px', ''));
      if ((rangeRefWidth / rangeRefMaxValue) * positionToDisplay > rangeRefSpanWidth / 2) {
        result = (rangeRefWidth / rangeRefMaxValue) * positionToDisplay - rangeRefSpanWidth / 2
      }
      if ((rangeRefWidth / rangeRefMaxValue) * positionToDisplay + rangeRefSpanWidth / 2 > rangeRefWidth) {
        result = rangeRefWidth - rangeRefSpanWidth
      }
    }
    if (seekState) {
      result = seekState - (Number(window.getComputedStyle(ReactDOM.findDOMNode(rangeThumbnailRef.current)).width.replace('px', '')) / 1.50)
      if (result < 0) {
        result = 0
      }
      if (result + (Number(window.getComputedStyle(ReactDOM.findDOMNode(rangeThumbnailRef.current)).width.replace('px', ''))) > Number(window.getComputedStyle(ReactDOM.findDOMNode(rangeRef.current)).width.replace('px', ''))) {
        result = Number(window.getComputedStyle(ReactDOM.findDOMNode(rangeRef.current)).width.replace('px', '')) - (Number(window.getComputedStyle(ReactDOM.findDOMNode(rangeThumbnailRef.current)).width.replace('px', '')))
      }
    }
    return result;
  }

  return <div
    style={{
      height: '8rem',
      width: '100%',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75))',
      color: 'white',
      zIndex: '4',
      position: 'absolute',
      bottom: '0',
      ...style
    }}
  >
    <div style={{
      height: '20%',
    }}>
      {
        <StyledButtonPlayer title="Play/Pause" onClick={(e) => { playPausePlayer(playerState, playList); }}>
          {
            getIconStatePlayer()
          }
        </StyledButtonPlayer>
      }
      { // Change here
        mode === 'REPLAY' && (playerFullScreen || displayInterface) && <>
          <StyledButtonPlayer title="Image précedente" onClick={() => {
            setSeek(positionSeek - 10, playList, {
              continueToPlay: false
            })
          }}>
            <FontAwesomeIcon icon={faStepBackward} />
          </StyledButtonPlayer>
          <StyledButtonPlayer title="Image suivante" onClick={() => setSeek(positionSeek + 10, playList, {
            continueToPlay: false
          })}>
            <FontAwesomeIcon icon={faStepForward} />
          </StyledButtonPlayer>
        </>
      }
      {
        mode === 'REPLAY' && <>
          <StyledButtonPlayer title="Rallentissement" onClick={() => { lowSpeed() }}>
            <FontAwesomeIcon icon={faBackward} />
          </StyledButtonPlayer>
          <StyledButtonPlayer title="Vitesse de lecture">
            x{currentPlayer.current && playbackRate}
          </StyledButtonPlayer>
          <StyledButtonPlayer title="Accélération" onClick={() => { upSpeed() }}>
            <FontAwesomeIcon icon={faForward} />
          </StyledButtonPlayer>
        </>}
      {
        (playerFullScreen || displayInterface) && <>
          <StyledButtonPlayer title="Dézoomer" onClick={() => {
            setZoom(zoom - 0.1)
          }}>
            <FontAwesomeIcon icon={faSearchMinus} />
          </StyledButtonPlayer>
          <StyledButtonPlayer title="Reset zoom" onClick={() => {
            setZoom(1)
            setPositionX(0.50)
            setPositionY(0.50)
          }}>
            <FontAwesomeIcon icon={faDesktop} />
          </StyledButtonPlayer>
          <StyledButtonPlayer title="Zoomer" onClick={() => {
            setZoom(zoom + 0.1)
          }}>
            <FontAwesomeIcon icon={faSearchPlus} />
          </StyledButtonPlayer>
        </>

      }
      {
        /*
        <StyledButtonPlayer title="Son">
        <FontAwesomeIcon icon={faVolumeOff} />
        <FontAwesomeIcon icon={faVolumeUp} />
        <FontAwesomeIcon icon={faVolumeDown} />
        </StyledButtonPlayer>
      */
      }
      <StyledButtonPlayer
        style={{
          float: 'right'
        }}
        title="Plein écran"
        onClick={() => { fullScreen() }}
      >
        {
          playerFullScreen === true ? <FontAwesomeIcon icon={faCompress} /> : <FontAwesomeIcon icon={faExpand} />
        }
      </StyledButtonPlayer>
      <StyledButtonPlayer
        style={{
          float: 'right'
        }}
        title="Zoom"
        onClick={() => {
          setPlayerTakeAllLittleScreen(!playerTakeAllLittleScreen)
        }}
      >
        <FontAwesomeIcon style={{ color: playerTakeAllLittleScreen ? 'blue' : 'white' }} icon={faArrowsAlt} />
      </StyledButtonPlayer>
    </div>


    <div style={{
      height: '80%',
    }}>
      {mode === 'REPLAY' && <div style={{
        width: '100%',
        display: 'flex',
      }}>
        <div style={{
          textAlign: 'start',
          marginLeft: '3px',
          width: '50%',
        }}>
          {playList && formatDate(playList[0].startDate.timestampMS)}
        </div>
        <div style={{
          textAlign: 'end',
          marginRight: '3px',
          width: '50%',
        }}>
          {playList && formatDate(playList[playList.length - 1].endDate.timestampMS)}
        </div>
      </div>}
      <div style={{
      }}>
        {
          mode === 'REPLAY' && <>
            <div style={{
              width: '95%',
              height: '150px',
              marginLeft: '2.5%',
              marginRight: '2.5%',
              position: 'absolute',
              top: '-110%',
              visibility: seekState ? 'visible' : 'hidden'
            }}>
              {(() => {
                if (positionSeekHover && srcThumbnail) {
                  rangeThumbnailRef.current.currentTime = positionSeekHover - srcThumbnail.startDate.timestampMS
                }
                return <>
                  <video
                    ref={rangeThumbnailRef}
                    src={srcThumbnail ? srcThumbnail.url : null}
                    muted
                    style={{
                      width: '180px',
                      height: '100%',
                      position: 'relative',
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      zIndex: 4,
                      left: `${positionThumbnail ? positionThumbnail : 0}px`
                    }} />
                  {seekState && <FontAwesomeIcon style={{
                    position: 'relative',
                    top: '-4rem',
                    zIndex: 3,
                    left: `${positionThumbnail ? positionThumbnail - 95 : -95}px`
                  }} icon={faSpinner} spin />}
                </>
              })()}
            </div>
            <input
              ref={rangeRef}
              style={{
                width: '95%',
                marginLeft: '2.5%',
                marginRight: '2.5%',
                WebkitAppearance: 'none',    /*nécessaire pour Chrome */
                padding: '0',                   /* nécessaire pour IE */
                font: 'inherit',                /* même rendu suivant font document */
                outline: 'none',
                color: '#069',                  /* sert pour couleur de référence, via currentColor, pour le curseur */
                opacity: '.8',
                background: '#CCC',             /* sert pour couleur de fond de la zone de déplacement */
                boxSizing: 'border-box',       /* même modèle de boîte pour tous */
                transition: 'opacity .2s',
                cursor: 'pointer',
              }}
              type="range"
              id="cowbell"
              name="cowbell"
              min={playList && playList[0].startDate.timestampMS || 0}
              max={playList && playList[playList.length - 1].endDate.timestampMS || 100}
              step="1"
              value={positionSeekHover || positionSeek}
              readOnly
              onMouseMove={(e) => {
                let currentHoverValue =
                  Math.round(((e.clientX - rangeRef.current.getBoundingClientRect().left)) / (Number(window.getComputedStyle(ReactDOM.findDOMNode(rangeRef.current)).width.replace('px', ''))) * (
                    Number(ReactDOM.findDOMNode(rangeRef.current).max) - Number(ReactDOM.findDOMNode(rangeRef.current).min)
                  ) + Number(ReactDOM.findDOMNode(rangeRef.current).min))
                if (currentHoverValue >= Number(ReactDOM.findDOMNode(rangeRef.current).max)) {
                  currentHoverValue = Number(ReactDOM.findDOMNode(rangeRef.current).max)
                }
                if (currentHoverValue <= Number(ReactDOM.findDOMNode(rangeRef.current).min)) {
                  currentHoverValue = Number(ReactDOM.findDOMNode(rangeRef.current).min)
                }
                setPositionSeekHover(currentHoverValue)
                setSeekState((e.clientX - rangeRef.current.getBoundingClientRect().left))
              }}
              onMouseLeave={(e) => {
                setSeekState(null)
                setPositionSeekHover(null)
              }}
              onMouseUp={(e) => {
                setSeekState(null)
                setSeek(positionSeekHover, playList, {
                  continueToPlay: true
                }, false)
              }}
            />
          </>
        }
        <div style={{
          width: '95%',
          marginLeft: '2.5%',
          marginRight: '2.5%',
        }}>
          <span
            ref={rangeSpanRef}
            style={{
              width: '180px',
              position: 'relative',
              left: `${rangeRef.current && positionSeek && (seekState || !seekState) ? getPositionRangeRef(rangeSpanRef) : 0}px`
            }}>
            {positionSeekHover && formatDate(positionSeekHover)}
            {(!positionSeekHover && positionSeek ? formatDate(positionSeek) : 0)}
          </span>
        </div>
      </div>
    </div>
  </div>
}

export default Player
