export const upload = {
    upload: `Upload`,
    uploadOneOrMoreVideoFilesByDragAndDropOrByClickingOnChooseFileSupportedFormatIsMP4EncodedInH264: `Upload one or more video files by drag and drop or by clicking on Choose file.
Supported format is MP4 encoded in H264`,
    chooseFile: `Choose file`,
    recentUploads: `Recent uploads`,
    date: `Date`,
    name: `Name`,
    start: `Start`,
    end: `End`,
    status: `Status`,
    delete: `Delete`,
    deleteThisVideo: `Delete the video`,
    thisActionIsIrreversible: `This action is irreversible.`,
    areYouSure: `Are you sure ?`,
}
export default upload
