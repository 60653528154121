import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import paginator from 'paginator'

import { Icon, AngleLeftIcon, AngleRightIcon } from './Icons'

const A = styled.a`
  position: relative;
  display: block;
  color: inherit;
  text-decoration: none;
  line-height: 1;

  ${Icon} {
    width: .875rem;
    vertical-align: bottom;
  }
`

const StyledPage = styled.li`
  display: inline-block;
  margin: 0 .5rem;
  padding: .125rem .25rem;
  line-height: 1;
  border-radius: 4px;
  border: 1px solid ${({ theme, active }) => active ? theme.gray : theme.white};
  color: ${({ theme }) => theme.black};
`

const Page = ({
  index,
  label,
  href,
  ariaLabel,
  active,
  disabled,
  onClick,
  ...props
}) => {
  const handleClick = (e) => {
    e.preventDefault();

    if (disabled) return
    onClick(index)
  }

  return (
    <StyledPage onClick={handleClick} active={active} {...props}>
      <A href={href} ariaLabel={ariaLabel} disabled={disabled}>
        {label}
      </A>
    </StyledPage>
  )
}

Page.propTypes = {
  index: PropTypes.number,
  label: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  href: PropTypes.string,
  ariaLabel: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
}

Page.defaultProps = {
  href: '#',
  ariaLabel: null,
  active: false,
  disabled: false,
}

const StyledPrevNext = styled(Page)`
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme, disabled }) => disabled ? theme.gray : theme.primaryColor};
`

const StyledPagination = styled.div`
  display: flex;
  margin: 1rem 0;
  justify-content: center;
`

const StyledPages = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
`

export const Pagination = ({
  nbItemsPerPage,
  nbPagesDisplayed,
  activePageIndex,
  totalItems,
  onChange,
  getPageUrl,
}) => {
  const [infos, setInfos] = useState({})
  const [pages, setPages] = useState([])

  useEffect(() => {
    const _infos = new paginator(
      nbItemsPerPage,
      nbPagesDisplayed,
    ).build(totalItems, activePageIndex)

    const { first_page, last_page } = _infos

    setInfos(_infos)
    setPages(Array(last_page - first_page + 1).fill().map((_, idx) => first_page + idx))
  }, [nbItemsPerPage, nbPagesDisplayed, totalItems, activePageIndex])

  if (!infos.has_previous_page && !infos.has_next_page) return null

  return (
    <StyledPagination>
      <StyledPages>
        <StyledPrevNext
          index={infos.previous_page}
          href={getPageUrl(infos.previous_page)}
          label={<AngleLeftIcon />}
          onClick={onChange}
          disabled={!infos.has_previous_page}
        />
        {pages.map(i =>
          <Page
            key={`page-${i}`}
            index={i}
            href={getPageUrl(i)}
            label={i + ''}
            onClick={onChange}
            active={activePageIndex === i}
          />
        )}
        <StyledPrevNext
          index={infos.next_page}
          href={getPageUrl(infos.next_page)}
          label={<AngleRightIcon />}
          onClick={onChange}
          disabled={!infos.has_next_page}
        />
      </StyledPages>
    </StyledPagination>
  )
}

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  nbItemsPerPage: PropTypes.number,
  nbPagesDisplayed: PropTypes.number,
  activePageIndex: PropTypes.number,
  getPageUrl: PropTypes.func,
}

Pagination.defaultProps = {
  nbItemsPerPage: 20,
  nbPagesDisplayed: 5,
  activePageIndex: 1,
  getPageUrl: i => '#'
}
