const resultScenario = {
    addToFavorites: "Add to favorites",
    allSequences: "All sequences",
    camera: "Camera",
    eventAt: "Event at",
    falsePositives: "False positives",
    favorites: "Favorites",
    filterByCamera: "Filter by camera",
    filterByType: "Filter by type",
    filterByView: "Filter by view",
    hidden: "Hidden",
    hideAll: "Hide all",
    hideThisResult: "Hide this result",
    highestToLowestScore: "Highest to lowest score",
    lowestToHighestScore: "Lowest to highest score",
    markAllAsSeen: "Mark all as seen",
    newestToOldest: "Newest to oldest",
    newResult: "New result",
    nonViewsOnly: "Non-views only",
    oldestToNewest: "Oldest to newest",
    onlyViews: "Only views",
    reportAsFalsePositive: "Report as false positive",
    results: "Results",
    scenariosResults: "Scenarios&gt;Results",
    sortBy: "Sort by",
    uniqueThisResult: "This result unique",
    scenario: 'Scenarios',
    removeFalsePositive: "Remove false positiv",
    wouldYouLikeToRemoveThisResultFromTheFalsePositiveList: "Would you like to remove this result from the false positive list",
    allFalsePositiveAssociate: "All false positive associate",
    close: "Close",
    makeThisResultVisible: "Make this result visible",
    removeFromFavorites: "Remove from favorites",
    addToFavorites: "Ajouter aux favoris",
}
export default resultScenario