import React from 'react';
import styled from "styled-components";

const TooltipContentContainer = styled.div`
  display: none;
  width: 120px;
  background-color: #0f1215;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  width: 300px;
  position: absolute;
  z-index: 2;
  top: 115%;
  left: calc(50% - 150px);
  font-size: .875rem;
  box-shadow: 5px 5px 16px 0px rgba(0, 0, 0, 0.3);

  div:first-child {
    padding: 1rem;
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: calc(50% - 10px);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`

const CameraTooltipContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    ${TooltipContentContainer} {
      display: block;
    }
  }

  ${TooltipContentContainer}:hover {
    visibility: visible;
  }
`

export const CameraTooltipButton = styled.button`
  width: 100%;
  text-align: center;
  padding: .9rem 0;
  color: ${({ disabled }) => disabled ? '#999' : '#fff'};
  background-color: transparent;
  border: none;
  border-top: 1px solid #fff;
  cursor: ${({ disabled }) => disabled ? 'inherit' : 'pointer'};

  &:focus {
    outline: none;
  }
`

const Info = styled.b`
  overflow-wrap: anywhere;
`

const Thumbnail = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const CameraTooltip = ({
  camera,
  children,
  content,
  deleteCamera,
  modifyCamera,
  hideInfo,
  hideControls,
  renderPreview,
  ...props
}) => {
  const TooltipContent = content

  return (
    <CameraTooltipContainer {...props}>
      {children}
      <TooltipContentContainer>
        <TooltipContent camera={camera} />
      </TooltipContentContainer>
      {/*<StyledCameraTooltip>
        <div>
          {renderPreview(camera)}
          {
            !hideInfo && (
              <>
                <Info>Stream: </Info>{camera.input}<br />
                <Info>Temps réel:</Info> {camera.realtime ? 'Oui' : 'Non'}<br />
                <Info>Secteurs:</Info> {camera.sectors.map(camera => camera.name).join(', ') || 'Aucun'}<br />
                <Info>Description:</Info> {camera.description ? camera.description : <i>Pas de description disponible.</i>}<br />
              </>
            )
          }
        </div>
        {
          !hideControls && (
            <>
              <CameraTooltipButton onClick={() => modifyCamera(camera)}>
                Modifier la configuration
              </CameraTooltipButton>
              <CameraTooltipButton onClick={() => deleteCamera(camera)}>
                Supprimer
              </CameraTooltipButton>
            </>
          )
        }
      </StyledCameraTooltip>*/}
    </CameraTooltipContainer>
  )
}

CameraTooltip.defaultProps = {
  hideControls: false,
  hideInfo: false
}
