import React from 'react'
import styled from 'styled-components'
import { H1, H3, P } from '../components/Typography'
import { useHistory } from 'react-router-dom'
import {
  TextInput,
  EditIcon,
  FormGroup,
  Radio,
  Link,
  Spinner
} from '../components/design-system'
import { Button, SmallButton } from '../components/Button'
import { useFetch } from '../services/fetch'
import { fetchAllUsers, updateUser, deleteUser } from '../services'

const StyledH1 = styled(H1)`
  font-family: Blinker;
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.8125rem;
`

const SectionLoading = styled.div`
  text-align: center;
`

export default function RoleAdmin() {
  const usersRequest = useFetch(fetchAllUsers, [])
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(false)
  const [activeUserId, setActiveUserId] = React.useState(null)

  async function onSubmit(e, form) {
    e.preventDefault()
    setIsUpdating(true)
    await updateUser(form.id, form)
    await usersRequest.refetch({ withLoader: false })
    setIsUpdating(false)
  }

  async function onDelete(e, form) {
    setIsDeleting(true)
    await deleteUser(form.id)
    await usersRequest.refetch({ withLoader: false })
    setActiveUserId(null)
    setIsDeleting(false)
  }

  if (usersRequest.loading || !usersRequest.data) return (
    <SectionLoading>
      <Spinner />
    </SectionLoading>
  )

  const users = usersRequest.data.users
  const activeUser = users.find((u) => u.id === activeUserId)

  return (
    <>
      <StyledH1>Gestion des utilisateurs</StyledH1>
      <Content>
        {activeUser && (
          <AddOrUpdateUser
            user={activeUser}
            isDeleting={isDeleting}
            isUpdating={isUpdating}
            onDelete={onDelete}
            onSubmit={onSubmit}
          />
        )}
        <UserList
          users={users}
          activeUserId={activeUserId}
          onUserSelect={setActiveUserId}
        />
      </Content>
    </>
  )
}

const UpdateUserContainer = styled.div`
  border-right: 1px solid #ddd;
  width: 35%;
  padding: 0 50px;
`
const UpdateUserForm = styled.form`
  ${FormGroup} {
    margin-bottom: 20px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 50px;
`

function AddOrUpdateUser({ user, isUpdating, isDeleting, onSubmit, onDelete }) {
  const [form, setForm] = React.useState(user)

  React.useEffect(() => {
    setForm(user)
  }, [user.id])

  const setField = (field) => (value) =>
    setForm((prevForm) => ({ ...prevForm, [field]: value }))

  return (
    <UpdateUserContainer>
      <H3>Modifier un utilisateur</H3>
      <UpdateUserForm onSubmit={(e) => onSubmit(e, form)}>
        <FormGroup>
          <TextInput
            value={form.lastname}
            onChange={setField('lastname')}
            buttonIcon={EditIcon}
            label="Nom"
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            value={form.firstname}
            onChange={setField('firstname')}
            buttonIcon={EditIcon}
            label="Prénom"
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            value={form.email}
            onChange={setField('email')}
            buttonIcon={EditIcon}
            label="eMail"
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            value={form.company}
            onChange={setField('company')}
            buttonIcon={EditIcon}
            label="Société"
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            value={form.companyNumber}
            onChange={setField('companyNumber')}
            buttonIcon={EditIcon}
            label="Numéro de poste"
          />
        </FormGroup>
        <P style={{ fontWeight: 'bold' }}>Rôle</P>
        <FormGroup>
          <Radio
            name="role"
            onChange={() => setField('type')('user')}
            checked={form.type === 'user'}
            radioColor="#50C5F3"
            label="Utilisateur"
          />
          <Radio
            name="role"
            checked={form.type === 'admin'}
            onChange={() => setField('type')('admin')}
            radioColor="#50C5F3"
            label="Administrateur"
          />
        </FormGroup>
        <P style={{ fontWeight: 'bold' }}>Actif</P>
        <FormGroup>
          <Radio
            name="active"
            onChange={() => setField('active')(true)}
            checked={form.active === true}
            radioColor="#50C5F3"
            label="Actif"
          />
          <Radio
            name="active"
            checked={form.active === false}
            onChange={() => setField('active')(false)}
            radioColor="#50C5F3"
            label="Désactivé"
          />
        </FormGroup>
        <ButtonContainer>
          <SmallButton
            disabled={isUpdating}
            type="submit"
            color="validGreen"
            hoverColor="#00cc00"
            style={{ marginRight: 10 }}
          >
            Valider
          </SmallButton>
          <SmallButton
            disabled={isDeleting}
            type="button"
            color="red"
            hoverColor="#e00800"
            onClick={(e) => onDelete(e, form)}
          >
            Supprimer
          </SmallButton>
        </ButtonContainer>
      </UpdateUserForm>
    </UpdateUserContainer>
  )
}

const UserListContainer = styled.div`
  width: 55%;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
`

const UserListUsersContainer = styled.div`
  display: flex;
  flex: 1;
`

const UserListAdminUsers = styled.div`
  padding: 10px;
  width: 50%;
  border-right: 1px solid #ddd;
`
const UserListUsers = styled.div`
  padding: 10px;
  padding-left: 30px;
  width: 50%;
`

function UserList({ users, activeUserId, onUserSelect }) {
  const regularUsers = users.filter((d) => d.type !== 'admin')
  const adminUsers = users.filter((d) => d.type === 'admin')

  return (
    <UserListContainer>
      <H3>Utilisateurs référencés</H3>
      <UserListUsersContainer>
        <UserListAdminUsers>
          <H3 style={{ fontSize: 20 }}>Administrateurs</H3>
          {adminUsers.map((user) => (
            <User
              key={user.id}
              user={user}
              isActive={activeUserId === user.id}
              onEdit={onUserSelect}
            />
          ))}
        </UserListAdminUsers>
        <UserListUsers>
          <H3 style={{ fontSize: 20 }}>Utilisateurs</H3>
          {regularUsers.map((user) => (
            <User
              key={user.id}
              user={user}
              isActive={activeUserId === user.id}
              onEdit={onUserSelect}
            />
          ))}
        </UserListUsers>
      </UserListUsersContainer>
    </UserListContainer>
  )
}

const UserName = styled(P)`
  margin: 0;
  cursor: pointer;
  user-select: none;
  color: ${({ theme, isActive }) =>
    isActive ? theme.primaryColor : undefined};
`

function User({ user, isActive, onEdit }) {
  const [open, setOpen] = React.useState(false)

  function toggle() {
    setOpen((prevOpen) => !prevOpen)
  }

  return (
    <div style={{ marginBottom: 8 }}>
      <UserName isActive={isActive} onClick={toggle}>
        {user.firstname} {user.lastname}
      </UserName>
      {open && (
        <div>
          <P style={{ margin: 0, marginLeft: 10 }}>
            eMail: {user.email}
            {user.company && (
              <>
                <br />
                `Société: ${user.company}`
              </>
            )}
            {user.employeeId && (
              <>
                <br />
                `N° de poste: ${user.employeeId}`
              </>
            )}
          </P>
          <Link as="p" onClick={() => onEdit(user.id)}>
            Modifier
          </Link>
        </div>
      )}
    </div>
  )
}
