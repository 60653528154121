import axios from 'axios'

export const testCamera = (params, cancelToken) =>
  axios
    .post('/api/cameras/test', params, { cancelToken })
    .then(({ data }) => data)

export const testCameraById = (id, cancelToken) =>
  axios
    .get('/api/cameras/test/' + id, { cancelToken })
    .then(({ data }) => data)

export const createCamera = (params) => axios.post('/api/cameras', params)

export const updateCamera = ({ id, ...params }) =>
  axios.put(`/api/cameras/${id}`, params)

export const getCamera = ({ id, ...params }) =>
  axios.get(`/api/cameras/${id}`, params)

export const deleteCamera = (id) => axios.delete(`/api/cameras/${id}`)

export const deleteMultipleCamera = (ids) =>
  axios.delete('/api/cameras', { data: { ids } })

export const getLastVideoForCamera = (id) =>
  axios.get(`/api/cameras/${id}/last-video`).then(({ data }) => data)

export const uploadIniCameras = data =>
  axios
    .post(
      '/api/cameras/upload',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    .then(({ data }) => data)

export const downloadIniCameras = () =>
  axios({
    url: `/api/cameras/download`,
    method: 'GET',
    responseType: 'blob'
  })
    .then(res => {
      console.log(res.data)

      const url = window.URL.createObjectURL(new Blob([res.data]))

      const link = document.createElement('a')

      link.href = url

      const date = new Date().toLocaleDateString("fr-FR").replaceAll('/', '-')

      link.setAttribute('download', `neurocop-save_${date}.ini`)

      document.body.appendChild(link)

      link.click()

      link.parentNode.removeChild(link)
    })
