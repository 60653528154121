import React, { useState, useEffect, useContext } from 'react'
import EnvContext from './../contexts/EnvContext'
import axios from 'axios'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import {
  EditIcon,
  Checkbox,
  PendingIcon,
  PendingAnimatedIcon,
  P,
} from './design-system'
import { TextInput, TextInputMultiline, ValidationTextInput } from './Input'
import { BigButton } from '../components/Button'
import { Error } from '../components/Typography'
import { testCamera } from '../services'

const EditCameraContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  & > div:nth-child(1) {
    flex-grow: 0.6;
  }

  & > div:nth-child(2) {
    flex-grow: 0.4;
    padding: 0 1rem;

    & > *:not(:first-child):not(:last-child) {
      margin-top: 1rem;
    }
  }

  button {
    width: min-content;
    margin-right: 5px;
    margin-left: 5px;
  }
`

const LeftPart = styled.div`
  width: 50%;
`

const EmptyDisplay = styled.div`
  margin-top: 1rem;
  background-color: #000;
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const StyledMapContainer = styled(MapContainer)`
  margin-top: 1rem;
  background-color: #000;
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

const FieldIcon = styled(FontAwesomeIcon)``

const CameraError = styled(Error)`
  margin-bottom: 1rem;
`

const CameraDisplayContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  column-gap: 0.5rem;

  & > img {
    cursor: pointer;
  }
`

const Thumbnail = styled.img`
  width: 100%;
  background: black;
`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: space-between;
`

const ThumbnailContainer = styled.div`
  position: relative;
  margin-top: 1rem;
  background-color: #000;
`

const RefreshPendingIcon = styled(PendingIcon)`
  position: absolute;
  width: 32px;
  height: 32px;
  right: 1rem;
  bottom: 1rem;
  color: #fff;

  &:hover {
    cursor: pointer;
  }
`

const RefreshPendingAnimatedIcon = styled(PendingAnimatedIcon)`
  position: absolute;
  width: 32px;
  height: 32px;
  right: 1rem;
  bottom: 1rem;
  color: #fff;
`

const EditParams = styled.div`
  padding: 1rem 0;
`

const ParamsButton = styled.button`
  background: white;
  border: 0;
  color: #50c5f3;
  padding: 0;

  span {
    font-size: 1.4rem;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`

const ParamsOption = styled.div`
  padding: 0.8rem 0;
`

const Mask = styled.div`
  position: absolute;
  background-color: #000;
  height: ${({ from, size }) =>
    from === 'top' || from === 'bottom' ? size : 100}%;
  width: ${({ from, size }) =>
    from === 'left' || from === 'right' ? size : 100}%;
  ${({ from }) => from === 'bottom' && 'bottom: 0;'}
  ${({ from }) => from === 'right' && 'right: 0;'}
`

const EditCamera = ({
  createCamera,
  creating,
  onSubmitTest,
  onCancelTest,
  selectedCamera,
  setSelectedCamera,
  setUriToTest,
  fetchCameras,
  setCreating,
  testing,
  testingError,
  uriToTest,
  updateCamera,
}) => {

  const [{ env }] = useContext(EnvContext)
  const [onRefresh, setOnRefresh] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [displayMap, setDisplayMap] = useState(false)
  const [error, setError] = useState(null)
  const [openedParams, setOpenedParams] = useState(false)

  const handleCancel = () => {
    onCancelTest()
    setSelectedCamera(null)
  }

  useEffect(() => {
    if (onRefresh) {
      // todo cancel refresh
    }

    setOnRefresh(false)
    setError(null)
  }, [selectedCamera])

  let canceller = axios.CancelToken.source()

  const handleRefresh = () => {
    setOnRefresh(true)
    setDisplayMap(false)
    setError('')

    testCamera({ input: selectedCamera.input }, canceller.token)
      .then(({ img: thumbnail }) => {
        setSelectedCamera({
          ...selectedCamera,
          thumbnail: 'data:image/jpeg;base64,' + thumbnail,
        })
        setOnRefresh(false)
        setOnRefresh(false)
        canceller = axios.CancelToken.source()
      })
      .catch((err) => {
        console.error(err)

        if (err.response && err.response.status === 500)
          setError('Communication avec la caméra impossible.')

        setOnRefresh(false)
        canceller = axios.CancelToken.source()
      })
  }

  const handleSubmit = () => {
    setError('')

    if (!selectedCamera.name) {
      setError('Veuillez donner un nom à la caméra.')
      return
    }
    if (!selectedCamera.input) {
      setError('Veuillez préciser un lien rtsp://.')
      return
    }

    setError(null)
    setSubmitting(true)

    if (creating) {
      createCamera(selectedCamera)
        .then(() => {
          fetchCameras()
          handleCancel()
          setCreating(false)
          setSubmitting(false)
          setUriToTest('')
        })
        .catch((err) => {
          console.error(err)

          if (err.response && err.response.status === 500)
            setError('Communication avec la caméra impossible.')
        })
    } else {
      updateCamera(selectedCamera)
        .then(() => {
          fetchCameras()
          handleCancel()
          setSubmitting(false)
        })
        .catch((err) => {
          console.error(err)

          if (err.response && err.response.status === 500)
            setError('Communication avec la caméra impossible.')
        })
    }
  }

  if (!selectedCamera) {
    return (
      <>
        <form onSubmit={onSubmitTest}>
          <ValidationTextInput
            placeholder="rtsp://"
            onChange={(v) =>
              selectedCamera
                ? setSelectedCamera({ ...selectedCamera, input: v })
                : setUriToTest(v)
            }
            value={selectedCamera?.input || uriToTest}
            button={
              testing ? (
                <FieldIcon icon={faSpinner} spin color="#fff" />
              ) : (
                'tester'
              )
            }
            onSubmit={onSubmitTest}
          />
        </form>
        {error && <CameraError>{error}</CameraError>}
        {testingError && <CameraError>{testingError}</CameraError>}
      </>
    )
  }

  const renderPreviews = () => {
    if (displayMap && lat !== -1.0 && long !== -1.0) {
      return (
        <StyledMapContainer
          key={`map-${lat}-${long}`}
          center={[lat, long]}
          zoom={15}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[lat, long]}>
            <Popup>{selectedCamera?.name}</Popup>
          </Marker>
        </StyledMapContainer>
      )
    } else if (
      (!displayMap || lat === -1.0 || long === -1.0) &&
      (selectedCamera.thumbnail || selectedCamera.thumbnailPath)
    ) {
      return (
        <ThumbnailContainer>
          <div>
            <Mask from="left" size={selectedCamera.mask_left * 100} />
            <Mask from="right" size={selectedCamera.mask_right * 100} />
            <Mask from="top" size={selectedCamera.mask_top * 100} />
            <Mask from="bottom" size={selectedCamera.mask_bottom * 100} />
            <Thumbnail
              src={
                selectedCamera.thumbnail
                  ? selectedCamera.thumbnail
                  : `${env.NODE_ENV != 'production' ? `${window.location.protocol}//${window.location.hostname}:${env.NODE_CONTAINER_DEV_PORT_API}` : window.location.origin}/thumbnails/${selectedCamera.thumbnailFilename}.${selectedCamera.thumbnailExtension}`
              }
            />
          </div>
          {onRefresh ? (
            <RefreshPendingAnimatedIcon />
          ) : (
            <RefreshPendingIcon onClick={handleRefresh} />
          )}
        </ThumbnailContainer>
      )
    } else if (!displayMap) {
      return (
        <EmptyDisplay>
          <FieldIcon color="#fff" icon={faSpinner} spin />
          {onRefresh ? (
            <RefreshPendingAnimatedIcon />
          ) : (
            <RefreshPendingIcon onClick={handleRefresh} />
          )}
        </EmptyDisplay>
      )
    }
  }

  let long = -1
  let lat = -1

  if (
    selectedCamera.coordinates &&
    selectedCamera.coordinates.length > 0 &&
    selectedCamera.coordinates.includes('/') &&
    selectedCamera.coordinates[selectedCamera.coordinates.indexOf('/') + 1]
  ) {
    long = parseFloat(selectedCamera.coordinates.split('/')[0])
    lat = parseFloat(selectedCamera.coordinates.split('/')[1])
  }

  return (
    <>
      {error && <CameraError>{error}</CameraError>}
      {testingError && <CameraError>{testingError}</CameraError>}
      <EditCameraContainer>
        <LeftPart>
          <form onSubmit={onSubmitTest}>
            <ValidationTextInput
              placeholder="rtsp://"
              onChange={(v) =>
                selectedCamera
                  ? setSelectedCamera({ ...selectedCamera, input: v })
                  : setUriToTest(v)
              }
              value={selectedCamera?.censoredInput || uriToTest}
              button={
                testing ? (
                  <FieldIcon icon={faSpinner} spin color="#fff" />
                ) : (
                  'tester'
                )
              }
              onSubmit={onSubmitTest}
              valid={true}
              disabled={true}
            />
          </form>
          {renderPreviews()}
        </LeftPart>
        <div>
          <TextInput
            label="Nom"
            icon={EditIcon}
            value={selectedCamera?.name}
            onChange={(v) => setSelectedCamera({ ...selectedCamera, name: v })}
          />
          <TextInput
            label="Coordonées GPS"
            placeholder="Latitude/Longitude"
            onChange={(v) =>
              setSelectedCamera({ ...selectedCamera, coordinates: v })
            }
            icon={EditIcon}
            value={selectedCamera?.coordinates || ''}
          />
          <TextInputMultiline
            label="Description (100 car. max)"
            onChange={(v) =>
              setSelectedCamera({ ...selectedCamera, description: v })
            }
            icon={EditIcon}
            value={selectedCamera?.description || ''}
            rows={5}
            maxlength={100}
          />
          {creating && (
            <P>
              <strong>ATTENTION: </strong> Après ajout de cette caméra,
              l'enregistrement des vidéos sera arrêté et les modifications
              seront prises en compte après un court instant.
            </P>
          )}
          <ButtonsContainer>
            <BigButton
              disabled={
                (!selectedCamera?.input && !uriToTest) || !selectedCamera.name
              }
              spin={submitting}
              onClick={handleSubmit}
            >
              Valider
            </BigButton>
            <BigButton
              onClick={handleCancel}
              color="#F61A1A"
              hoverColor="#E71414"
            >
              Annuler
            </BigButton>
          </ButtonsContainer>
        </div>
      </EditCameraContainer>
      {lat !== -1 && long !== -1 && selectedCamera.coordinates && (
        <CameraDisplayContainer>
          <img
            style={{ opacity: displayMap ? 0.5 : 1 }}
            src="images/camera-round.svg"
            onClick={() => setDisplayMap(false)}
          />
          <img
            style={{ opacity: displayMap ? 1 : 0.5 }}
            src="images/localization-round.svg"
            onClick={() => setDisplayMap(true)}
          />
        </CameraDisplayContainer>
      )}
      <EditParams>
        <ParamsButton onClick={() => setOpenedParams(!openedParams)}>
          {openedParams && '✕'} Paramètres avancés
        </ParamsButton>
        {openedParams && (
          <div>
            <ParamsOption>
              <Checkbox
                label="Caméra avec pré-traitement VPI"
                onChange={(v) =>
                  setSelectedCamera({
                    ...selectedCamera,
                    anpr_real_time: !selectedCamera?.anpr_real_time,
                  })
                }
                checked={selectedCamera?.anpr_real_time || false}
              />
              <Checkbox
                label="Caméra avec pré-traitement Recherche"
                onChange={(v) =>
                  setSelectedCamera({
                    ...selectedCamera,
                    detect_real_time: !selectedCamera?.detect_real_time,
                  })
                }
                checked={selectedCamera?.detect_real_time || false}
              />
            </ParamsOption>
            <ParamsOption>
              <TextInput
                style={{ marginTop: 10 }}
                label="Masque gauche (%):"
                icon={EditIcon}
                type="number"
                min={0}
                max={100}
                value={(selectedCamera.mask_left || 0) * 100}
                onChange={(v) =>
                  setSelectedCamera({ ...selectedCamera, mask_left: v / 100 })
                }
              />
              <TextInput
                style={{ marginTop: 10 }}
                label="Masque droite (%):"
                icon={EditIcon}
                type="number"
                min={0}
                max={100}
                value={(selectedCamera.mask_right || 0) * 100}
                onChange={(v) =>
                  setSelectedCamera({ ...selectedCamera, mask_right: v / 100 })
                }
              />
              <TextInput
                style={{ marginTop: 10 }}
                label="Masque haut (%):"
                icon={EditIcon}
                type="number"
                min={0}
                max={100}
                value={(selectedCamera.mask_top || 0) * 100}
                onChange={(v) =>
                  setSelectedCamera({ ...selectedCamera, mask_top: v / 100 })
                }
              />
              <TextInput
                style={{ marginTop: 10 }}
                label="Masque bas (%):"
                icon={EditIcon}
                type="number"
                min={0}
                max={100}
                value={(selectedCamera.mask_bottom || 0) * 100}
                onChange={(v) =>
                  setSelectedCamera({ ...selectedCamera, mask_bottom: v / 100 })
                }
              />
            </ParamsOption>
          </div>
        )}
      </EditParams>
    </>
  )
}

export default EditCamera
