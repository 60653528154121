import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const useEventListener = (target, type, listener, ...options) => {
  useEffect(
    () => {
      const targetIsRef = target.hasOwnProperty('current')
      const currentTarget = targetIsRef ? target.current : target

      if (currentTarget)
        currentTarget.addEventListener(type, listener, ...options)

      return () => {
        if (currentTarget)
          currentTarget.removeEventListener(type, listener, ...options)
      }
    }, [target, type, listener, options]
  )
}

export const Dropzone = ({
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDrop,
  isOnDrag,
  ...props
}) => {
  const el = useRef(null)

  useEventListener(el, 'dragenter', onDragEnter)
  useEventListener(el, 'dragleave', onDragLeave)
  useEventListener(el, 'dragover', onDragOver)
  useEventListener(el, 'drop', onDrop)

  return <div ref={el} {...props} />
}

Dropzone.propTypes = {
  onDragOver: PropTypes.func,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  onDrop: PropTypes.func
}

Dropzone.defaultProps = {
  onDragOver: () => {},
  onDragEnter: () => {},
  onDragLeave: () => {},
  onDrop: () => {}
}

const StyledFileDropzone = styled(Dropzone)`
  border-style: dashed;
  border-width: ${({ isOnDrag }) => isOnDrag ? '4px' : '2px'};
  border-color: ${({ theme }) => theme.fileDropzone.borderColor};
  border-radius: 5px;
`

export const FileDropzone = props => {
  const [isOnDrag, setIsOnDrag] = useState(false)

  const onDragOverHandler = e => {
    setIsOnDrag(true)

    return props.onDragOver(e)
  }

  const onDragEnterHandler = e => {
    setIsOnDrag(true)

    return props.onDragEnter(e)
  }

  const onDragLeaveHandler = e => {
    setIsOnDrag(false)

    return props.onDragLeave(e)
  }

  const onDropHandler = e => {
    setIsOnDrag(false)

    return props.onDrop(e)
  }

  return (
    <StyledFileDropzone
      onDragOver={onDragOverHandler}
      onDragEnter={onDragEnterHandler}
      onDragLeave={onDragLeaveHandler}
      onDrop={onDropHandler}
      isOnDrag={isOnDrag}
      {...props}
    />
  )
}

FileDropzone.propTypes = Dropzone.propTypes

FileDropzone.defaultProps = Dropzone.defaultProps
