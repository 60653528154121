import axios from 'axios'

export const fetchAllUploads = params =>
  axios.get('/api/uploads', { params }).then(({ data }) => data)

export const uploadFile = (
  file,
  {
    onUploadProgress = () => null,
    cancelToken,
    ...config
  }
) => {
  const formData = new FormData()

  formData.append('files[]', file)

  return axios.post('/api/uploads', formData, {
    cancelToken: cancelToken.token,
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: e => onUploadProgress(Math.round((e.loaded * 100) / e.total), e),
    ...config
  }).then(({ data }) => data)
}

export const deleteUpload = id => axios.delete(`/api/uploads/${id}`)
