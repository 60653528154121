export const upload = {
    upload: `Importer`,
    uploadOneOrMoreVideoFilesByDragAndDropOrByClickingOnChooseFileSupportedFormatIsMP4EncodedInH264: `Importer un ou plusieurs fichiers vidéos en drag and drop ou en cliquant sur Selectionner . Le format supporté est le MP4 encodé en H264`,
    chooseFile: `Sélectionner des vidéos`,
    recentUploads: `Vidéos récentes`,
    date: `Date`,
    name: `Nom`,
    start: `Début`,
    end: `Fin`,
    status: `Statut`,
    delete: `Supprimer`,
    deleteThisVideo: `Supprimer la vidéo`,
    thisActionIsIrreversible: `Cette action est irréversible.`,
    areYouSure: `Êtes-vous sûr de vouloir faire ça ?`,
}
export default upload
