import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { Link as RouterLink, useHistory } from 'react-router-dom'
import { Icon } from './Icons'

export const BlankButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: 0px solid red;
  line-height: 1;
  font-weight: inherit;

  &:hover, &:focus {
    outline: none;
  }
`

const StyledButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-family: Blinker;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  cursor: pointer;

  &:focus, &:hover {
    text-decoration: none;
    outline: none;
  }

  ${({ primary, theme }) => primary && `
    color: #fff;
    background-color: ${theme.primaryColor};
    border-color: ${theme.primaryColor};

    &:hover {
      color: #fff;
      background-color: ${theme.primaryHover};
      border-color: ${theme.primaryHover};
    }
  `}

  ${({ danger, theme }) => danger && `
    color: #fff;
    background-color: ${theme.dangerRed};
    border-color: ${theme.dangerRed};

    &:hover {
      color: #fff;
      background-color: ${theme.dangerRed};
      border-color: ${theme.dangerRed};
    }
  `}

  &:disabled {
    opacity: .75;
    pointer-events: none;
  }

  ${Icon} {
    width: 1rem;
    height: 1rem;
    vertical-align: baseline;

    &:first-child {
      margin-right: .5rem;
    }
    &:last-child {
      margin-left: .5rem;
    }
  }
`

StyledButton.propTypes = {
  primary: PropTypes.bool,
  danger: PropTypes.bool,
  theme: PropTypes.shape({
    dangerRed: PropTypes.string,
    primaryHover: PropTypes.string,
    primaryColor: PropTypes.string
  }),
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  disabled: PropTypes.bool
}

export const Button = ({ leftIcon, rightIcon, children, ...props }) =>
  <StyledButton {...props}>
    {leftIcon}<span>{children}</span>{rightIcon}
  </StyledButton>

Button.propTypes = {
  ...StyledButton.propTypes,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  children: PropTypes.any,
  onClick: PropTypes.func
}

Button.defaultProps = {
  onClick: () => null
}

const AStyle = css`
  cursor: pointer;
  color: ${({ color }) => color};
  font-size: inherit;
  text-decoration: ${({ decoration }) => decoration};
  overflow-wrap: anywhere;

  &:focus { outline: none; }
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }

  ${({ disabled }) => disabled && `
    opacity: .75;
    pointer-events: none;
  `}
`

AStyle.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node
}

export const A = styled.a.attrs(props => ({
  color: props.color || props.theme.primaryColor || '#000',
  decoration: props.decoration || 'none',
}))`
  ${AStyle}
`

A.propTypes = AStyle.propTypes

export const Link = styled(RouterLink).attrs(props => ({
  color: props.color || props.theme.primaryColor || '#000',
  decoration: props.decoration || 'none',
}))`
  ${AStyle}
`

Link.propTypes = AStyle.propTypes

export const NavButton = ({ to, ...props }) => {
  const history = useHistory()

  return <Button {...props} onClick={() => {window.scrollTo(0, 0); history.push(to)}} />
}

NavButton.propTypes = {
  ...Button.propTypes,
  to: PropTypes.string
}
