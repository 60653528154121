import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import useList from '../hooks/useList'

import Table from '../components/Table'
import { PageSection } from '../components/PageSection'
import { SmallButton } from '../components/Button'
import { ConfirmationModal } from '../components'

import {
  P,
  Form,
  Button,
  PlusIcon,
  FileDropzone
} from '../components'

import { H1 } from '../components/Typography'

import * as services from '../services'

import strftime from 'strftime'

import { useTranslation } from 'react-i18next'

const StyledH1 = styled(H1)`
  color: #000;
  text-transform: none;
  margin-bottom: 0;
`

const Container = styled.div`
  width: auto;
  flex: 1;
  padding: 2rem;
`

const StyledFileDropzone = styled(FileDropzone)`
  flex: 1;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto;
  max-width: 1024px;
`

const StyledForm = styled(Form)`
  text-align: center;
`

const StyledP = styled(P)`
  margin-bottom: 1.5rem;
`

const StyledInput = styled.input`
  display: none;
`

const StyledInputsZone = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const HistoryPageSection = styled(PageSection)`
  text-align: center;
`

const formatDate = date => (
  strftime('%d/%m/%Y', new Date(+date)) +
  '\n' +
  strftime('%H:%M', new Date(+date))
)

export default () => {

  const { t } = useTranslation()
  const [onLoading, setOnLoading] = useState(true)
  const [isDeleting, setIsDeleting] = useState(false)
  const [toDelete, setToDelete] = useState(null)
  const [
    { items: uploads },
    {
      setItem: setUpload,
      setItems: setUploads,
      updateItem: updateUpload,
      updateItems: updateUploads,
      deleteItem: deleteUpload
    }] = useList()
  const [
    { items: requestCancelTokens },
    {
      setItem: setRequestCancelTokens,
      deleteItem: deleteRequestCancelTokens
    }] = useList()

  useEffect(() => {
    setOnLoading(true)
    services
      .fetchAllUploads()
      .then(({ uploads }) => {
        setUploads(uploads)
        setOnLoading(false)
      })
      .catch((err) => {
        console.error(err)

        setOnLoading(false)
      })
  }, [])

  const tmpId = () =>
    ('0000' + ((Math.random() * (100000 - 101) + 101) | 0)).slice(-5)

  const upload = files => {
    for (var i = 0, len = files.length; i < len; i++) {
      let cameraId = tmpId()
      let file = files[i]
      const [ext, ...splittedname] = file.name.split('.').reverse()
      const filename = file.name.replaceAll(' ', '_').replace('.' + ext, '').replace(/[^a-z0-9_]/gi, '')

      let video = {
        id: cameraId,
        cameraId,
        saveStatus: 'uploading',
        uploadProgress: 0,
        cameraName: filename,
        mimetype: file.type
      }

      setUpload(video)

      const cancelToken = services.getRequestCancelToken()
      setRequestCancelTokens({ id: video.id, cancelToken })

      const _upload = (id, file) => {
        services
          .uploadFile(
            file,
            {
              onUploadProgress: uploadProgress => {
                updateUpload(id, { uploadProgress })
              },
              cancelToken
            }
          )
          .then(data => {
            deleteUpload(id)

            setUploads(data.uploads)

            deleteRequestCancelTokens(id)
          })
          .catch(({ response }) => {
            if (
              response
              && response.data.message
              && response.data.message === 'File format not supported'
            )
              updateUpload(id, { saveStatus: 'invalid' })
            else
              updateUpload(id, { saveStatus: 'error' })

            deleteRequestCancelTokens(id)
          })
      }

      _upload(cameraId, file)
    }
  }

  const onDrop = e => {
    e.preventDefault()
    e.stopPropagation()

    upload(e.dataTransfer.files)

    return false
  }

  const onDragOver = e => {
    e.preventDefault()
    e.stopPropagation()

    return false
  }

  const handleChange = e => {
    upload(e.target.files)

    e.target.form.reset()
  }

  const tableHeader = [
    {
      id: 'date',
      name: t('upload.date'),
      sort: (a, b) => new Date(Number(a?.startAt) / 1000) - new Date(Number(b?.startAt) / 1000),
    },
    {
      id: 'name',
      name: t('upload.name'),
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      },
    },
    {
      id: 'startAt',
      name: t('upload.start'),
      sort: (a, b) => new Date(Number(a?.startAt)) - new Date(Number(b?.startAt)),
    },
    {
      id: 'saveAt',
      name: t('upload.end'),
      sort: (a, b) => new Date(Number(a?.saveAt)) - new Date(Number(b?.saveAt)),
    },
    {
      id: 'saveStatus',
      name: t('upload.status'),
      sort: (a, b) => {
        if (a?.saveStatus < b?.saveStatus) return -1
        if (a?.saveStatus > b?.saveStatus) return 1
        return 0
      },
    },
    {
      id: 'onDelete'
    }
  ]

  const tableBody = uploads.reduce((accumulator, value) => {
    if (accumulator.find(acc => acc.id === value.cameraId) !== undefined) {
      return accumulator.map(acc => {
        if (acc.id === value.cameraId) {
          const startAt = [
            ...acc.videos,
            value
          ].reduce((accumulator, value) => {
            if (parseInt(value.startAt, 10) < accumulator) {
              return parseInt(value.startAt, 10)
            }

            return accumulator
          }, parseInt(value.startAt, 10))
          const saveAt = [
            ...acc.videos,
            value
          ].reduce((accumulator, value) => {
            if (parseInt(value.saveAt, 10) > accumulator) {
              return parseInt(value.saveAt, 10)
            }

            return accumulator
          }, parseInt(value.saveAt, 10))
          const saveStatus = [
            ...acc.videos,
            value
          ].reduce((accumulator, value) => {
            if (accumulator === value.saveStatus) {
              return 'done'
            }

            return value.saveStatus
          }, 'done')

          return {
            ...acc,
            startAt,
            saveAt,
            saveStatus,
            date: startAt,
            videos: [
              ...acc.videos,
              value
            ]
          }
        }

        return acc
      })
    }

    return [
      ...accumulator,
      {
        id: value.cameraId,
        name: value.cameraName,
        date: parseInt(value.startAt, 10),
        startAt: parseInt(value.startAt, 10),
        saveAt: parseInt(value.saveAt, 10),
        saveStatus: value.saveStatus,
        uploadProgress: value.uploadProgress,
        videos: [
          value
        ],
        onDelete: value.saveStatus !== 'uploading' ? (
          <SmallButton
            disabled={isDeleting}
            type="button"
            color="red"
            hoverColor="#e00800"
            onClick={() => setToDelete(value)}
          >
            {t('upload.delete')}
          </SmallButton>
        ) : ''
      }
    ]
  }, [])
    .map(upload => {
      if (upload.saveStatus === 'uploading') {
        return {
          ...upload,
          saveStatus: `uploading ${upload.uploadProgress}%`,
          startAt: '???',
          saveAt: '???',
          date: formatDate(Date.now()),
        }
      }

      return {
        ...upload,
        startAt: formatDate(upload.startAt),
        saveAt: formatDate(upload.saveAt),
        date: formatDate(upload.date)
      }
    })

  const handleCancelDelete = () => {
    setToDelete(null)
    setIsDeleting(false)
  }

  const handleConfirmDelete = toDelete => {
    setIsDeleting(true)
    services
      .deleteUpload(toDelete.cameraId)
      .then(() => {
        uploads.forEach(upload => {
          if (upload.cameraId === toDelete.cameraId) {
            deleteUpload(upload.id)
          }
        })
        setToDelete(null)
        setIsDeleting(false)
      })
      .catch((err) => {
        console.error(err)

        setToDelete(null)
        setIsDeleting(false)
      })
  }

  return (
    <Container>
      <StyledFileDropzone
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        <StyledContent>
          <StyledH1>{t('upload.upload')}</StyledH1>
          <StyledForm onSubmit={e => e.preventDefault()}>
            <StyledP>{t('upload.uploadOneOrMoreVideoFilesByDragAndDropOrByClickingOnChooseFileSupportedFormatIsMP4EncodedInH264')}</StyledP>
            <StyledInput type="file" id="files" name="files" multiple onChange={handleChange} />
            <StyledInputsZone>
              <Button
                as="label"
                htmlFor="files"
                primary
                leftIcon={<PlusIcon />}
              >
                {t('upload.chooseFile')}
              </Button>
            </StyledInputsZone>
          </StyledForm>
          {
            (!onLoading && tableBody.length > 0) && (
              <HistoryPageSection title={t('upload.recentUploads')} icon="/images/search.svg">
                <Table header={tableHeader} body={tableBody} loading={onLoading} />
              </HistoryPageSection>
            )
          }
        </StyledContent>
      </StyledFileDropzone>
      {
        toDelete &&
        <ConfirmationModal isOpen danger
          title={t('upload.areYouSure')}
          description={`${t('upload.deleteThisVideo')} ${toDelete.cameraName}". ${t('upload.thisActionIsIrreversible')}`}
          onConfirm={() => handleConfirmDelete(toDelete)}
          onCancel={() => handleCancelDelete()}
        />
      }
    </Container>
  )
}
