import React, { useState, useEffect, useContext } from 'react'
import EnvContext from './../contexts/EnvContext'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import {
    faSpinner
} from '@fortawesome/free-solid-svg-icons'

import * as services from './../services'
import { useTranslation } from 'react-i18next'

const Thumbnail = styled.img`
  width: 100%;
  background: black;
`

export const TooltipCameraList = ({ cameraList }) => {
    const [{ env }] = useContext(EnvContext)
    const { t } = useTranslation()
    const [hover, setHover] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cameraListDetail, setCameraListDetail] = useState(false);
    const [selectedCameraHover, setSelectedCameraHover] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);

    useEffect(() => {
        if (hover && !cameraListDetail?.length && !loading) {
            setLoading(true)
            Promise.all(cameraList.map((camera) => services.getCamera({ id: camera }))).then((response => {
                let result = [];
                result = [...result, ...response.map(itemResponse => {
                    return itemResponse.data.cameras[0]
                })]
                setCameraListDetail(result)
                setLoading(false)
            }))
        }
    }, [hover])

    useEffect(() => {
        if (selectedCameraHover && (selectedCameraHover.thumbnail || (selectedCameraHover.thumbnailFilename && selectedCameraHover.thumbnailExtension))) {
            axios
                .get(selectedCameraHover.thumbnail
                    ? selectedCameraHover.thumbnail
                    : `${env.NODE_ENV != 'production' ? `${window.location.protocol}//${window.location.hostname}:${env.NODE_CONTAINER_DEV_PORT_API}` : window.location.origin}/thumbnails/${selectedCameraHover.thumbnailFilename}.${selectedCameraHover.thumbnailExtension}`)
                .then(() => {
                    setThumbnail({
                        src: selectedCameraHover.thumbnail
                            ? selectedCameraHover.thumbnail
                            : `${env.NODE_ENV != 'production' ? `${window.location.protocol}//${window.location.hostname}:${env.NODE_CONTAINER_DEV_PORT_API}` : window.location.origin}/thumbnails/${selectedCameraHover.thumbnailFilename}.${selectedCameraHover.thumbnailExtension}`
                    })
                })
                .catch((e) => {
                    setThumbnail({ error: e.toJSON().message })
                })
        } else {
            setThumbnail({ error: 'thumbnail invalid' })
        }

    }, [selectedCameraHover])

    return <span
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => {
            setHover(false); setSelectedCameraHover(null);
            setThumbnail(null);
        }}
        style={{
            'cursor': 'pointer',
            'position': 'relative',
        }}
    >
        <b>{`${cameraList?.length} ${t('investigation.camera')}${cameraList?.length > 1 ? 's' : ''}`}</b>
        {hover && cameraList.length > 0 && <div style={{
            'position': 'absolute',
            'border': '1px solid lightgrey',
            'backgroundColor': 'white',
            'padding': '0.1rem',
            'zIndex': '1',
            'width': '20rem',
            'top': '1rem',
            'borderRadius': '5px'
        }}>
            {loading && <span style={{
                'display': 'table',
                'margin': 'auto',
            }}>
                <FontAwesomeIcon icon={faSpinner} color="#ccc" spin={true} />
            </span>}
            {!loading && cameraListDetail?.length > 0 && <div style={{ 'display': 'flex' }}>
                <ul style={{
                    'listStyle': 'none',
                    'margin': '0',
                    'marginTop': '0.1rem',
                    'width': '50%'
                }}>
                    {
                        cameraListDetail.map(cameraDetail => {
                            return <>{cameraDetail?.id &&
                                <li key={cameraDetail.id} style={{
                                    'fontWeight': selectedCameraHover?.id === cameraDetail.id ? 'bold' : 'normal'
                                }}
                                    onMouseEnter={() => {
                                        setThumbnail({ loading: true })
                                        setSelectedCameraHover({ ...cameraDetail })
                                    }}
                                >{
                                        cameraDetail.name
                                    }
                                    {selectedCameraHover?.id === cameraDetail.id && <div style={{
                                        'position': 'absolute',
                                        'border': '1px solid lightgrey',
                                        'backgroundColor': 'white',
                                        'padding': '0.1rem',
                                        'zIndex': '1',
                                        'width': '20rem',
                                        'top': '1rem',
                                        'right': '20rem',
                                        'borderRadius': '5px'
                                    }}>
                                        {thumbnail.loading && <span style={{
                                            'display': 'table',
                                            'margin': 'auto',
                                        }}>
                                            <FontAwesomeIcon icon={faSpinner} color="#ccc" spin={true} />
                                        </span>}
                                        {thumbnail?.error && `Pas d'image disponible pour cette caméra`}
                                        {thumbnail?.src && <Thumbnail
                                            src={thumbnail.src}
                                        />}
                                    </div>}
                                </li>
                            }</>
                        })
                    }
                </ul></div>}
        </div>}
    </span>;
}