import styled, { css } from 'styled-components'

const GRID_COLUMNS = 12

const CFStyle = css`
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`

export const CF = styled.div`
  ${CFStyle}
`

export const Header = styled.header`
  ${CFStyle}
`

export const Footer = styled.footer`
  ${CFStyle}
`

export const Section = styled.section`
  ${CFStyle}
`
export const Container = styled.div`
  ${CFStyle}
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: ${props => props.textAlign || 'inherit'};

  @media (min-width: 400px) {
    width: 85%;
    padding: 0;
  }
  @media (min-width: 550px) {
    width: 80%;
  }
`
export const Row = styled.div`
  ${CFStyle}
  ${({ flex }) => flex && 'display: flex;'}
`

export const Columns = styled.div.attrs(props => ({
  nb: props.nb || GRID_COLUMNS,
}))`
  width: 100%;
  float: left;
  box-sizing: border-box;
  margin-left: 4%;
  text-align: ${props => props.textAlign || 'inherit'};

  @media (min-width: 550px) {
    width: ${({ nb }) => ((nb / GRID_COLUMNS)*100) - ((GRID_COLUMNS - nb)/3)}%;
  }
  @media (min-width: 550px) {
    &:first-child {
      margin-left: 0;
    }
  }
`

export const Hr = styled.hr`
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  border-width: 0;
  border-top: 1px solid #e1e1e1;
  max-width: 960px;
  margin: 30px auto;
  padding: 0 20px;
  width: 100%;

  @media (min-width: 400px) {
    padding: 0;
    width: 85%;
  }

  @media (min-width: 550px) {
    width: 80%;
  }
}
`

export const CenterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  flex-direction: column;
`
