import React from 'react'
import { useLocation } from "react-router-dom"
import PlayerGrid from '../components/PlayerGridCam';

const RewindPlay = () => {
  const [cameraList, setCameraList] = React.useState([])
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  React.useEffect(() => {
    setCameraList(params.get("cameraId").split(",").map((cameraIdString) => { return cameraIdString * 1 }))
    setStartDate(params.get("startDate") && params.get("startDate").length > 0 ? params.get("startDate") * 1 : null)
    setEndDate(params.get("endDate") && params.get("endDate").length > 0 ? params.get("endDate") * 1 : null)
  }, [])

  return <>
    {
      startDate && endDate && cameraList.length >= 1 && <PlayerGrid
        cameraList={cameraList}
        startDateTimeStamp={startDate}
        endDateTimeStamp={endDate}
        mode={'REPLAY'}
      />
    }</>
}

export default RewindPlay;