export const personalInformations = {
    company: "Entreprise",
    confirmChanges: "Valider les changements",
    email: "Email",
    firstName: "Prénom",
    lastName: "Nom",
    personalInformation: "Informations personnelles",
    phoneNumber: "N° de poste",
    searchHistory: "Historique de recherche",
    userManagement: "Gestion des utilisateurs"
}
export default personalInformations