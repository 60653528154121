import axios from 'axios'

export const fetchHistoryStandard = () =>
  axios.get(`/api/history/standard`).then(({ data }) => data)

export const fetchHistoryVPI = () =>
  axios.get(`/api/history/vpi`).then(({ data }) => data)

export const fetchHistoryScenario = () =>
  axios.get(`/api/history/scenario`).then(({ data }) => data)

export const fetchHistoryAnprAlert = () =>
  axios.get(`/api/history/anprAlerts`).then(({ data }) => data)

export const fetchHistoryDetectAlert = () =>
  axios.get(`/api/history/detectAlerts`).then(({ data }) => data)