import React from 'react'
import axios from 'axios'
import { motion } from 'framer-motion/dist/framer-motion'
import { PageSection } from '../PageSection'
import { useFetch } from '../../services/fetch'
import { getLastVideoForCamera, getScenarioZones, testCameraById } from '../../services'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import { AnimateSharedLayout } from 'framer-motion/dist/framer-motion'
import Editor from './Editor'
import Preview from './Preview'
import { useTranslation } from 'react-i18next'

export default function ZoneEditorSection({
  camera,
  zones,
  events,
  onImageLoad,
  onSubmit
}) {
  const { t } = useTranslation()
  const [opened, setOpened] = React.useState(false)
  const [image, setImage] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [updateLoading, setUpdateLoading] = React.useState(false)

  let canceller = axios.CancelToken.source()

  React.useEffect(() => {
    setLoading(true)

    getScenarioZones().then(({ zones: sz }) => {
      testCameraById(
        camera.id,
        canceller.token
      ).then(data => {
        let oimage = null

        if (data) {
          let img = new Image()
          const ocanvas = document.createElement('canvas')
          const octx = ocanvas.getContext('2d')

          let rwidth = 0
          let rheight = 0

          img.onload = () => {
            rwidth = 720
            rheight = (img.height / img.width) * 720

            ocanvas.width = rwidth
            ocanvas.height = rheight

            octx.drawImage(img, 0, 0, 720, (img.height / img.width) * 720)

            oimage = {
              src: ocanvas.toDataURL('image/jpeg'),
              width: rwidth,
              height: rheight,
              originalWidth: img.width,
              originalHeight: img.height,
            }

            setImage(oimage)
            setLoading(false)
            onImageLoad(oimage, sz.filter(zo => zo.config_id == camera.id))
          }

          img.src = 'data:image/jpeg;base64,' + data.img
        }
      }).catch(err => console.log(err))
    })
  }, [camera.id])

  function open() {
    setOpened(true)
  }

  function close() {
    setOpened(false)
  }

  function submit(newZones, image) {
    setUpdateLoading(true)

    onSubmit(newZones, setOpened, setUpdateLoading, image)
  }

  return (
    <PageSection
      as={motion.div}
      key={camera.id}
      transition={{ duration: 0.25, type: 'tween' }}
      initial={{ opacity: 0, y: -40 }}
      enter={{ opacity: 0, y: 40 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -40 }}
      title={`${t('scenario.createModifyZone')} ${camera?.name}`}
      icon="/images/target.svg"
      description={
        <>
          <strong> {t('scenario.defineZoneDetection')} </strong>
          {t('scenario.defineZoneDetectionDetail')}
        </>
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <AnimateSharedLayout type="crossfade">
          <AnimatePresence>
            {opened && (
              <motion.div
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                onClick={close}
                style={{
                  background: 'rgba(0,0,0,0.8)',
                  position: 'fixed',
                  width: '100%',
                  height: '100%',
                  left: 0,
                  top: 0,
                  zIndex: 2001,
                }}
              />
            )}
          </AnimatePresence>
          {opened ? (
            <div
              style={{
                top: '50%',
                left: '50%',
                position: 'fixed',
                transform: 'translate(-50%,-50%)',
                zIndex: 2001,
              }}
            >
              <motion.div
                initial={false}
                layoutId="modal"
                transition={{
                  delay: 0.15,
                  mass: 0.1,
                  type: 'spring',
                }}
                style={{
                  padding: '15px 20px',
                  borderRadius: 3,
                }}
                animate={{ backgroundColor: '#000' }}
              >
                <Editor
                  image={image}
                  initialZones={zones}
                  events={events}
                  onSubmit={submit}
                  onCancel={close}
                />
              </motion.div>
            </div>
          ) : (
            <motion.div
              initial={false}
              layoutId="modal"
              animate={{
                backgroundColor: '#fff',
              }}
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Preview
                image={image}
                zones={zones}
                onClick={open}
                loading={loading}
              />
            </motion.div>
          )}
        </AnimateSharedLayout>
      </div>
    </PageSection>
  )
}
