import React, { useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { getAssetsRootURL } from '../../../utils'

import { SpinnerAnimatedIcon } from '../components/design-system/Icons'
import * as services from '../services'

import {
  Section,
  H1,
  H4,
  Form,
  FormGroup,
  TextInput,
  Button,
  Link,
} from '../components'

const ResetPasswordContainer = styled(Section)`
  padding: 0 4%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;

  ${Form} {
    width: 21.875rem;
    margin: 0 auto;
  }
`

const StyledVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`

const StyledH1 = styled(H1)`
  letter-spacing: .563rem;
  text-transform: uppercase;
  font-size: 2.25rem;
  margin: 1rem 0;
  color: white;
  font-weight: normal;
`

const StyledH4 = styled(H4)`
  font-size: 1.125rem;
  color: white;
  margin: 1rem 0 2rem 0;
  font-weight: normal;
  text-align: center;
`

const Error = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.errorRed};
`

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`

const ErrorLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.errorRed};

  &:hover {
    cursor: pointer;
  }
`

export default () => {
  const [resquestSent, setRequestSent] = useState(false)
  const [onRequest, setOnRequest] = useState(false)
  const [onError, setOnError] = useState(false)
  const [onRequestError, setOnRequestError] = useState(null)
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const query = new URLSearchParams(useLocation().search)

  const email = query.get('email')
  const code = query.get('code')

  const showError = () => {
    if (onError) {
      return (
        <Error>
          {(password.length <= 0) ? <>Mot de passe requis<br /></> : null}
          {(passwordConfirm.length <= 0) ? <>Confirmation du mot de passe requise<br /></> : null}
          {(password !== passwordConfirm) ? <>Confirmation du mot de passe incorrecte<br /></> : null}
          {(!email || !code) ? <>Les paramètres Email et/ou Code sont manquants, le lien est incorrect.<br /></> : null}
        </Error>
      )
    } else if (onRequestError && onRequestError.status === 412) {
      return (
        <Error>
          Email et/ou Code incorrects.
        </Error>
      )
    } else if (onRequestError && onRequestError.status === 404) {
      return (
        <Error>
          Email incorrect.
        </Error>
      )
    } else if (onRequestError === 500) {
      return (
        <Error>
          Une erreur est survenue, réessayez plus tard ou contactez un administrateur via ce <ErrorLink target='_blank' href='https://neurocr.ai/contact'>formulaire de contact</ErrorLink>
        </Error>
      )
    }

    return null
  }

  const handleSubmit = e => {
    setOnError(false)
    setOnRequest(true)
    setOnRequestError(null)

    e.preventDefault()
    e.stopPropagation()

    if (
      password.lenght <= 0
      || passwordConfirm.length <= 0
      || password !== passwordConfirm
      || !email
      || !code
    ) {
      setOnRequest(false)
      setOnError(true)

      return false
    }

    services
      .resetPassword({ email, code, password })
      .then(() => {
        setRequestSent(true)
        setOnRequest(false)
      })
      .catch(err => {
        console.error(err)

        if (err.response && err.response.status)
          setOnRequestError(err.response.status)

        setOnRequest(false)
      })
  }

  return (
    <>
      <StyledVideo
        autoPlay
        loop
        muted
        id='background-video'
        poster={getAssetsRootURL(true) + '/images/bg-poster.png'}
      >
        <source src={getAssetsRootURL(true) + '/videos/bg.mp4'} type='video/mp4' />
      </StyledVideo>
      <ResetPasswordContainer>
        <StyledH1>Ça nous arrive à tous !</StyledH1>
        <StyledH4>
          {
            resquestSent
              ? 'Votre mot de passe à été réinitialisé !'
              : 'Réinitialisation du mot de passe:'
          }
        </StyledH4>
        {
          !resquestSent && (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <TextInput
                  required
                  dark
                  label='Mot de passe'
                  name='password'
                  type='password'
                  onChange={setPassword}
                  value={password}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  required
                  dark
                  label='Confirmation du mot de passe'
                  name='password-confirm'
                  type='password'
                  onChange={setPasswordConfirm}
                  value={passwordConfirm}
                  validator={v => password && v != password ? 'Différent du mot de passe' : null}
                />
              </FormGroup>
              {showError()}
              <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <Button
                  primary
                  leftIcon={onRequest ? <SpinnerAnimatedIcon /> : null}
                  type='submit'
                >
                  Confirmer
                </Button>
              </div>
            </Form>
          )
        }
        <div style={{ textAlign: 'center', marginTop: '.938rem' }}>
          <StyledLink to="/">Retour à l’accueil</StyledLink>
        </div>
      </ResetPasswordContainer>
    </>
  )
}
