import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'

import axios from 'axios'
import fuzz from 'fuzzball'

import {
  Checkbox,
  Icon,
  TriangleRightIcon,
  SearchInput,
  TextInput,
  Select,
  Option,
  CameraIcon,
  CloseIcon,
  BlankButton,
  Button,
  Link,
  TabsContainer
} from '../components/design-system'
import { Modal, ModalTitle, ModalBody } from '../components/Modal'
import { P } from '../components/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
  faTv
} from '@fortawesome/free-solid-svg-icons'

import { TabsUl, TabsLi, TabsContent } from '../components'

import Screens from './Screens'
import Views from './Views'

import WebRTCPlayer from '../components/WebRTCPlayer'
import PlayerGrid from '../components/PlayerGridCam'

const CustomTabs = styled(TabsContainer)`
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10px;
  height: 100vh;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background-color: black;
  flex-direction: row;
  flex: 1;
`

const OptionsModal = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const OptionModal = styled.li`
  display: inline-block;
  margin: 0 10px 0 0;
`

const WatchMenuContainer = styled.div`
  position: relative;
  float: left;
  display: flex;
  flex-direction: column;
  width: ${({ isOpened }) => isOpened ? '20%' : '5%'};
  background-color: black;
  min-height: 100vh;
  overflow-y: scroll;
  border-right: 1px solid ${({ theme }) => theme.primaryColor};
  height: 100vh;
`

const SelectContainer = styled.div`
  text-align: center;
  margin: .5rem 0;
`

const SearchInputContainer = styled.div`
  text-align: center;
  width: 80%;
  margin: .5rem auto;
  color: white;
`

const MenuOpener = styled.button`
  margin-top: 0.5rem;
  width: 100%;
  color: white;
  font-size: 2rem;
  text-align: center;
  background-color: black;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Blinker;

  &:focus { outline: none; }

  &:hover { cursor: pointer; }
`

const StyledCameraIcon = styled(CameraIcon)`
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.5rem;
`

const StyledSelectByView = styled(Select)`
  background-color: black;
  margin: 0.3rem 0;
  z-index: 1000;
`

const OptionViews = styled(Option)`
  padding: .1rem 1rem;
  margin: .3rem 0;
  z-index: 1001;
  
  &:hover {
    cursor: pointer;
    color: #50C5F3;
  }
`

const StyledSelectByLine = styled(Select)`
  background-color: black;
  margin: 0.3rem 0;
`

const OptionLine = styled.option`
  padding: .1rem 1rem;
  
  &:hover {
    cursor: pointer;
    color: #50C5F3;
  }
`

const SectorToggler = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: .5rem;
  color: white;
  cursor: pointer;
  margin-top: .2rem;

  > div {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > ${Icon} {
    margin-right: 1rem;
    ${({ collapsed }) => !collapsed && `transform: rotate(90deg);`}
  }
`

const SectorsContainer = styled.div`
  width: 90%;
  margin: 0 5% .5rem;
`

const SectorHead = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  font-family: Blinker;
  font-size: 1.225rem;
  text-transform: Capitalize;
  padding-top: 3px;
`

const SectorName = styled.span`
  margin: -3px 0.5rem;
`

const CamerasCount = styled.div`
  display: inline;
  color: #969696;
  font-family: Roboto;
`

const SectorInfos = styled.div`
  display: inline;
`

const CamerasList = styled.div`
  margin-left: 2rem;
  color: #969696;
  border-left: 1px solid #969696;
  display: ${({ collapsed }) => collapsed ? 'none' : 'block'};
`

const CameraItem = styled.div`
  display: flex;
`

const StyledSearchInput = styled(SearchInput)`
  width: 100%;
`

const CloseButton = styled(BlankButton)`
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  right: 0px;
  top: 0px;
`

const StyledCloseIcon = styled(CloseIcon)`
  margin: .5rem;
  float: right;
`

const SaveContainer = styled.div`
  width: 80%;
  margin: .5rem auto;
`

const SaveButton = styled(Button)`
  width: 100%;
`

const LinkContainer = styled.div`
  text-align: center;
  font-size: 1rem;
  padding: .8rem 0;
  color: ${({ theme }) => theme.primaryColor};
`

const CameraPosition = styled.span`
  margin: 0 0.5rem;
  color: #969696;
`

const DeleteViewModal = ({
  viewToDelete,
  setViewToDelete,
  setOnSave,
  setSelectedView,
  setViews,
  views
}) => {
  const [deleting, setDeleting] = useState(false)

  const handleCancel = () => {
    setViewToDelete(null)
  }

  const handleDelete = () => {
    setDeleting(true)
    setOnSave(true)

    axios
      .delete(`/api/views/${viewToDelete}`)
      .then(() => {
        setOnSave(false)
        setDeleting(false)
        setViewToDelete(null)
        setSelectedView(null)
        setViews(views.filter(view => view.viewId !== viewToDelete))
      })
      .catch(error => {
        console.error(error)

        setOnSave(false)
        setDeleting(false)
        setViewToDelete(null)
      })
  }

  return (
    <Modal
      isOpen={viewToDelete}
      onCloseClick={handleCancel}
      onBackgroundClick={handleCancel}
      onEscapeKeydown={handleCancel}
    >
      <ModalTitle>Êtes-vous sûr de vouloir faire ça ?</ModalTitle>
      <ModalBody>
        <P>
          Souhaitez-vous vraiment supprimer la vue <b>{viewToDelete?.name}</b> ?
          <br />Cette action est irréversible.
        </P>
        <OptionsModal>
          <OptionModal>
            <Button
              danger
              spin={deleting}
              disabled={deleting || process.env.NODE_ENV === 'demonstration'}
              onClick={handleDelete}
            >
              Supprimer
            </Button>
          </OptionModal>
          <OptionModal>
            <Button onClick={handleCancel}>Annuler</Button>
          </OptionModal>
        </OptionsModal>
      </ModalBody>
    </Modal>
  )
}

const CameraViewModal = ({
  cameraOnModal,
  setCameraOnModal,
  camerasFullWidth,
  setCamerasFullWidth
}) => {
  const handleClose = () => {
    setCameraOnModal(null)
  }

  return (
    <Modal
      style={{
        width: '90%',
        height: '90%',
        maxWidth: 'none',
        color: '#000'
      }}
      isOpen={cameraOnModal}
      onCloseClick={handleClose}
      onBackgroundClick={handleClose}
      onEscapeKeydown={handleClose}
    >
      <ModalBody style={{ display: 'flex', width: '100%', height: '100%' }}>
        <WebRTCPlayer
          hideCheck={true}
          hideOrderControls={true}
          hideModalControl={true}
          camera={cameraOnModal}
          setCamerasOrder={() => null}
          camerasOrder={[]}
          cameraOrderIndex={0}
          camerasFullWidth={camerasFullWidth}
          setCamerasFullWidth={setCamerasFullWidth}
        />
      </ModalBody>
    </Modal>
  )
}

const Sector = ({
  sector,
  selectCamera,
  selectedCameras,
  setSelectedCameras,
  search,
  camerasOrder,
  setCamerasOrder,
  camerasFullWidth,
  setCamerasFullWidth
}) => {
  const [collapsed, setCollapsed] = useState(false)

  const checked = sector.cameras.every(
    camera => selectedCameras.includes(camera.id)
  )

  return (
    <>
      <SectorToggler
        onClick={() => setCollapsed(!collapsed)}
        collapsed={collapsed}
      >
        <TriangleRightIcon />
        <div>
          <SectorHead>
            <Checkbox
              labelColor="white"
              onChange={
                () => {
                  if (checked) {
                    setSelectedCameras([
                      ...selectedCameras
                        .filter(
                          selectedCameraId => (
                            !sector
                              .cameras
                              .map(({ id }) => id)
                              .includes(selectedCameraId)
                          )
                        )
                    ])

                    setCamerasOrder([
                      ...camerasOrder.filter(
                        cid => !sector.cameras.map(({ id }) => id).includes(cid)
                      )
                    ])

                    setCamerasFullWidth([
                      ...camerasFullWidth.filter(
                        cid => !sector.cameras.map(({ id }) => id).includes(cid)
                      )
                    ])

                    return
                  }

                  setSelectedCameras([
                    ...new Set([
                      ...sector.cameras.map(({ id }) => id),
                      ...selectedCameras
                    ])
                  ])

                  setCamerasOrder([
                    ...camerasOrder,
                    ...sector
                      .cameras
                      .map(({ id }) => id)
                      .filter(
                        cid => !camerasOrder.includes(cid)
                      )
                  ])
                }
              }
              checked={checked}
            />
            <SectorName>
              {sector.name}
            </SectorName>
          </SectorHead>
          <SectorInfos>
            <CamerasCount>
              {sector.cameras.length} caméras
            </CamerasCount>
          </SectorInfos>
        </div>
      </SectorToggler>
      <CamerasList collapsed={collapsed}>
        {
          sector
            .cameras
            .filter(
              camera => {
                if (
                  sector
                    .name
                    .substr(0, search.length)
                    .toUpperCase()
                  === search.toUpperCase()
                  || sector.name.includes(search)
                  || fuzz.partial_ratio(sector.name, search) >= 85
                )
                  return true

                if (
                  camera
                    .name
                    .substr(0, search.length)
                    .toUpperCase()
                  === search.toUpperCase()
                  || camera.name.includes(search)
                  || fuzz.partial_ratio(camera.name, search) >= 85
                )
                  return true
              }
            )
            .map(
              (camera, i) => {
                const isOnSelect = selectedCameras.includes(camera.id)
                const cameraOrderIndex = camerasOrder.length > 0 ? camerasOrder.findIndex(c => c === camera.id) : 0

                return (
                  <CameraItem key={'camera-item-' + i}>
                    <span>—&nbsp;</span>
                    <Checkbox
                      key={'camera-checkbox-' + i}
                      onChange={() => {
                        if (isOnSelect) {
                          setCamerasOrder([
                            ...camerasOrder.filter(c => c !== camera.id)
                          ])
                          setCamerasFullWidth([
                            ...camerasFullWidth.filter(c => c !== camera.id)
                          ])
                        } else {
                          setCamerasOrder([
                            ...camerasOrder,
                            camera.id
                          ])
                        }

                        selectCamera(
                          camera.id,
                          !isOnSelect
                        )
                      }
                      }
                      checked={isOnSelect}
                      label={camera.name}
                      labelColor='#fff'
                    />
                    {
                      isOnSelect && (
                        <CameraPosition>
                          ({cameraOrderIndex + 1})
                        </CameraPosition>
                      )
                    }
                  </CameraItem>
                )
              }
            )
        }
      </CamerasList>
    </>
  )
}

const WatchMenu = ({
  isOpened,
  onOpen,
  views,
  setViews,
  isLoadingViews,
  selectedView,
  setSelectedView,
  setSelectedCameras,
  sectors,
  selectCamera,
  selectedCameras,
  viewName,
  setViewName,
  setViewToDelete,
  onSave,
  setOnSave,
  cameras,
  camerasOrder,
  setCamerasOrder,
  camerasFullWidth,
  setCamerasFullWidth
}) => {
  const [search, setSearch] = useState('')
  let linkTo = null

  const onChangeView = value => {
    setSelectedView(value)

    if (!value) {
      setSelectedCameras([])
      setViewName('')
      setCamerasOrder([])

      return
    }

    const view = views.find(view => view.viewId === value)

    if (view) {

      setCamerasOrder(view.camerasOrder)

      setSelectedCameras(
        view.cameras ? view.cameras.split(',').map(v => parseInt(v, 10)) : []
      )
      setViewName(view.name)
    }
  }

  const saveView = () => {
    setOnSave(true)

    axios
      .post('/api/views', {
        name: viewName,
        cameras: selectedCameras,
        camerasOrder,
        camerasFullWidth
      })
      .then(({ data: { view } }) => {
        setViews([
          ...views,
          {
            ...view,
            viewId: view.id,
            cameras: view.cameras.join(','),
            camerasOrder: view.camerasOrder.join(',')
          }
        ])

        onChangeView(view.id)

        setOnSave(false)
      })
      .catch(error => {
        console.error(error)

        setOnSave(false)
      })
  }

  const updateView = () => {
    setOnSave(true)

    axios
      .put(`/api/views/${selectedView}`, {
        name: viewName,
        cameras: selectedCameras,
        camerasOrder,
        camerasFullWidth
      })
      .then(({ data: { view } }) => {
        const newView = {
          ...view,
          viewId: parseInt(view.id, 10),
          camerasOrder: view.camerasOrder.join(','),
          cameras: view.cameras.join(',')
        }

        setViews([
          ...views.map(v => v.viewId === parseInt(view.id, 10) ? newView : v)
        ])

        setOnSave(false)
      })
      .catch(error => {
        console.error(error)

        setOnSave(false)
      })
  }

  const deleteView = () => setViewToDelete(selectedView)

  if (
    selectedCameras
    && selectedCameras.length > 0
    && camerasOrder
    && camerasOrder.length > 0
  ) {
    linkTo = (
      '/live?cameras='
      + selectedCameras.join(',')
      + '&cameras_order='
      + camerasOrder.join(',')
    )

    if (camerasFullWidth && camerasFullWidth.length > 0)
      linkTo += '/live?cameras_full_width=' + camerasFullWidth.join(',')
  }

  return (
    <WatchMenuContainer isOpened={isOpened}>
      {
        isOpened && (
          <CloseButton onClick={() => onOpen(!isOpened)}>
            <StyledCloseIcon color="#50C5F3" />
          </CloseButton>
        )
      }
      <MenuOpener onClick={() => onOpen(!isOpened)}>
        <StyledCameraIcon />
        {
          isOpened && 'Caméras'
        }
      </MenuOpener>
      {
        isOpened && (
          <>
            {
              isLoadingViews ? (
                <SelectContainer>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin={true}
                    style={{ marginRight: '5px', color: '#fff' }}
                  />
                </SelectContainer>
              ) : (
                <>
                  <SelectContainer>
                    {
                      views.length > 0 && (
                        <StyledSelectByView
                          name='views'
                          placeholder='Nouvelle vue'
                          value={selectedView}
                          dark
                          style={{ width: '80%' }}
                          onChange={onChangeView}
                        >
                          {
                            (Array.isArray(views) && views.length > 0) && (
                              [
                                {
                                  viewId: null,
                                  name: 'Nouvelle vue',
                                  order: '',
                                  cameras: ''
                                },
                                ...views
                              ].map(view => {
                                return (
                                  <OptionViews
                                    key={'view-option-' + view.viewId}
                                    value={view.viewId}
                                    selected={selectedView === view.viewId}
                                  >
                                    {
                                      view.viewId === null ? (
                                        view.name
                                      ) : (
                                        `Vue: ${view.name} (#${view.viewId})`
                                      )
                                    }
                                  </OptionViews>
                                )
                              })
                            )
                          }
                        </StyledSelectByView>
                      )
                    }
                  </SelectContainer>
                  <SearchInputContainer>
                    <StyledSearchInput
                      dark={true}
                      placeholder="Rechercher"
                      onChange={setSearch}
                    />
                  </SearchInputContainer>
                </>
              )
            }
            {
              linkTo && (
                <LinkContainer>
                  <Link target="_blank" to={linkTo}>
                    <FontAwesomeIcon
                      icon={faTv}
                      style={{ marginRight: '5px' }}
                    />
                    Afficher la sélection en mode écran
                  </Link>
                </LinkContainer>
              )
            }
            <SectorsContainer>
              {
                sectors
                  .filter(
                    sector => {
                      if (search === '')
                        return true

                      if (
                        sector
                          .name
                          .substr(0, search.length)
                          .toUpperCase()
                        === search.toUpperCase()
                        || sector.name.includes(search)
                        || fuzz.partial_ratio(sector.name, search) >= 85
                      )
                        return true

                      if (
                        sector.cameras.find(
                          camera =>
                            camera
                              .name
                              .substr(0, search.length)
                              .toUpperCase()
                            === search.toUpperCase()
                            || camera.name.includes(search)
                            || fuzz.partial_ratio(camera.name, search) >= 85
                        )
                      )
                        return true

                      return false
                    }
                  )
                  .map(
                    (sector, i) => (
                      <Sector
                        key={'sector-item-' + i}
                        sector={sector}
                        selectCamera={selectCamera}
                        selectedCameras={selectedCameras}
                        setSelectedCameras={setSelectedCameras}
                        search={search}
                        selectedView={selectedView}
                        camerasOrder={camerasOrder}
                        setCamerasOrder={setCamerasOrder}
                        camerasFullWidth={camerasFullWidth}
                        setCamerasFullWidth={setCamerasFullWidth}
                      />
                    )
                  )
              }
              <Sector
                key={'sector-all'}
                sector={{
                  name: 'Caméras',
                  cameras
                }}
                selectCamera={selectCamera}
                selectedCameras={selectedCameras}
                setSelectedCameras={setSelectedCameras}
                search={search}
                selectedView={selectedView}
                camerasOrder={camerasOrder}
                setCamerasOrder={setCamerasOrder}
                camerasFullWidth={camerasFullWidth}
                setCamerasFullWidth={setCamerasFullWidth}
              />
            </SectorsContainer>
            {
              linkTo && (
                <LinkContainer>
                  <Link to={linkTo} target="_blank">
                    <FontAwesomeIcon
                      icon={faTv}
                      style={{ marginRight: '5px' }}
                    />
                    Afficher la sélection en mode écran
                  </Link>
                </LinkContainer>
              )
            }
            <SearchInputContainer>
              {
                onSave ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin={true}
                    style={{ marginRight: '5px' }}
                  />
                ) : (
                  <TextInput
                    dark
                    value={selectedView ? viewName : ''}
                    required
                    label='Nom de la vue'
                    onChange={value => setViewName(value.replace(/ /g, "_"))}
                  />
                )
              }
            </SearchInputContainer>
            {
              !selectedView ? (
                <>
                  <SaveContainer>
                    <SaveButton
                      primary
                      onClick={saveView}
                      disabled={onSave || viewName.length == 0}
                    >
                      {
                        onSave
                          ? (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              spin={true}
                              style={{ marginRight: '5px' }}
                            />
                          ) : (
                            'Créer la vue'
                          )
                      }
                    </SaveButton>
                  </SaveContainer>
                </>
              ) : (
                <>
                  <SaveContainer>
                    <SaveButton
                      primary
                      onClick={updateView}
                      disabled={onSave}
                    >
                      {
                        onSave
                          ? (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              spin={true}
                              style={{ marginRight: '5px' }}
                            />
                          ) : (
                            'Mettre à jour la vue'
                          )
                      }
                    </SaveButton>
                  </SaveContainer>
                  <SaveContainer>
                    <SaveButton
                      danger
                      onClick={deleteView}
                      disabled={onSave}
                    >
                      {
                        onSave
                          ? (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              spin={true}
                              style={{ marginRight: '5px' }}
                            />
                          ) : (
                            'Supprimer la vue'
                          )
                      }
                    </SaveButton>
                  </SaveContainer>
                </>
              )
            }
          </>
        )
      }
    </WatchMenuContainer>
  )
}

const Watch = () => {
  const [sectors, setSectors] = useState([])
  const [cameras, setCameras] = useState([])
  const [isMenuOpened, setIsMenuOpened] = useState(true)
  const [selectedView, setSelectedView] = useState(null)
  const [views, setViews] = useState([])
  const [isLoadingViews, setIsLoadingViews] = useState(true)
  const [selectedCameras, setSelectedCameras] = useState([])
  const [viewName, setViewName] = useState('')
  const [viewToDelete, setViewToDelete] = useState(null)
  const [onSave, setOnSave] = useState(false)
  const [camerasOrder, setCamerasOrder] = useState([])
  const [camerasFullWidth, setCamerasFullWidth] = useState([])
  const [cameraOnModal, setCameraOnModal] = useState(null)

  useEffect(() => {
    axios.get('/api/sectors')
      .then(({ data: { sectors } }) => {
        setSectors(JSON.parse(JSON.stringify(sectors)))

        return axios.get('/api/cameras')
      })
      .then(({ data: { cameras } }) => {
        setCameras(JSON.parse(JSON.stringify(cameras)))

        return axios.get('/api/views')
      })
      .then(({ data: { views } }) => {
        let result = views.map(viewItem => {
          return {
            ...viewItem,
            camerasOrder: viewItem.camerasOrder.search('{') != -1 && viewItem.camerasOrder.search('}') != -1 ? JSON.parse(viewItem.camerasOrder.replace('{', '[').replace('}', ']')) : []
          }
        })
        setViews(result)

        setIsLoadingViews(false)
      })
  }, [])

  const selectCamera = (id, selected) => {
    if (selected) {
      setSelectedCameras([...new Set([id, ...selectedCameras])])
      return
    }

    setSelectedCameras(selectedCameras.filter(cameraId => cameraId !== id))
  }

  const history = useHistory()
  const { resource } = useParams()

  return (
    <Container>
      <DeleteViewModal
        viewToDelete={viewToDelete}
        setViewToDelete={setViewToDelete}
        setOnSave={setOnSave}
        setSelectedView={setSelectedView}
        setViews={setViews}
        views={views}
      />
      <CameraViewModal
        cameraOnModal={
          cameras.find(
            camera => cameraOnModal && camera.id === cameraOnModal.id
          )
        }
        setCameraOnModal={setCameraOnModal}
        camerasFullWidth={camerasFullWidth}
        setCamerasFullWidth={setCamerasFullWidth}
      />
      {
        resource === 'views' && (
          <WatchMenu
            isOpened={isMenuOpened}
            isLoadingViews={isLoadingViews}
            onOpen={setIsMenuOpened}
            views={views}
            setViews={setViews}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            sectors={sectors}
            selectedCameras={selectedCameras}
            selectCamera={selectCamera}
            setSelectedCameras={setSelectedCameras}
            viewName={viewName}
            setViewName={setViewName}
            setViewToDelete={setViewToDelete}
            onSave={onSave}
            setOnSave={setOnSave}
            cameras={cameras}
            camerasOrder={camerasOrder}
            setCamerasOrder={setCamerasOrder}
            camerasFullWidth={camerasFullWidth}
            setCamerasFullWidth={setCamerasFullWidth}
          />
        )
      }
      <PlayerGrid cameraList={selectedCameras} startDateTimeStamp={null} endDateTimeStamp={null} mode='LIVE' />
    </Container>
  )
}

export default Watch
