import React from 'react'
import { motion, useSpring } from 'framer-motion/dist/framer-motion'
import { H3, P, TextInput } from '../'
import { Button, SmallButton } from '../Button'
import { v4 as uuid } from 'uuid'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faExclamationTriangle,
  faHandPaper,
  faLocationArrow,
  faSearchMinus,
  faSearchPlus,
  faSpinner,
  faSyncAlt,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import styled, { useTheme } from 'styled-components'

import { useTranslation } from 'react-i18next'

const Separator = styled.hr`
  border: 0;
  margin: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`

function drawPolygon(ctx, polygon, { color, width, dashed }) {
  if (dashed) {
    ctx.fillStyle = `rgba(${color.join(',')}, 0.2)`
    ctx.setLineDash([5, 10])
  } else {
    ctx.fillStyle = `rgba(${color.join(',')}, 0.4)`
  }
  ctx.strokeStyle = `rgba(${color.join(',')}, 1)`
  ctx.lineWidth = width
  ctx.beginPath()
  for (let j = 0; j < polygon.length; j++) {
    const { x, y } = polygon[j]
    if (j === 0) ctx.moveTo(x, y)
    else ctx.lineTo(x, y)
  }
  ctx.closePath()
  ctx.fill()
  ctx.stroke()
  ctx.setLineDash([])
}

function drawCentroid(ctx, polygon, { label }) {
  const centroid = getCentroid(polygon)

  ctx.fillStyle = '#111d'
  ctx.beginPath()
  ctx.arc(centroid.x, centroid.y, 10, 0, 2 * Math.PI)
  ctx.fill()

  if (label) {
    ctx.fillStyle = '#fff'
    ctx.fillText(label, centroid.x - 3, centroid.y + 3)
  }
}

function drawBackground(ctx, image, { origin, zoom }) {
  ctx.fillStyle = '#000'
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
  ctx.drawImage(
    image,
    origin.x,
    origin.y,
    image.width / zoom,
    image.height / zoom,
    0,
    0,
    ctx.canvas.width,
    ctx.canvas.height
  )
}

function drawVertices(ctx, polygon, { hoveredIndex, activeIndex }) {
  for (let j = 0; j < polygon.length; j++) {
    ctx.fillStyle = activeIndex === j ? '#d00' : '#fff'
    const radius = hoveredIndex === j ? 6 : 3
    const { x, y } = polygon[j]
    ctx.beginPath()
    ctx.arc(x, y, radius, 0, 2 * Math.PI)
    ctx.stroke()
    ctx.fill()
  }
}

function getCentroid(polygon) {
  const centroid = { x: 0, y: 0 }
  let totalArea = 0
  for (let j = 0; j < polygon.length; j++) {
    const p1 = polygon[j]
    const p2 = polygon[(j + 1) % polygon.length]
    const area = p1.x * p2.y - p2.x * p1.y
    centroid.x += (p1.x + p2.x) * area
    centroid.y += (p1.y + p2.y) * area
    totalArea += area
  }
  const denom = totalArea * 3
  centroid.x /= denom
  centroid.y /= denom

  return centroid
}

function isInside(p, polygon) {
  const n = polygon.length
  if (n < 3) return false

  const extreme = { x: 10000, y: p.y }

  let count = 0
  let i = 0
  do {
    const next = (i + 1) % n
    if (doIntersect(polygon[i], polygon[next], p, extreme)) {
      if (orientation(polygon[i], p, polygon[next]) == 0)
        return onSegment(polygon[i], p, polygon[next])
      count++
    }
    i = next
  } while (i != 0)

  return count % 2 == 1
}

function doIntersect(p1, q1, p2, q2) {
  let o1 = orientation(p1, q1, p2)
  let o2 = orientation(p1, q1, q2)
  let o3 = orientation(p2, q2, p1)
  let o4 = orientation(p2, q2, q1)

  if (o1 != o2 && o3 != o4) return true
  if (o1 == 0 && onSegment(p1, p2, q1)) return true
  if (o2 == 0 && onSegment(p1, q2, q1)) return true
  if (o3 == 0 && onSegment(p2, p1, q2)) return true
  if (o4 == 0 && onSegment(p2, q1, q2)) return true
  return false
}

function orientation(p, q, r) {
  const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y)
  if (val == 0) return 0
  return val > 0 ? 1 : 2
}

function onSegment(p, q, r) {
  return (
    q.x <= Math.max(p.x, r.x) &&
    q.x >= Math.min(p.x, r.x) &&
    q.y <= Math.max(p.y, r.y) &&
    q.y >= Math.min(p.y, r.y)
  )
}

function initState(initialZones) {
  return {
    pendingDeleteId: null,
    isResetPressed: false,
    isPlusPressed: false,
    isMinusPressed: false,
    highLightedId: null,
    editingId: null,
    isAdding: false,
    isPanning: false,
    zones: initialZones,
  }
}

function stateReducer(state, action) {
  switch (action.type) {
    case 'addVertex': {
      return {
        ...state,
        zones: state.zones.map((z) => {
          if (z.id === state.editingId) {
            z.vertices.push(action.point)
          }
          return z
        }),
      }
    }
    case 'removePendingZone': {
      const zones = state.zones.filter((z) => z.id !== state.pendingDeleteId)
      if (state.pendingDeleteId === state.editingId) {
        return {
          ...state,
          editingId: null,
          isAdding: false,
          pendingDeleteId: null,
          zones,
        }
      }
      return { ...state, zones, pendingDeleteId: null }
    }
    case 'resetPendingDelete': {
      return { ...state, pendingDeleteId: null }
    }
    case 'pressReset': {
      return { ...state, isResetPressed: true }
    }
    case 'pressPlus': {
      return { ...state, isPlusPressed: true }
    }
    case 'pressMinus': {
      return { ...state, isMinusPressed: true }
    }
    case 'releaseReset': {
      return { ...state, isResetPressed: false }
    }
    case 'releasePlus': {
      return { ...state, isPlusPressed: false }
    }
    case 'releaseMinus': {
      return { ...state, isMinusPressed: false }
    }
    case 'editZone': {
      return {
        ...state,
        highLightedId: null,
        editingId: action.zone.id,
      }
    }
    case 'highlightZone': {
      return {
        ...state,
        highLightedId: action.zone.id,
      }
    }
    case 'enablePanning': {
      return { ...state, isPanning: true, highLightedId: null }
    }
    case 'disablePanning': {
      return { ...state, isPanning: false }
    }
    case 'unhighlightZone': {
      return {
        ...state,
        highLightedId: null,
      }
    }
    case 'removeActiveZone': {
      const hasEvent = action.events.find((e) => e.zoneId === state.editingId)
      if (hasEvent) {
        return { ...state, pendingDeleteId: state.editingId }
      }
      const zones = state.zones.filter((z) => z.id !== state.editingId)
      return { ...state, editingId: null, isAdding: false, zones }
    }
    case 'initZoneVertexMove': {
      return {
        ...state,
        zones: state.zones.map((zone) => {
          if (zone.id === state.editingId) {
            return { ...zone, vertices: [...zone.vertices] }
          }
          return zone
        }),
      }
    }
    case 'removeZone': {
      const { zone } = action
      const hasEvent = action.events.find((e) => e.zoneId === zone.id)
      if (hasEvent) {
        return { ...state, pendingDeleteId: zone.id }
      }
      const zones = state.zones.filter((z) => z.id !== zone.id)
      if (zone.id === state.editingId) {
        return { ...state, editingId: null, zones }
      }
      return { ...state, zones }
    }
    case 'validateOrAddZone': {
      if (state.editingId) {
        const zone = state.zones.find((z) => z.id === state.editingId)
        if (zone.vertices.length < 4) {
          return state
        }
        return { ...state, editingId: null, isAdding: false }
      }
      const id = uuid()
      return {
        ...state,
        isAdding: true,
        isPanning: false,
        highLightedId: null,
        editingId: id,
        zones: [
          ...state.zones,
          {
            id,
            name: `Zone ${state.zones.length + 1}`,
            vertices: [],
          },
        ],
      }
    }
    case 'setZoneName': {
      const { zone, name } = action
      return {
        ...state,
        zones: state.zones.map((z) => {
          if (z.id === zone.id) {
            return { ...z, name }
          }
          return z
        }),
      }
    }
  }
}

export default React.memo(function Editor({
  image,
  initialZones,
  events = [],
  onSubmit,
  onCancel,
}) {
  const canvasRef = React.useRef()
  const imageRef = React.useRef()
  const [loading, setLoading] = React.useState(false)
  const [state, dispatch] = React.useReducer(
    stateReducer,
    initialZones,
    initState
  )
  const {
    zones,
    pendingDeleteId,
    highLightedId,
    editingId,
    isPanning,
    isAdding,
    isPlusPressed,
    isMinusPressed,
    isResetPressed,
  } = state

  const activeZone = zones.find((z) => z.id === editingId)
  const zonesInList = isAdding ? zones.filter((z) => z !== activeZone) : zones
  const pendingDeleteZone = zones.find((z) => z.id === pendingDeleteId)
  const pendingEventCount = events.filter((e) => e.zoneId === pendingDeleteId)
    .length

  function editZone(zone) {
    dispatch({ type: 'editZone', zone })
  }

  function highlightZone(zone) {
    canvasRef.current.style.cursor = 'pointer'
    dispatch({ type: 'highlightZone', zone })
  }

  function unhighlightZone() {
    canvasRef.current.style.cursor = 'crosshair'
    dispatch({ type: 'unhighlightZone' })
  }

  function removeActiveZone() {
    dispatch({ type: 'removeActiveZone', events })
  }

  function removePendingZone() {
    dispatch({ type: 'removePendingZone' })
  }

  async function removeZone(zone) {
    dispatch({ type: 'removeZone', zone, events })
  }

  function setZoneName(zone, name) {
    dispatch({ type: 'setZoneName', zone, name })
  }

  function validateOrAddZone() {
    dispatch({ type: 'validateOrAddZone' })
  }

  function enablePanning() {
    if (!isPanning) dispatch({ type: 'enablePanning' })
  }

  function disablePanning() {
    dispatch({ type: 'disablePanning' })
  }

  function resetPendingDelete() {
    dispatch({ type: 'resetPendingDelete' })
  }

  useEvent('wheel', (e) => {
    e.preventDefault()
    if (e.deltaY > 0) {
      pane.zoomIn()
      dispatch({ type: 'pressPlus' })
      setTimeout(() => dispatch({ type: 'releasePlus' }), 200)
    } else {
      pane.zoomOut()
      dispatch({ type: 'pressMinus' })
      setTimeout(() => dispatch({ type: 'releaseMinus' }), 200)
    }
  })

  useEvent(
    'keydown',
    (e) => {
      switch (e.key) {
        case 'Home':
          e.preventDefault()
          pane.resetView()
          dispatch({ type: 'pressReset' })
          break
        case 'Escape':
          e.preventDefault()
          onCancel()
          break
        case '+':
          pane.zoomIn()
          dispatch({ type: 'pressPlus' })
          break
        case '-':
          pane.zoomOut()
          dispatch({ type: 'pressMinus' })
          break
        case 'Control':
          e.preventDefault()
          enablePanning()
          break
        case 'Enter':
          e.preventDefault()
          validateOrAddZone()
          break
        case 'Delete':
          e.preventDefault()
          removeActiveZone()
          break
      }
    },
    [isPanning, activeZone, events]
  )

  useEvent('keyup', ({ key }) => {
    switch (key) {
      case 'Control':
        disablePanning()
        break
      case 'Home':
        dispatch({ type: 'releaseReset' })
        break
      case '+':
        dispatch({ type: 'releasePlus' })
        break
      case '-':
        dispatch({ type: 'releaseMinus' })
        break
    }
  })

  const hoverPointIndex = React.useRef(null)
  const clickedPointIndex = React.useRef(null)
  const hasClicked = React.useRef(false)
  const mousePosition = React.useRef({ x: 0, y: 0 })

  const pane = usePaneNavigation({
    imageWidth: image.width,
    imageHeight: image.height,
    mousePosition,
  })

  React.useEffect(() => {
    canvasRef.current.style.cursor = isPanning ? 'move' : 'crosshair'
  }, [isPanning])

  React.useEffect(() => {
    if (!canvasRef.current) return
    const ctx = canvasRef.current.getContext('2d')
    let timerId

    function draw() {
      drawBackground(ctx, imageRef.current, pane)
      for (let i = 0; i < zones.length; i++) {
        const zoneId = zones[i].id
        const vertices = zones[i].vertices.map(pane.imageToScreenCoords)
        if (zoneId === highLightedId) {
          drawPolygon(ctx, vertices, { color: [0, 0, 238], width: 1 })
          drawCentroid(ctx, vertices, { label: i + 1 })
        } else if (zoneId === editingId) {
          const options = { color: [255, 0, 0], width: 3 }
          if (isAdding) {
            vertices.push(mousePosition.current)
            options.width = 2
            options.dashed = true
          }
          drawPolygon(ctx, vertices, options)
          drawVertices(ctx, vertices, {
            hoveredIndex: hoverPointIndex.current,
            activeIndex: clickedPointIndex.current,
          })
        } else {
          drawPolygon(ctx, vertices, { color: [239, 108, 0], width: 1 })
          drawCentroid(ctx, vertices, { label: i + 1 })
        }
      }

      timerId = requestAnimationFrame(draw)
    }
    draw()
    return () => cancelAnimationFrame(timerId)
  }, [zones, editingId, isAdding, highLightedId])

  function onCanvasMouseUp(e) {
    e.preventDefault()
    hasClicked.current = false
    clickedPointIndex.current = null
  }

  function initZoneVertexMove(coords) {
    canvasRef.current.style.cursor = 'grabbing'
    clickedPointIndex.current = hoverPointIndex.current
    dispatch({ type: 'initZoneVertexMove', coords })
  }

  function onCanvasMouseDown(e) {
    e.preventDefault()
    const cursorCoords = pane.screenToImageCoords(mousePosition.current)

    hasClicked.current = true
    if (isPanning) {
    } else if (hoverPointIndex.current !== null) {
      initZoneVertexMove(cursorCoords)
    } else if (activeZone) {
      dispatch({ type: 'addVertex', point: cursorCoords })
    } else {
      const highlighted = zones.find((z) => z.id == highLightedId)
      if (highlighted) {
        editZone(highlighted)
      }
    }
  }

  function onCanvasMouseMove(e) {
    e.preventDefault()
    mousePosition.current = {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
    }
    const cursorCoords = pane.screenToImageCoords(mousePosition.current)

    if (isPanning) {
      if (hasClicked.current) {
        pane.panView(e.nativeEvent.movementX, e.nativeEvent.movementY)
      }
    } else if (!activeZone) {
      highlightZoneAtCoords(cursorCoords)
    } else if (clickedPointIndex.current !== null) {
      moveZoneVertex(clickedPointIndex.current, cursorCoords)
    } else {
      hoverZoneVertexAtCoords(activeZone, cursorCoords)
    }
  }

  function moveZoneVertex(pointIndex, coords) {
    activeZone.vertices[pointIndex] = coords
  }

  function hoverZoneVertexAtCoords(zone, coords) {
    const hoverTriggerRadius = 8
    const hoveredVertexIndex = zone.vertices.findIndex(
      ({ x, y }) => Math.hypot(coords.x - x, coords.y - y) < hoverTriggerRadius
    )
    if (hoveredVertexIndex >= 0) {
      hoverPointIndex.current = hoveredVertexIndex
      canvasRef.current.style.cursor = 'grab'
    } else {
      hoverPointIndex.current = null
      canvasRef.current.style.cursor = isAdding ? 'pointer' : 'crosshair'
    }
  }

  function highlightZoneAtCoords(coords) {
    let zoneToHighlight
    for (let i = zones.length - 1; i >= 0; i--) {
      const zone = zones[i]
      if (isInside(coords, zone.vertices)) {
        zoneToHighlight = zone
        break
      }
    }
    if (zoneToHighlight) {
      highlightZone(zoneToHighlight)
    } else {
      unhighlightZone()
    }
  }

  async function submit() {
    pane.resetView()
    setLoading(true)

    await onSubmit(zones, image)
    setLoading(false)
  }

  const theme = useTheme()

  const { t } = useTranslation()

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          borderRadius: 10,
        }}
      >
        <H3 style={{ color: 'white' }}>{t('scenario.createModifyZone')}</H3>
        <FontAwesomeIcon
          size="2x"
          color={theme.primaryColor}
          icon={faTimes}
          style={{ cursor: 'pointer' }}
          onClick={onCancel}
        />
      </div>
      <P
        style={{
          marginBottom: 30,
          color: 'rgba(255,255,255,0.6)',
        }}
      >
        <strong>{t('scenario.defineZoneDetection')}</strong>
        {t('scenario.defineZoneDetectionDetail')}
      </P>
      <div style={{ display: 'flex' }}>
        <motion.div style={{ marginRight: 10, width: 'min-content' }}>
          <ToolButton
            onClick={disablePanning}
            active={!isPanning}
            icon={faLocationArrow}
            tooltip={t('popUpZoneDefinitionForAScenario.drawZone')}
          />
          <ToolButton
            onClick={enablePanning}
            active={isPanning}
            icon={faHandPaper}
            tooltip={t('popUpZoneDefinitionForAScenario.moveOnView')}
            shortcut={t('popUpZoneDefinitionForAScenario.shortcutHoldDownCtrl')}
          />
          <Separator />
          <ToolButton
            onClick={pane.zoomIn}
            pressed={isPlusPressed}
            icon={faSearchPlus}
            tooltip={t('popUpZoneDefinitionForAScenario.zoomIn')}
            shortcut="+"
          />
          <ToolButton
            onClick={pane.zoomOut}
            pressed={isMinusPressed}
            icon={faSearchMinus}
            tooltip={t('popUpZoneDefinitionForAScenario.zoomOut')}
            shortcut="-"
          />
          <Separator />
          <ToolButton
            onClick={pane.resetView}
            pressed={isResetPressed}
            icon={faSyncAlt}
            tooltip={t('popUpZoneDefinitionForAScenario.resetView')}
            shortcut={t('popUpZoneDefinitionForAScenario.shortcutHome')}
          />
        </motion.div>
        <div
          style={{
            width: image.width,
            height: image.height,
            position: 'relative',
            flexShrink: 0,
          }}
        >
          <motion.img
            ref={imageRef}
            transition={{
              delay: 0.15,
              mass: 0.1,
              type: 'spring',
            }}
            style={{
              width: image.width,
              height: image.height,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            src={image.src}
            layoutId="image"
          />
          <motion.canvas
            ref={canvasRef}
            width={image.width}
            height={image.height}
            transition={{
              delay: 0.15,
              mass: 0.1,
              type: 'spring',
            }}
            style={{
              width: image.width,
              height: image.height,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            onMouseMove={onCanvasMouseMove}
            onMouseDown={onCanvasMouseDown}
            onMouseUp={onCanvasMouseUp}
            layoutId="canvas"
          />
        </div>
        <div style={{ overflow: 'hidden', marginLeft: 15, width: 250 }}>
          <AnimatePresence>
            {zonesInList.map((z, i) => (
              <motion.div
                layout
                key={z.id}
                enter={{ x: 0, opacity: 1 }}
                initial={{ x: -300, opacity: 0 }}
                exit={{ x: -300, opacity: 0 }}
                transition={{ mass: 0.2 }}
                animate={z.id === highLightedId ? 'active' : 'idle'}
                onMouseOver={() => highlightZone(z)}
                onMouseOut={() => unhighlightZone(z)}
                variants={{
                  idle: {
                    x: 0,
                    opacity: 1,
                    background: 'rgba(125, 125, 226, 0)',
                    border: '1px dashed rgba(125, 125, 226, 0)',
                  },
                  active: {
                    x: 0,
                    opacity: 1,
                    background: 'rgba(125, 125, 226, 0.4)',
                    border: '1px dashed rgba(0, 0, 226)',
                  },
                }}
                style={{
                  display: 'flex',
                  marginBottom: 8,
                  borderRadius: 5,
                  padding: '5px 0',
                }}
              >
                <TextInput
                  dark
                  value={z.name}
                  onChange={(newValue) => setZoneName(z, newValue)}
                  label={`${t('popUpZoneDefinitionForAScenario.zoneName')} ${i + 1}`}
                />
                <Button
                  type="button"
                  active={z.id === editingId}
                  onClick={() => editZone(z)}
                  style={{ padding: '10px', border: 0 }}
                >
                  <FontAwesomeIcon color="white" icon={faEdit} />
                </Button>
                <Button
                  type="button"
                  onClick={() => removeZone(z)}
                  style={{ padding: '10px', border: 0 }}
                >
                  <FontAwesomeIcon color="white" icon={faTrash} />
                </Button>
              </motion.div>
            ))}
            {!isAdding && zones.length === 0 && (
              <motion.div
                layout
                animate={{ height: 80, y: 0, opacity: 1, padding: 10 }}
                enter={{ height: 80, y: 0, opacity: 1, padding: 10 }}
                initial={{ height: 0, y: -20, opacity: 0, padding: 0 }}
                exit={{ height: 0, y: -20, opacity: 0, padding: 0 }}
                transition={{
                  type: 'spring',
                  damping: 13,
                }}
                style={{
                  overflow: 'hidden',
                  background: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: 5,
                  padding: 10,
                  height: 80,
                  marginBottom: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <P
                    style={{
                      color: 'rgba(255, 255, 255, .8)',
                      fontWeight: 'bold',
                    }}
                  >
                    {t('popUpZoneDefinitionForAScenario.noZone')}
                  </P>
                  <P style={{ color: 'rgba(255,255,255,0.4)' }}>
                    {t('popUpZoneDefinitionForAScenario.noZoneDescription')}
                  </P>
                </div>
              </motion.div>
            )}
            {isAdding && (
              <motion.div
                layout
                animate={{ height: 'auto', y: 0, opacity: 1, padding: 10 }}
                enter={{ height: 'auto', y: 0, opacity: 1, padding: 10 }}
                initial={{ height: 0, y: -20, opacity: 0, padding: 0 }}
                exit={{ height: 0, y: -20, opacity: 0, padding: 0 }}
                transition={{
                  type: 'spring',
                  damping: 13,
                }}
                style={{
                  overflow: 'hidden',
                  height: 'auto',
                  background:
                    activeZone.vertices.length < 4
                      ? 'rgba(255, 255, 255, 0.1)'
                      : 'rgba(0, 150, 136, 0.2)',
                  borderRadius: 5,
                  marginBottom: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <P
                    style={{
                      color: 'rgba(255, 255, 255, .8)',
                      fontWeight: 'bold',
                    }}
                  >
                    {activeZone.vertices.length < 4
                      ? "Ajout d'une zone"
                      : 'Zone définie'}
                  </P>
                  <P style={{ color: 'rgba(255,255,255,0.4)' }}>
                    {activeZone.vertices.length < 4
                      ? t('popUpZoneDefinitionForAScenario.drawZoneDescription')
                      : t('popUpZoneDefinitionForAScenario.validZone')}
                  </P>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <SmallButton
            type="button"
            color={activeZone ? '#26a69a' : undefined}
            hoverColor={activeZone ? '#00766c' : undefined}
            disabled={isAdding && activeZone.vertices.length < 4}
            onClick={validateOrAddZone}
            style={{ width: '100%' }}
          >
            {activeZone ? t('popUpZoneDefinitionForAScenario.validateZone') : t('popUpZoneDefinitionForAScenario.addZone')}
          </SmallButton>
        </div>
      </div>
      <div
        style={{
          marginTop: 20,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <AnimatePresence>
            {pendingDeleteId && (
              <motion.div
                initial={{ y: -30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                enter={{ y: 0, opacity: 1 }}
                exit={{ y: -30, opacity: 0 }}
                transition={{ type: 'spring', mass: 0.3, damping: 8 }}
                style={{
                  borderRadius: 5,
                  minWidth: 0,
                  background: 'rgba(255,153,0,0.15)',
                  padding: '5px 10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <P
                  as="p"
                  style={{
                    maxWidth: 400,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    color: 'white',
                  }}
                >
                  <FontAwesomeIcon
                    color="#ffa000"
                    icon={faExclamationTriangle}
                    style={{ marginRight: 15 }}
                  />
                  {pendingEventCount} événement(s) défini sur la zone{' '}
                  <i>« {pendingDeleteZone.name} »</i>
                </P>
                <PendingZoneConfirmButton
                  onClick={removePendingZone}
                  onTimeout={resetPendingDelete}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SmallButton
            type="button"
            color="rgba(255,0,0,0.5)"
            hoverColor="rgba(255,0,0,0.3)"
            onClick={onCancel}
            style={{
              marginRight: 20,
              fontWeight: 'normal',
            }}
          >
            {t('popUpZoneDefinitionForAScenario.cancel')}
          </SmallButton>
          <SmallButton
            type="button"
            color="#43a047"
            disabled={loading}
            hoverColor="#00701a"
            onClick={submit}
          >
            {loading && (
              <FontAwesomeIcon
                icon={faSpinner}
                style={{ marginRight: 10 }}
                spin
              />
            )}
            {loading
              ? 'Enregistrement en cours...'
              : t('popUpZoneDefinitionForAScenario.saveChanges')}
          </SmallButton>
        </div>
      </div>
    </>
  )
})

function PendingZoneConfirmButton({ onClick, onTimeout }) {
  const [hovered, setHovered] = React.useState(false)
  return (
    <div
      style={{
        position: 'relative',
        marginLeft: 15,
      }}
    >
      <Tooltip isOpen={hovered} color="red" direction="up">
        <P
          style={{
            color: 'white',
            whiteSpace: 'nowrap',
            fontSize: '.8rem',
          }}
        >
          Les événements associés à la zone seront supprimés.
        </P>
      </Tooltip>
      <SmallButton
        as={motion.button}
        onClick={onClick}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        whileHover={{
          background: 'rgba(255,0,0,0.5)',
          scale: 1,
        }}
        whileTap={{
          scale: 0.9,
        }}
        animate={{
          background: 'rgba(255,0,0,0.2)',
          scale: 1,
        }}
        style={{
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          fontSize: '1rem',
          fontWeight: 'normal',
        }}
      >
        Suppr. quand même
        <CountDown timeout={10} onEnd={onTimeout} style={{ marginLeft: 5 }} />
      </SmallButton>
    </div>
  )
}

function useEvent(eventName, callback, deps = []) {
  React.useEffect(() => {
    document.body.addEventListener(eventName, callback, { passive: false })
    return () => document.body.removeEventListener(eventName, callback)
  }, deps)
}

function usePaneNavigation({
  imageWidth,
  imageHeight,
  mousePosition,
  panSpeed = 12,
}) {
  const zoom = useSpring(1, { mass: 0.1, damping: 10 })
  const xOffset = useSpring(0, { mass: 0.1, damping: 10 })
  const yOffset = useSpring(0, { mass: 0.1, damping: 10 })

  return {
    get origin() {
      return this.screenToImageCoords({ x: 0, y: 0 })
    },

    get zoom() {
      return zoom.get()
    },

    zoomIn() {
      zoom.set(zoom.get() * 1.5)
    },

    zoomOut() {
      zoom.set(Math.max(zoom.get() / 1.5, 1))
    },

    panView(dx, dy) {
      xOffset.set(xOffset.get() - dx * panSpeed)
      yOffset.set(yOffset.get() - dy * panSpeed)
    },

    resetView() {
      zoom.set(1)
      xOffset.set(0)
      yOffset.set(0)
    },

    screenToImageCoords(coords) {
      const z = coords.z || zoom.get()
      const x = (z - 1) * (imageWidth / 2) + xOffset.get()
      const y = (z - 1) * (imageHeight / 2) + yOffset.get()
      return {
        x: (coords.x + x) / z,
        y: (coords.y + y) / z,
      }
    },

    imageToScreenCoords(coords) {
      const z = coords.z || zoom.get()
      const x = (z - 1) * (imageWidth / 2) + xOffset.get()
      const y = (z - 1) * (imageHeight / 2) + yOffset.get()
      return {
        x: z * coords.x - x,
        y: z * coords.y - y,
      }
    },
  }
}

export function Tooltip({
  isOpen,
  delay = 0,
  direction = 'left',
  color,
  children,
}) {
  const position = (() => {
    switch (direction) {
      case 'left':
        return {
          start: { x: '30%', y: '-50%' },
          end: { x: 'calc(-100% - 15px)', y: '-50%' },
          left: 0,
          top: '50%',
        }
      case 'up':
        return {
          start: { x: '-50%', y: 0 },
          end: { x: '-50%', y: 'calc(-100% - 5px)' },
          left: '50%',
          top: 0,
        }
      case 'down':
        return {
          start: { x: '-50%', y: -15 },
          end: { x: '-50%', y: 15 },
          left: '50%',
          top: '100%',
        }
    }
  })()

  const animStart = {
    ...position.start,
    opacity: 0,
    background: 'rgb(51,51,51)',
  }

  const animEnd = {
    ...position.end,
    opacity: 1,
    background: color,
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          transition={{
            type: 'spring',
            damping: 8,
            mass: 0.1,
            delay,
          }}
          initial={animStart}
          animate={animEnd}
          exit={animStart}
          style={{
            left: position.left,
            top: position.top,
            pointerEvents: 'none',
            borderRadius: 3,
            padding: '5px 10px',
            position: 'absolute',
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const ToolButton = React.forwardRef(
  ({ icon, style, active, pressed, tooltip, shortcut, ...props }, ref) => {
    const { t } = useTranslation()
    const [opened, setOpened] = React.useState(false)

    function open() {
      setOpened(true)
    }

    function close() {
      setOpened(false)
    }

    return (
      <div
        onMouseOver={open}
        onMouseOut={close}
        style={{ position: 'relative' }}
      >
        <Tooltip color="rgb(28, 130, 223)" delay={0.3} isOpen={opened}>
          <P
            style={{
              fontWeight: 'bold',
              color: 'white',
              whiteSpace: 'nowrap',
              fontSize: '.8rem',
            }}
          >
            {tooltip}
          </P>
          {shortcut && (
            <P
              style={{
                color: 'white',
                whiteSpace: 'nowrap',
                fontSize: '.7rem',
              }}
            >
              {t('popUpZoneDefinitionForAScenario.shortcut')} :
            </P>
          )}
        </Tooltip>
        <Button
          ref={ref}
          as={motion.button}
          type="button"
          whileTap={{
            scale: 0.8,
          }}
          whileHover={{
            scale: 1.1,
            background: 'rgb(33, 150, 243)',
          }}
          animate={{
            scale: pressed ? 0.8 : 1,
            background:
              (active && 'rgba(33, 150, 243, 0.4)') ||
              (pressed && 'rgb(33, 150, 243)') ||
              'rgba(255, 255, 255, 0.2)',
          }}
          {...props}
          style={{
            border: 0,
            padding: 10,
            margin: '3px 0',
            width: 34,
            height: 34,
            pointerEvents: active ? 'none' : undefined,
            ...style,
          }}
        >
          <FontAwesomeIcon
            color={active ? 'rgb(33, 150, 243)' : 'white'}
            icon={icon}
          />
        </Button>
      </div>
    )
  }
)

function CountDown({
  radius = 5,
  timeout = 10,
  size = 15,
  color = 'white',
  style,
  onEnd = () => { },
}) {
  const svgSize = 20
  const circleRef = React.useRef()

  React.useEffect(() => {
    let timeId
    const start = Date.now()

    function draw() {
      const t = (Date.now() - start) / 1000
      if (t >= timeout) {
        onEnd()
      } else {
        const perim = 2 * Math.PI * radius
        circleRef.current.style.strokeDasharray = `${(1 - t / timeout) * perim
          } ${perim}`
        timeId = requestAnimationFrame(draw)
      }
    }
    draw()
    return () => cancelAnimationFrame(timeId)
  }, [timeout])

  return (
    <svg
      height={svgSize}
      width={svgSize}
      viewBox={`0 0 ${svgSize} ${svgSize}`}
      style={{ width: size, height: size, ...style }}
    >
      <circle
        ref={circleRef}
        r={5}
        cx={svgSize / 2}
        cy={svgSize / 2}
        fill="transparent"
        stroke={color}
        strokeWidth={2 * radius}
        transform={`rotate(-90) translate(${-svgSize})`}
      />
    </svg>
  )
}
