import React, { useState } from 'react'
import styled from 'styled-components'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { getAssetsRootURL } from '../../../utils'

import {
  SpinnerAnimatedIcon,
  SendIcon
} from '../components/design-system/Icons'
import * as services from '../services'

import {
  Section,
  H1,
  H4,
  Form,
  FormGroup,
  TextInput,
  Button,
  Link,
} from '../components'

const ForgotPasswordContainer = styled(Section)`
  padding: 0 4%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;

  ${Form} {
    width: 21.875rem;
    margin: 0 auto;
  }
`

const StyledVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`

const StyledH1 = styled(H1)`
  letter-spacing: .563rem;
  text-transform: uppercase;
  font-size: 2.25rem;
  margin: 1rem 0;
  color: white;
  font-weight: normal;
`

const StyledH4 = styled(H4)`
  font-size: 1.125rem;
  color: white;
  margin: 1rem 0 2rem 0;
  font-weight: normal;
  text-align: center;
`

const Error = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.errorRed};
`

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`

const FormGroupCaptcha = styled(FormGroup)`
  justify-content: center;
`

const ErrorLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.errorRed};

  &:hover {
    cursor: pointer;
  }
`

// Source: https://emailregex.com/
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default () => {
  const [email, setEmail] = useState('')
  const [resquestSent, setRequestSent] = useState(false)
  const [onRequest, setOnRequest] = useState(null)
  const [onError, setOnError] = useState(false)
  const [onRequestError, setOnRequestError] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')

  const showError = () => {
    if (onError) {
      return (
        <Error>
          {(email.length <= 0) ? <>Email requis<br /></> : null}
          {!EMAIL_REGEX.test(email) ? <>Email format incorrect<br /></> : null}
          {(captchaToken.length <= 0) ? <>Captcha requis<br /></> : null}
        </Error>
      )
    } else if (onRequestError === 404) {
      return (
        <Error>
          Aucun compte n'a été trouvé pour cette adresse email.
        </Error>
      )
    } else if (onRequestError === 500) {
      return (
        <Error>
          Une erreur est survenue, réessayez plus tard ou contactez un administrateur via ce <ErrorLink target='_blank' href='https://neurocr.ai/contact'>formulaire de contact</ErrorLink>
        </Error>
      )
    }

    return null
  }

  const handleSubmit = e => {
    setOnError(false)
    setOnRequestError(null)
    setOnRequest(true)

    e.preventDefault()
    e.stopPropagation()

    if (
      email.length <= 0
      || !EMAIL_REGEX.test(email)
      || (process.env.ON_CLOUD === '1' && captchaToken.length <= 0)
    ) {
      setOnRequest(false)
      setOnError(true)

      return false
    }

    services
      .requestPassword({
        email,
        captchaToken
      })
      .then(() => {
        setRequestSent(true)
        setOnRequest(false)
      })
      .catch(err => {
        console.error(err)

        if (err.response && err.response.status)
          setOnRequestError(err.response.status)

        setOnRequest(false)
      })
  }

  const handleVerificationSuccess = (
    captchaToken => setCaptchaToken(captchaToken)
  )

  return (
    <>
      <StyledVideo
        autoPlay
        loop
        muted
        id='background-video'
        poster={getAssetsRootURL(true) + '/images/bg-poster.png'}
      >
        <source src={getAssetsRootURL(true) + '/videos/bg.mp4'} type='video/mp4' />
      </StyledVideo>
      <ForgotPasswordContainer>
        <StyledH1>Ça nous arrive à tous !</StyledH1>
        <StyledH4>
          {
            resquestSent
              ? 'Un nouveau mot de passe vous sera fournit par email dans les meilleurs délais !'
              : 'Pour réinitialiser votre mot de passe, merci de renseigner votre email:'
          }
        </StyledH4>
        {
          !resquestSent && (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <TextInput
                  dark
                  required
                  name='email'
                  label='Email'
                  value={email}
                  onChange={setEmail}
                  validator={v => !EMAIL_REGEX.test(v) ? 'Non valide' : null}
                />
              </FormGroup>
              {
                process.env.ON_CLOUD === '1' ? (
                  <FormGroupCaptcha>
                    <HCaptcha
                      theme='dark'
                      sitekey={process.env.HCAPTCHA_SITEKEY}
                      onVerify={token => handleVerificationSuccess(token)}
                    />
                  </FormGroupCaptcha>
                ) : null
              }
              {showError()}
              <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <Button
                  primary
                  leftIcon={onRequest ? <SpinnerAnimatedIcon /> : <SendIcon />}
                  type='submit'
                >
                  Envoyer
                </Button>
              </div>
            </Form>
          )
        }
        <div style={{ textAlign: 'center', marginTop: '.938rem' }}>
          <StyledLink to="/">Retour à l’accueil</StyledLink>
        </div>
      </ForgotPasswordContainer>
    </>
  )
}
