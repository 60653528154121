const alertResultAlert = {
    alertsResults: "Alertes &gt; Résultats",
    allSequences: "Toutes les séquences",
    back: "Retour",
    cameras: "Caméras",
    camera: "Caméra",
    cancel: "Annuler",
    closePane: "Fermer le volet",
    falsePositives: "Faux positifs",
    favorites: "Favories",
    filterByCamera: "Filter par caméra",
    filterByType: "Filter par type",
    filterByView: "Filtrer par vues",
    hidden: "Cachées",
    highestToLowestScore: "Du score le plus haut au plus bas",
    launchedOn: "Lancée le ",
    lowestToHighestScore: "Du score le plus bas au plus haut",
    markAllAsSeen: "Tout marquer comme vu",
    newestToOldest: "Du plus récent au plus ancien",
    nonViewedOnly: "Uniquement les non-vues",
    oldestToNewest: "Du plus ancien au plus récent",
    refresh: "Rafraichir",
    results: "Résultas",
    sortBy: "Trier par",
    viewedOnly: "Uniquement les vues",
    filterBySector: "Filtrer par secteur",
    scoreBaseOn: "Score basé sur",
    model: "Modèle",
    color: "Couleur",
    all: "Toutes",
    search: "Recherche",
    result: "Résultats",
    treatmentVideoInProgress: "Traitement des videos en cours",
    noResultDoNewSearch: "Aucun résultat. Effectuer une",
    newSearch: "nouvelle recherche",
    removeFalsePositive: "Retirer des faux positifs",
    wouldYouLikeToRemoveThisResultFromTheFalsePositiveList: "Souhaitez vous retirer ce résultat de la liste des faux positifs ?",
    uniqueThisResult: "Uniquement ce résultat",
    allFalsePositiveAssociate: "Tout les faux positifs associés",
    close: "Fermer",
    FilterByYype: "Filtrer par type",
    details: "Détails",
    LPR: "VPI",
    startAt: "Lancée le",
    closeThsSlide: "Fermer le volet",
    alerts: "Alertes",
    noResult: "Aucun résultat",
    back: 'Retour',
}
export default alertResultAlert