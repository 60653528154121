import axios from 'axios'

export const addExport = params =>
  axios
    .post(`/api/exports`, params)
    .then(({ data }) => data)

export const fetchMyExports = ({ limit }) => {
  let url = `/api/exports`

  if (limit)
    url = `/api/exports?limit=${limit}`

  return axios
    .get(url)
    .then(({ data }) => data)
}

export const fetchMyExport = ({ exportId }) =>
  axios
    .get(`/api/exports/${exportId}`)
    .then(({ data }) => data)
