import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import AuthContext from '../contexts/AuthContext'

export default ({ component: Component, role = 'user', ...props }) => {
  const [{ loading, authenticated, user }] = useContext(AuthContext)

  return (
    <Route {...props}
      render={_props => {
        if (loading) {
          return null
        }

        const userIsAuthorized = role === 'user' || (role === 'admin' && user?.type === 'admin')
        return (
          authenticated && userIsAuthorized
          ? <Component {..._props} />
          : <Redirect to={props.redirectTo ? props.redirectTo : '/login'} />
        )
      }}
    />
  )
}
