export const investigation = {
    description: 'Déscription',
    name: 'Nom',
    all: "Périmètre entier",
    animals: "Animaux",
    cars: "Voitures",
    color: "Couleur",
    completed: "Terminé",
    criteria: "Critères",
    date: "Date",
    defineTheSearchPeriod: "Définissez la période de recherche.",
    end: "Fin",
    filterTheCamerasToBeSearchedIfNecessaryGroupSeveralCamerasByAreasViaTheCamerasMenu: "Filtrer les caméras concernées par la recherche. Au besoin, regroupez plusieurs caméras par secteurs via le ",
    menuCamera: "menu Caméras",
    from: "Du",
    heavyVehiclesTrucks: "Véhicules lourds / Camionettes",
    hideCameras: "Masquer les caméras",
    showCameras: "Afficher les caméras",
    latestSearches: "Investigation récentes",
    luggage: "Bagages",
    make: "Marque",
    model: "Modèle",
    motorcyclesScootersBicycles: "Motos / Scooters / Vélos",
    nameSearch: "Nommer l'investigation",
    descriptionSearch: "Description de l'investigation",
    newSearch: "Nouvelle investigation",
    people: "Personnes",
    perimeter: "Périmètre",
    period: "Période",
    redDotsIndicateRealTimeProcessingOnTheCorrespondingCamera: "Les pastilles rouges indiquent le traitement en temps réel sur la caméra concernée.",
    results: "Résultats",
    selectTheSearchCriteria: "Indiquez sur quoi porte la recherche.",
    start: "Début",
    startSearch: "Lancer l'investigation",
    status: "Status",
    time: "Heure",
    to: "Au",
    type: "Type",
    unknown: "Inconnu",
    viewFullHistory: "Voir l'historique complet",
    xxxCameras: "xxx caméras",
    camera: "caméra",
    noResult: "Aucun résultat",
}
export default investigation