import React, { useEffect } from 'react'
import axios from 'axios'
import strftime from 'strftime'
import styled from 'styled-components'
import { CF } from '../components/Grid'
import { H1, Link, P } from '../components/Typography'
import Pagination from '../components/Pagination'
import Player from '../components/Player'
import { useParams } from 'react-router'
import Filters, { useFilters } from '../components/SequencesFilters'
import { SmallButton } from '../components/Button'
import {
  motion,
  AnimateSharedLayout,
  AnimatePresence,
} from 'framer-motion/dist/framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
  faTimes,
  faEyeSlash,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import { useFetch, useInterval, useSubscription } from '../services/fetch'
import {
  getScenarioResults,
  updateScenariosSearchFavorite,
  updateScenariosSearchHidden
} from '../services/scenarios'
import { Tooltip } from '../components/Scenarios/Editor'

const MargedContainer = styled(CF)`
  margin: 15px;
  text-align: center;
  label {
    margin-right: 15px;
  }
`

const Headings = styled.div`
  margin: 10px 0;
`

function ScenariosSearchResults() {
  const { id } = useParams()
  const { filters, setFilter } = useFilters({
    sortType: 'at:desc',
    sortBy: 'at:desc',
  })
  const [loading, setLoading] = React.useState(false)
  const { data, refetch } = useSubscription(
    () =>
      getScenarioResults(id, {
        ...filters,
        limit: 20,
        offset: ((filters.page || 1) - 1) * 20,
      }),
    5000
  )

  React.useEffect(() => {
    refetch()
  }, [id, filters])

  const allSeen = React.useMemo(() => {
    if (!data?.scenarios.length) return true
    return data.scenarios.reduce((acc, s) => s.hasSeen && acc, true)
  }, [data])

  return (
    <>
      <MargedContainer>
        <Link to="/scenarios/search">Scénarios</Link>
        <span>{' >'} Résultats</span>
      </MargedContainer>
      <Headings>
        <H1 style={{ margin: '0' }}>Résultats</H1>
      </Headings>
      <div style={{ textAlign: 'center' }}>
        <FontAwesomeIcon size="2x" icon={faSpinner} spin={true} />
      </div>
      <div style={{ width: '90%', margin: 'auto', marginTop: 10 }}>
        <Filters
          params={{ has_seen: true }}
          filters={filters}
          setFilter={setFilter}
        />
      </div>
      <motion.div
        style={{
          width: '90%',
          height: '100%',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.05,
            },
          },
        }}
        initial="hidden"
        exit="hidden"
        enter="show"
        animate="show"
      >
        <AnimatePresence>
          {data?.scenarios.map((s, i) => (
            <Thumbnail key={s.track_id} index={i} result={s} />
          ))}
        </AnimatePresence>
      </motion.div>
      {!!data?.scenarios.length && (
        <Pagination
          activePage={filters.page}
          onChange={setFilter('page')}
          itemsCountPerPage={20}
          totalItemsCount={data?.nbResults}
          pageRangeDisplayed={5}
        />
      )}
    </>
  )
}

export default ScenariosSearchResults

const fetchSequences = ({ requestId, configId, trackId }) =>
  axios
    .post(`/api/scenarios/${requestId}/detections/${configId}/${trackId}`)
    .then(({ data }) => data)

const Thumbnail = React.memo(({ result, index }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { data, refetch } = useFetch(
    () =>
      fetchSequences({
        requestId: result.requestId,
        configId: result.configId,
        trackId: result.track_id,
      }),
    [result.requestId, result.configId, result.track_id]
  )

  useInterval(
    () => {
      if (data?.sequence.videos.length === 0) refetch()
    },
    data?.sequence.videos.length ? null : 3000
  )

  const sequence = data?.sequence
  const videos = React.useMemo(() => sequence?.videos, [sequence])

  const polygonStart = React.useMemo(
    () => ({
      index: sequence?.enterVideoIndex,
      time: sequence?.enterVideoTime,
    }),
    [sequence]
  )

  const polygonEnd = React.useMemo(
    () => ({
      index: sequence?.exitVideoIndex,
      time: sequence?.exitVideoTime,
    }),
    [sequence]
  )

  const inZoneDuration = React.useMemo(() => {
    const diff = (sequence?.exitAt - sequence?.enterAt) / 1000
    const mn = Math.floor(diff / 60)
    const s = diff % 60
    if (mn) return `${mn} mn ${s} sec`
    return `${s} sec`
  }, [sequence])

  const [isFavoriteLoading, setIsFavoriteLoading] = React.useState(false)
  async function markFavorite() {
    setIsFavoriteLoading(true)
    await scenarioMarkFavorite({
      requestId: result.requestId,
      configId: result.configId,
      trackId: result.track_id,
      favorite: !result.favorite,
    })
    result.favorite = !result.favorite
    setIsFavoriteLoading(false)
  }

  const [isHiddenLoading, setIsHiddenLoading] = React.useState(false)
  async function markHidden() {
    setIsHiddenLoading(true)
    await scenarioMarkHidden({
      requestId: result.requestId,
      configId: result.configId,
      trackId: result.track_id,
      hidden: !result.hidden,
    })
    result.hidden = !result.hidden
    setIsHiddenLoading(false)
  }

  function open() {
    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
  }

  if (!sequence) return null

  const poster =
    sequence.videos.length > 0
      ? sequence.poster + '?width=720&t=' + sequence.videos.length
      : null

  const buttons = (
    <div style={{ display: 'flex' }}>
      <IconButton
        layoutId="hidden"
        icon={faEyeSlash}
        onClick={markHidden}
        isLoading={isHiddenLoading}
        tooltip={
          result.hidden ? 'Rendre ce résultat visible' : 'Cacher ce résultat'
        }
        active={result.hidden}
      />
      <IconButton
        layoutId="favorite"
        icon={faStar}
        onClick={markFavorite}
        isLoading={isFavoriteLoading}
        tooltip={
          result.favorite ? 'Retirer des favoris' : 'Ajouter aux favoris'
        }
        active={result.favorite}
      />
    </div>
  )

  return (
    <AnimateSharedLayout>
      <motion.div
        key={result.track_id}
        layoutId="modal"
        layout="position"
        onClick={open}
        initial="hidden"
        enter="show"
        exit="exit"
        variants={{
          hidden: {
            opacity: 0,
            y: -50,
            transition: { duration: 0.35 },
          },
          show: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.35 },
          },
          exit: {
            opacity: 0,
            y: 50,
            transition: { duration: 0.15 },
          },
        }}
        style={{
          cursor: 'pointer',
          border: '1px solid #eee',
          borderRadius: 5,
          width: '23%',
          margin: 10,
          position: 'relative',
        }}
      >
        <AnimatePresence>
          {!result.hasSeen && (
            <motion.div
              layoutId="alert"
              exit={{
                y: '-100%',
                opacity: 0,
              }}
              enter={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                duration: 0.3,
                ease: 'easeInOut',
              }}
              style={{
                background: 'red',
                fontSize: '0.8em',
                color: 'white',
                fontWeight: 'bold',
                borderRadius: 3,
                padding: '3px 5px',
                position: 'absolute',
                top: -20,
                zIndex: 1000,
                right: 0,
              }}
            >
              Nouveau résultat
            </motion.div>
          )}
        </AnimatePresence>
        <motion.div
          layoutId="trackId"
          initial={{
            y: -40,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.2 + index * 0.03,
              type: 'spring',
              damping: 12,
            },
          }}
          style={{
            position: 'absolute',
            display: 'flex',
            background: 'white',
            top: -3,
            right: -3,
            zIndex: 999,
            borderBottomLeftRadius: 5,
            padding: 5,
          }}
        >
          # {result.track_id}
        </motion.div>
        <motion.div
          initial={{
            y: -40,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.1 + index * 0.03,
              type: 'spring',
              damping: 12,
            },
          }}
          style={{
            background: '#66BB6A',
            padding: 5,
            position: 'absolute',
            top: -3,
            left: -3,
            zIndex: 999,
            borderRadius: 5,
          }}
          layoutId="rating"
        >
          <FontAwesomeIcon icon={faStar} size="xs" color="white" />
          <FontAwesomeIcon icon={faStar} size="xs" color="white" />
          <FontAwesomeIcon icon={faStar} size="xs" color="white" />
        </motion.div>
        <motion.div layoutId="player">
          <Player
            poster={poster}
            polygon={result.zone}
            highlightPolygonStart={polygonStart}
            highlightPolygonEnd={polygonEnd}
            videos={sequence.videos}
          />
        </motion.div>
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          {buttons}
          <div
            style={{
              textAlign: 'right',
              fontSize: '.8rem',
              padding: '5px 10px',
            }}
          >
            <motion.p layoutId="title" style={{ margin: 0, zIndex: 999 }}>
              Caméra {result.configName} le{' '}
              {strftime('%d/%m/%Y', new Date(+result.at))}
            </motion.p>
            <motion.p layoutId="date" style={{ margin: 0, zIndex: 999 }}>
              <strong>Événement à: </strong>{' '}
              {strftime('%H:%M:%S', new Date(+sequence.enterAt))},{' '}
              {/*<strong>Sortie à: </strong>{' '}
              {strftime('%H:%M:%S', new Date(+sequence.exitAt))}*/}
            </motion.p>
            {/*<motion.p layoutId="duration" style={{ margin: 0, zIndex: 999 }}>
              <strong>Durée à proximité de la zone:</strong> {inZoneDuration}
          </motion.p>*/}
          </div>
        </div>
      </motion.div>
      <motion.div
        style={{
          pointerEvents: 'none',
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          left: 0,
          top: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 9999,
        }}
      >
        <motion.div
          style={{ background: 'black', pointerEvents: 'initial' }}
          variants={{
            closed: { width: 0, height: 0, opacity: 0, borderRadius: '50%' },
            opened: {
              width: '100%',
              height: '100%',
              opacity: 0.2,
              borderRadius: 0,
            },
          }}
          onClick={() => setIsOpen(false)}
          initial="closed"
          animate={isOpen ? 'opened' : 'closed'}
          enter="opened"
          exit="closed"
          transition={{ duration: 0.3, ease: 'easeOut' }}
        ></motion.div>
      </motion.div>
      <motion.div
        style={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          left: 0,
          top: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 9999,
          pointerEvents: 'none',
        }}
      >
        {isOpen && (
          <motion.div
            layoutId="modal"
            style={{
              pointerEvents: 'initial',
              background: 'white',
              width: 800,
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  fontSize: '.8rem',
                  padding: '5px 10px',
                }}
              >
                <motion.p layoutId="title" style={{ margin: 0 }}>
                  Caméra {result.configName} le{' '}
                  {strftime('%d/%m/%Y', new Date(+result.at))}
                </motion.p>
                <motion.p layoutId="date" style={{ margin: 0 }}>
                  <strong>Événement à: </strong>{' '}
                  {strftime('%H:%M:%S', new Date(+sequence.enterAt))},{' '}
                  {/*<strong>Sortie à: </strong>{' '}
                  {strftime('%H:%M:%S', new Date(+sequence.exitAt))}*/}
                </motion.p>
                {/*<motion.p layoutId="duration" style={{ margin: 0 }}>
                  <strong>Durée à proximité de la zone:</strong>{' '}
                  {inZoneDuration}
              </motion.p>*/}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AnimatePresence>
                  {!result.hasSeen && (
                    <motion.div
                      exit={{
                        y: -50,
                        opacity: 0,
                      }}
                      enter={{
                        y: 0,
                        opacity: 1,
                      }}
                      layoutId="alert"
                      style={{
                        background: 'red',
                        fontSize: '0.8em',
                        color: 'white',
                        fontWeight: 'bold',
                        borderRadius: 3,
                        padding: '3px 5px',
                        marginRight: 10,
                      }}
                    >
                      Nouveau résultat
                    </motion.div>
                  )}
                </AnimatePresence>
                <motion.div
                  style={{
                    background: '#66BB6A',
                    padding: 5,
                    borderRadius: 5,
                    zIndex: 999,
                  }}
                  layoutId="rating"
                >
                  <FontAwesomeIcon icon={faStar} size="xs" color="white" />
                  <FontAwesomeIcon icon={faStar} size="xs" color="white" />
                  <FontAwesomeIcon icon={faStar} size="xs" color="white" />
                </motion.div>
                <motion.div
                  layoutId="trackId"
                  style={{
                    display: 'flex',
                    background: 'white',
                    zIndex: 999,
                    borderRadius: 5,
                    padding: 5,
                  }}
                >
                  # {result.track_id}
                </motion.div>
                <motion.button
                  whileHover={{ scale: 1.2 }}
                  animate={{ scale: 1 }}
                  style={{
                    cursor: 'pointer',
                    border: 0,
                    background: 'none',
                    marginLeft: 5,
                  }}
                  onClick={close}
                >
                  <FontAwesomeIcon size="2x" icon={faTimes} />
                </motion.button>
              </div>
            </div>
            <motion.div layoutId="player">
              <Player
                poster={poster}
                polygon={result.zone}
                highlightPolygonStart={polygonStart}
                highlightPolygonEnd={polygonEnd}
                showControls
                videos={videos}
              />
            </motion.div>
            {buttons}
          </motion.div>
        )}
      </motion.div>
    </AnimateSharedLayout>
  )
})

const IButton = styled(motion.button)({
  borderRadius: '50%',
  border: 0,
  background: 'none',
  cursor: 'pointer',
  width: 30,
  height: 30,
  position: 'relative',
  flexShrink: 0,
  transition: 'all 150ms ease-out',
  '&:disabled': {
    background: '#eee',
    pointerEvents: 'none',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    left: 0,
    top: 0,
    background: ({ active }) => (active ? 'red' : 'black'),
    transform: 'scale(0)',
    opacity: 0,
    transition: 'all 150ms ease-out',
  },
  '&:hover': {
    '&:before': {
      opacity: 0.2,
      transform: 'scale(1)',
    },
  },
})

function IconButton({ icon, active, layoutId, isLoading, onClick, tooltip }) {
  const [opened, setOpened] = React.useState(false)

  function open() {
    setOpened(true)
  }

  function close() {
    setOpened(false)
  }

  return (
    <motion.div
      layoutId={layoutId}
      style={{ position: 'relative', height: 'fit-content', margin: 5 }}
    >
      <Tooltip
        direction="down"
        isOpen={opened}
        color="rgba(0, 0, 0, 0.8)"
        delay={0}
      >
        <P
          style={{
            fontWeight: 'bold',
            color: 'white',
            whiteSpace: 'nowrap',
            fontSize: '.8rem',
            margin: 0,
          }}
        >
          {tooltip}
        </P>
      </Tooltip>
      <IButton
        type="button"
        onMouseOver={open}
        onMouseOut={close}
        onClick={(e) => {
          e.stopPropagation()
          onClick(e)
        }}
        animate={{
          scale: 1,
        }}
        whileTap={{
          scale: 0.7,
        }}
        disabled={isLoading}
        active={active}
      >
        {isLoading ? (
          <FontAwesomeIcon icon={faSpinner} color="#ccc" spin={true} />
        ) : (
          <FontAwesomeIcon icon={icon} color={active ? '#e00' : '#000'} />
        )}
      </IButton>
    </motion.div>
  )
}
