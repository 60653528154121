import React, { useState, useContext, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import AuthContext from '../../contexts/AuthContext'
import styled from 'styled-components'
import strftime from 'strftime'

import * as services from '../../services'
import { VpiProgress } from '../VPI'
import { SearchProgress } from '../Search'

import SearchContext from '../../contexts/SearchContext'

import {
  Checkbox,
  H1,
  Icon,
  SearchInput,
} from '../../components/design-system'
import { Link } from '../../components/Typography'
import Table from '../../components/Table'
import { ZonePreview } from '../ScenariosSearch'
import { eventTypes, subjects } from '../Scenarios'
import { TooltipCameraList } from '../../components/TooltipCameraList'

const StyledH1 = styled(H1)`
  font-family: Blinker;
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
`
const Edit = styled.div`
  background-color: #fff;
  position: absolute;
  bottom: ${({ toggle }) => (toggle ? '0' : '-64px')};
  opacity: ${({ toggle }) => (toggle ? '1' : '0')};
  left: 0;
  width: 100%;
  align-items: center;
  height: 200px;
  box-shadow: 0px -5px 18px 5px rgb(0 0 0 / 20%);
  transition: bottom 0.5s ease, opacity 0.5s ease-in;
`

const EditContent = styled.div`
  margin: auto;
  width: 70%;

  h2 {
    font-family: Blinker;
  }
`

const ColoredLink = styled.span`
  color: ${({ color }) => color};
  text-decoration: underline;
  cursor: pointer;
  position absolute;
  top: 1.3rem;
  right: 1.5rem;

  ${Icon} {
    margin-left: .3rem;
    vertical-align: middle;
  }
`

const StyledCheckbox = styled(Checkbox)`
  label {
    color: ${({ color }) => color};
    height: 1000px;
  }
`

const Camera = styled.div`
  background-color: ${({ activated }) =>
    activated ? '#999999' : 'transparent'};
  color: ${({ activated }) => (activated ? '#fff' : '#999999')};
  border: 2px solid #999999;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1.25rem;
  height: 2rem;
  width: 6rem;
`

const StyledSearchInput = styled(SearchInput)`
  flex-grow: 1;
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  & > *:not(:first-child):not(:last-child) {
    margin: 0 1rem;
  }
`

const SelectContainer = styled.div`
  width: 30%;
`

const Space = styled.div`
  flex-grow: 0.8;
`

const CenterText = styled.div`
  text-align: center;
`

const SectionHistory = styled.div`
  text-align: center;
`

export default () => {
  const themeContext = useContext(ThemeContext)
  const [{ user }] = useContext(AuthContext)
  const [{ tags, carMakes, carColors, carModels }] = useContext(SearchContext)

  const [filters, setFilters] = useState({
    standard: true,
    vpi: true,
    scenarios: true,
    alerts: true
  })
  const [vpiSearch, setVpiSearch] = useState([])
  const [detectSearch, setDetectSearch] = useState([])
  const [scenarioSearch, setScenarioSearch] = useState([])
  const [alerts, setAlerts] = useState([])
  const [allSearch, setAllSearch] = useState([])
  const [selected, setSelected] = useState(null)
  const [nbRows, setNbRows] = useState(20)
  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(1)
  const [loadingVPI, setLoadingVPI] = useState(true)
  const [loadingScenario, setLoadingScenario] = useState(true)
  const [loadingDetect, setLoadingDetect] = useState(true)
  const [loadingAlert, setLoadingAlert] = useState(true)

  const itemsCountPerPage = 16
  const pageRangeDisplayed = 5
  let tableHeader = user.type === "admin" ? [{
    id: 'users',
    name: 'Utilisateur',
    sort: (a, b) => {
      if (a?.users < b?.users) return -1
      if (a?.users > b?.users) return 1
      return 0
    },
  }] : [];
  tableHeader = [
    ...tableHeader,
    {
      id: 'date',
      name: 'Date',
      sort: (a, b) => new Date(Number(a?.date)) - new Date(Number(b?.date)),
    },
    {
      id: 'name',
      name: 'Nom',
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      },
    },
    {
      id: 'description',
      name: 'Description',
      sort: (a, b) => {
        if (a?.description < b?.description) return -1
        if (a?.description > b?.description) return 1
        return 0
      },
    },
    {
      id: 'perimeter',
      name: 'Périmètre',
      sort: (a, b) => {
        const lengthA = (a?.cameras || '').split(',').length
        const lengthB = (b?.cameras || '').split(',').length
        return lengthA - lengthB
      },
    },
    {
      id: 'type',
      name: 'Type',
      sort: (a, b) => {
        if (a?.type < b?.type) return -1
        if (a?.type > b?.type) return 1
        return 0
      },
    },
    {
      id: 'status',
      name: 'Status',
      sort: (a, b) => {
        if (a?.status < b?.status) return -1
        if (a?.status > b?.status) return 1
        return 0
      },
    },
    { id: 'action', name: 'Action' },
  ]

  useEffect(() => {
    services.fetchHistoryVPI().then(({ requests }) => {
      setLoadingVPI(false)
      return setVpiSearch(
        requests.map((e) => ({
          id: `VPI_${e.id}`,
          users: `${e.firstname.charAt(0).toUpperCase() + e.firstname.slice(1)} ${e.lastname.toUpperCase()}`,
          description: e.description,
          cameras: e.cameras,
          date:
            strftime('%d/%m/%Y', new Date(Number(e.at))) +
            ' - ' +
            strftime('%H:%M', new Date(Number(e.at))),
          name: e.name,
          perimeter: e.cameras && <TooltipCameraList cameraList={e.cameras.split(',')} />,
          type: 'VPI',
          status: <VpiProgress searchId={e.id} status={e.status} />,
          action:
            +e.at > Date.now() - 345600000 ? (
              <Link to={`/vpi/${e.id}`}>Résultats</Link>
            ) : (
              <span>Non disponible</span>
            ),
        }))
      )
    })

    Promise.all([services.fetchHistoryScenario(), services.fetchCameras()]).then((response) => {
      const requests = response[0].scenarios
      const cameras = response[1].cameras || [];
      setLoadingScenario(false)
      return setScenarioSearch(
        requests.map((s) => ({
          id: `scenario_${s.id}`,
          users: s.firstname && s.lastname ? `${s.firstname.charAt(0).toUpperCase() + s.firstname.slice(1)} ${s.lastname.toUpperCase()}` : null,
          date:
            strftime('%d/%m/%Y', new Date(Number(s.at))) +
            ' - ' +
            strftime('%H:%M', new Date(Number(s.at))),
          name: s.name,
          description: s.description,
          status: s.enabled ? 'Activé' : 'Désactivé',
          perimeter: <>
            <span>
              {cameras.find(c => s.configId === c.id)?.name}
            </span>
            <br />
            <ZonePreview
              zones={s.zones}
              configId={s.configId}
              camera={cameras.find(c => s.configId === c.id)}
            />
          </>,
          type: <>{s.tags.map((tag, i) => (
            <span style={{ whiteSpace: 'nowrap' }} key={i}>
              {subjects()[tag.name].name}
              {
                eventTypes()[s.scenarioType]?.selectType !== 'checkbox' && (
                  ' x ' + tag.items
                )
              } /
            </span>))}
            <br />
            <span> {eventTypes()[s.scenarioType]?.name}</span>
          </>,
          action: <Link to={`/scenarios/searchs/results/${s.id}`}>Résultat</Link>,
        }))
      )
    })

    services.fetchHistoryStandard().then(({ requests }) => {
      setLoadingDetect(false)
      const formattedRequests = requests.map((e, i) => {
        if (i >= 10) return null
        let search = ''
        const searchType = tags.filter((tag) => tag.name === e.tag)[0]
          .displayName
        const gender = {
          female: 'Femme',
          male: 'Homme',
          girl: 'Fille',
          boy: 'Garçon',
        }
        const age = {
          adult: 'Adulte (+18)',
          infant: 'Enfant',
        }
        const clothingColor = {
          black: 'Noir',
          blue: 'Bleu',
          gray: 'Gris',
          green: 'Vert',
          red: 'Rouge',
          white: 'Blanc',
          yellow: 'Jaune',
        }
        const getGender = (gender, age) => {
          if (gender === 'female') return age === 'infant' ? 'Fille' : 'Femme'
          return age === 'infant' ? 'Garçon' : 'Homme'
        }

        if (
          e.tag === 'car' ||
          e.tag === 'heavyVehicles' ||
          e.tag === 'twoWheeled'
        ) {
          search +=
            carMakes.filter((brand) => brand.name === e.make)[0]?.displayName ||
            e.make ||
            ''
          search += ' '
          search +=
            carModels.filter((model) => model.name === e.model)[0]
              ?.displayName ||
            e.model ||
            ''
          search += ' '
          search +=
            carColors.filter((color) => color.name === e.color)[0]
              ?.displayName ||
            e.color ||
            ''
        }

        if (e.tag === 'person') {
          search += gender[e?.gender] ? `${getGender(e.gender, e.age)} ` : ''
          search += age[e?.age] ? `${age[e?.age]} ` : ''
          search += clothingColor[e?.top_color]
            ? `Haut ${clothingColor[e?.top_color]}`
            : ''
          search += clothingColor[e?.bottom_color]
            ? ` Bas ${clothingColor[e?.bottom_color]}`
            : ''
        }

        return {
          id: `standard_${e.id}`,
          cameras: e.cameras,
          users: `${e.firstname.charAt(0).toUpperCase() + e.firstname.slice(1)} ${e.lastname.toUpperCase()}`,
          description: e.description,
          date:
            strftime('%d/%m/%Y', new Date(Number(e.at))) +
            ' - ' +
            strftime('%H:%M', new Date(Number(e.at))),
          name: e.name,
          perimeter: e.cameras && <TooltipCameraList cameraList={e.cameras.split(',')} />,
          type: searchType,
          status: <SearchProgress searchId={e.id} status={e.status} />,
          action:
            +e.at > Date.now() - 345600000 ? (
              <Link to={`/search/${e.id}`}>Résultats</Link>
            ) : (
              <span>Non disponible</span>
            ),
        }
      })

      setDetectSearch(formattedRequests)

      Promise.all([services.fetchHistoryAnprAlert(), services.fetchHistoryDetectAlert()]).then(response => {
        setLoadingAlert(false)

        const alertsData = response.length > 0
          ? [...response[0].alerts, ...response[1].alerts]
          : []

        const result = alertsData.map((alert) => {
          const createdDate = new Date(Number(alert.at))
          return {
            id: `alert_${alert.type === 'vpi' ? 'vpi' : 'detection'}_${alert.id}`,
            users: `${alert.firstname.charAt(0).toUpperCase() + alert.firstname.slice(1)} ${alert.lastname.toUpperCase()}`,
            type: alert.type === 'vpi' ? 'Alert vpi' : 'Alert detection',
            date: [
              strftime('%d/%m/%Y', createdDate),
              strftime('%H:%M', createdDate),
            ].join(' - '),
            perimeter: <TooltipCameraList cameraList={alert.cameras?.split(',')} />,
            name: alert.name,
            description: alert.description,
            status:
              alert.total > 0 ? (
                <span style={{ color: 'red' }}>+ {alert.total} alertes</span>
              ) : (
                "Pas d'alerte"
              ),
            action: (
              <Link
                to={`/alert/${alert.type === 'vpi' ? 'vpi' : 'detection'}/${alert.id}`}
              >
                Résultats
              </Link>
            )
          }
        })
        setAlerts(result)
      })
    })
  }, [])

  useEffect(() => {
    let concatSearch = []
    if (filters.vpi) { concatSearch = [...concatSearch, ...vpiSearch] }
    if (filters.standard) { concatSearch = [...concatSearch, ...detectSearch] }
    if (filters.scenarios) { concatSearch = [...concatSearch, ...scenarioSearch] }
    if (filters.alerts) { concatSearch = [...concatSearch, ...alerts] }
    setAllSearch(
      concatSearch.sort(
        (a, b) => new Date(Number(a?.date)) - new Date(Number(b?.date))
      )
    )
  }, [detectSearch, vpiSearch, scenarioSearch, alerts, filters])

  const handlePageChange = (index) => {
    setPage(index)
    setOffset((index - 1) * itemsCountPerPage)
  }

  const handleSearch = (value) => {
    const concatSearch = allSearch.sort(
      (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at))
    )
    const filteredNameSearch = concatSearch.filter(
      (row) => row?.name.toLowerCase().search(value.toLowerCase()) !== -1
    )

    const filteredUserSearch = concatSearch.filter(
      (row) => row?.users.toLowerCase().search(value.toLowerCase()) !== -1
    )

    const filteredDescriptionSearch = concatSearch.filter(
      (row) => row?.description ? row?.description.toLowerCase().search(value.toLowerCase()) !== -1 : false
    )

    let filteredSearch = [...filteredDescriptionSearch, ...filteredNameSearch, ...filteredUserSearch]

    filteredSearch = filteredSearch.filter(element => {
      return element !== null;
    });

    filteredSearch = filteredSearch.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)

    setAllSearch(filteredSearch)
  }

  const changeFilter = (filter, value) => {
    const newFilters = {
      ...filters,
      [filter]: value,
    }
    setAllSearch([])
    setFilters(newFilters)
  }

  return (
    <>
      <StyledH1>Historique</StyledH1>
      <Filters>
        <StyledCheckbox
          label="Investigation"
          labelColor="#144E95"
          checked={filters.standard}
          onChange={(v) => changeFilter('standard', v)}
          inline
        />
        <StyledCheckbox
          label="VPI"
          labelColor="#CF6B46"
          checked={filters.vpi}
          onChange={(v) => changeFilter('vpi', v)}
          inline
        />
        <StyledCheckbox
          label="Alert"
          labelColor="#801818"
          checked={filters.alerts}
          onChange={(v) => changeFilter('alerts', v)}
          inline
        />
        <StyledCheckbox
          label="Scenarios"
          labelColor="#370028"
          checked={filters.scenarios}
          onChange={(v) => changeFilter('scenarios', v)}
          inline
        />
        <StyledSearchInput
          onChange={(v) => handleSearch(v)}
          placeholder="Rechercher..."
        />
        <Space />
        {/*<SelectContainer>
          <Select value={nbRows} onChange={(v) => setNbRows(Number(v))}>
            <option value="20">Afficher 20 lignes</option>
            <option value="50">Afficher 50 lignes</option>
            <option value="100">Afficher 100 lignes</option>
          </Select>
  </SelectContainer>*/}
      </Filters>
      <SectionHistory>
        {
          !loadingVPI && !loadingDetect && !loadingScenario && !loadingAlert && allSearch.length <= 0 ? (
            <div>Aucun résultat</div>
          ) : <Table header={tableHeader} body={allSearch} loading={loadingVPI && loadingDetect && loadingScenario && loadingAlert} />
        }
      </SectionHistory>
      {/* <Pagination
        activePage={page}
        totalItems={100}
        itemsCountPerPage={itemsCountPerPage}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={handlePageChange}
      /> */}
      {/* <Edit toggle={!!selected}>
        <ColoredLink
          color={themeContext.errorRed}
          onClick={() => setSelected(null)}
        >
          Fermer le volet <CrossIcon />
        </ColoredLink>
        <EditContent>
          <h2>Périmètre:</h2>
          <Camera activated>LH1Z1-2</Camera>
          <Camera>LH1Z1-2</Camera>
        </EditContent>
      </Edit> */}
    </>
  )
}