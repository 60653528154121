import axios from 'axios'

export const fetchAdminProcessingDetections = () =>
  axios.get(`/api/admin/detections`).then(({ data }) => data)

export const fetchAdminScenariosSearchs = () =>
  axios.get(`/api/admin/scenarios/searchs`).then(({ data }) => data)

export const fetchAdminScenariosAlerts = () =>
  axios.get(`/api/admin/scenarios/alerts`).then(({ data }) => data)

export const fetchAdminProcessingVpiDetections = () =>
  axios.get(`/api/admin/vpi-detections`).then(({ data }) => data)

export const cancelDetections = (body) =>
  axios.post(`/api/admin/cancel-detections`, body).then(({ data }) => data)

export const cancelVpiDetections = (body) =>
  axios.post(`/api/admin/cancel-vpi-detections`, body).then(({ data }) => data)

export const fetchAdminProcessingAlerts = () =>
  axios.get(`/api/admin/alerts`).then(({ data }) => data)

export const cancelAdminAlerts = (body) =>
  axios.post(`/api/admin/cancel-alerts`, body).then(({ data }) => data)
