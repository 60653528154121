import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'
import strftime from 'strftime'

import { Icon, AngleDownIcon, AngleUpIcon, Spinner } from './design-system/'
import { CenterContainer } from './Grid'

const StyledTable = styled.table`
  width: 100%;
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
  text-align: left;

  td,
  th {
    border: 1px solid #e5e5e5;
    padding: .35rem .8rem;
  }

  th {
    text-transform: uppercase;
  }
`;

const Tr = styled.tr`
  width: 100%;

  td {
    color: ${({ selected, isSelected }) => {
    if (isSelected) return '#000;'
    if (!!selected) return '#999;'
    return '#000;'
  }}
    transition: color .4s ease-in-out;
  }

  ${Icon} {
    color: ${({ theme }) => theme.primaryColor};
    vertical-align: middle;
    cursor: pointer;
    margin-left: .5rem;
    display: ${({ selected, isSelected }) => {
    if (isSelected) return 'inline;'
    if (!!selected) return 'none;'
    return 'inline;'
  }}
  }
`

const TDCenter = styled.td`
  text-align: center;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SpinnerContainer = styled.div`
  width: 100%;
`

const Table = ({ style, selected, header, body, loading }) => {
  const [{order, by}, setState] = useState({ order: 'desc', by: header[0].id })

  const sortedBody = React.useMemo(() => {
    let newBody = body || []
    const sortFunction = header.find(h => h.id === by).sort
    
    newBody = newBody.slice().sort(sortFunction)

    if (order === 'asc') {
      newBody = newBody.slice().reverse()
    }

    return newBody
  }, [body, order, by])

  function handleSort(headerId) {
    setState(prevState => {
      let newOrder = prevState.order === 'desc' ? 'asc' : 'desc'
      if (prevState.by !== headerId) {
        newOrder = 'asc'
      }
      return { by: headerId, order: newOrder }
    })
  }


  return (
    loading ? (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    ) : (
      <StyledTable style={style}>
        <thead>
          <Tr>
            {header?.map((e) => (
              <th key={e.id}>
                <Flex>
                  {e.name}
                  {e.sort && (by !== e.id || order === 'desc') && <AngleDownIcon onClick={() => handleSort(e.id)} />}
                  {e.sort && order === 'asc' && by === e.id && <AngleUpIcon onClick={() => handleSort(e.id)} />}
                </Flex>
              </th>
            ))}
          </Tr>
        </thead>
        <tbody>
          {loading && <tr><td></td></tr>}
          {sortedBody.map((e) => e && (
            <Tr
              key={e.id}
              isSelected={selected?.id === e.id}
              selected={selected}
            >
              {header.map((ee) => (
                <td key={ee.id}>{e[ee.id]}</td>
              ))}
            </Tr>
          ))}
        </tbody>
      </StyledTable>
    )
  )
}

Table.propTypes = {
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  header: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      sort: PropTypes.func,
    })
  ).isRequired,
  body: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
  loading: PropTypes.bool,
}

Table.defaultProps = {
  body: null,
  refresh: () => { },
}

export default Table
