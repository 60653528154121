import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'
import SearchContext from '../contexts/SearchContext'
import * as services from '../services'
import { Tabs } from '../components'
import Table from '../components/Table'
import CancelAlertModal from '../components/CancelAlertModal'
import { CenterContainer, Container } from '../components/Grid'
import { PageTitle, Error } from '../components/Typography'
import { PageSection } from '../components/PageSection'
import { Checkbox, EditIcon } from '../components/design-system'
import { SmallButton, BigButton } from '../components/Button'
import { Select, TextInput } from '../components/Input'
import SearchCriteriaSection from '../components/SearchCriteriaSection'
import SearchCamerasSection from '../components/SearchCamerasSection'
import PlateSearch from '../components/PlateSearch'
import { useSubscription } from '../services/fetch'
import { Link } from '../components/Typography'
import EnvContext from '../contexts/EnvContext'
import { TooltipCameraList } from '../components/TooltipCameraList'
import { useTranslation } from 'react-i18next'
import { DateTimePicker } from '../components/Input'

const Label = styled.div`
  padding: 0 0.3rem;
  font-size: 0.7rem;
  color: #999999;
  background-color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ${({ labelOverflow }) => (labelOverflow ? 'ellipsis' : '')};
  max-width: 75%;
`

const Description = styled(CenterContainer)`
  margin-top: 0;
  text-align: center;
`

const NameInputContainer = styled(CenterContainer)`
  width: 32%;
  margin: auto;
  flex-direction: column;

  form > *:not(:first-child) {
    margin-top: 1rem;
  }
`

const StyledPageSection = styled(PageSection)`
  ${({ displayCameras }) => displayCameras && 'border: none !important;'}
  padding-bottom: ${({ displayCameras }) =>
    displayCameras ? '0' : '2rem'} !important;
`

const NotificationTypeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: center;

  & > *:not(:first-child) {
    margin-left: 2rem;
  }
`

const TabContent = styled(Container)`
  margin-top 2rem;
  width: 90% !important;
`

const PlateSearchContainer = styled.div`
  margin-top: 1.5rem;
`

const SectionHistory = styled.div`
  text-align: center;
`

const RealtimeLegend = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 0.7rem;
  background-color: #f61a1a;
  display: inline-block;
`

const Standard = ({
  form,
  setForm,
  selectTag,
  selectModel,
  selectColor,
  selectMake,
}) => (
  <SearchCriteriaSection
    selected={form.tag}
    selectTag={selectTag}
    selectModel={selectModel}
    selectColor={selectColor}
    selectMake={selectMake}
    form={form}
    setForm={setForm}
  />
)

const Alert = () => {
  const [
    { tags, cameras, carMakes, carColors, carModels },
    { fetchCameras, fetchCarMakes, fetchCarModels },
  ] = useContext(SearchContext)
  const { t } = useTranslation()

  const [{ loading: loadingEnv, env }] = useContext(EnvContext)

  const [allSectors, setAllSectors] = useState(null)
  const [currentTab, setCurrentTab] = useState('vpi')
  const [frequency, setFrenquency] = useState('real-time')
  const [vpiSearch, setVpiSearch] = useState([])
  const [standardSearch, setStrandardSearch] = useState([])
  const [displayCameras, setDisplayCameras] = useState(true)
  const [researchName, setResearchName] = useState('')
  const [researchDescription, setResearchDescription] = useState('')
  const [endDate, setEndDate] = useState(new Date())
  const [submitError, setSubmitError] = useState(null)
  const [newPlate, setNewPlate] = useState('')
  const [plates, setPlates] = useState([])
  const [plateError, setPlateError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [camerasLoading, setCamerasLoading] = useState(true)
  const [programmStop, setProgrammStop] = useState(false)
  const [form, setForm] = useState({
    cameras: [],
    notifications: ['app'],
    tag: 'car',
    since: new Date(
      Date.now() - 365 * 24 * 60 * 60 * 1000 - 1 * 60 * 60 * 1000 // -1year -1h in ms
    )
  })

  const alerts = useSubscription(
    () => Promise.all([
      services.fetchAllAnprAlerts(),
      services.fetchAllDetectionAlerts(),
    ]).then(data => {
      setLoading(false)

      return data
    }),
    5000
  )

  useEffect(() => {
    fetchCameras().then(
      ({ cameras }) => {
        setForm({ ...form, cameras: [] })
        setCamerasLoading(false)
      }
    )
    fetchCarMakes()
    services.fetchAllSectors().then(({ sectors }) => {
      setAllSectors(sectors)
    })
  }, [])

  useEffect(() => {
    services.fetchAllVPISearch().then(({ requests }) =>
      setVpiSearch(
        requests.splice().map((e) => ({
          ...e,
          type: 'VPI',
          search: e.plates.split(',').join(', '),
          // date: strftime('%d/%m/%Y', new Date(Number(e.at))),
          // hour: strftime('%H:%M', new Date(Number(e.at)))
        }))
      )
    )
  }, [])

  useEffect(() => {
    services.fetchAllStandardSearch().then(({ requests }) => {
      const formattedRequests = requests.slice().map((e, i) => {
        if (i >= 10) return null
        let search = ''
        const searchType = tags.filter((tag) => tag.name === e.tag)[0]
          .displayName
        const gender = {
          female: 'Femme',
          male: 'Homme',
          girl: 'Fille',
          boy: 'Garçon',
        }
        const age = {
          adult: 'Adulte (+18)',
          infant: 'Enfant',
        }
        const clothingColor = {
          black: 'Noir',
          blue: 'Bleu',
          gray: 'Gris',
          green: 'Vert',
          red: 'Rouge',
          white: 'Blanc',
          yellow: 'Jaune',
        }
        const getGender = (gender, age) => {
          if (gender === 'female') return age === 'infant' ? 'Fille' : 'Femme'
          return age === 'infant' ? 'Garçon' : 'Homme'
        }

        if (
          e.tag === 'car' ||
          e.tag === 'heavyVehicles' ||
          e.tag === 'twoWheeled'
        ) {
          search +=
            carMakes.filter((brand) => brand.name === e.make)[0]?.displayName ||
            e.make ||
            ''
          search += ' '
          search +=
            carModels.filter((model) => model.name === e.model)[0]
              ?.displayName ||
            e.model ||
            ''
          search += ' '
          search +=
            carColors.filter((color) => color.name === e.color)[0]
              ?.displayName ||
            e.color ||
            ''
        }

        if (e.tag === 'person') {
          search += gender[e?.gender] ? `${getGender(e.gender, e.age)} ` : ''
          search += age[e?.age] ? `${age[e?.age]} ` : ''
          search += clothingColor[e?.top_color]
            ? `Haut ${clothingColor[e?.top_color]}`
            : ''
          search += clothingColor[e?.bottom_color]
            ? ` Bas ${clothingColor[e?.bottom_color]}`
            : ''
        }

        return {
          ...e,
          type: searchType,
          date: strftime('%d/%m/%Y', new Date(Number(e.at))),
          hour: strftime('%H:%M', new Date(Number(e.at))),
          search: search,
        }
      })

      setStrandardSearch(formattedRequests)
    })
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (researchName === '') {
      setSubmitError('Veuillez entrer un nom pour votre recherche.')
      return
    }

    if (form.cameras.length === 0) {
      setSubmitError(
        'Veuillez choisir au moins une caméra pour votre recherche.'
      )
      return
    }

    let params = {
      name: researchName,
      description: researchDescription,
      cameras: form.cameras.join(','),
      since: form.since.getTime(),
      date_end: endDate && programmStop ? endDate.getTime() : null,
      notifications: form.notifications.join(','),
    }
    const vehiculeParams = {
      make: form?.make,
      model: form?.model,
      color: form?.color,
    }
    const personParams = {
      age: form?.age,
      gender: form?.gender,
      topColor: form?.topColor,
      bottomColor: form?.bottomColor,
    }
    const vpiParams = {
      plates: plates.map((p) => p.toUpperCase()).join(','),
    }

    if (currentTab !== 'vpi') {
      params = {
        ...params,
        tag: form.tag,
      }
    }
    if (form?.tag === 'car' && currentTab === 'standard') {
      params = {
        ...params,
        ...vehiculeParams,
      }
    }
    if (form?.tag === 'person' && currentTab === 'standard') {
      params = {
        ...params,
        ...personParams,
      }
    }
    if (currentTab === 'vpi') {
      params = {
        ...params,
        ...vpiParams,
      }
    }

    try {
      if (currentTab === 'vpi') {
        await services.createAnprAlert(params)
      } else {
        await services.createDetectionAlert(params)
      }
      alerts.refetch()
      setSubmitError(null)
      setResearchName('')
    } catch (error) {
      setSubmitError(
        t("alert.youHaveReachedTheLimitOfSimultaneousAlertsPleaseDeleteAnAlertOrContactTheAdministratorToContinue")
      )
      console.error(error)
    }
  }

  const toggleDisplayCameras = () => setDisplayCameras(!displayCameras)

  const selectTag = (event) =>
    setForm({ ...form, tag: event.target.value, make: null, model: null })
  const selectMake = (value) => {
    fetchCarModels(value)
    setForm({ ...form, make: value })
  }
  const selectModel = (value) => setForm({ ...form, model: value })
  const selectColor = (value) => setForm({ ...form, color: value })
  const selectNotificationType = (value, type) => {
    const include = form.notifications.includes(type)

    if (value && !include) {
      setForm({
        ...form,
        notifications: [...form.notifications, type],
      })
    }
    if (!value && include) {
      setForm({
        ...form,
        notifications: form.notifications.filter((e) => e !== type),
      })
    }
  }

  const handleSetResearchName = (value) => {
    const regexTest = /(^[a-zA-Z0-9\_\-]+$)|^$/g.test(value)

    if (regexTest) setResearchName(value)
  }

  const handleChange = (value) => {
    const regexTest = /(^[a-zA-Z0-9\*]+$)|^$/g.test(value)

    if (regexTest) setNewPlate(value.toUpperCase())
  }
  const addPlate = (event) => {
    event.preventDefault()

    if (newPlate === '') {
      setPlateError("Veuillez entrer un numéro de plaque d'immatriculation.")
      return
    }
    if (plates.filter((plate) => plate === newPlate).length != 0) {
      setPlateError("Numéro de plaque d'immatriculation déjà entré.")
      return
    }
    setPlates([...plates, newPlate])
    setNewPlate('')
    setPlateError(null)
  }


  if (loadingEnv) {
    return null
  }

  const panes = []

  if (env?.ACTIVE_DETECTOR_ALERTS) {
    panes.push({
      key: 'standard',
      title: t('alert.stadard'),
      content: (
        <TabContent>
          <Standard
            form={form}
            setForm={setForm}
            selectTag={selectTag}
          // selectModel={selectModel}
          // selectColor={selectColor}
          // selectMake={selectMake}
          />
        </TabContent>
      ),
    })
  }

  if (env?.ACTIVE_VPI_ALERTS) {
    panes.push(
      {
        key: 'vpi',
        title: t('alert.lPR'),
        content: (
          <TabContent>
            {t('alert.enterThePlatesToBeSearchedIfYouOnlyHavePartOfThePlateMakeSureYouPutTheNumbersYouHaveInTheirExactPositionAndPutInThePlaceOfTheOthersForExampleForA7characterFrenchPlateIfYouOnlyHaveTheFirstCharacterAAndTheFourthAndFifthCharacters1And2EnterA12')}
            <PlateSearchContainer>
              <PlateSearch
                plates={plates}
                setPlates={setPlates}
                addPlate={addPlate}
                newPlate={newPlate}
                handleChange={handleChange}
                plateError={plateError}
              />
            </PlateSearchContainer>
          </TabContent>
        ),
      }
    )
  }

  return (
    <>
      <PageTitle>{t('alert.alertes')}</PageTitle>
      <Description>
        <span>
          {t('alert.alertsWorkLikeASearchButInRealTime')}
        </span>
        {t('alert.youWillBeNotifiedOfEachMatchWithTheSearchCriteriaInTheNavigationBar')}
      </Description>
      <StyledPageSection
        displayCameras={displayCameras}
        title={t('alert.perimeter')}
        icon="/images/camera.png"
        description={
          <>
            <strong>{t('alert.selectLPRAlertCameras')}</strong>
            <br />
            <br />
            <RealtimeLegend />
            {' ' + t('alert.redDotsIndicateRealtimeProcessingOnTheCorrespondingCamera')}
          </>
        }
      >
        <SmallButton type="button" onClick={toggleDisplayCameras} primary>
          {displayCameras ? t('alert.hideCameras') : t('alert.showCameras')}
        </SmallButton>
      </StyledPageSection>
      <SearchCamerasSection
        camerasList={cameras.filter(camera => camera.active)}
        sectors={allSectors}
        selectedCameras={form.cameras}
        loading={camerasLoading}
        setSelectedCameras={(v) => setForm({ ...form, cameras: v })}
        toggle={displayCameras}
        showOnRealtimeVPI={currentTab === 'vpi'}
        showOnRealtimeDetect={currentTab === 'standard'}
      />
      <PageSection
        title={t('alert.criteria')}
        icon="/images/filters.svg"
        description={
          <>
            {t('alert.selectTheSearchCriteria')}
          </>
        }
      >
        <Tabs
          currentTab={currentTab}
          panes={panes}
          onChange={(v) => setCurrentTab(v)}
        />
      </PageSection>
      <PageSection>
        <NotificationTypeContainer>
          <b>{t('alert.notifications')} :</b>
          <Checkbox
            checked={form.notifications.includes('app')}
            onChange={(v) => selectNotificationType(v, 'app')}
            label={t('alert.application')}
            inline
          />
          {/*<Checkbox
            checked={form.notifications.includes('email')}
            onChange={(v) => selectNotificationType(v, 'email')}
            label="Email"
            inline
          />
          <Checkbox
            checked={form.notifications.includes('sms')}
            onChange={(v) => selectNotificationType(v, 'sms')}
            label="SMS"
            inline
          />
          */}
        </NotificationTypeContainer>
        <NameInputContainer>
          <form onSubmit={handleSubmit}>
            <Select
              label={` ${t('alert.notificationFrequency')}`}
              name="frequency"
              value={frequency}
              onChange={setFrenquency}
            >
              <option value="real-time">{t('alert.realtimeDefault')}</option>
            </Select>
            <TextInput
              label={t('alert.nameSearch')}
              icon={EditIcon}
              value={researchName}
              onChange={handleSetResearchName}
            />
            <TextInput
              label={t('alert.descriptionOfSearch')}
              icon={EditIcon}
              value={researchDescription}
              onChange={setResearchDescription}
            />
            <Checkbox checked={programmStop}
              onChange={(v) => setProgrammStop(v)}
              label={t('alert.planShutdown')}
              inline />
            {
              programmStop && <>
                <Label>{t('alert.planShutdownOn')} :</Label>
                <DateTimePicker
                  value={endDate}
                  max={new Date(new Date().setMonth(new Date().getMonth() + 12))}
                  onChange={v => setEndDate(v)}
                />
              </>
            }
            {submitError && <Error>{submitError}</Error>}
            <BigButton type="submit">{t('alert.launchAlert')}</BigButton>
          </form>
        </NameInputContainer>
      </PageSection>
      <PageSection title={t('alert.latestAlerts')} icon="/images/task.svg">
        <SectionHistory>
          <AlertHistory
            alerts={alerts}
            loading={loading}
            alertType={(alert) =>
              alert.type || tags.find((tag) => tag.name === alert.tag).displayName
            }
          />
        </SectionHistory>
      </PageSection>
    </>
  )
}

export default Alert

function AlertHistory({ alerts, alertType, loading }) {
  const { t } = useTranslation()
  const header = [
    {
      id: 'endDate',
      name: t('alert.shutdownPlan'),
      sort: (a, b) => {
        if (a.endDate < b.endDate) return -1
        if (a.endDate > b.endDate) return 1
        return 0
      },
    },
    {
      id: 'at',
      name: t('alert.launchedOn'),
      sort: (a, b) => {
        if (a.at < b.at) return -1
        if (a.at > b.at) return 1
        return 0
      },
    },
    {
      id: 'name',
      name: t('alert.name'),
      sort: (a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      },
    },
    {
      id: 'description',
      name: t('alert.description'),
      sort: (a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      },
    },
    {
      id: 'type',
      name: t('alert.type'),
      sort: (a, b) => {
        if (a.type < b.type) return -1
        if (a.type > b.type) return 1
        return 0
      },
    },
    {
      id: 'perimeter',
      name: t('alert.perimeter'),
      sort: (a, b) => {
        const lenA = a.cameras?.split(',').length
        const lenB = b.cameras?.split(',').length
        if (lenA < lenB) return -1
        if (lenA > lenB) return 1
        return 0
      },
    },
    {
      id: 'total',
      name: t('alert.status'),
      sort: (a, b) => {
        if (a.total < b.total) return -1
        if (a.total > b.total) return 1
        return 0
      },
    },
    {
      id: 'action',
      name: t('alert.action'),
    },
    {
      id: 'cancel',
      name: '',
    },
  ]

  const alertsData = alerts.data
    ? [...alerts.data[0].alerts, ...alerts.data[1].alerts]
    : []

  const body = alertsData.map((alert) => {
    const createdDate = new Date(Number(alert.at))
    const endDate = new Date(Number(alert.end_date))
    const type = alertType(alert)
    return {
      id: alert.id,
      type,
      endDate: [
        strftime('%d/%m/%Y', endDate),
        strftime('%H:%M', endDate),
      ].join(' - '),
      at: [
        strftime('%d/%m/%Y', createdDate),
        strftime('%H:%M', createdDate),
      ].join(' - '),
      perimeter: <TooltipCameraList cameraList={alert.cameras?.split(',')} />,
      name: alert.name,
      description: alert.description,
      total:
        alert.total > 0 ? (
          <span style={{ color: 'red' }}>+ {alert.total} {t('alert.alertes')}</span>
        ) : (
          t('alert.noAlerts')
        ),
      cancel: (
        <CancelAlertModal
          style={{ marginLeft: 10 }}
          onCancel={async () => {
            if (type === 'vpi') {
              await services.cancelAnprAlert(alert.id)
            } else {
              await services.cancelDetectionAlert(alert.id)
            }
            alerts.refetch()
          }}
        />
      ),
      action: (
        <Link
          to={`/alert/${type === 'vpi' ? 'vpi' : 'detection'}/${alert.id}`}
        >
          {t('alert.results')}
        </Link>
      )
    }
  })

  return (
    !loading && body.length <= 0 ? (
      <div>{t('alert.noAlerts')}</div>
    ) : <Table header={header} body={body} loading={loading} />
  )
}
