import styled, { css } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

const TextStyle = css`
  color: #222;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6;
}
`

export const HStyle = css`
  font-family: Blinker;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
`

export const H1 = styled.h1`
  ${HStyle}
  color: #000;
  font-size: 40px;
  margin: 30px 0;
  text-align: center;
  @media (min-width: 550px) {
    font-size: 50px;
  }
`

export const H2 = styled.h2`
  ${HStyle}
  color: #74ccf8;
  font-size: 36px;
  line-height: 1.25;
  @media (min-width: 550px) {
    font-size: 42px;
  }
`

export const H3 = styled.h3`
  ${HStyle}
  font-size: 24px;
  margin-bottom: 15px;
`

export const P = styled.p`
  ${TextStyle}
  margin-top: 0;
  margin-bottom: 25px;

  ${({ textAlign }) => textAlign && `
    text-align: ${textAlign}
  `}
`

export const A = styled.a`
  display: inline-block;
  color: #74ccf8;
  text-decoration: none;
  overflow-wrap: anywhere;

  &:focus { outline: none; }
`

export const Link = styled(RouterLink)`
  color: #74ccf8;

  &:focus { outline: none; }
`

export const Span = styled.span`
  ${TextStyle}
  margin: 0;

  ${({ color }) => color && `
    color: ${color}
  `}
`

export const Sup = styled.sup`
  ${({ color }) => color && `
    color: ${color}
  `}
`

export const ColoredLink = styled.span`
  color: ${({ color, theme }) => color ? color : theme.primaryColor};
  text-decoration: underline;
  cursor: pointer;
`

export const Error = styled.div`
  text-align: center;
  margin-top: 1rem;
  color: ${({ theme }) => theme.errorRed};
`

export const PageTitle = styled(H1)`
  color: #000;
  text-transform: capitalize;
  margin-bottom: 0;
`
