import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'
import * as services from '../services'
import SearchContext from '../contexts/SearchContext'
import { PageSection } from '../components/PageSection'
import { SmallButton, BigButton } from '../components/Button'
import { CenterContainer, Container, Columns } from '../components/Grid'
import { H1, Error, Link } from '../components/Typography'
import { EditIcon, SearchIcon, Spinner, Radio, FormGroup } from '../components/design-system'
import { TextInput } from '../components/Input'
import Table from '../components/Table'
import SearchDateSection from '../components/SearchDateSection'
import ExportsCameraSection from '../components/ExportsCameraSection'
import { useSubscription } from '../services/fetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindows, faLinux, faApple } from '@fortawesome/free-brands-svg-icons'

const StyledH1 = styled(H1)`
  color: #000;
  text-transform: none;
  margin-bottom: 0;
`

const PasswordContainer = styled.div`
  width: 50%;
  margin: auto;
`

const CamerasPageSection = styled(PageSection)`
  ${({ displayCameras }) => displayCameras && 'border: none !important;'}
  padding-bottom: ${({ displayCameras }) =>
    displayCameras ? '0' : '2rem'} !important;
`

const NameInputContainer = styled(CenterContainer)`
  width: 32%;
  margin: auto;
  flex-direction: column;
`

const HistoryPageSection = styled(PageSection)`
  text-align: center;

  ${SmallButton} {
    margin-top: 2rem;
  }
`

const CustomFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 5rem;
`

const CustomColumns = styled(Columns)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Exports = () => {
  const [{ cameras }, { fetchCameras }] = useContext(SearchContext)

  const [os, setOs] = useState('windows')
  const [password, setPassword] = useState('')
  const [camera, setCamera] = useState([])
  const [exportName, setExportName] = useState('')
  const [submitError, setSubmitError] = useState(null)
  const [tableBody, setTableBody] = useState([])
  const [displayCameras, setDisplayCameras] = useState(true)
  const [since, setSince] = useState(new Date(Date.now() - 1000 * 60 * 60))
  const [until, setUntil] = useState(new Date())

  useEffect(() => {
    fetchCameras()
  }, [])

  const renderOs = os => {
    let icon = faWindows

    if (os === 'linux')
      icon = faLinux

    if (os === 'mac')
      icon = faApple

    return (
      <>
        <FontAwesomeIcon icon={icon} />
        &nbsp;
        {os.charAt(0).toUpperCase() + os.slice(1)}
      </>
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (exportName === '') {
      setSubmitError('Veuillez entrer un nom pour votre export.')
      return
    }

    if (password === '') {
      setSubmitError('Veuillez entrer un password pour votre export.')
      return
    }

    if (!camera) {
      setSubmitError(
        'Veuillez choisir une caméra pour votre export.'
      )
      return
    }

    if (!os) {
      setSubmitError(
        'Veuillez choisir un système d\'exploitation pour votre export.'
      )
      return
    }

    setSubmitError(null)

    Promise.all(camera.map(async (cameraItem) => {
      return services
        .addExport({
          os,
          exportName,
          camera: cameraItem,
          password,
          since: since.getTime(),
          until: until.getTime(),
        })
    })).then(dataResponse => {
      setTableBody([
        ...dataResponse.map((data) => {
          return {
            id: data.id,
            os: renderOs(data.os),
            date: strftime('%d/%m/%Y', new Date(Number(data.at))) +
              '\n' +
              strftime('%H:%M', new Date(Number(data.at))),
            camera: cameras.find(c => c.id === data.configId).name,
            name: data.name,
            start: strftime('%d/%m/%Y', new Date(Number(data.since))) +
              '\n' +
              strftime('%H:%M', new Date(Number(data.since))),
            end: strftime('%d/%m/%Y', new Date(Number(data.until))) +
              '\n' +
              strftime('%H:%M', new Date(Number(data.until))),
            link: <ExportProgress exportId={data.id} />
          }
        }),
        ...tableBody
      ])
      setCamera(null)
      setPassword('')
      setSince(new Date(Date.now() - 1000 * 60 * 60))
      setUntil(new Date())
      setExportName('')
    }).catch((e) => {
      console.log(e)
      setSubmitError(
        'Problème technique: Erreur API.'
      )
    })
  }

  const toggleDisplayCameras = () => setDisplayCameras(!displayCameras)

  const setDate = (name, value) => {
    if (name === 'since')
      setSince(value)
    if (name === 'until')
      setUntil(value)
  }

  const handleSetExportName = (value) => {
    const regexTest = /(^[a-zA-Z0-9\_\-]+$)|^$/g.test(value)

    if (regexTest) setExportName(value)
  }

  const tableHeader = [
    {
      id: 'os',
      name: 'os',
      sort: (a, b) => {
        const lengthA = (a?.camera || '').split(',').length
        const lengthB = (b?.camera || '').split(',').length
        return lengthA - lengthB
      }
    },
    {
      id: 'date',
      name: 'Date',
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    {
      id: 'name',
      name: 'Nom',
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      },
    },
    {
      id: 'start',
      name: 'Début',
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    {
      id: 'end',
      name: 'Fin',
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    {
      id: 'camera',
      name: 'Caméra',
      sort: (a, b) => {
        const lengthA = (a?.camera || '').split(',').length
        const lengthB = (b?.camera || '').split(',').length
        return lengthA - lengthB
      },
    },
    { id: 'link', name: 'Télécharger' },
  ]

  useEffect(() => {
    services.fetchMyExports({ limit: 20 }).then(({ exports }) => {
      const body = exports.map(e => ({
        id: e.id,
        os: renderOs(e.os),
        date:
          strftime('%d/%m/%Y', new Date(Number(e.at))) +
          '\n' +
          strftime('%H:%M', new Date(Number(e.at))),
        name: e.name,
        start:
          strftime('%d/%m/%Y', new Date(Number(e.since))) +
          '\n' +
          strftime('%H:%M', new Date(Number(e.since))),
        end:
          strftime('%d/%m/%Y', new Date(Number(e.until))) +
          '\n' +
          strftime('%H:%M', new Date(Number(e.until))),
        camera: cameras.find(c => c.id === e.config_id) ? cameras.find(c => c.id === e.config_id).name : '',
        link: <ExportProgress exportId={e.id} />
      }))

      setTableBody(body)
    })
  }, [cameras])

  return (
    <>
      <StyledH1>Nouvel Export Vidéo</StyledH1>
      <CamerasPageSection
        displayCameras={displayCameras}
        title="Périmètre"
        icon="/images/camera.png"
        description={
          <>
            <strong>Choisissez la caméra</strong> concernée par l'export.
          </>
        }
      >
        <SmallButton type="button" onClick={toggleDisplayCameras} primary>
          {displayCameras ? 'Masquer les caméras' : 'Afficher les caméras'}
        </SmallButton>
      </CamerasPageSection>
      <ExportsCameraSection
        camerasList={cameras}
        sectors={[]}
        selectedCamera={camera}
        setSelectedCamera={v => {
          if (camera?.find(cam => cam === v)) {
            setCamera(camera.filter(cam => {
              return cam != v
            }))
          } else {
            setCamera([...(camera || []), v])
          }
        }}
        toggle={displayCameras}
      />
      <PageSection
        title="Système d'exploitation"
        icon="/images/desktop.svg"
        description={
          <>
            <strong>Choisissez le système d'exploitation</strong> sur lequel vous souhaitez visionner l'export.{' '}
          </>
        }
      >
        <Container>
          <FormGroup>
            <CustomColumns nb={4}>
              <CustomFontAwesomeIcon icon={faWindows} />
              <Radio
                name="os"
                onChange={() => setOs('windows')}
                checked={os === 'windows'}
                label="Windows"
                boxColor="#50C5F3"
              />
            </CustomColumns>
            <CustomColumns nb={4}>
              <CustomFontAwesomeIcon icon={faLinux} />
              <Radio
                name="os"
                checked={os === 'linux'}
                onChange={() => setOs('linux')}
                label="Linux"
                boxColor="#50C5F3"
              />
            </CustomColumns>
            <CustomColumns nb={4}>
              <CustomFontAwesomeIcon icon={faApple} />
              <Radio
                name="os"
                checked={os === 'mac'}
                onChange={() => setOs('mac')}
                label="Mac"
                boxColor="#50C5F3"
              />
            </CustomColumns>
          </FormGroup>
        </Container>
      </PageSection>
      <PageSection
        title="Mot de passe"
        icon="/images/filters.svg"
        description={
          <>
            <strong>Choisissez un mot de passe</strong> pour protéger l'accès aux vidéos de l'export.{' '}
          </>
        }
      >
        <PasswordContainer>
          <TextInput
            label="Mot de passe"
            type="password"
            icon={EditIcon}
            value={password}
            onChange={v => setPassword(v)}
          />
        </PasswordContainer>
      </PageSection>
      <PageSection
        title="Période"
        icon="/images/calendar.png"
        description={
          <>
            <strong>Définissez la période</strong> d'export.
          </>
        }
      >
        <SearchDateSection
          until={until}
          since={since}
          setDate={setDate}
        />
      </PageSection>
      <PageSection>
        <NameInputContainer>
          <form onSubmit={handleSubmit}>
            <TextInput
              label="Nommer l'export"
              icon={EditIcon}
              value={exportName}
              onChange={handleSetExportName}
            />
            {submitError && <Error>{submitError}</Error>}
            <BigButton
              onClick={handleSubmit}
              type="submit"
              leftIcon={<SearchIcon />}
            >
              Lancer l'export
            </BigButton>
          </form>
        </NameInputContainer>
      </PageSection>
      <HistoryPageSection title="Historique des exports" icon="/images/search.svg">
        <Table header={tableHeader} body={tableBody} loading={false} />
      </HistoryPageSection>
    </>
  )
}

export default Exports

export function ExportProgress({ exportId }) {
  const { data } = useSubscription(
    () => services.fetchMyExport({ exportId }),
    4000
  )

  if (data && data.status !== 'done') {
    let result = <Spinner />;
    switch (data.status) {
      case 'canceled':
        result = <>Annulé</>;
        break;
      case 'doing':
        result = <Spinner />;
        break;
      case 'error':
        result = <>Erreur</>;
        break;
      case 'todo':
        result = <>En attente</>;
        break;
    }
    return result;
  }

  return (
    <Link
      target="_blank"
      to={`/api/exports/download/${exportId}`}
    >
      Télécharger
    </Link>
  )
}
