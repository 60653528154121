import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const StyledButton = styled.button`
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #bbb;
  box-sizing: border-box;
  color: #555;
  cursor: pointer;
  display: inline-block;
  font-family: Blinker;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 10px 30px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  line-height: 16px;

  &:hover,
  &:focus {
    color: #333;
    border-color: #888;
    outline: 0;
  }

  transition: all 150ms;

  ${({ active, theme }) =>
    active &&
    css`
      background: red;
      opacity: 0.6;
      cursor: inherit;
    `}

  ${({ primary }) =>
    primary &&
    `
    background-color: #74ccf8;
    border-color: #74ccf8;
    border-radius: 3px;
    color: #fff;

    &:hover, &:focus {
      color: #fff;
      background-color: #1eaedb;
      border-color: #1eaedb;
    }
  `}

  ${({ red }) =>
    red &&
    `
    background-color: #f87f74;
    border-color: #f87f74;
    border-radius: 3px;
    color: #fff;

    &:hover, &:focus {
      color: #fff;
      background-color: #de6d63;
      border-color: #de6d63;
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    background-color: transparent;
    border: 1px solid #a4a6aa;
    color: #a4a6aa;
    cursor: auto;

    &:hover {
      background-color: transparent;
      border: 1px solid #a4a6aa;
      color: #a4a6aa;
    }
  `}
`

export const Button = ({ spin, children, ...props }) => (
  <StyledButton {...props}>
    {!spin && children}
    {spin && <FontAwesomeIcon icon={faSpinner} spin />}
  </StyledButton>
)

export const SmallButton = styled.button`
  width: fit-content;
  font-family: Blinker;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  background-color: ${({ theme, color }) =>
    color ? theme[color] || color : theme.primaryColor};
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 0.4rem 0.9rem;

  &:focus {
    outline: none;
    background-color: ${({ theme, hoverColor }) =>
      hoverColor ? hoverColor : theme.primaryHover};
  }

  &:hover {
    background-color: ${({ theme, hoverColor }) =>
      hoverColor ? hoverColor : theme.primaryHover};
  }

  &:disabled {
    color: ${({ theme }) => theme.input.disabled.color};
    opacity: 0.6;
    cursor: initial;
    &:hover {
      background-color: ${({ theme, color }) =>
        color ? theme[color] || color : theme.primaryColor};
    }
  }
`

const StyledBigButton = styled(SmallButton)`
  padding: 1rem 2rem;
  font-size: 1.6rem;
  display: grid;
  grid-template-columns: ${({ icon }) => (icon ? 'auto auto' : 'auto')};
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  margin-top: 1.5rem;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    `
  opacity: .75;
  pointer-events: none;
`}
`

export const BigButton = ({
  children,
  rightIcon,
  leftIcon,
  spin,
  ...props
}) => {
  return (
    <StyledBigButton {...props} icon={!!rightIcon || !!leftIcon}>
      {!spin && leftIcon && leftIcon}
      {!spin && children}
      {spin && <FontAwesomeIcon icon={faSpinner} spin />}
      {!spin && rightIcon && rightIcon}
    </StyledBigButton>
  )
}

BigButton.propTypes = {
  rightIcon: PropTypes.node,
  leftIcon: PropTypes.node,
}
