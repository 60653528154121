import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import SRModal from 'styled-react-modal'
import { BlankButton, Button } from './CTAs'
import { H3, P } from './Typography'
import { CloseIcon } from './Icons'

const StyledModal = SRModal.styled`
  position: relative;
  width: 80%;
  max-width: 960px;
  margin: 2.5% auto;
  background-color: #fff;
  border-radius: 3px;
  padding: 20px;
`

const CloseButton = styled(BlankButton)`
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  right: -20px;
  top: -20px;
`

const Container = styled.div``

export const Modal = ({ onCloseClick, children, ...props }) => (
  <StyledModal {...props}>
    <CloseButton onClick={onCloseClick}>
      <CloseIcon />
    </CloseButton>
    <Container>
      {children}
    </Container>
  </StyledModal>
)

export const ModalTitle = styled(H3)``

export const ModalBody = styled.div``

const Options = styled.ul`
  list-style: none;
  margin: 1rem 0 0;
  padding: 0;
`

const Option = styled.li`
  display: inline-block;
  margin: 0 1rem 0 0;
`

export const ConfirmationModal = ({
  title,
  description,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  danger,
  children,
  ...props
}) => (
  <Modal
    onCloseClick={onCancel}
    onBackgroundClick={onCancel}
    onEscapeKeydown={onCancel}
    {...props}
  >
    <ModalTitle>{title}</ModalTitle>
    <ModalBody>
      {!!description && <P>{description}</P>}
      {children}
      <Options>
        <Option>
          <Button primary danger={danger} onClick={onConfirm}>
            {confirmLabel}
          </Button>
        </Option>
        <Option>
          <Button onClick={onCancel}>{cancelLabel}</Button>
        </Option>
      </Options>
    </ModalBody>
  </Modal>
)

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  children: PropTypes.any,
}

ConfirmationModal.defaultProps = {
  onCancel: e => null,
  title: 'Confirmer',
  description: null,
  confirmLabel: 'Valider',
  cancelLabel: 'Annuler',
}
