import axios from 'axios'

export const fetchAllAnprAlerts = () =>
  axios.get(`/api/anpr-alerts/requests`).then(({ data }) => data)

export const fetchAnprAlertsCount = () =>
  axios.get(`/api/anpr-alerts/requests/count`).then(({ data }) => data)

export const fetchAnprAlert = (id) =>
  axios.get(`/api/anpr-alerts/requests/${id}`).then(({ data }) => data)

export const fetchAnprAlertResults = ({
  id,
  sortBy,
  sortType,
  cameras,
  filter,
  sector,
  has_seen,
}) =>
  axios
    .get( 
      `/api/anpr-alerts/requests/${id}/detections` +
        `?sortBy=${sortBy}` +
        `&sortType=${sortType}` +
        (sector ? `&sector=${sector}` : '') +
        (cameras ? `&cameras=${cameras}` : '') +
        (has_seen ? `&has_seen=${has_seen}` : '') +
        `&filter=${filter}`
    )
    .then(({ data }) => data)

export const createAnprAlert = (body) =>
  axios.post(`/api/anpr-alerts/requests`, body).then(({ data }) => data)

export const putAnprAlertFavorite = ({ id, favorite }) =>
  axios.put(`/api/anpr-alerts/requests/${id}/favorite`, { favorite }).then(({ data }) => data)

export const putAnprAlertHide = ({ id, hidden }) =>
  axios.put(`/api/anpr-alerts/requests/${id}/hide`, { hidden }).then(({ data }) => data)

export const markAllAnprAlertResultsAsSeen = (id) =>
  axios.put(`/api/anpr-alerts/requests/${id}/seen`).then(({ data }) => data)

export const cancelAnprAlert = (id) =>
  axios.put(`/api/anpr-alerts/requests/${id}/cancel`).then(({ data }) => data)

export const putAnprAlertResultFalsePositive = ({
  id,
  action
}) =>
  axios
    .put(
      `/api/anpr-alerts/results/${id}/false-positive`,
      {},
      { params: { action } }
    ).then(({ data }) => data)
