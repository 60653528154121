import EventEmitter from 'eventemitter3'

export default class VideoGroup extends EventEmitter {
  constructor({ canvas, videoUrls = [], startAt, endAt }) {
    super()
    this.readyState = {}
    this.videoUrls = videoUrls
    this.videos = []

    this.timerId = null
    this.isPlaying = false
    this.startAt = startAt
    this.startAt.time = Math.max(0, this.startAt.time)

    this.endAt = endAt
    this.totalDuration = 0
    this.currentVideoId = this.startAt?.index || 0
    this.ctx = canvas.getContext('2d')

    window.addEventListener('resize', this._resize)
  }

  load() {
    if (this.videos.length !== this.videoUrls.length)
      this.videos = this.videoUrls.map(this._initializeVideo)
  }

  get currentVideo() {
    return this.videos[this.currentVideoId]
  }

  get isReady() {
    const states = Object.values(this.readyState)
    return (
      states.length === this.videos.length && states.every((isReady) => isReady)
    )
  }

  get time() {
    return this.currentVideo.currentTime
  }

  set time(t) {
    this.currentVideo.currentTime = t
  }

  get timeGrouped() {
    return this.toTimeGrouped(
      this.currentVideo.currentTime,
      this.currentVideoId
    )
  }

  toTimeGrouped = (time, id) => {
    let t = time - this.startAt.time
    for (let i = this.startAt.index; i < id; i++) {
      t += this.videos[i].duration
    }
    return t
  }

  set timeGrouped(time) {
    this.pause()
    this.currentVideo.currentTime =
      this.startAt.index === this.currentVideoId ? this.startAt.time : 0

    let i = this.startAt.index
    let videoTime = this.startAt.time + time
    while (this.videos[i].duration < videoTime) {
      videoTime -= this.videos[i].duration
      i++
    }
    this.currentVideoId = i
    this.currentVideo.currentTime = videoTime
  }

  get durationGrouped() {
    let duration = 0
    for (let i = this.startAt.index; i < this.endAt.index; i++) {
      duration += this.videos[i].duration
    }
    return duration - this.startAt.time + this.endAt.time
  }

  get progress() {
    return this.timeGrouped / this.durationGrouped
  }

  set progress(progress) {
    this.timeGrouped = Math.max(0, Math.min(1, progress)) * this.durationGrouped
  }

  setTime = (videoId, timeRel) => {
    this.pause()
    this.currentVideo.currentTime =
      this.startAt.index === this.currentVideoId ? this.startAt.time : 0
    this.currentVideoId = videoId
    this.currentVideo.currentTime = timeRel
  }

  draw = async () => {
    if (this.isReady) {
      if (
        this.currentVideoId === this.endAt.index &&
        this.currentVideo.currentTime >= this.endAt.time
      )
        await this.nextVideo()
      this._resize()
      this.emit('draw', this)
    }
  }

  play = () => {
    this.currentVideo.addEventListener('ended', this.nextVideo)
    if (this.timerId) {
      clearInterval(this.timerId)
    }
    this.timerId = setInterval(this.draw, 40)

    if (this.currentVideo.paused) this.currentVideo.play()
  }

  nextVideo = () => {
    this.currentVideo.currentTime =
      this.startAt.index === this.currentVideoId ? this.startAt.time : 0
    this.currentVideo.removeEventListener('ended', this.nextVideo)

    if (
      this.currentVideoId + 1 >
      Math.min(this.videos.length, this.endAt.index)
    ) {
      this.currentVideoId = this.startAt.index
      this.currentVideo.currentTime = this.startAt.time
    } else {
      this.currentVideoId++
      this.currentVideo.currentTime = 0
    }
    return this.play()
  }

  pause = () => {
    if (!this.currentVideo.paused) {
      this.currentVideo.pause()
    }
    if (this.timerId) {
      clearInterval(this.timerId)
    }
  }

  stop = () => {
    this.pause()
    this.currentVideoId = this.startAt.index
    this.currentVideo.currentTime = this.startAt.time
  }

  destroy() {
    this.videos.forEach((videoElement) => {
      videoElement.removeEventListener('waiting', this._loadStart)
      videoElement.removeEventListener('stalled', this._loadStart)
      videoElement.removeEventListener('loadstart', this._loadStart)
      videoElement.removeEventListener('canplay', this._canPlay)
      videoElement.removeEventListener('loadedmetadata', this._loadedmetadata)
      videoElement.removeEventListener('timeupdate', this._timeupdate)
      videoElement.removeEventListener('ended', this.nextVideo)
      document.body.removeChild(videoElement)
    })
    window.removeEventListener('resize', this._resize)
    this.readyState = {}
    if (this.timerId) clearInterval(this.timerId)
  }

  _initializeVideo = (src, id) => {
    const videoElement = document.createElement('video')
    videoElement.setAttribute('src', src)
    videoElement.setAttribute('data-id', id)
    videoElement.style.position = 'absolute'
    videoElement.style.left = -1000
    videoElement.style.top = -1000
    videoElement.style.width = 0
    videoElement.style.height = 0
    videoElement.muted = true
    videoElement.onplaying = () => (this.isPlaying = true)
    videoElement.onpause = () => (this.isPlaying = false)
    videoElement.addEventListener('loadstart', this._loadStart)
    videoElement.addEventListener('waiting', this._loadStart)
    videoElement.addEventListener('stalled', this._loadStart)
    videoElement.addEventListener('canplay', this._canPlay)
    videoElement.addEventListener('loadedmetadata', this._loadedmetadata)
    videoElement.addEventListener('timeupdate', this._timeupdate)
    document.body.appendChild(videoElement)
    return videoElement
  }

  _timeupdate = (e) => {
    this.emit('timeupdate', this)
  }

  _loadStart = (e) => {
    this.readyState[e.target.getAttribute('data-id')] = false
    this.emit('loading')
  }

  _canPlay = (e) => {
    this.readyState[e.target.getAttribute('data-id')] = true
    if (this.isReady) {
      this.draw()
      this.emit('loaded')
    }
  }

  _resize = () => {
    this.ctx.canvas.width = this.ctx.canvas.getBoundingClientRect().width
    this.ctx.canvas.height =
      (this.ctx.canvas.width / (this.currentVideo.videoWidth || 1)) *
      this.currentVideo.videoHeight
  }

  _loadedmetadata = async (e) => {
    this.totalDuration += e.target.duration
    if (this.currentVideo === e.target) {
      this._resize()
      this.currentVideoId = this.startAt.index
      this.currentVideo.currentTime = this.startAt.time
    }
  }
}
