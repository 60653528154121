import React from 'react'
import axios from 'axios'
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'
import CancelAlertModal from '../components/CancelAlertModal'
import { useHistory } from 'react-router-dom'
import { Checkbox, Container, Link, P } from '../components'
import Table from '../components/Table'
import { PageSection } from '../components/PageSection'
import { PageTitle } from '../components/Typography'
import targetRed from '../../../../../www/images/targetred.svg'
import strftime from 'strftime'
import { BigButton, SmallButton } from '../components/Button'
import { useFetch, useSubscription } from '../services/fetch'
import { SearchIcon, Spinner } from '../components/design-system'
import { fetchCameras, getLastVideoForCamera, testCameraById, getScenarioZones }
  from '../services'
import styled, { css } from 'styled-components'
import {
  addScenariosSearchs,
  getScenariosRequests,
  cancelScenariosSearch,
  updateScenarioZones,
  fetchScenarioRequest
} from '../services/scenarios'
import ZoneEditorSection from '../components/Scenarios/ZoneEditorSection'
import { v4 as uuid } from 'uuid'
import EventEditorSection, {
  eventReducer,
} from '../components/Scenarios/EventEditorSection'
import SearchDateSection from '../components/SearchDateSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Preview from '../components/Scenarios/Preview'
import { SpinnerAnimatedIcon } from '../components/design-system/Icons'
import { object } from 'prop-types'
import { eventTypes, subjects } from './Scenarios'
import { H3 } from '../components/Typography'
import { useTranslation } from 'react-i18next'

const Icon = styled.img`
  filter: brightness(0);
  height: 1.4rem;
  margin-right: 0.8rem;
  user-select: none;
`

const StyledH3 = styled(H3)`
  width: 100%;
  display: block;
  padding: 0 10%;
`

const TableSection = styled.div`
  width: 90%;
  margin: 0 10%;
  display: contents;
`

const PageTitleCustom = styled(PageTitle)`
  text-transform: none;
`

const RealtimeLegend = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  background-color: #f61a1a;
  display: inline-block;
`

const TargetLegend = ({ divStyle }) => (
  <div style={{
    backgroundColor: 'rgb(255, 159, 0)',
    borderRadius: '1rem',
    position: 'relative',
    width: '1rem',
    height: '1rem',
    display: 'inline-block',
    verticalAlign: 'text-top',
    ...divStyle
  }}>
    <img
      src='/images/target.svg'
      width={16}
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '0.8rem',
        height: '0.8rem',
        margin: 'auto',
      }}
    />
  </div>
)

const Error = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  color: #777;
  font-style: italic;
`

const CameraButtonCheckbox = styled(motion.div)`
  position: absolute;
  top: 0;
  width: 16px;
  height: 16px;
  border: 2px solid white;
  transition: color 150ms, background 150ms;
  border-radius: 50%;
`

CameraButtonCheckbox.defaultProps = {
  initial: { opacity: 0, scale: 0, y: '-50%' },
  animate: { opacity: 1, scale: 1 },
  enter: { opacity: 0, scale: 0 },
  exit: { opacity: 0, scale: 1.2 },
}

const CameraButton = styled(motion.button)`
  position: relative;
  cursor: pointer;
  border: 2px solid #999999;
  color: #999999;
  font-family: Blinker;
  font-size: 1rem;
  text-align: center;
  font-weight: normal;
  background: white;
  border-radius: 5px;
  padding: 8px 10px;
  text-transform: uppercase;
  ${CameraButtonCheckbox} {
    background: ${({ theme, background }) => theme[background] || background};
    color: ${({ theme, color }) => theme[color] || color};
  }

  ${({ active, theme }) =>
    active
      ? css`
          color: white;
          background: ${theme.primaryColor};
          border-color: ${theme.primaryColor};
        `
      : css`
          &:hover {
            border-color: ${theme.primaryColor};
            color: ${theme.primaryColor};
          }
        `}
`

const CustomSpinnerAnimatedIcon = styled(SpinnerAnimatedIcon)`
  display: block;
  margin: 2rem auto;
`

CameraButton.defaultProps = {
  initial: {
    y: 100,
    opacity: 0,
  },
  exit: {
    y: 100,
    opacity: 0,
    transition: { duration: 0.2 },
  },
  animate: { y: 0, opacity: 1 },
  enter: { y: 0, opacity: 1 },
  layout: 'position',
}

function ScenariosSearch() {
  const requests = useSubscription(getScenariosRequests, 4000)
  const [open, setOpen] = React.useState(true)
  const [cameraFilter, setCameraFilter] = React.useState(null)
  const [zones, setZones] = React.useState({})
  const { t } = useTranslation()

  const history = useHistory()

  function toggle() {
    setOpen((prevState) => !prevState)
  }

  const camerasReq = useFetch(fetchCameras, [])
  const cameras = camerasReq.data?.cameras || []

  const [selectedCameraId, setSelectedCameraId] = React.useState(null)
  const selectedCamera = cameras.find((c) => c.id === selectedCameraId)

  React.useEffect(() => {
    if (cameras.length > 0 && selectedCameraId === null) {
      setSelectedCameraId(cameras[0].id)
    }

    getScenarioZones().then(({ zones: scenarioZones }) => {
      setZones(
        scenarioZones.reduce(
          (acc, z) => {
            const zone = {
              id: z.id,
              name: z.name,
              configId: z.config_id,
              vertices: z.coordinates.split(';').map((v) => {
                const [x, y] = v.split(',').map((a) => +a)
                return { x, y }
              }),
            }

            return {
              ...acc,
              [z.config_id]: (
                acc[z.config_id]
                  ? ([...acc[z.config_id], zone])
                  : [zone]
              )
            }
          },
          {}
        )
      )
    })
  }, [cameras, selectedCameraId])

  const filteredCameras = React.useMemo(() => {
    if (cameraFilter === 'zone') {
      return cameras.filter((c) => zones?.[c.id]?.length > 0)
    }
    return cameras
  }, [cameras, zones, cameraFilter])

  const filteringByZone = cameraFilter === 'zone'

  function filterCamerasByZone() {
    setCameraFilter(filteringByZone ? null : 'zone')
  }

  async function createScenario(scenario) {
    try {
      const timeOffset = new Date().getTimezoneOffset()

      const newScenario = await addScenariosSearchs({
        ...scenario, configId: selectedCameraId, timeOffset
      })

      history.push(`/scenarios/searchs/results/${newScenario.scenarios[0].id}`)
    } catch (err) {
      console.error('Error: ', err)
    }
  }

  return (
    <>
      <PageTitleCustom>{t('scenario.scenarioRealTime')}</PageTitleCustom>
      <P textAlign="center">
        <strong>
          {t('scenario.defineScenarioSimpleOrComplexe')}
        </strong>
        {t('scenario.defineScenarioSimpleOrComplexeOnBasepredefine')}
        <br />
        {t('scenario.notificationDescriptionNeuroCop')}{" "}
        <strong>
          {t('scenario.notificationDescriptionNeuroCopImportant')}
        </strong>
      </P>
      <PageSection
        title="Caméra"
        icon="/images/camera.png"
        description={
          <>
            <strong>
              {t('scenario.chooseCamera')}
            </strong>
            <div style={{ margin: '1rem 0 0' }}>
              <TargetLegend />{' '}
              {t('scenario.definitionColorShipOrange')}
              <br />
              <RealtimeLegend />{' '}
              {t('scenario.definitionColorShipRed')}
            </div>
          </>
        }
      >
        <SmallButton as={motion.button} layout primary onClick={toggle}>
          {open ? t('scenario.hideCamera') : t('scenario.showCamera')}
        </SmallButton>
      </PageSection>
      <motion.div
        transition={{ type: 'spring', stiffness: 100, damping: 16 }}
        variants={{
          open: { height: 'auto' },
          closed: { height: 0 },
        }}
        animate={open ? 'open' : 'closed'}
        style={{
          overflow: 'hidden',
          width: '100%',
          textAlign: 'center',
          boxShadow:
            'inset 0px -13px 17px -12px rgba(0, 0, 0, 0.2), inset 0px 13px 17px -12px rgba(0, 0, 0, 0.2)',
        }}
      >
        {
          !camerasReq.loading ? (
            <Container
              style={{ paddingTop: 20, paddingBottom: 20, overflow: 'hidden' }}
            >
              <div style={{ display: 'flex' }}>
                <strong style={{ marginRight: 20 }}>{t('scenario.show')} : </strong>
                <Checkbox
                  checked={filteringByZone}
                  onChange={filterCamerasByZone}
                  label={
                    <>
                      <TargetLegend />{' '}{t('scenario.unique')}
                    </>
                  }
                />
              </div>
              <motion.div
                style={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(8, 1fr)',
                  columnGap: '1rem',
                  rowGap: '1rem',
                  marginTop: '1.5rem',
                }}
              >
                <AnimatePresence>
                  {filteredCameras.map((camera, index) => (
                    <CameraButton
                      key={camera.id}
                      color="white"
                      background="primaryColor"
                      active={selectedCameraId === camera.id}
                      onClick={() => setSelectedCameraId(camera.id)}
                      transition={{
                        type: 'spring',
                        damping: 20,
                        duration: 0.1,
                        delay: index * 0.01,
                      }}
                    >
                      <AnimatePresence>
                        {camera.detect_real_time && (
                          <RealtimeLegend
                            style={{
                              left: -10,
                              position: 'absolute',
                              top: -10,
                              border: '2px solid white'
                            }}
                          />
                        )}
                      </AnimatePresence>
                      <AnimatePresence>
                        {zones?.[camera.id]?.length > 0 && (
                          <TargetLegend divStyle={{
                            position: 'absolute',
                            bottom: '-0.65rem',
                            left: '-0.65rem',
                            border: '2px solid white'
                          }} />
                        )}
                      </AnimatePresence>
                      <AnimatePresence>
                        {selectedCameraId === camera.id && (
                          <CameraButtonCheckbox style={{ right: -10 }} />
                        )}
                      </AnimatePresence>
                      {camera.name}
                    </CameraButton>
                  ))}
                </AnimatePresence>
              </motion.div>
            </Container>
          ) : (
            <CustomSpinnerAnimatedIcon />
          )
        }
      </motion.div>
      <AnimatePresence exitBeforeEnter>
        {selectedCamera && zones && (
          <ScenarioForm
            initialZones={zones[selectedCameraId]}
            requests={requests}
            camera={selectedCamera}
            cameras={cameras}
            onSubmit={createScenario}
            allZones={zones}
            setAllZones={setZones}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default ScenariosSearch

const initialForm = () => ({
  zones: [],
  events: [{
    id: uuid(),
    name: '',
    description: '',
    eventType: '',
    zoneIds: {},
    subjects: {},
    everytime: true,
    timeValueStart: '00:00',
    timeValueEnd: '23:59',
    duration: 1
  }],
  image: {},
  dateRange: {
    since: new Date(Date.now() - 60 * 60 * 1000),
    until: new Date(),
  },
})

function formReducer(prevState, action) {
  switch (action.type) {
    case 'reset': {
      return initialForm(action)
    }
    case 'initZones': {
      const { image, zones } = action

      return {
        ...prevState,
        zones: zones.map((z) => ({
          ...z,
          /*vertices: z.vertices.map((v) => ({
            x: (v.x * image.width) / image.originalWidth,
            y: (v.y * image.height) / image.originalHeight,
          })),*/
        })),
        image,
      }
    }
    case 'updateZones': {
      const { zones, image } = action
      const zoneIds = zones.map((z) => z.id)
      const events = prevState.events.filter((e) => zoneIds.includes(e.zoneId))
      return { ...prevState, events, zones, image }
    }
    case 'deleteEvent': {
      return {
        ...prevState,
        events: prevState.events.filter((e) => e.id !== action.event.id),
      }
    }
    case 'addEvent': {
      const event = {
        id: uuid(),
        name: '',
        description: '',
        eventType: '',
        zoneIds: {},
        subjects: {},
        everytime: true,
        timeValueStart: '00:00',
        timeValueEnd: '23:59',
        duration: 1
      }
      return {
        ...prevState,
        events: [event, ...prevState.events],
      }
    }
    default: {
      if (action.event) {
        return {
          ...prevState,
          events: prevState.events.map((e) => {
            if (e.id === action.event.id) {
              return eventReducer(e, action)
            }
            return e
          }),
        }
      }
    }
  }
}

function ScenarioForm({ initialZones = [], requests, camera, cameras, onSubmit, allZones, setAllZones }) {
  const [isLoading, setLoading] = React.useState(false)
  const [canSendForm, setCanSendForm] = React.useState(false)
  const [since, setSince] = React.useState(new Date(Date.now() - 1000 * 60 * 60))
  const [until, setUntil] = React.useState(new Date())
  const [form, dispatch] = React.useReducer(
    formReducer,
    allZones,
    initialForm
  )

  const setDate = (name, value) => {
    if (name === 'since')
      setSince(value)
    if (name === 'until')
      setUntil(value)
  }

  const { zones, events, dateRange } = form

  async function submit(e) {
    try {
      e.preventDefault()
      setLoading(true)
      await onSubmit({
        ...form,
        since: since.getTime(),
        until: until.getTime()
      })
      setLoading(false)
    } catch (err) {
      console.error('Error on submitting scenarios: ', err)
    }
  }

  function updateZones(z, setOpened, setOnloading, i) {
    updateScenarioZones({
      zones: z,
      configId: camera.id,
      image: {
        height: i.height,
        originalHeight: i.originalHeight,
        originalWidth: i.originalWidth,
        width: i.width
      }
    }).then(({ zones: newZones }) => {
      dispatch({
        type: 'updateZones', zones: newZones.map(zone => {
          return {
            ...zone,
            vertices: zone.coordinates.split(';').map((v) => {
              const [x, y] = v.split(',').map((a) => +a)
              return { x, y }
            }).map((v) => ({
              x: (v.x * i.width) / i.originalWidth,
              y: (v.y * i.height) / i.originalHeight,
            }))
          }
        }), image: i
      })

      setOnloading(false)
      setOpened(false)
    })
  }

  function initZones(im, zr) {
    dispatch({
      type: 'initZones', im, zones: zr.map(zone => {
        return {
          ...zone,
          vertices: zone.coordinates.split(';').map((v) => {
            const [x, y] = v.split(',').map((a) => +a)
            return { x, y }
          }).map((v) => ({
            x: (v.x * im.width) / im.originalWidth,
            y: (v.y * im.height) / im.originalHeight,
          }))
        }
      })
    }/*initialZones*/)
  }

  function updateDateRange(dateRange) {
    dispatch({ type: 'updateDateRange', dateRange })
  }

  function reset() {
    dispatch({ type: 'reset' })
  }

  React.useEffect(() => {
    reset()
  }, [camera.id])

  const { t } = useTranslation()

  return (
    <>
      <form onSubmit={submit}>
        <ZoneEditorSection
          camera={camera}
          events={events}
          zones={zones}
          onImageLoad={(image, zones) => {
            initZones(image, zones)
          }}
          onSubmit={updateZones}
        />
        <EventEditorSection
          events={events}
          zones={zones}
          dispatch={dispatch}
          setCanSendForm={setCanSendForm}
          hasTimeRange={false}
        />
        <PageSection
          title="Période"
          icon="/images/calendar.png"
          description={
            <>
              <strong>{t('scenario.definePeriode')} </strong> {t('scenario.definePeriodeOfSearch')}.
            </>
          }
        >
          <SearchDateSection
            until={until}
            since={since}
            setDate={setDate}
          />
        </PageSection>
        <PageSection>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <BigButton
              type="submit"
              leftIcon={<SearchIcon />}
              disabled={!canSendForm || isLoading}
              style={{ width: 'inherit', display: 'flex', marginTop: 0 }}
            >
              {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
              Lancer la recherche scénario
            </BigButton>
          </div>
          {
            events.length > 0 && !canSendForm && (
              <Error>
                {t('scenario.eventNotCorrectlyFilledIn')}
              </Error>
            )
          }
        </PageSection>
      </form>
      <ScenarioHistory requests={requests} cameras={cameras} />
    </>
  )
}

function DateRangeSection({ dateRange, onDateChange }) {
  const { t } = useTranslation()
  return (
    <PageSection
      title="Période"
      icon="/images/calendar.svg"
      description={
        <>
          <strong>{t('scenario.definePeriode')} </strong> {t('scenario.definePeriodeOfSearch')}:
        </>
      }
    >
      <SearchDateSection
        since={dateRange.since}
        until={dateRange.until}
        setDate={onDateChange}
      />
    </PageSection>
  )
}

function ScenarioHistory({ requests, cameras }) {
  const { t } = useTranslation()
  const { data, isLoading, refetch } = requests
  const header = [
    {
      id: 'date',
      name: t('scenario.date'),
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    {
      id: 'name',
      name: t('scenario.name'),
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      }
    },
    {
      id: 'description',
      name: 'Description',
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      }
    },
    {
      id: 'description',
      name: 'Description',
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      }
    },
    {
      id: 'zoneName',
      name: t('scenario.zoneName'),
    },
    {
      id: 'eventType',
      name: t('scenario.eventType'),
      sort: (a, b) => {
        if (a?.scenarioType < b?.scenarioType) return -1
        if (a?.scenarioType > b?.scenarioType) return 1
        return 0
      }
    },
    {
      id: 'tag',
      name: t('scenario.tags'),
    },
    {
      id: 'start',
      name: t('scenario.from'),
      sort: (a, b) => new Date(Number(a?.start)) - new Date(Number(b?.start)),
    },
    {
      id: 'end',
      name: t('scenario.to'),
      sort: (a, b) => new Date(Number(a?.end)) - new Date(Number(b?.end)),
    },
    {
      id: 'camera',
      name: t('scenario.camera'),
      sort: (a, b) => {
        if (a?.camera < b?.camera) return -1
        if (a?.camera > b?.camera) return 1
        return 0
      }
    },
    {
      id: 'status',
      name: t('scenario.status'),
      sort: (a, b) => {
        if (a?.status < b?.status) return -1
        if (a?.status > b?.status) return 1
        return 0
      }
    },
    {
      id: 'result',
      name: t('scenario.results'),
    },
    {
      id: 'action',
      name: t('scenario.action'),
    },
  ]

  const body = data?.scenarios.map((s) => ({
    id: s.id,
    date:
      strftime('%d/%m/%Y', new Date(Number(s.at))) +
      '\n' +
      strftime('%H:%M', new Date(Number(s.at))),
    name: s.name,
    description: s.description,
    zoneName: (
      <ZonePreview
        zones={s.zones}
        configId={s.configId}
        camera={cameras.find(c => s.configId === c.id)}
      />
    ),
    status: <ScenarioProgress requestId={s.id} status={s.status} />,
    camera: cameras.find(c => s.configId === c.id)?.name,
    tag: s.tags.map((tag, i) => (
      <span style={{ whiteSpace: 'nowrap' }} key={i}>
        {subjects()[tag.name].name}
        {
          eventTypes()[s.scenarioType]?.selectType !== 'checkbox' && (
            ' x ' + tag.items
          )
        }
        <br />
      </span>)
    ),
    start:
      strftime('%d/%m/%Y', new Date(Number(s.since))) +
      '\n' +
      strftime('%H:%M', new Date(Number(s.since))),
    end:
      strftime('%d/%m/%Y', new Date(Number(s.until))) +
      '\n' +
      strftime('%H:%M', new Date(Number(s.until))),
    eventType: eventTypes()[s.scenarioType]?.name,
    result: <Link to={`/scenarios/searchs/results/${s.id}`}>{t('scenario.results')}</Link>,
    action: (
      s.status !== 'done' && s.status !== 'canceled' && s.enabled && (
        <CancelAlertModal
          onCancel={async e => {
            await cancelScenariosSearch(s.id)
            await refetch()
          }}
        />
      )
    ),
  }))

  if (!body?.length) return null

  return (
    <TableSection>
      <StyledH3>
        <Icon src="/images/search.svg" />
        Scénarios en recherche récents
      </StyledH3>
      <Table
        style={{ width: '80%', margin: '0 10% 30px' }}
        header={header}
        body={body}
        loading={isLoading}
      />
    </TableSection>
  )
}

export function ZonePreview({ configId, zones, camera }) {
  const [isOpened, setOpened] = React.useState(false)
  const [image, setImage] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  let canceller = axios.CancelToken.source()

  React.useEffect(() => {
    setLoading(true)

    testCameraById(
      camera.id,
      canceller.token
    ).then(data => {
      let oimage = null

      if (data) {
        let img = new Image()
        const ocanvas = document.createElement('canvas')
        const octx = ocanvas.getContext('2d')

        let rwidth = 0
        let rheight = 0

        img.onload = () => {
          rwidth = 720
          rheight = (img.height / img.width) * 720

          ocanvas.width = rwidth
          ocanvas.height = rheight

          octx.drawImage(img, 0, 0, 720, (img.height / img.width) * 720)

          oimage = {
            src: ocanvas.toDataURL('image/jpeg'),
            width: rwidth,
            height: rheight,
            originalWidth: img.width,
            originalHeight: img.height,
          }

          setImage(oimage)
          setLoading(false)
        }

        img.src = 'data:image/jpeg;base64,' + data.img
      }
    })
  }, [camera])

  const parsedZones = React.useMemo(() => {
    if (!image) {
      return []
    }

    return zones.map(zone => {
      const vertices = zone.coordinates.split(';').map((v) => {
        const [x, y] = v.split(',').map((a) => +a)
        return {
          x: (x * image.width) / image.originalWidth,
          y: (y * image.height) / image.originalHeight,
        }
      })

      return { name: zone.name, vertices }
    })
  }, [zones, image])

  function onHover() {
    setOpened(true)
  }

  function onBlur() {
    setOpened(false)
  }

  return (
    <div
      onMouseEnter={onHover}
      onMouseLeave={onBlur}
      style={{
        position: 'relative',
        fontWeight: 'bold',
        textDecoration: 'underline dotted',
        cursor: 'pointer',
      }}
    >
      {
        zones.map((zone, i) => (
          <span style={{ whiteSpace: 'nowrap' }} key={i}>
            {zone.name}<br />
          </span>)
        )
      }
      <AnimatePresence exitBeforeEnter>
        {isOpened && (
          <motion.div
            initial={{
              y: '50%',
              scale: 0.7,
              opacity: 0,
              transformOrigin: 'bottom left',
            }}
            enter={{ y: 0, opacity: 1 }}
            exit={{ y: '50%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              bottom: 30,
              left: 0,
              background: 'white',
            }}
            transition={{
              ease: 'easeOut',
              duration: 0.25,
            }}
          >
            <Preview
              layout={false}
              image={image}
              zones={parsedZones}
              loading={loading}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export function ScenarioProgress({ requestId, status = 'todo' }) {
  const { loading, data } = useSubscription(
    () => fetchScenarioRequest(requestId),
    (status === 'done' || status === 'canceled') ? null : 4000
  )

  const progress = data?.request.progress

  if (status === 'canceled') {
    return 'Annulé'
  }

  if (status === 'done') {
    return '100%'
  }

  if (loading || isNaN(progress)) {
    return <Spinner style={{ display: 'block', margin: 'auto' }} />
  }
  if (Math.ceil(progress) === 100.0) {
    return 'Terminé'
  }
  return Math.ceil(progress) + '%'
}
