import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import AuthContext from '../contexts/AuthContext'

export default () => {
  const [{ loading, authenticated }, { logout }] = useContext(AuthContext)

  const history = useHistory()

  if (loading || !authenticated) {
    history.push('/')
  } else if (logout) {
    logout()
  } else {
    history.goBack()
  }

  return null
}
