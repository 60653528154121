export const signup = {
    confirmPassword: "Confirmation du mot de passe",
    email: "Email",
    firstName: "Prénom",
    lastName: "Nom",
    login: "Connexion",
    password: "Mot de passe",
    signUp: "Inscription"
};

export default signup;