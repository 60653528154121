export const login = {
    'welcome': 'Bienvenue',
    'email': 'Email',
    'password': 'Mot de passe',
    'login': 'Connexion',
    'loginIncorrect': 'Email et/ou mot de passe incorrects',
    'signup': 'Inscription',
    'passwordForgotten': 'Mot de passe oublié',
    'passwordRequired': 'Mot de passe requis',
    'emailRequired': 'Email requis',
    'emailBadFormat': 'Email format incorrect',
    'accountNotAvailable': 'Votre compte est créé mais pas encore actif. Veuillez vous rapprocher d\'un administrateur via ce ',
    'formularErrorLink': 'formulaire de contact',
    'error': 'Une erreur est survenue, réessayez plus tard ou contactez un administrateur via ce',
    'errorMaintenance': 'L\'application est en maintenance, nous faisons notre maximum pour rétablir le service au plus vite.',
};

export default login;