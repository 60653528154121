import React, { useState } from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import * as services from '../services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faStar, faDownload } from '@fortawesome/free-solid-svg-icons'
import { CenterContainer } from '../components/Grid'
import { CF, Row, Hr } from '../components/Grid'
import { Button, SmallButton } from '../components/Button'
import { H1, P, Link } from '../components/Typography'
import SequenceFootages from '../components/SequencesFootages'
import ProgressBar from '../components/ProgressBar'
import { Modal, ModalTitle, ModalBody } from '../components/Modal'
import Pagination from '../components/Pagination'
import Player from '../components/Player'
import { useFetch, useInterval, useSubscription } from '../services/fetch'
import Filters, { useFilters } from '../components/SequencesFilters'
import { putPlateFavorite, putPlateHidden, putAnprResultFalsePositive } from '../services'
import SequencesDetails from '../components/SequencesDetails'
import SequencesHeader from '../components/SequencesHeader'
import strftime from 'strftime'
import { useTranslation } from 'react-i18next'

const ModalOptions = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ModalOption = styled.li`
  display: inline-block;
  margin: 0 10px 0 0;
`

const Section = styled.section`
  max-width: 80%;
  margin: auto;
  text-align: center;
`
const Container = styled(CF)``
const MargedContainer = styled(Container)`
  margin: 15px;
`
const Headings = styled.div`
  margin-bottom: 30px;
`
const ButtonLink = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  color: #74ccf8;
  text-decoration: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`

const BackButtonContainer = styled(CenterContainer)`
  width: 100%;
  margin-bottom: 2rem;
`

const PlateDetectionsContainer = ({
  plate,
  detections,
  onClickThumbnail,
  onClickFavorite,
  onClickHide,
  onClickFalsePositive
}) => {
  const itemsCountPerPage = 16
  const pageRangeDisplayed = 5
  const { t } = useTranslation()

  const [page, setPage] = useState(1)
  const offset = (page - 1) * itemsCountPerPage

  const filteredFootages = detections.slice(offset, offset + itemsCountPerPage)

  return (
    <Container>
      <Row>
        <SequenceFootages
          footages={filteredFootages}
          pauseAtFirstDetection
          getSequenceUrl={(footage) =>
            `/api/plates/request/${footage.requestId}/${footage.trackId}/sequence`
          }
          onFootageClick={onClickThumbnail}
          onFootageAddToFavorite={onClickFavorite}
          onFootageHide={onClickHide}
          onFootageDeclareFalsePositive={onClickFalsePositive}
          renderNoResult={() => null}
        />
      </Row>
      {detections.length > itemsCountPerPage && (
        <Pagination
          activePage={page}
          totalItemsCount={detections.length}
          itemsCountPerPage={itemsCountPerPage}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={setPage}
        />
      )}
      <BackButtonContainer>
        <SmallButton as={RouterLink} to="/vpi">
          {t('alertResultAlert.back')}
        </SmallButton>
      </BackButtonContainer>
    </Container>
  )
}

const StyledP = styled(P)`
  font-size: 1.1rem;
`

const Plates = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { filters, setFilter } = useFilters()
  const [currentPlate, setCurrentPlate] = useState(null)
  const [activePlateId, setActivePlateId] = useState(null)

  const [
    canceledFalsePositiveTrackId,
    setCanceledFalsePositiveTrackId
  ] = useState(null)
  const [
    canceledFalsePositiveRequestId,
    setCanceledFalsePositiveRequestId
  ] = useState(null)
  const closeModalFalsePositive = () => {
    setCanceledFalsePositiveTrackId(null)
    setCanceledFalsePositiveRequestId(null)
  }

  function closeModal() {
    setCurrentPlate(null)
  }

  const requestFetch = useSubscription(
    () => services.fetchVPIRequest({ id }),
    2000
  )

  const request = requestFetch.data?.request || { cameras: '' }

  const detectionFetch = useSubscription(
    () => services.fetchVPIDetections({ id, ...filters }),
    request.status === "done" ? null : 2000
  )

  function refresh() {
    requestFetch.refetch()
    detectionFetch.refetch()
  }

  const progress = request.progress || 0
  const plates = detectionFetch.data?.plates || {}
  const loading = detectionFetch.loading
  const searchPlates = request?.plates?.split(',') || []

  async function toggleFavorite(footage) {
    footage.favorite = !footage.favorite
    await putPlateFavorite(footage)
    detectionFetch.setData({ plates: { ...plates } })
  }

  async function toggleHide(footage) {
    footage.hidden = !footage.hidden
    await putPlateHidden(footage)
    detectionFetch.setData({ plates: { ...plates } })
  }

  async function toggleDeclareFalsePositive(footage) {
    if (!footage.falsePositiveZoneId) {
      footage.falsePositiveZoneId = !footage.falsePositiveZoneId
      await putAnprResultFalsePositive({
        trackId: footage.trackId,
        requestId: footage.requestId,
        action: 'add'
      })
      detectionFetch.setData({ plates: { ...plates } })
    } else {
      setCanceledFalsePositiveTrackId(footage.trackId)
      setCanceledFalsePositiveRequestId(footage.requestId)
    }
  }

  async function removeFalsePositive(action) {
    activePlate.detections = activePlate.detections.map(plate => {
      if (
        plate.trackId === canceledFalsePositiveTrackId
        && plate.requestId === canceledFalsePositiveRequestId
      ) {
        return {
          ...plate,
          falsePositiveZoneId: false
        }
      }

      return plate
    })
    await putAnprResultFalsePositive({
      trackId: canceledFalsePositiveTrackId,
      requestId: canceledFalsePositiveRequestId,
      action
    })
    detectionFetch.setData({ plates: { ...plates } })
    closeModalFalsePositive()
  }

  React.useEffect(() => {
    if (!activePlateId) {
      setActivePlateId(Object.keys(plates)[0])
    }
  }, [activePlateId, plates])

  React.useEffect(() => {
    if (!activePlateId && searchPlates.length > 0) {
      setActivePlateId(searchPlates[0])
    }
  }, [searchPlates])

  const activePlate = plates[activePlateId]
  const stats = activePlate?.stats || {}

  return (
    <div>
      <ProgressBar value={progress} hideAt={100} />
      <SequencesDetails request={request} loading={requestFetch.loading} />
      <Section>
        <MargedContainer>
          <Link to="/vpi">{t('alertResultAlert.LPR')}</Link>
          <span>{' >'} {t('alertResultAlert.result')}</span>
        </MargedContainer>
      </Section>
      <Headings>
        <SequencesHeader stats={stats} editNameValue={request.name} />
        <PlatesTabs
          searchPlates={searchPlates}
          onChange={setActivePlateId}
          value={activePlateId}
        />
        {progress < 100 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledP
              style={{ textAlign: 'center', margin: '0', fontSize: '13px' }}
            >
              <FontAwesomeIcon icon={faSpinner} size="lg" spin />
              <span style={{ marginLeft: 10 }}>
                {t('alertResultAlert.treatmentVideoInProgress')} ({progress}%)
                {' - '}
              </span>
              <ButtonLink onClick={refresh}>
                <FontAwesomeIcon
                  icon={loading ? faSpinner : faDownload}
                  spin={loading}
                  style={{ marginRight: '5px' }}
                />
                {!loading && t('alertResultAlert.refresh')}
              </ButtonLink>
            </StyledP>
          </div>
        )}
      </Headings>
      <Section>
        <Filters params={request} filters={filters} setFilter={setFilter} />
        {!loading && activePlate && (
          <PlateDetectionsContainer
            key={`plates-${activePlate.plate}`}
            plate={activePlate}
            detections={activePlate.detections}
            onClickThumbnail={setCurrentPlate}
            onClickFavorite={toggleFavorite}
            onClickHide={toggleHide}
            onClickFalsePositive={toggleDeclareFalsePositive}
          />
        )}
        {!loading && progress == 100 && !activePlate?.detections.length && (
          <P style={{ textAlign: 'center', margin: '25px 0' }}>
            {t('alertResultAlert.noResultDoNewSearch')}{' '}
            <Link to="/vpi">{t('alertResultAlert.newSearch')}</Link> ?
          </P>
        )}
        {(loading || (progress !== 100 && !activePlate?.detections.length)) && (
          <FontAwesomeIcon icon={faSpinner} size="3x" spin />
        )}
      </Section>
      <Modal
        isOpen={!!currentPlate}
        onCloseClick={closeModal}
        onBackgroundClick={closeModal}
        onEscapeKeydown={closeModal}
        style={{ maxHeight: '90%', overflow: 'hidden' }}
      >
        {currentPlate && (
          <>
            <P style={{ marginBottom: 5 }}>
              Caméra {currentPlate.configName} -
              {strftime('%d/%m/%Y • %H:%M:%S', new Date(Number(currentPlate.at)))} -
              <FontAwesomeIcon icon={faStar} />
              {currentPlate.score > 33.3 &&
                <FontAwesomeIcon icon={faStar} />
              }
              {currentPlate.score > 66.6 &&
                <FontAwesomeIcon icon={faStar} />
              }
            </P>
            <Player
              videos={currentPlate.videos}
              showControls
              pauseAtFirstDetection
            />
          </>
        )}
      </Modal>
      <Modal
        isOpen={
          !!canceledFalsePositiveTrackId && !!canceledFalsePositiveRequestId
        }
        onCloseClick={closeModalFalsePositive}
        onBackgroundClick={closeModalFalsePositive}
        onEscapeKeydown={closeModalFalsePositive}
        style={{ maxHeight: '90%', overflow: 'hidden' }}
      >
        <ModalTitle>{t('alertResultAlert.removeFalsePositive')}</ModalTitle>
        <ModalBody>
          <p>
            {t('alertResultAlert.wouldYouLikeToRemoveThisResultFromTheFalsePositiveList')}?
          </p>
        </ModalBody>
        <ModalOptions>
          <ModalOption>
            <Button onClick={() => removeFalsePositive('remove')}>
              {t('alertResultAlert.uniqueThisResult')}
            </Button>
          </ModalOption>
          <ModalOption>
            <Button onClick={() => removeFalsePositive('removeAll')}>
              {t('alertResultAlert.allFalsePositiveAssociate')}
            </Button>
          </ModalOption>
          <ModalOption>
            <Button onClick={closeModalFalsePositive}>{t('alertResultAlert.close')}</Button>
          </ModalOption>
        </ModalOptions>
      </Modal>
    </div>
  )
}

export default Plates

const PlateList = styled.div`
  display: flex;
  justify-content: center;
`

const Plate = styled.button`
  margin: 10px;
  padding: 8px 10px;
  background: white;
  cursor: pointer;
  border: 1px solid ${({ active }) => (active ? 'red' : 'black')};
  transition: all 150ms;
  &:hover {
    border-color: rgba(255, 0, 0, 0.6);
    color: rgba(255, 0, 0, 0.6);
  }
  border-radius: 4px;
  color: ${({ active }) => (active ? 'red' : 'black')};
`

function PlatesTabs({ value, onChange, searchPlates }) {
  return (
    <PlateList>
      {searchPlates.map((plate) => (
        <Plate
          key={plate}
          onClick={() => onChange(plate)}
          active={value === plate}
        >
          {plate}
        </Plate>
      ))}
    </PlateList>
  )
}
