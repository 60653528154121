import { useEffect, useReducer, useRef, useMemo } from 'react'

const initialState = {
  loading: false,
  data: null,
  error: null,
}

function fetchReducer(prevState, action) {
  switch (action.type) {
    case 'fetching':
      return { ...prevState, loading: action.withLoader }
    case 'fetched':
      return { ...prevState, data: action.data, loading: false }
    case 'error':
      return { ...prevState, error: action.error, loading: false }
    default:
      throw new Error('action not allowed')
  }
}

export function useFetch(service, dependencies) {
  const [state, dispatch] = useReducer(fetchReducer, initialState)

  function setData(data) {
    dispatch({ type: 'fetched', data })
  }

  async function refetch({ withLoader = true } = {}) {
    let data
    try {
      dispatch({ type: 'fetching', withLoader })
      data = await service()
      setData(data)
    } catch (error) {
      dispatch({ type: 'error', error })
    }
    return data
  }

  useEffect(() => {
    refetch()
  }, dependencies)

  return { ...state, refetch, setData }
}

export function useSubscription(service, delay) {
  const fetch = useFetch(service, [])
  useInterval(() => fetch.refetch({ withLoader: false }), delay)
  return fetch
}

export function useInterval(callback, delay) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    let id
    // Cancel schedule if no delay is specified.
    if (delay === null) {
      return () => id && clearTimeout(id)
    }

    async function tick() {
      try {
        await savedCallback.current()
      } catch (error) {}

      if (delay !== null) {
        id = setTimeout(tick, delay)
      }
    }
    tick()

    return () => id && clearTimeout(id)
  }, [delay])
}
