import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { theme as DesignSystemTheme } from './components/design-system/Theme'

const theme = {
  ...DesignSystemTheme,
  checkbox: {
    borderColor: '#50C5F3',
    labelColor: '#50C5F3',
    label: {
      color: '#50C5F3'
    }
  }
}

// Don't add external fonts here, use the `fonts.css` file instead
const GlobalStyle = createGlobalStyle`
  :root, body {
    height: 100%;
  }
  body {
    font-size: 0.9375rem;
    line-height: 1.6;
    font-weight: 400;
    font-family: Roboto, "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #222;
  }
  #app {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export {
  theme,
  ThemeProvider,
  GlobalStyle
}
