import axios from 'axios'

export const fetchAllSectors = params =>
  axios
    .get(`/api/sectors`, { params })
    .then(({ data }) => data)

export const addSector = params =>
  axios
    .post(`/api/sectors`, params)
    .then(({ data }) => data)

export const deleteSector = id =>
  axios
    .delete(`/api/sectors/${id}`, {data: {}})

export const updateSector = (id, params) =>
  axios
    .put(`/api/sectors/${id}`, params)
    .then(({ data }) => data)
