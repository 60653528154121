import React, { createContext, useReducer, useEffect } from 'react'

import * as auth from '../services/auth'
import { fetchUser } from '../services/users'

const initialState = {
  loading: true,
  authenticated: false,
  user: null,
  error: null
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'LOADING':
      return { ...state, loading: true, error: null }
    case 'SET_USER':
      let { user, error } = payload

      return {
        ...state,
        user,
        authenticated: !!user,
        loading: false,
        error
      }
    default:
      throw new Error()
  }
}

const errorFromResponse = res => {
  return ({
    status: res?.status || null,
    message: (res?.data && res?.data.message) || null
  })
}

const context = createContext(initialState);
const { Provider } = context;

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: 'LOADING' })
    fetchUser('me')
      .then(({ user }) => dispatch({
        type: 'SET_USER',
        payload: { user, error: null }
      }))
      .catch(err => {
        const error = errorFromResponse(err.response)

        dispatch({
          type: 'SET_USER', payload: { user: null, error }
        })
      })
  }, [])

  const actions = {
    login: (email, password) => {
      dispatch({ type: 'LOADING' })
      return auth.login(email, password)
        .then(({ user }) => dispatch({ type: 'SET_USER', payload: { user } }))
        .catch(err => {
          const error = errorFromResponse(err.response)

          return dispatch({
            type: 'SET_USER',
            payload: { user: null, error }
          })
        })
    },
    logout: () =>
      auth.logout().then(() => dispatch({ type: 'SET_USER', payload: { user: null, error: null } }))
  }

  return <Provider value={[state, actions]}>{children}</Provider>
}

export default context
