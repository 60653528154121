import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import fr_translation from './fr'
import en_translation from './en'

const langs = {
  fr: fr_translation,
  en: en_translation
}

const options = {
  load: 'languageOnly',
  resources: langs,
  interpolation: {
    escapeValue: false, // react already safes from xss,
  },
  react: {
    useSuspense: false,
  },
  debug: false
}

if (typeof window !== 'undefined') {
  i18n.use(initReactI18next)
}

if (!i18n.isInitialized) {
  i18n.init(options)
}

export default i18n
