import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'
import * as services from '../services'
import SearchContext from '../contexts/SearchContext'
import { PageSection } from '../components/PageSection'
import { SmallButton, BigButton } from '../components/Button'
import { CenterContainer, Columns } from '../components/Grid'
import { H1, Error, Link } from '../components/Typography'
import { SearchIcon, Spinner } from '../components/design-system'
import Table from '../components/Table'
import SearchDateSection from '../components/SearchDateSection'
import ExportsCameraSection from '../components/ExportsCameraSection'
import { useSubscription } from '../services/fetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StyledH1 = styled(H1)`
  color: #000;
  text-transform: none;
  margin-bottom: 0;
`

const CamerasPageSection = styled(PageSection)`
  ${({ displayCameras }) => displayCameras && 'border: none !important;'}
  padding-bottom: ${({ displayCameras }) =>
    displayCameras ? '0' : '2rem'} !important;
`

const NameInputContainer = styled(CenterContainer)`
  width: 32%;
  margin: auto;
  flex-direction: column;
`

const HistoryPageSection = styled(PageSection)`
  text-align: center;

  ${SmallButton} {
    margin-top: 2rem;
  }
`

const Rewind = () => {
  const [{ cameras }, { fetchCameras }] = useContext(SearchContext)

  const [camera, setCamera] = useState(null)
  const [submitError, setSubmitError] = useState(null)
  const [displayCameras, setDisplayCameras] = useState(true)
  const [since, setSince] = useState(new Date(Date.now() - 1000 * 60 * 60))
  const [until, setUntil] = useState(new Date())
  const [tableBody, setTableBody] = useState([])

  useEffect(() => {
    fetchCameras()
  }, [])

  const tableHeader = [
    {
      id: 'date',
      name: 'Date',
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    {
      id: 'camera',
      name: 'Caméra',
      sort: (a, b) => {
        const lengthA = (a?.camera || '').split(',').length
        const lengthB = (b?.camera || '').split(',').length
        return lengthA - lengthB
      },
    },
    {
      id: 'start',
      name: 'Début',
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    {
      id: 'end',
      name: 'Fin',
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    { id: 'link', name: 'Voir' },
  ]

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!camera) {
      setSubmitError(
        'Veuillez choisir une caméra pour la relecture.'
      )
      return
    }

    setSubmitError(null)

    services.addRewind({
      camera,
      since: since.getTime(),
      until: until.getTime(),
      path: `/rewind/play?cameraId=${camera}&startDate=${since.getTime()}&endDate=${until.getTime()}`
    })
      .then(data => {
        let cameraName = "";
        if (data.config_id_list && data.config_id_list.length >= 1) {
          let arrayCameraId;
          arrayCameraId = data.config_id_list

          cameraName = arrayCameraId.map(cameraId => `${cameras.find(c => c.id == cameraId) ? cameras.find(c => c.id == cameraId).name : ''} `)
        } else {
          cameraName = cameras.find(c => c.id === data.config_id) ? cameras.find(c => c.id === e.config_id).name : ''
        }
        setTableBody([
          {
            id: data.id,
            date: strftime('%d/%m/%Y', new Date(Number(data.at))) +
              '\n' +
              strftime('%H:%M', new Date(Number(data.at))),
            camera: cameraName,
            start: strftime('%d/%m/%Y', new Date(Number(data.since))) +
              '\n' +
              strftime('%H:%M', new Date(Number(data.since))),
            end: strftime('%d/%m/%Y', new Date(Number(data.until))) +
              '\n' +
              strftime('%H:%M', new Date(Number(data.until))),
            link: data.path ? <a target={'_blank'} href={data.path}>Voir le replay</a> : <RewindProgress rewindId={data.id} />
          },
          ...tableBody
        ])

        setCamera(null)
        setSince(new Date(Date.now() - 1000 * 60 * 60))
        setUntil(new Date())
      })
      .catch((e) => {
        console.log(e)
        setSubmitError(
          'Problème technique: Erreur API.'
        )
      })
  }

  useEffect(() => {
    services.fetchMyRewinds({ limit: 20 }).then(({ rewinds }) => {
      const body = rewinds.map(e => {
        let cameraName = "";
        if (e.config_id_list && e.config_id_list.length >= 1) {
          let arrayCameraId;
          arrayCameraId = e.config_id_list
          cameraName = arrayCameraId.map(cameraId => `${cameras.find(c => c.id == cameraId) ? cameras.find(c => c.id == cameraId).name : ''} `)
        } else {
          cameraName = cameras.find(c => c.id === e.config_id) ? cameras.find(c => c.id === e.config_id).name : ''
        }
        return {
          id: e.id,
          date:
            strftime('%d/%m/%Y', new Date(Number(e.at))) +
            '\n' +
            strftime('%H:%M', new Date(Number(e.at))),
          start:
            strftime('%d/%m/%Y', new Date(Number(e.since))) +
            '\n' +
            strftime('%H:%M', new Date(Number(e.since))),
          end:
            strftime('%d/%m/%Y', new Date(Number(e.until))) +
            '\n' +
            strftime('%H:%M', new Date(Number(e.until))),
          camera: cameraName,
          link: e.path ? <a target={'_blank'} href={e.path}>Voir le replay</a> : <RewindProgress rewindId={e.id} />
        }
      })

      setTableBody(body)
    })
  }, [cameras])

  const toggleDisplayCameras = () => setDisplayCameras(!displayCameras)

  const setDate = (name, value) => {
    if (name === 'since')
      setSince(value)
    if (name === 'until')
      setUntil(value)
  }

  return (
    <>
      <StyledH1>Relecture</StyledH1>
      <CamerasPageSection
        displayCameras={displayCameras}
        title="Périmètre"
        icon="/images/camera.png"
        description={
          <>
            <strong>Choisissez la/les caméra</strong> concernée par la relecture.
          </>
        }
      >
        <SmallButton type="button" onClick={toggleDisplayCameras} primary>
          {displayCameras ? 'Masquer les caméras' : 'Afficher les caméras'}
        </SmallButton>
      </CamerasPageSection>
      <ExportsCameraSection
        camerasList={cameras}
        sectors={[]}
        selectedCamera={camera && [...camera]}
        setSelectedCamera={v => {
          if (camera) {
            if (camera.findIndex(cameraItem => v === cameraItem) != -1) {
              setCamera([...camera.filter(cameraItem => cameraItem != v)])
            } else {
              setCamera([v, ...camera])
            }
          } else {
            setCamera([v])
          }
        }}
        toggle={displayCameras}
      />
      <PageSection
        title="Période"
        icon="/images/calendar.png"
        description={
          <>
            <strong>Définissez la période</strong> de relecture.
          </>
        }
      >
        <SearchDateSection
          until={until}
          since={since}
          setDate={setDate}
        />
      </PageSection>
      <PageSection>
        <NameInputContainer>
          <form onSubmit={handleSubmit}>
            {submitError && <Error>{submitError}</Error>}
            <BigButton
              onClick={handleSubmit}
              type="submit"
              leftIcon={<SearchIcon />}
            >
              Lancer la relecture
            </BigButton>
          </form>
        </NameInputContainer>
      </PageSection>
      <HistoryPageSection
        title="Historique des relectures"
        icon="/images/search.svg"
      >
        <Table header={tableHeader} body={tableBody} loading={false} />
      </HistoryPageSection>
    </>
  )
}

export default Rewind

export function RewindProgress({ rewindId }) {
  const { data } = useSubscription(
    () => services.fetchMyRewind({ rewindId }),
    4000
  )

  if (data && data.status !== 'done') {
    return <Spinner />
  }

  return (
    <Link to={`/rewind/play?rewindId=${rewindId}`}>
      Voir
    </Link>
  )
}
