import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'
import qs from 'qs'
import * as services from '../services'
import SearchContext from '../contexts/SearchContext'
import { CenterContainer } from '../components/Grid'
import { PageTitle, Error, Link } from '../components/Typography'
import { PageSection } from '../components/PageSection'
import { EditIcon, SearchIcon, Spinner } from '../components/design-system'
import { SmallButton, BigButton } from '../components/Button'
import { TextInput } from '../components/Input'
import Table from '../components/Table'
import SearchDateSection from '../components/SearchDateSection'
import SearchCriteriaSection from '../components/SearchCriteriaSection'
import SearchCamerasSection from '../components/SearchCamerasSection'
import { fetchStandardSearch } from '../services'
import { useSubscription } from '../services/fetch'
import { TooltipCameraList } from '../components/TooltipCameraList'
import { useTranslation } from 'react-i18next'

const NameInputContainer = styled(CenterContainer)`
  width: 32%;
  margin: auto;
  flex-direction: column;
`

const StyledPageSection = styled(PageSection)`
  ${({ displayCameras }) => displayCameras && 'border: none !important;'}
  padding-bottom: ${({ displayCameras }) =>
    displayCameras ? '0' : '2rem'} !important;
`

const HistoryPageSection = styled(PageSection)`
  text-align: center;

  ${SmallButton} {
    margin-top: 2rem;
  }
`

const RealtimeLegend = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 0.7rem;
  background-color: #f61a1a;
  display: inline-block;
`

const Search = ({ history }) => {
  const { t } = useTranslation()
  const [
    { cameras, tags, carMakes, carColors, carModels },
    { fetchCameras, fetchCarMakes, fetchCarModels },
  ] = useContext(SearchContext)

  const [displayCameras, setDisplayCameras] = useState(true)
  const [researchName, setResearchName] = useState('')
  const [descriptionResearchName, setDescriptionResearchName] = useState('')
  const [submitError, setSubmitError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [camerasLoading, setCamerasLoading] = useState(true)
  const [carMakesLoading, setCarMakesLoading] = useState(true)
  const [carModelsLoading, setCarModelsLoading] = useState(false)
  const [tableBody, setTableBody] = useState([])
  const [allSectors, setAllSectors] = useState(null)
  const [searchIsLoading, setSearchIsLoading] = useState(false)
  const [form, setForm] = useState({
    cameras: [],
    tag: 'car',
    // -1h in ms
    since: new Date(Date.now() - 60 * 60 * 1000),
    until: new Date(),
  })

  useEffect(() => {
    fetchCameras().then(({ cameras }) => {
      setForm({
        ...form,
        cameras: [],
      })
      setCamerasLoading(false)
    })
    fetchCarMakes().then(() => {
      setCarMakesLoading(false)
    })
    services.fetchAllSectors().then(({ sectors }) => {
      setAllSectors(sectors)
    })
  }, [])

  const handleSetResearchName = (value) => {
    const regexTest = /(^[a-zA-Z0-9\_\-]+$)|^$/g.test(value)

    if (regexTest) setResearchName(value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    let params = {
      tag: form.tag,
      name: researchName,
      description: descriptionResearchName,
      cameras: form.cameras.join(','),
      since: form.since.getTime(),
      until: form.until.getTime(),
    }
    const vehiculeParams = {
      make: form?.make,
      model: form?.model,
      color: form?.color,
    }
    const personParams = {
      age: form?.age,
      gender: form?.gender,
      topColor: form?.topColor,
      bottomColor: form?.bottomColor,
    }

    if (researchName === '') {
      setSubmitError('Veuillez entrer un nom pour votre recherche.')
      return
    }

    if (form.cameras.length === 0) {
      setSubmitError(
        'Veuillez choisir au moins une caméra pour votre recherche.'
      )
      return
    }

    setSubmitError(null)
    if (form.tag === 'person') {
      params = {
        ...params,
        ...personParams,
      }
    }
    if (form.tag === 'car') {
      params = {
        ...params,
        ...vehiculeParams,
      }
    }

    setSubmitError(null)
    setSearchIsLoading(true)
    services.addStandardSearch(params).then(({ request }) => {
      setSearchIsLoading(false)
      history.push(`/search/${request.id}?${qs.stringify(params)}`)
    })
  }

  const toggleDisplayCameras = () => setDisplayCameras(!displayCameras)

  const selectTag = (e) =>
    setForm({ ...form, tag: e.target.value, make: null, model: null })

  const selectMake = (value) => {
    setForm({ ...form, make: value })
    setCarModelsLoading(true)
    fetchCarModels(value).then(() => {
      setCarModelsLoading(false)
    })
  }

  const selectModel = (value) => setForm({ ...form, model: value })
  const selectColor = (value) => setForm({ ...form, color: value })
  const setDate = (name, value) => setForm({ ...form, [name]: value })

  const tableHeader = [
    {
      id: 'date',
      name: t('investigation.date'),
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    {
      id: 'name',
      name: t('investigation.name'),
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      },
    },
    {
      id: 'description',
      name: t('investigation.description'),
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      },
    },
    {
      id: 'start',
      name: t('investigation.start'),
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    {
      id: 'end',
      name: t('investigation.end'),
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    {
      id: 'perimeter',
      name: t('investigation.perimeter'),
      sort: (a, b) => {
        const lengthA = (a?.cameras || '').split(',').length
        const lengthB = (b?.cameras || '').split(',').length
        return lengthA - lengthB
      },
    },
    {
      id: 'type',
      name: t('investigation.type'),
      sort: (a, b) => {
        if (a?.searchType < b?.searchType) return -1
        if (a?.searchType > b?.searchType) return 1
        return 0
      },
    },
    {
      id: 'status',
      name: t('investigation.status'),
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      },
    },
    { id: 'results', name: t('investigation.results') },
  ]

  useEffect(() => {
    services.fetchAllStandardSearch({ limit: 100 }).then(({ requests }) => {
      const body = requests.map((e) => {
        let search = ''
        const searchType = tags.filter((tag) => tag.name === e.tag)[0]
          .displayName
        const gender = {
          female: 'Femme',
          male: 'Homme',
          girl: 'Fille',
          boy: 'Garçon',
        }
        const age = {
          adult: 'Adulte (+18)',
          infant: 'Enfant',
        }
        const clothingColor = {
          black: 'Noir',
          blue: 'Bleu',
          gray: 'Gris',
          green: 'Vert',
          red: 'Rouge',
          white: 'Blanc',
          yellow: 'Jaune',
        }

        const getGender = (gender, age) => {
          if (gender === 'female') return age === 'infant' ? 'Fille' : 'Femme'
          return age === 'infant' ? 'Garçon' : 'Homme'
        }

        if (
          e.tag === 'car' ||
          e.tag === 'heavyVehicles' ||
          e.tag === 'twoWheeled'
        ) {
          search +=
            carMakes.filter((brand) => brand.name === e.make)[0]?.displayName ||
            e.make ||
            ''
          search += ' '
          search +=
            carModels.filter((model) => model.name === e.model)[0]
              ?.displayName ||
            e.model ||
            ''
          search += ' '
          search +=
            carColors.filter((color) => color.name === e.color)[0]
              ?.displayName ||
            e.color ||
            ''
        }

        if (e.tag === 'person') {
          search += gender[e?.gender] ? `${getGender(e.gender, e.age)} ` : ''
          search += age[e?.age] ? `${age[e?.age]} ` : ''
          search += clothingColor[e?.top_color]
            ? `Haut ${clothingColor[e?.top_color]}`
            : ''
          search += clothingColor[e?.bottom_color]
            ? ` Bas ${clothingColor[e?.bottom_color]}`
            : ''
        }

        return {
          id: e.id,
          date:
            strftime('%d/%m/%Y', new Date(Number(e.at))) +
            '\n' +
            strftime('%H:%M', new Date(Number(e.at))),
          name: e.name,
          description: e.description,
          start:
            strftime('%d/%m/%Y', new Date(Number(e.since))) +
            '\n' +
            strftime('%H:%M', new Date(Number(e.since))),
          end:
            strftime('%d/%m/%Y', new Date(Number(e.until))) +
            '\n' +
            strftime('%H:%M', new Date(Number(e.until))),
          cameras: e.cameras,
          perimeter: <TooltipCameraList cameraList={e.cameras?.split(',')} />,
          type: t('investigation.' + searchType),
          status: <SearchProgress searchId={e.id} status={e.status} />,
          results: <Link to={`/search/${e.id}`}>{t('investigation.results')}</Link>
        }
      })
      setTableBody(body)
      setLoading(false)
    })
  }, [])

  return (
    <>
      <PageTitle>{t('investigation.newSearch')}</PageTitle>
      <StyledPageSection
        displayCameras={displayCameras}
        title={t('investigation.perimeter')}
        icon="/images/camera.png"
        description={
          <>
            {t('investigation.filterTheCamerasToBeSearchedIfNecessaryGroupSeveralCamerasByAreasViaTheCamerasMenu')}
            <Link to="/cameras">{t('investigation.menuCamera')}</Link>.
            <br />
            <br />
            <RealtimeLegend /> {t('scenario.definitionColorShipRed')}.
          </>
        }
      >
        <SmallButton type="button" onClick={toggleDisplayCameras} primary>
          {displayCameras ? t('investigation.hideCameras') : t('investigation.showCameras')}
        </SmallButton>
      </StyledPageSection>
      <SearchCamerasSection
        tooltip
        hideInfo={true}
        hideControls={true}
        camerasList={cameras.filter(camera => camera.active)}
        loading={camerasLoading}
        sectors={allSectors}
        selectedCameras={form.cameras}
        setSelectedCameras={(v) => setForm({ ...form, cameras: v })}
        toggle={displayCameras}
        showOnRealtimeDetect={true}
      />
      <PageSection
        title={t('investigation.criteria')}
        icon="/images/filters.svg"
        description={
          <>
            {t('investigation.selectTheSearchCriteria')}
          </>
        }
      >
        <SearchCriteriaSection
          selected={form.tag}
          selectTag={selectTag}
          selectModel={selectModel}
          selectColor={selectColor}
          selectMake={selectMake}
          isOnCarMakesLoading={carMakesLoading}
          isOnCarModelsLoading={carModelsLoading}
          isOnCarColorsLoading={false}
          form={form}
          setForm={setForm}
        />
      </PageSection>
      <PageSection
        title={t('investigation.period')}
        icon="/images/calendar.png"
        description={
          <>
            {t('investigation.defineTheSearchPeriod')}
          </>
        }
      >
        <SearchDateSection
          until={form.until}
          since={form.since}
          setDate={setDate}
        />
      </PageSection>
      <PageSection>
        <NameInputContainer>
          <form onSubmit={handleSubmit}>
            <TextInput
              label={t('investigation.nameSearch')}
              icon={EditIcon}
              value={researchName}
              onChange={handleSetResearchName}
            />
            <br />
            <TextInput
              label={t('investigation.descriptionSearch')}
              icon={EditIcon}
              value={descriptionResearchName}
              onChange={setDescriptionResearchName}
            />
            {submitError && <Error>{submitError}</Error>}
            <BigButton
              type="submit"
              leftIcon={<SearchIcon />}
              disabled={!form.cameras.length || !researchName || searchIsLoading}
            >
              {t('investigation.startSearch')}
            </BigButton>
          </form>
        </NameInputContainer>
      </PageSection>
      <HistoryPageSection title={t('investigation.latestSearches')} icon="/images/search.svg">
        {
          !loading && tableBody.length <= 0 ? (
            <div>
              {t('investigation.noResult')}
            </div>
          ) : <Table header={tableHeader} body={tableBody} loading={loading} />
        }
        <SmallButton onClick={() => history.push('/account/history')}>
          {t('investigation.viewFullHistory')}
        </SmallButton>
      </HistoryPageSection>
    </>
  )
}

export default Search

export function SearchProgress({ searchId, status }) {
  const { t } = useTranslation()
  const { loading, data } = useSubscription(
    () => fetchStandardSearch(searchId),
    status === 'done' ? null : 4000
  )
  const progress = data?.request.progress

  if (loading || !progress) {
    return <Spinner />
  }

  if (Math.ceil(progress) === 100.0) {
    return t('investigation.completed')
  }
  return Math.ceil(progress) + '%'
}
