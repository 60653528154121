const alert = {
  action: `Action`,
  alertes: `Alerts`,
  alertsWorkLikeASearchButInRealTime: `Alerts work like a search, but in real time.`,
  all: `All`,
  application: `Application`,
  areYouSureYouWantToDoThis: `Are you sure you want to do this?`,
  cancel: `Cancel`,
  cancelAlert: `Cancel Alert`,
  close: `Close`,
  criteria: `Criteria`,
  doYouReallyWantToCancelThisAlert: `Do you really want to cancel this alert?`,
  enterThePlatesToBeSearchedIfYouOnlyHavePartOfThePlateMakeSureYouPutTheNumbersYouHaveInTheirExactPositionAndPutInThePlaceOfTheOthersForExampleForA7characterFrenchPlateIfYouOnlyHaveTheFirstCharacterAAndTheFourthAndFifthCharacters1And2EnterA12: `Enter the plates to be searched. If you only have part of the plate, make sure you put the numbers you have in their exact position and put "*" "in the place of the others. For example, for a 7-character French plate, if you only have the first character "A" and the fourth and fifth "characters "1" and "2", enter "A**12**".`,
  showCameras: `Display camera`,
  hideCameras: `Hide cameras`,
  latestAlerts: `Latest alerts`,
  launchAlert: `Launch alert`,
  launchedOn: `Launched on`,
  planShutdownOn: `plan the shutdown on`,
  planShutdown: `plan the shutdown`,
  shutdownPlan: `Shutdown plan on`,
  lPR: `LPR`,
  stadard: `Standard`,
  name: `Name`,
  description: `Description`,
  nameSearch: `Name search`,
  noAlerts: `No alerts`,
  notificationFrequency: `Notification frequency`,
  notifications: `Notifications`,
  perimeter: `Perimeter`,
  realtimeDefault: `pre-treatment (default)`,
  redDotsIndicateRealtimeProcessingOnTheCorrespondingCamera: `Red dots indicate pre-treatment processing on the corresponding camera.`,
  results: `Results`,
  selectLPRAlertCameras: `Select LPR alert cameras`,
  selectTheSearchCriteria: `Select the search criteria.`,
  status: `Status`,
  thisActionIsIrreversible: `This action is irreversible.`,
  type: `Type`,
  xxxAlerts: `xxx alerts`,
  xxxCameras: `xxx cameras`,
  youWillBeNotifiedOfEachMatchWithTheSearchCriteriaInTheNavigationBar: `You will be notified of each match with the search criteria in the navigation bar.`,
  descriptionOfSearch: 'Description of the search',
  youHaveReachedTheLimitOfSimultaneousAlertsPleaseDeleteAnAlertOrContactTheAdministratorToContinue: `You have reached the limit of simultaneous alerts. Please delete an alert or contact the administrator to continue.`,
}

export default alert
