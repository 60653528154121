import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon, TriangleRightIcon, TriangleDownIcon } from './Icons'

export const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

export const Li = styled.li``

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const IconWrapper = styled.div`
  vertical-align: middle;
  margin-right: 0.5rem;
  line-height: 0;

  &, ${Icon} {
    width: 1rem;
    height: 1rem;
  }
`

const CollapsedLiLabel = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${IconWrapper} {
    color: ${({ theme, collapsed }) => collapsed ? theme.ul.li.collapsed.color : theme.primaryColor};
    &:hover {
      transition: color 0.25s ease;
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`


const CollapsedLiContent = styled.div`
  margin-left: 1.5rem;
  display: ${({ collapsed }) => collapsed ? 'none' : 'block'};
`

export const CollapsedLi = ({ label, onClick, collapsed: collapsedProp, ...props }) => {
  let [collapsed, setHidden] = useState(collapsedProp)

  useEffect(() => {
    setHidden(collapsedProp)
  }, [collapsedProp])

  return (
    <Li {...props}>
      <CollapsedLiLabel
        onClick={
          e => onClick(e) && setHidden(!collapsed)
        }
        collapsed={collapsed}
      >
        <IconWrapper>
          {collapsed ? <TriangleRightIcon /> : <TriangleDownIcon />}
        </IconWrapper>
        <LabelWrapper>{label}</LabelWrapper>
      </CollapsedLiLabel>
      <CollapsedLiContent collapsed={collapsed}>
        {props.children}
      </CollapsedLiContent>
    </Li>
  )
}

CollapsedLi.propTypes = {
  label: PropTypes.node.isRequired,
}

export const Tree = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul { padding-left: 1rem; }
  > ul { padding-left: 0; }

  li {
    margin: 0;
    padding: 0;
    border-left: 1px solid ${({ theme }) => theme.gray};
    &:last-child { border-left: none; }
    &:before {
      display: inline-block;
      position: relative;
      top: -0.3rem;
      left: 0;
      height: 1rem;
      width: 1rem;
      margin-right: .5rem;
      border-bottom: 1px solid ${({ theme }) => theme.gray};
      content: "";
    }
    &:last-child:before {
      border-left: 1px solid ${({ theme }) => theme.gray};
    }
  }
`
