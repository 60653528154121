import React, { useState } from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import { CF, CenterContainer } from '../components/Grid'
import { Modal, ModalTitle, ModalBody } from '../components/Modal'
import Pagination from '../components/Pagination'
import { Button, SmallButton } from '../components/Button'
import Player from '../components/Player'
import ProgressBar from '../components/ProgressBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { P } from '../components'
import { faSpinner, faDownload, faStar } from '@fortawesome/free-solid-svg-icons'
import * as services from '../services'
import Filters, { useFilters } from '../components/SequencesFilters'
import SequencesDetails from '../components/SequencesDetails'
import SequencesHeader from '../components/SequencesHeader'
import SequenceFootages from '../components/SequencesFootages'
import { Link } from '../components/Typography'
import { useSubscription } from '../services/fetch'
import {
  putStandardSearchFavorite,
  putStandardSearchHide,
  putStandardSearchFalsePositive,
} from '../services'
import strftime from 'strftime'
import { useTranslation } from 'react-i18next'

const Section = styled.section`
  max-width: 80%;
  margin: auto;
  text-align: center;
`

const SequencesContainer = styled.div`
  width: 90%;
  margin: auto;
`

const BackButtonContainer = styled(CenterContainer)`
  width: 100%;
  margin-bottom: 2rem;
`

const ButtonLink = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  color: #74ccf8;
  text-decoration: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`

const StyledP = styled(P)`
  font-size: 1.1rem;
`

const Container = styled(CF)``
const MargedContainer = styled(Container)`
  margin: 15px;
`

const ModalOptions = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ModalOption = styled.li`
  display: inline-block;
  margin: 0 10px 0 0;
`

const fetchDetections = ({
  id,
  offset,
  sortBy,
  sortType,
  cameras,
  filter,
  sector,
}) =>
  axios
    .get(
      `/api/search/${id}/results` +
      `?offset=${offset}` +
      `&sortBy=${sortBy}` +
      `&sortType=${sortType}` +
      (sector ? `&sector=${sector}` : '') +
      (cameras ? `&cameras=${cameras}` : '') +
      `&filter=${filter}`
    )
    .then(({ data }) => data)

const Detections = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { filters, setFilter } = useFilters()

  const [currentSequence, setCurrentSequence] = useState(null)
  const [canceledFalsePositiveId, setCanceledFalsePositiveId] = useState(null)
  const closeModalFalsePositive = () => setCanceledFalsePositiveId(null)

  const closeModal = () => setCurrentSequence(null)

  const requestFetch = useSubscription(() => services.fetchStandardSearch(id), 2000)

  const request = requestFetch.data?.request || { cameras: '', progress: 0 }

  const detectionsFetch = useSubscription(
    () => fetchDetections({
      id,
      offset: ((filters.page || 1) - 1) * 20,
      sortType: filters.sortType,
      sortBy: filters.sortBy,
      cameras: filters.cameras,
      filter: filters.filter,
      sector: filters.sector,
    }),
    request.status === "done" ? null : 2000
  )

  function refresh() {
    requestFetch.refetch()
    detectionsFetch.refetch()
  }

  async function toggleFavorite(footage) {
    footage.favorite = !footage.favorite
    await putStandardSearchFavorite(footage)
    detectionsFetch.setData({ ...detectionsFetch.data })
  }

  async function toggleHide(footage) {
    footage.hidden = !footage.hidden
    await putStandardSearchHide(footage)
    detectionsFetch.setData({ ...detectionsFetch.data })
  }

  async function toggleDeclareFalsePositive(footage) {
    if (!footage.falsePositiveZoneId) {
      footage.falsePositiveZoneId = !footage.falsePositiveZoneId
      await putStandardSearchFalsePositive({ id: footage.id, action: 'add' })
      detectionsFetch.setData({ ...detectionsFetch.data })
    } else {
      setCanceledFalsePositiveId(footage.id)
    }
  }

  async function removeFalsePositive(action) {
    sequences = sequences.map(sequence => {
      if (sequence.id === canceledFalsePositiveId) {
        return {
          ...sequence,
          falsePositiveZoneId: false
        }
      }

      return sequence
    })
    await putStandardSearchFalsePositive({
      id: canceledFalsePositiveId,
      action
    })
    detectionsFetch.setData({ ...detectionsFetch.data })
    closeModalFalsePositive()
  }

  let sequences = detectionsFetch.data?.results || []
  const nbResults = detectionsFetch.data?.nbResults || 0
  const loading = detectionsFetch.loading
  const stats = detectionsFetch.data?.stats || []
  const progress = request.progress || 0

  return (
    <>
      {!loading && (
        <ProgressBar value={Math.ceil(progress)} hideAt={100} />
      )}
      <SequencesDetails request={request} loading={requestFetch.loading} />
      <Section>
        <MargedContainer>
          <Link to="/search">{t('alertResultAlert.search')}</Link>
          <span>{' >'} {t('alertResultAlert.result')}</span>
        </MargedContainer>
      </Section>
      <SequencesHeader stats={stats} editNameValue={request.name} />
      {request.progress < 100 && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledP style={{ textAlign: 'center', margin: '1rem 0' }}>
            <FontAwesomeIcon icon={faSpinner} size="lg" spin />
            <span style={{ marginLeft: 10 }}>
              {t('alertResultAlert.treatmentVideoInProgress')} ({Math.ceil(request.progress)}%)
              {' - '}
            </span>
            <ButtonLink onClick={refresh}>
              <FontAwesomeIcon
                icon={loading ? faSpinner : faDownload}
                spin={loading}
                style={{ marginRight: '5px' }}
              />
              {!loading && t('alertResultAlert.refresh')}
            </ButtonLink>
          </StyledP>
        </div>
      )}
      <SequencesContainer>
        {nbResults > 0 && (
          <Filters params={request} filters={filters} setFilter={setFilter} />
        )}
        <SequenceFootages
          loading={loading}
          footages={sequences}
          onFootageClick={setCurrentSequence}
          onFootageAddToFavorite={toggleFavorite}
          onFootageDeclareFalsePositive={toggleDeclareFalsePositive}
          onFootageHide={toggleHide}
          getSequenceUrl={(footage) =>
            `/api/detections/results/${footage.id}`
          }
          renderNoResult={() => (
            <p style={{ textAlign: 'center' }}>
              {request.progress === 100 ? (
                <>
                  {t('alertResultAlert.noResultDoNewSearch')} {' '}
                  <Link to="/search">{t('alertResultAlert.newSearch')}</Link>
                </>
              ) : (
                <FontAwesomeIcon icon={faSpinner} size="4x" spin />
              )}
            </p>
          )}
        />
      </SequencesContainer>
      {sequences.length > 0 && (
        <Pagination
          activePage={filters.page}
          onChange={setFilter('page')}
          itemsCountPerPage={20}
          totalItemsCount={nbResults}
          pageRangeDisplayed={5}
        />
      )}
      <BackButtonContainer>
        <SmallButton as={RouterLink} to="/search">
          {t('alertResultAlert.back')}
        </SmallButton>
      </BackButtonContainer>
      <Modal
        isOpen={!!currentSequence}
        onCloseClick={closeModal}
        onBackgroundClick={closeModal}
        onEscapeKeydown={closeModal}
        style={{ maxHeight: '90%', overflow: 'hidden' }}
      >
        {currentSequence && (
          <>
            <P style={{ marginBottom: 5 }}>
              {t('alertResultAlert.cameras')} {currentSequence.configName} -
              {strftime('%d/%m/%Y • %H:%M:%S', new Date(Number(currentSequence.at)))} -
              <FontAwesomeIcon icon={faStar} />
              {currentSequence.score > 33.3 &&
                <FontAwesomeIcon icon={faStar} />
              }
              {currentSequence.score > 66.6 &&
                <FontAwesomeIcon icon={faStar} />
              }
              - #{currentSequence.trackId}
            </P>
            <Player videos={currentSequence.videos} showControls />
          </>
        )}
      </Modal>
      <Modal
        isOpen={canceledFalsePositiveId}
        onCloseClick={closeModalFalsePositive}
        onBackgroundClick={closeModalFalsePositive}
        onEscapeKeydown={closeModalFalsePositive}
        style={{ maxHeight: '90%', overflow: 'hidden' }}
      >
        <ModalTitle>{t('alertResultAlert.removeFalsePositive')}</ModalTitle>
        <ModalBody>
          <p>
            {t('alertResultAlert.wouldYouLikeToRemoveThisResultFromTheFalsePositiveList')}
          </p>
        </ModalBody>
        <ModalOptions>
          <ModalOption>
            <Button onClick={() => removeFalsePositive('remove')}>
              {t('alertResultAlert.uniqueThisResult')}
            </Button>
          </ModalOption>
          <ModalOption>
            <Button onClick={() => removeFalsePositive('removeAll')}>
              {t('alertResultAlert.allFalsePositiveAssociate')}
            </Button>
          </ModalOption>
          <ModalOption>
            <Button onClick={closeModalFalsePositive}>{t('alertResultAlert.close')}</Button>
          </ModalOption>
        </ModalOptions>
      </Modal>
    </>
  )
}

export default Detections
