import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import qs from 'qs'

import { TextInput } from '../components/Input'
import { ColoredLink, Error, P } from '../components/Typography'
import { AddCircleFilledIcon } from '../components/design-system'
import { Container, CenterContainer } from '../components/Grid'
import { SmallButton } from './Button'
import { useTranslation } from 'react-i18next'

const PlateInput = styled(TextInput)`
  input {
    font-family: Blinker !important;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: bold;
    min-width: 150px;
    margin-right: 0;
    padding: .5em;
    text-align: center;
    &::placeholder {
      color: #ddd;
    }
  }
`

const PlateInputContainer = styled(CenterContainer)`
  width: 25%;
  margin: auto;
  flex-direction: column;
`

const PlatesContainer = styled(Container)`
  width: 100%;
  text-align: center;
  display: ${({ display }) => display};
  border-top: 1px solid #E5E5E5;
  margin-top: .5rem !important;
  padding-top: 1.5rem !important;
`

const PlateContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 2rem;
`

const Plates = styled.div`
  width: fit-content;
  font-family: Blinker;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  border: 1px solid #000;
  padding: 0 1.5rem;
  border-radius: 5px;
`

const DeletePlate = styled(ColoredLink)`
  color: ${({ theme }) => theme.errorRed};
  font-size: .75rem;
  margin-top: .2rem;
`

const PlateSearch = ({ plates, setPlates, addPlate, newPlate, handleChange, plateError }) => {
  const { t } = useTranslation()
  return (
    <>
      <PlateInputContainer>
        <form onSubmit={addPlate} style={{ display: 'flex' }}>
          <PlateInput
            value={newPlate}
            onChange={handleChange}
            placeholder="*******"
          />
          <SmallButton disabled={newPlate === ''} style={{ marginLeft: 10 }}>
            {t('lPR.add')}
          </SmallButton>
        </form>
      </PlateInputContainer>
      {
        plateError && <Error>{plateError}</Error>
      }
      <PlatesContainer>
        {plates.map((plate, i) => (
          <PlateContainer key={`plate-${plate}-${i}`}>
            <Plates>{plate}</Plates>
            <DeletePlate onClick={() => setPlates(plates.filter(ee => plate !== ee))}>
              {t('lPR.delete')}
            </DeletePlate>
          </PlateContainer>
        ))}
        {plates.length === 0 && (
          <P style={{ color: 'grey' }}>
            {t('lPR.noPlateSelectedTheSearchWillBePerformedOnAllPlates')}
          </P>
        )}
      </PlatesContainer>
    </>
  )
}

export default PlateSearch
