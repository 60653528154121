import { hot } from 'react-hot-loader/root'
import React from 'react'
import 'normalize.css'
import 'leaflet/dist/leaflet.css'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider, GlobalStyle, theme } from './Theme'
import { AuthProvider } from './contexts/AuthContext'
import { EnvProvider } from './contexts/EnvContext'
import NotificationsScenarios from './components/NotificationsScenarios'

import { Footer } from './components'

import Navigation from './modules/Navigation'
import ProtectedRoute from './modules/ProtectedRoute'

import Home from './pages/Home'
import ForgotPassword from './pages/ForgotPassword'
import Login from './pages/Login'
import Logout from './pages/Logout'
import Register from './pages/Register'
import ResetPassword from './pages/ResetPassword'
import AlertResults from './pages/AlertResults'
import VpiAlertResults from './pages/VpiAlertResults'
import Search from './pages/Search'
import Detections from './pages/Detections'
import VPI from './pages/VPI'
import Plates from './pages/Plates'
import Cameras from './pages/Cameras'
import Watch from './pages/Watch'
import Live from './pages/Live'
import Account from './pages/Account'
import Resources from './pages/Resources'
import Alert from './pages/Alert'
import Scenarios from './pages/Scenarios'
import Rewind from './pages/Rewind'
import RewindPlay from './pages/RewindPlay'
import Exports from './pages/Exports'
import Uploads from './pages/Uploads'
import Stats from './pages/Stats'
import Monitoring from './pages/Monitoring'
import ScenariosAlertResults from './pages/ScenariosAlertResults'
import ScenariosSearchResults from './pages/ScenariosSearchResults'

import { ModalProvider } from 'styled-react-modal'
import { SearchProvider } from './contexts/SearchContext'


import { useSSR } from 'react-i18next'
import './i18n'
import { localStorageServices } from './services'

const App = () => {

  const getDefaultLanguage = () => {
    let result = 'en';
    const localStorageLanguage = localStorageServices.getLanguage();
    const navigatorLanguage = window.navigator.language.slice(0, 2);
    if (localStorageLanguage && (localStorageLanguage === 'en' || localStorageLanguage === 'fr')) {
      result = localStorageLanguage
    } else if (navigatorLanguage && (navigatorLanguage === 'en' || navigatorLanguage === 'fr')) {
      result = navigatorLanguage
    }
    return result;
  }

  useSSR(
    window.initialI18nStore,
    getDefaultLanguage()
  )

  return (
    <BrowserRouter>
      <EnvProvider>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <ModalProvider>
              {
                window.location.pathname != "/live" && window.location.pathname != "/rewind/play" && <>
                  <NotificationsScenarios />
                  <Navigation />
                </>
              }
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 0 auto',
                  position: 'relative',
                }}
              >
                <SearchProvider>
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={Register} />
                    <Route
                      exact
                      path="/forgot-password"
                      component={ForgotPassword}
                    />
                    <Route
                      exact
                      path="/reset-password"
                      component={ResetPassword}
                    />
                    <ProtectedRoute exact path="/" component={Home} />
                    <ProtectedRoute
                      path="/account/:resource"
                      component={Account}
                    />
                    <ProtectedRoute exact path="/logout" component={Logout} />
                    <ProtectedRoute
                      exact
                      path="/watch/:resource"
                      component={Watch}
                    />
                    <ProtectedRoute
                      exact
                      path="/live"
                      component={Live}
                    />
                    <ProtectedRoute exact path="/alerts" component={Alert} />
                    <ProtectedRoute
                      exact
                      path="/cameras"
                      role="admin"
                      component={Cameras}
                    />
                    <ProtectedRoute
                      exact
                      path="/resources"
                      role="admin"
                      component={Resources}
                    />
                    <ProtectedRoute exact path="/search" component={Search} />
                    <ProtectedRoute
                      exact
                      path="/search/:id"
                      component={Detections}
                    />
                    <ProtectedRoute
                      exact
                      path="/alert/detection/:id"
                      component={AlertResults}
                    />
                    <ProtectedRoute
                      exact
                      path="/alert/vpi/:id"
                      component={VpiAlertResults}
                    />
                    <ProtectedRoute exact path="/vpi" component={VPI} />
                    <ProtectedRoute path="/vpi/:id" component={Plates} />
                    <ProtectedRoute
                      exact
                      path="/scenarios/:resource"
                      component={Scenarios}
                    />
                    <ProtectedRoute
                      exact
                      path="/scenarios/alerts/results/:id"
                      component={ScenariosAlertResults}
                    />
                    <ProtectedRoute
                      exact
                      path="/scenarios/searchs/results/:id"
                      component={ScenariosSearchResults}
                    />
                    <ProtectedRoute exact path="/rewind" component={Rewind} />
                    <ProtectedRoute
                      path="/rewind/play"
                      component={RewindPlay}
                    />
                    <ProtectedRoute
                      path="/stats"
                      component={Stats}
                    />
                    <ProtectedRoute
                      path="/monitoring"
                      component={Monitoring}
                    />
                    <ProtectedRoute exact path="/exports" component={Exports} />
                    <ProtectedRoute exact path="/uploads" component={Uploads} />
                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                </SearchProvider>
              </div>
              {
                window.location.pathname != "/live" && window.location.pathname != "/rewind/play" && <>
                  <Footer />
                </>
              }
            </ModalProvider>
          </AuthProvider>
        </ThemeProvider>
      </EnvProvider>
    </BrowserRouter>
  )
}

export default hot(App)
