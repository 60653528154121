import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { CenterContainer } from './Grid'
import { TextInput } from './Input'
import { ColoredLink } from './Typography'
import { PendingIcon, CloseCircleFilledIcon } from './design-system'

import * as services from '../services'

const SectorInputContainer = styled(CenterContainer)`
  width: 30%;
  margin: auto;
  flex-direction: column;
`

const SectorsContainer = styled(CenterContainer)`
  padding-top: 1.5rem;
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 1rem;
  min-width: 80vw;
  flex-wrap: wrap;
`

const SectorNameContainer = styled.div`
  position: relative;
  cursor: pointer;
  text-align: center;
  border: 2px solid #000;
  color: #000;
  background-color: transparent;
  border-radius: 5px;
  padding: .2rem .6rem;

  ${({ selected }) => selected && (
    'border-color: #50C5F3;'
    + 'color: #50C5F3;'
  )}
`

const SectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${SectorNameContainer} {
    ${({ hasCameras, selected }) => !selected && !hasCameras && 'border-color: #E5E5E5;'}
  }

  & > *:not(:first-child) {
    font-size: .688rem;
  }
  & > *:nth-child(n+3) {
    text-decoration: underline;
  }
  & > *:first-child {
    ${({ hasCameras, selected }) => !selected && !hasCameras && 'color: #999999;'}
  }
  & > *:nth-child(2) {
    color: #595959;
    margin: .3rem 0;
    ${({ hasCameras, selected }) => !selected && !hasCameras && 'color: #999999;'}
  }
`

const StyledCloseCircleFilledIcon = styled(CloseCircleFilledIcon)`
  width: 1.6rem;
  height: 1.6rem;

  &:hover {
    cursor: pointer;
  }
`

const PendingIconButton = styled(PendingIcon)`
  &:hover {
    cursor: pointer;
  }
`

const SectorCreation = ({
  allSectors,
  newSector,
  setNewSector,
  addButton,
  setSectorToDelete,
  onCreateSector,
  onSubmitSector
}) => {
  const [sectors, setSectors] = useState([])
  const [selectedSector , setSelectedSector] = useState(null)
  const [updateSectorValue, setUpdateSectorValue] = useState('')
  const AddButton = addButton

  useEffect(() => {
    setSectors(allSectors)
  }, [ allSectors ])

  const onUpdateSector = (e, sectorId) => {
    e.preventDefault()
    e.stopPropagation()

    services
      .updateSector(sectorId, { name: updateSectorValue })
      .then(() => {
        setSectors(sectors.map(sector => {
          if (sector.id === sectorId) {
            return {
              ...sector,
              name: updateSectorValue
            }
          }

          return sector
        }))

        setUpdateSectorValue(updateSectorValue)
      })
      .catch(err => {
        console.error(err)
      })
  }

  return (
    <SectorInputContainer>
      <form onSubmit={e => onSubmitSector(e, true)}>
        <TextInput
          label='Créer nouveau secteur'
          disabled={onCreateSector}
          icon={() => <AddButton withCameras={false} />}
          value={newSector}
          onChange={v => setNewSector(v)}
        />
      </form>
      {
        selectedSector && (
          <SectorsContainer>
            <form onSubmit={e => onUpdateSector(e, selectedSector)}>
              <TextInput
                label='Changer le nom du secteur'
                icon={
                  () => (
                    <PendingIconButton
                      onClick={e => onUpdateSector(e, selectedSector)}
                    />
                  )
                }
                value={updateSectorValue}
                onChange={v => setUpdateSectorValue(v)}
              />
            </form>
            <StyledCloseCircleFilledIcon
              color='#F61A1A'
              onClick={() => {
                setSelectedSector(null)
                setUpdateSectorValue('')
              }}
            />
          </SectorsContainer>
        )
      }
      <SectorsContainer>
        {
          sectors &&
          sectors.map((sector, i) => {
            return (
              <SectorContainer
                key={`${sector.name}-${i}`}
                selected={selectedSector === sector.id}
                hasCameras={sector.cameras.length > 0}
                onClick={() => {
                  setUpdateSectorValue(sector.name)
                  setSelectedSector(sector.id)
                }}
              >
                <SectorNameContainer selected={selectedSector === sector.id}>
                  {sector.name}
                </SectorNameContainer>
                <div>
                  {
                    sector?.cameras
                      ? sector?.cameras.length
                      : 0
                  }
                  {
                    sector?.cameras && sector?.cameras.length > 1
                      ? ' caméras'
                      : ' caméra'
                  }
                </div>
                <ColoredLink onClick={() => {
                  setUpdateSectorValue(sector.name)
                  setSelectedSector(sector.id)
                }}>
                  Renommer
                </ColoredLink>
                <ColoredLink
                  color='#F61A1A'
                  onClick={() => setSectorToDelete(sector)}
                >
                  Supprimer
                </ColoredLink>
              </SectorContainer>
            )
          })
        }
      </SectorsContainer>
    </SectorInputContainer>
  )
}

export default SectorCreation
