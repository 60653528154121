import React from 'react'
import styled from 'styled-components'

import WebRTCPlayer from '../components/WebRTCPlayer'

const Container = styled.div`
  display: ${({ active }) => active ? 'flex' : 'none'};
  flex: 1;
  height: 100%;
`

const CamerasContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: #000;
  justify-content: center;
  margin: 0;
  padding: 0;
`

const CameraContainer = styled.div`
  display: flex;
  color: #fff;
  width: ${({ camerasByLine }) => 100 / camerasByLine}%;
`

const Info = styled.div`
  display: flex;
  flex: 1;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-family: Blinker;
  font-size: 1.2rem;
  text-align: center;
`

export default ({
  cameras,
  camerasByLine,
  active,
  setSelectedCameras,
  camerasOrder,
  setCamerasOrder,
  camerasFullWidth,
  setCamerasFullWidth,
  cameraOnModal,
  setCameraOnModal
}) => (
  <Container active={active}>
    <CamerasContainer>
      {
        camerasOrder?.length > 0 ? (
          camerasOrder.map(
            (cid, i) => {
              const camera = cameras.find(c => c.id === cid)

              if (!camera)
                return

              return (
                <CameraContainer
                  camerasByLine={camerasByLine}
                  key={'cameras-container-' + camera.id}
                >
                  <WebRTCPlayer
                    dark
                    camera={camera}
                    uncheckCamera={
                      id => {
                        setSelectedCameras([
                          ...cameras.map(c => c.id).filter(z => z !== id)
                        ])
                        setCamerasOrder([
                          ...camerasOrder.filter(cid => cid !== id)
                        ])
                      }
                    }
                    setCamerasOrder={setCamerasOrder}
                    camerasOrder={camerasOrder}
                    cameraOrderIndex={i}
                    camerasFullWidth={camerasFullWidth}
                    setCamerasFullWidth={setCamerasFullWidth}
                    cameraOnModal={cameraOnModal}
                    setCameraOnModal={setCameraOnModal}
                  />
                </CameraContainer>
              )
            }
          )
        ) : (
          <Info>
            Aucune caméra selectionnée.
            <br />
            Sélectionner une vue ou une caméra dans le menu de gauche.
          </Info>
        )
      }
    </CamerasContainer>
  </Container>
)
