import axios from 'axios'

export const fetchAllVPISearch = () =>
  axios
    .get(`/api/anpr/requests`)
    .then(({ data }) => data)

export const fetchVPIRequest = ({ id }) =>
  axios.get(`/api/anpr/requests/${id}`).then(({ data }) => data)

export const fetchVPIDetections = ({ id, sortType, sortBy, filter, cameras, sector }) =>
  axios
    .get(
      `/api/anpr/requests/${id}/detections?` +
        `sortType=${sortType}` +
        `&sortBy=${sortBy}` +
        `&filter=${filter}` +
        `&sector=${sector}` +
        `&cameras=${cameras}`
    )
    .then(({ data }) => data)

export const addVAPISearch = params =>
  axios
    .post(`/api/anpr/requests`, params)
    .then(({ data }) => data)

export const putPlateFavorite = ({ requestId, trackId, favorite }) =>
  axios
    .put(`/api/anpr/requests/${requestId}/detections/${trackId}/favorite`, { favorite })
    .then(({ data }) => data)

export const putPlateHidden = ({ requestId, trackId, hidden }) =>
  axios
    .put(`/api/anpr/requests/${requestId}/detections/${trackId}/hide`, { hidden })
    .then(({ data }) => data)

export const putAnprResultFalsePositive = ({ trackId, requestId, action }) =>
  axios
    .put(
      `/api/anpr/requests/${requestId}/detections/${trackId}/false-positive`,
      {},
      { params: { action } }
    ).then(({ data }) => data)
