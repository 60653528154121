import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { A, Link } from './CTAs'
import { H4, H5, P } from './Typography'
import { Icon, AngleRightIcon } from './Icons'
import { Container, Columns } from './Grid'
import { Img } from './Media'

const StyledFooter = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.primaryColor};
`

const ImgMesh = styled(Img)`
  height: 176px;
  width: 220px;
  @media (max-width: 768px) {
    position: absolute;
    bottom: 20%;
    right: 0%;
  }
`

const ImgLogo = styled(Img)`
  width: 183px;
  margin-bottom: 1.5rem;
  @media (min-width: 768px) {
    width: 220px;
  }
`

const PaddedContainer = styled(Container)`
  padding: 1.5rem 1.875rem 1.25rem;
  @media (min-width: 768px) {
    padding: 2rem 0;
  }
`

const MargedH4 = styled(H4)`
  color: ${({ theme }) => theme.white};
  margin-bottom: .75rem;
`

const MargedH5 = styled(H5)`
  color: ${({ theme }) => theme.white};
  margin-bottom: .75rem;
`

const MargedP = styled(P)`
  margin-bottom: 1rem;
`

const Ul = styled.ul`
  list-style-type: none;
  padding: 1.5rem 1rem 0 0;
  margin: 0;
  @media (min-width: 1024px) {
    padding-top: 0;
  }
`

const Li = styled.li`
  position: relative;
  margin-bottom: ${({ extraMargin }) => extraMargin ? '1.5' : '.5'}rem;
  padding: 0 0 0 1.5rem;
  color: ${({ theme }) => theme.black};
  font-family: Saira;

  ${Icon} {
    position: absolute;
    top: .125rem;
    left: 0;
    color: ${({ theme }) => theme.white};
    width: 1.125rem;
    height: 1.125rem;
  }
  > span {
    display: block;
    margin-bottom: .5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${A}, ${Link} {
    color: ${({ theme }) => theme.black};
  }
`

Li.propTypes = {
  extraMargin: PropTypes.bool,
  theme: PropTypes.shape({
    black: PropTypes.string,
    white: PropTypes.string,
  }),
}

Li.defaultProps = {
  extraMargin: false,
}

const Copyright = styled.div`
  background-color: ${({ theme }) => theme.black};
  ${P} {
    color: ${({ theme }) => theme.white};
    text-align: center;
    line-height: 3rem;
  }
`
Copyright.propTypes = {
  theme: PropTypes.shape({
    black: PropTypes.string,
    white: PropTypes.string,
  }),
}

export const Footer = ({ app }) => (
  <StyledFooter>
    <PaddedContainer>
      <Columns nb={3.25}>
        <ImgLogo src='/images/footer-logo.svg' alt='logo' />
        <ImgMesh src='/images/footer-mesh.svg' alt='mesh' />
      </Columns>
      <Columns nb={6}>
        <MargedH4>{app === 'neurocop' ? 'NeuroCop' : 'NeurOCR'}, conçu par Neuronalys.</MargedH4>
        <MargedH5>Une société française experte du domaine de l'intelligence Artificielle.</MargedH5>
        <MargedP>
          Notre but : permettre l'optimisation et l'évolution des process métiers grâce à notre gamme logicielle d'IA complète.
          Les solutions Neuronalys sont distribuées sur les secteurs de la sécurité, de l'industrie, de la santé,
          du retail, du secteur public et des technologies de pointe.
        </MargedP>
        <MargedP>
          Parmi ses clients, l'entreprise compte notamment des grands noms de l'Aéronautique et de l'Energie, des universités
          et les forces de l'ordre de différents pays.
        </MargedP>
      </Columns>
      <Columns nb={2.75}>
        <Ul>
          <Li extraMargin>
            <AngleRightIcon />
            <span>NEURONALYS</span>
            <span>
              104 Rue Nationale<br />
              59800 Lille
            </span>
            <span>
              03.74.09.66.64
            </span>
          </Li>
          <Li>
            <AngleRightIcon />
            <A href='https://neuronalys.fr/' target='_blank'>Neuronalys.fr</A>
          </Li>
          <Li extraMargin>
            <AngleRightIcon />
            <A href='https://neuronalys.ai/#contact' target='_blank'>contact@neuronalys.ai</A>
          </Li>
          <Li>
            <AngleRightIcon />
            <Link to='/legal-notices'>Mentions légales</Link>
          </Li>
          <Li>
            <AngleRightIcon />
            <Link to='/cookies'>Gestion des cookies</Link>
          </Li>
        </Ul>
      </Columns>
    </PaddedContainer>
    <Copyright>
      <P>© Neuronalys 2020 - Tous droits réservés</P>
    </Copyright>
  </StyledFooter>
)


Footer.propTypes = {
  app: PropTypes.string,
}

Footer.defaultProps = {
  app: 'neurocop',
}
