export const searchHistory = {
    action: "Action",
    date: "Date",
    displayXxxxLines: "Afficher xxxx lignes",
    lPRSearch: "Recherche VPI",
    name: "Nom",
    perimeter: "Périmètre",
    personalInformation: "Informations personnelles",
    searchByName: "Rechercher par nom",
    searchHistory: "Historique de recherche",
    standardSearch: "Recherche standard",
    status: "Status",
    type: "Type",
    userManagement: "Gestion des utilisateurs"
}

export default searchHistory