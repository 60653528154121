const header = {
  'live': 'Live',
  'replay': 'Relecture',
  'exports': 'Exports',
  'investigations': 'Investigations',
  'lpr': 'VPI',
  'alerts': 'Alertes',
  'scenarios': 'Scénarios',
  'map': 'Carte',
  'stat': 'Statistique',
  'uploads': 'Uploads',
  'cameras': 'Cameras',
  'resources': 'Ressources',
  'monitoring': 'Monitoring',
}

export default header
