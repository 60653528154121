const alert = {
  action: `Action`,
  alertes: `Alertes`,
  alertsWorkLikeASearchButInRealTime: `Les alertes fonctionnent sur le même principe qu’une investigation.`,
  all: `Périmètre entier`,
  application: `Application`,
  areYouSureYouWantToDoThis: `Êtes-vous sûr de vouloir faire ça ?`,
  cancel: `Annuler`,
  cancelAlert: `Annuler l'Alerte`,
  close: `Fermer`,
  criteria: `Critères`,
  doYouReallyWantToCancelThisAlert: `Souhaitez-vous vraiment annuler cette alerte ?`,
  enterThePlatesToBeSearchedIfYouOnlyHavePartOfThePlateMakeSureYouPutTheNumbersYouHaveInTheirExactPositionAndPutInThePlaceOfTheOthersForExampleForA7characterFrenchPlateIfYouOnlyHaveTheFirstCharacterAAndTheFourthAndFifthCharacters1And2EnterA12: `Indiquez les plaques à rechercher. Si vous ne disposez que d’une partie de la plaque, veillez à positionner les numéros dont vous disposez à leur place exacte et inscrivez « * » à la place des autres. Par exemple pour une plaque française à 7 caractères, si vous ne disposez que du premier caractère « A » et des quatrième et cinquième caractères « 1 » et « 2 », renseignez « A**12** ».`,
  showCameras: `Afficher les caméras`,
  hideCameras: `Masquer les caméras`,
  latestAlerts: `Alertes récentes`,
  launchAlert: `Lancer l'alerte`,
  launchedOn: `Lancée le`,
  planShutdownOn: `planifier l'arrêt le`,
  planShutdown: `planifier l'arrêt`,
  shutdownPlan: `Arrêt planifié le`,
  lPR: `VPI`,
  stadard: `Standard`,
  name: `Nom`,
  description: `Description`,
  nameSearch: `Nommer la recherche`,
  noAlerts: `Pas d'alerte`,
  notificationFrequency: `Fréquences des notifications`,
  notifications: `Notifications`,
  perimeter: `Périmètre`,
  realtimeDefault: `En pré-traitement (défaut)`,
  redDotsIndicateRealtimeProcessingOnTheCorrespondingCamera: `Les pastilles rouges indiquent que la caméra est en pré-traitement.`,
  results: `Résultats`,
  selectLPRAlertCameras: `Sélectionner les caméras en alerte VPI`,
  selectTheSearchCriteria: `Indiquez sur quoi porte la recherche.`,
  status: `Status`,
  thisActionIsIrreversible: `Cette action est irréversible.`,
  type: `Type`,
  xxxAlerts: `xxx alertes`,
  xxxCameras: `xxx caméras`,
  youWillBeNotifiedOfEachMatchWithTheSearchCriteriaInTheNavigationBar: `Chaque correspondance avec les critères de recherche vous sera notifié dans la barre de navigation`,
  descriptionOfSearch: 'Description de la recherche',
  youHaveReachedTheLimitOfSimultaneousAlertsPleaseDeleteAnAlertOrContactTheAdministratorToContinue: `Vous avez atteint la limite d'alertes en simultané. Veuillez supprimer une alerte ou contacter l'administrateur pour pouvoir continuer.`,
}

export default alert
