import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { CenterContainer } from '../components/Grid'
import { H1, H3 } from '../components/Typography'
import { useTranslation } from 'react-i18next'

const StyledH1 = styled(H1)`
  margin: 0;
`

const ConfidenceContainer = styled(CenterContainer)`
  flex-direction: row;
  margin-top: 0;
`

const ConfidenceRating = styled.div`
  color: ${({ confidence }) => {
    if (confidence === 'high') return '#92DE42'
    if (confidence === 'medium') return '#FF9F00'
    if (confidence === 'low') return '#F61A1A'
    return '#000'
  }};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.5rem;

  ${H3} {
    color: #000;
  }
`

const StyledH3 = styled(H3)`
  text-align: center;
  margin: 0;
`


const SequencesHeader = ({ editNameValue, stats }) => {
  const { t } = useTranslation()
  return (
    <>
      <StyledH3>{editNameValue}</StyledH3>
      <StyledH1>{t('resultScenario.results')}</StyledH1>
      <Confidence stats={stats} />
    </>
  )
}

export default SequencesHeader

function Confidence({ stats }) {
  return (
    <ConfidenceContainer>
      <ConfidenceRating confidence='high'>
        <div>
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
        </div>
        <H3>{stats.nbDetectsRank3}</H3>
      </ConfidenceRating>
      <ConfidenceRating confidence='medium'>
        <div>
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
        </div>
        <H3>{stats.nbDetectsRank2}</H3>
      </ConfidenceRating>
      <ConfidenceRating confidence='low'>
        <div><FontAwesomeIcon icon={faStar} /></div>
        <H3>{stats.nbDetectsRank1}</H3>
      </ConfidenceRating>
    </ConfidenceContainer>
  )
}
