import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import strftime from 'strftime'

const Input = `
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-color: #fff;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;

  color: inherit;
  font: inherit;
  font-size: 15px;

  height: 38px;
  margin: 0 0 1.5rem 0;
  padding: 6px 10px;

  &[disabled] {
    cursor: default;
  }

  &[data-error] {
    border-color: #F87474;
  }
`

const CheckboxStyle = css`
  box-sizing: border-box;
  display: inline;
  margin-bottom: 1.5rem;
  padding: 0;
`

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: 600;

  &[disabled] {
    cursor: default;
    color: #a4a6aa;
  }
`

export const LabelBody = styled.span`
  display: inline-block;
  font-weight: normal;
  margin-left: 5px;
`

export const TextField = styled.input.attrs(props => ({
  type: "text",
}))`
  ${Input}
  ${({ fullwidth }) => fullwidth && 'width: 100%;'}
`
export const TextArea = styled.textarea.attrs(props => ({
  rows: props.rows || 5,
}))`
  ${Input}
  resize: none;
  height: auto;
  ${({ fullwidth }) => fullwidth && 'width: 100%;'}
`
export const Checkbox = styled.input.attrs(props => ({
  type: 'checkbox',
  ...props,
}))`
  ${CheckboxStyle}
`

export const Radio = styled.input.attrs(props => ({
  type: 'radio',
}))`
  ${CheckboxStyle}
`

export const Select = styled.select`
  ${Input}
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
`

export const DatePicker = styled.input.attrs(props => ({
  type: 'date'
}))`
  ${Input}
`

export const TimePicker = styled.input.attrs(props => ({
  type: 'text'
}))`
  ${Input}
  width: 80px;
  margin-left: 5px;

  &.error {
    color: red
  }
`

export const DateTimePicker = ({ value, max, onChange, ...props }) => {
  const [date, setDate] = useState(strftime('%Y-%m-%d', value))
  const [time, setTime] = useState(strftime('%H:%M', value))
  const [dateFormatError, setDateFormatError] = useState(false)
  const [timeFormatError, setTimeFormatError] = useState(false)

  const onChangeDate = (e) => {
    const value = new Date(e.target.value+'T'+time)

    setDate(e.target.value)
    if (value instanceof Date && !isNaN(value)) {
      setDateFormatError(false)
      onChange(value)
    } else setDateFormatError(false)
  }

  const onChangeTime = (e) => {
    const value = new Date(date+'T'+e.target.value)

    setTime(e.target.value)
    if (value instanceof Date && !isNaN(value)) {
      setTimeFormatError(false)
      onChange(value)
    } else setTimeFormatError(true)
  }

  return (
    <div {...props}>
      <DatePicker
        value={date}
        max={strftime('%Y-%m-%d', max)}
        onChange={onChangeDate} placeholder="JJ/MM/AAAA"
        className={`${dateFormatError ? 'error' : ''}`}
      />
      <TimePicker
        value={time}
        onChange={onChangeTime}
        className={`${timeFormatError ? 'error' : ''}`}
        placeholder="HH:mm"
      />
    </div>
  )
}
