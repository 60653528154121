import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: ${({ active }) => active ? 'flex' : 'none'};
  background-color: #000;
  color: #fff;
  flex: 1;
  height: 100%;
`;

export default ({ active }) => {
  return (
    <Container active={active}>
      Screens
    </Container>
  )
}
