import React from 'react'
import AdminSearchHistory from '../components/AdminSearchHistory'
import AdminAlertsHistory from '../components/AdminAlertsHistory'
import AdminVpiHistory from '../components/AdminVpiHistory'
import AdminScenariosAlertsHistory from '../components/AdminScenariosAlertsHistory'
import AdminScenariosSearchHistory from '../components/AdminScenariosSearchHistory'
import { PageTitle } from '../components/Typography'
import { DiskUsage } from '../components/DiskUsage'
import axios from 'axios'

/*useEffect(() => {
  axios.get('/api/htop')
  .then(({ data }) => {
    console.log(data)
    return (
      <iframe
      src={data}
      >
    </iframe>
    )
  })
}, [])*/

function Resources() {

  axios.get('/api/monitoring/htop')
    .then(({ data }) => {
      console.log(data)
      let ifHtop = document.getElementById("htopIf")
      ifHtop.src = data
      // check if the 2 divs are loaded before...useEffect ?
      //ifHtop.previousSibling.previousSibling.previousSibling.style.backgroundColor = "white"
      //let hideShit = document.getElementById("hideShit")
      //hideShit.style.width = ifHtop.previousSibling.previousSibling.previousSibling.offsetLeft+"px"
      //hideShit.style.top = ifHtop.offsetTop+"px"

      axios.get('/api/monitoring/nvtop')
        .then(({ data }) => {
          console.log(data)
          let nvtopIf = document.getElementById("nvtopIf")
          nvtopIf.src = data
        })
    })

  const styleIfHtop = { float: "left", minHeight: 635, marginTop: -119 }
  const styleNvHtop = { float: "left", minHeight: 635, marginTop: -119 }
  const stylehideShit = { display: "inline-grid", position: "relatuve", width: "100%", height: 118, backgroundColor: "white" }
  const stylehideShitH3 = { textAlign: "center", paddingTop: "20px" }
  const style = { width: '100%', height: 500 }
  const url =
    `http://${window.location.hostname}:3002/dashboard-solo/db/docker-monitoring?refresh=10s&orgId=1&theme=light`
  return (
    <>
      <PageTitle>Ressources</PageTitle>
      <AdminAlertsHistory />
      <AdminSearchHistory />
      <AdminVpiHistory />
      <AdminScenariosSearchHistory />
      {/* <StatsCameras />
      <StatsRecords />
      <StatsNetwork /> */}
      <DiskUsage />
      {/*<iframe
        style={style}
        src={`${url}&panelId=2`}docker-compose -f docker-compose-dev-romain.yml up
        width="450"
        height="200"
        frameBorder="0"
      ></iframe>
      <iframe
        style={style}
        src={`${url}&panelId=1`}
        width="450"
        height="200"
        frameBorder="0"
      ></iframe>
      <iframe
        style={style}
        src={`${url}&panelId=8`}
        width="450"
        height="200"
        frameBorder="0"
      >
      </iframe>*/}
      <div id="flexFrames">
        <div
          id="hideShit"
          style={stylehideShit}
        >
          <h3
            style={stylehideShitH3}
          >
            CPU(s) / GPU(s)
          </h3>
        </div>
        <iframe
          style={styleIfHtop}
          id="htopIf"
          width="50%"
          height="100%"
          frameBorder="0"
          scrolling="no"
        ></iframe>
        <iframe
          style={styleNvHtop}
          id="nvtopIf"
          width="50%"
          height="100%"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </div>
    </>
  )
}

export default Resources
