export const popUpZoneDefinitionForAScenario = {
    addZone: "Ajouter une zone",
    cancel: "Annuler",
    createEditZones: "Créer / Modifier les zones",
    drawThePerimeterOfEachDetectionZoneByPreciselyPositioningThePointsWithTheTool: "Définissez avec précision les périmètres des zones de détection en positionnant les points à l'aide de l'outil.",
    drawZone: "Tracer la zone",
    drawZoneDescription: "Définissez la zone à ajouter, en positionnant les points sur l'image de gauche.",
    moveOnView: "Se déplacer sur la vue",
    resetView: "Réinitialiser la vue",
    saveChanges: "Enregistrer les modifications",
    shortcut: "-",
    shortcutHoldDownCtrl: "Ctrl enfoncé",
    shortcutHome: "Home",
    zoneName: "Nom de la zone",
    zoomIn: "Zoom avant",
    zoomOut: "Zoom arrière",
    validateZone: "Valider la zone",
    noZone: "Aucune zone définie",
    noZoneDescription: "Cliquez sur le bouton ci-dessous pour ajouter une nouvelle zone.",
    validZone: "Validez votre zone en cliquant sur le bouton ci-dessous.",
    shortcut: 'Raccourci',
}
export default popUpZoneDefinitionForAScenario