import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { P } from './Typography'
import { Button, SmallButton } from './Button'
import { Modal, ModalTitle, ModalBody } from './Modal'
import { useTranslation } from 'react-i18next'

const ModalOptions = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ModalOption = styled.li`
  display: inline-block;
  margin: 0 10px 0 0;
`

function CancelAlertButton({ onCancel, ...props }) {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)
  const history = useHistory()
  const location = useLocation()

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  async function cancel() {
    setCancelLoading(true)
    setOpen(false)
    await onCancel()
    setCancelLoading(false)
    history.push(`${location.pathname}`)
  }

  return (
    <>
      <SmallButton
        {...props}
        color="red"
        hoverColor="#e00800"
        onClick={openModal}
        disabled={cancelLoading}
      >
        {t('popUpCancelScenario.cancel')}
      </SmallButton>
      <Modal
        isOpen={isOpen}
        onCloseClick={closeModal}
        onBackgroundClick={closeModal}
        onEscapeKeydown={closeModal}
      >
        <ModalTitle>{t('popUpCancelScenario.areYouSure')}</ModalTitle>
        <ModalBody>
          <P>
            {t('popUpCancelScenario.doYouReallyWantToCancelThisAlert')}
            <br />
            {t('popUpCancelScenario.thisActionIsIrreversible')}
          </P>
          <ModalOptions>
            <ModalOption>
              <Button red spin={cancelLoading} onClick={cancel}>
                {t('popUpCancelScenario.cancelAlert')}
              </Button>
            </ModalOption>
            <ModalOption>
              <Button onClick={closeModal}>{t('popUpCancelScenario.close')}</Button>
            </ModalOption>
          </ModalOptions>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CancelAlertButton
