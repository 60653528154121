import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'
import * as services from '../services'
import SearchContext from '../contexts/SearchContext'
import { PageSection } from '../components/PageSection'
import { SmallButton, BigButton } from '../components/Button'
import { CenterContainer } from '../components/Grid'
import { H1, Error, Link } from '../components/Typography'
import { EditIcon, SearchIcon, Spinner } from '../components/design-system'
import { TextInput } from '../components/Input'
import Table from '../components/Table'
import SearchDateSection from '../components/SearchDateSection'
import SearchCamerasSection from '../components/SearchCamerasSection'
import PlateSearch from '../components/PlateSearch'
import { fetchVPIRequest } from '../services'
import { useSubscription } from '../services/fetch'
import { TooltipCameraList } from '../components/TooltipCameraList'
import { useTranslation } from 'react-i18next'

const StyledH1 = styled(H1)`
  color: #000;
  text-transform: none;
  margin-bottom: 0;
`

/*
 ** Perimeter Section
 */

const PerimeterPageSection = styled(PageSection)`
  ${({ displayCameras }) => displayCameras && 'border: none !important;'}
  padding-bottom: ${({ displayCameras }) =>
    displayCameras ? '0' : '2rem'} !important;
`

const NameInputContainer = styled(CenterContainer)`
  width: 32%;
  margin: auto;
  flex-direction: column;
`

const HistoryPageSection = styled(PageSection)`
  text-align: center;

  ${SmallButton} {
    margin-top: 2rem;
  }
`

const RealtimeLegend = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 0.7rem;
  background-color: #f61a1a;
  display: inline-block;
`

const VPI = ({ history }) => {
  const { t } = useTranslation()
  const [{ cameras }, { fetchCameras }] = useContext(SearchContext)

  const [newPlate, setNewPlate] = useState('')
  const [plates, setPlates] = useState([])
  const [researchName, setResearchName] = useState('')
  const [researchDescription, setResearchDescription] = useState('')
  const [plateError, setPlateError] = useState(null)
  const [camerasLoading, setCamerasLoading] = useState(true)
  const [submitError, setSubmitError] = useState(null)
  const [tableBody, setTableBody] = useState([])
  const [loading, setLoading] = useState(true)
  const [displayCameras, setDisplayCameras] = useState(true)
  const [allSectors, setAllSectors] = useState(null)
  const [form, setForm] = useState({
    cameras: [],
    plates: [],
    since: new Date(Date.now() - 1000 * 60 * 60), // -1h in ms
    until: new Date(),
  })

  useEffect(() => {
    fetchCameras().then(
      ({ cameras }) => {
        setForm({ ...form, cameras: [] })
        setCamerasLoading(false)
      }
    )
    services.fetchAllSectors().then(({ sectors }) => {
      setAllSectors(sectors)
    })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (researchName === '') {
      setSubmitError('Veuillez entrer un nom pour votre recherche.')
      return
    }

    if (form.cameras.length === 0) {
      setSubmitError(
        'Veuillez choisir au moins une caméra pour votre recherche.'
      )
      return
    }

    setSubmitError(null)

    services
      .addVAPISearch({
        ...form,
        name: researchName,
        description: researchDescription,
        cameras: form.cameras.join(','),
        plates: plates.map((p) => p.toUpperCase()).join(','),
        since: form.since.getTime(),
        until: form.until.getTime(),
      })
      .then(({ request }) => {
        history.push(`/vpi/${request.id}`)
      })
  }

  const handleChange = (value) => {
    const regexTest = /(^[a-zA-Z0-9\*]+$)|^$/g.test(value)

    if (regexTest) setNewPlate(value.toUpperCase())
  }

  const toggleDisplayCameras = () => setDisplayCameras(!displayCameras)

  const setDate = (name, value) => setForm({ ...form, [name]: value })

  const addPlate = (event) => {
    event.preventDefault()

    if (newPlate === '') {
      setPlateError("Veuillez entrer un numéro de plaque d'immatriculation.")
      return
    }
    if (plates.filter((plate) => plate === newPlate).length != 0) {
      setPlateError("Numéro de plaque d'immatriculation déjà entré.")
      return
    }
    setPlates([...plates, newPlate])
    setNewPlate('')
    setPlateError(null)
  }

  const handleSetResearchName = (value) => {
    const regexTest = /(^[a-zA-Z0-9\_\-]+$)|^$/g.test(value)

    if (regexTest) setResearchName(value)
  }

  const tableHeader = [
    {
      id: 'date',
      name: t('lPR.date'),
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    {
      id: 'name',
      name: t('lPR.name'),
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      },
    },
    {
      id: 'description',
      name: t('lPR.description'),
      sort: (a, b) => {
        if (a?.description < b?.description) return -1
        if (a?.description > b?.description) return 1
        return 0
      },
    },
    {
      id: 'start',
      name: t('lPR.start'),
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    {
      id: 'end',
      name: t('lPR.end'),
      sort: (a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)),
    },
    {
      id: 'perimeter',
      name: t('lPR.perimeter'),
      sort: (a, b) => {
        const lengthA = (a?.cameras || '').split(',').length
        const lengthB = (b?.cameras || '').split(',').length
        return lengthA - lengthB
      },
    },
    {
      id: 'type',
      name: t('lPR.type'),
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      },
    },
    {
      id: 'status',
      name: t('lPR.status'),
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      },
    },
    { id: 'results', name: t('lPR.results') },
  ]

  useEffect(() => {
    services.fetchAllVPISearch().then(({ requests }) => {
      const body = requests.map((e) => ({
        id: e.id,
        date:
          strftime('%d/%m/%Y', new Date(Number(e.at))) +
          '\n' +
          strftime('%H:%M', new Date(Number(e.at))),
        name: e.name,
        description: e.description,
        start:
          strftime('%d/%m/%Y', new Date(Number(e.since))) +
          '\n' +
          strftime('%H:%M', new Date(Number(e.since))),
        end:
          strftime('%d/%m/%Y', new Date(Number(e.until))) +
          '\n' +
          strftime('%H:%M', new Date(Number(e.until))),
        cameras: e.cameras,
        perimeter: <TooltipCameraList cameraList={e.cameras?.split(',')} />,
        type: 'VPI',
        status: <VpiProgress searchId={e.id} status={e.status} />,
        results: <Link to={`/vpi/${e.id}`}>{t('lPR.results')}</Link>
      }))

      setTableBody(body)
      setLoading(false)
    })
  }, [])

  return (
    <>
      <StyledH1>{t('lPR.newLPRSearch')}</StyledH1>
      <PerimeterPageSection
        displayCameras={displayCameras}
        title={t('lPR.perimeter')}
        icon="/images/camera.png"
        description={
          <>
            {t('lPR.filterTheCamerasToBeSearchedIfNecessaryGroupSeveralCamerasByAreaViaTheCamerasMenu')}
            <Link to="/cameras">{t('lPR.nemuCamera')}.</Link>.
            <br />
            <br />
            <RealtimeLegend />{t('scenario.definitionColorShipRed')}.
          </>
        }
      >
        <SmallButton type="button" onClick={toggleDisplayCameras} primary>
          {displayCameras ? t('lPR.hideCameras') : t('lPR.showCameras')}
        </SmallButton>
      </PerimeterPageSection>
      <SearchCamerasSection
        selectedCameras={form.cameras}
        camerasList={cameras.filter(camera => camera.active)}
        sectors={allSectors}
        loading={camerasLoading}
        setSelectedCameras={(v) => setForm({ ...form, cameras: v })}
        toggle={displayCameras}
        showOnRealtimeVPI={true}
      />
      <PageSection
        title={t('lPR.plates')}
        icon="/images/filters.svg"
        description={
          <>
            {t('lPR.enterThePlatesToBeSearchedIfYouOnlyHavePartOfThePlate')}
          </>
        }
      >
        <PlateSearch
          plates={plates}
          setPlates={setPlates}
          addPlate={addPlate}
          newPlate={newPlate}
          handleChange={handleChange}
          plateError={plateError}
        />
      </PageSection>
      <PageSection
        title={t('lPR.period')}
        icon="/images/calendar.png"
        description={
          <> {t('lPR.defineTheSearchPeriod')}
          </>
        }
      >
        <SearchDateSection
          until={form.until}
          since={form.since}
          setDate={setDate}
        />
      </PageSection>
      <PageSection>
        <NameInputContainer>
          <form onSubmit={handleSubmit}>
            <TextInput
              label={t('lPR.nameSearch')}
              icon={EditIcon}
              value={researchName}
              onChange={handleSetResearchName}
            />
            {submitError && <Error>{submitError}</Error>}
            <br />
            <TextInput
              label={t('lPR.descriptionOfTheSearch')}
              icon={EditIcon}
              value={researchDescription}
              onChange={setResearchDescription}
            />
            <BigButton
              onClick={handleSubmit}
              type="submit"
              leftIcon={<SearchIcon />}
            >
              {t('lPR.startSearch')}
            </BigButton>
          </form>
        </NameInputContainer>
      </PageSection>
      <HistoryPageSection title={t('lPR.searchesInProgress')} icon="/images/search.svg">
        {
          !loading && tableBody.length <= 0 ? (
            <div>{t('lPR.NoResultat')}</div>
          ) : <Table header={tableHeader} body={tableBody} loading={loading} />
        }
        <SmallButton onClick={() => history.push('/account/history')}>
          {t('lPR.viewFullHistory')}
        </SmallButton>
      </HistoryPageSection>
    </>
  )
}

export default VPI

export function VpiProgress({ searchId, status }) {
  const { t } = useTranslation()
  const { loading, data } = useSubscription(
    () => fetchVPIRequest({ id: searchId }),
    status === 'done' ? null : 4000
  )
  const progress = data?.request.progress

  if (loading || !progress) {
    return <Spinner />
  }
  if (Math.ceil(progress) === 100.0) {
    return t('lPR.completed')
  }
  return Math.ceil(progress) + '%'
}
