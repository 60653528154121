import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import AuthContext from '../contexts/AuthContext'
import EnvContext from '../contexts/EnvContext'

import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavbarCollapse,
  NavbarNav,
  NavItem,
  NavLink,
  BurgerMenuIcon,
  SubNav,
  SubNavItem,
  SubNavLink,
  Dropdown,
  Option
} from '../components'

import Login from './Login'
import { fetchAnprAlertsCount, fetchDetectionAlertsCount, fetchScenariosAlertsCount, localStorageServices } from '../services'
import { useSubscription } from '../services/fetch'

import { useTranslation } from 'react-i18next'

const Language = styled.img`
  height: 1.5rem;
  width: auto;
  align-self: center;
  cursor: pointer;
  opacity: ${({ selected }) => selected ? '1' : '0.4'};
  padding: 0 0.2rem;
`

const LanguagesContainer = styled.div`
  display: flex;
  column-gap: .4rem;
  margin-left: auto;
`

const StyledBurgerMenuIcon = styled(BurgerMenuIcon)`
  color: ${({ theme }) => theme.primaryColor};
  width: 58px;
  height: 14px;
`

const Notification = styled.sup`
  background: ${({ theme }) => theme.errorRed};
  border-radius: 50%;
  padding: 3px 8px;
  color: white;
`

export default () => {
  const [{ loading: loadingAuth, authenticated, user }] = useContext(AuthContext)
  const [{ loading: loadingEnv, env }] = useContext(EnvContext)
  const [collapsed, setCollapsed] = useState(true)
  const el = useRef(null)
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language)


  const loading = loadingEnv && loadingAuth

  const handleClick = (e) => {
    if (el.current && el.current.contains(e.target)) setCollapsed(!collapsed)
    else setCollapsed(true)
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, true)
    return () => document.removeEventListener('click', handleClick, true)
  }, [collapsed])

  const changeLang = newLanguage => {
    i18n.changeLanguage(newLanguage, () => {
      setCurrentLanguage(newLanguage)
      localStorageServices.setLanguage(newLanguage)
    })
  }

  return loading || !authenticated ? null : (
    <Navbar>
      <NavbarBrand to="/">
        <img src="/images/neurocop_logo.svg" width="112px" height="40px" />
      </NavbarBrand>
      <NavbarToggler ref={el}>
        <StyledBurgerMenuIcon />
      </NavbarToggler>
      <NavbarCollapse collapsed={collapsed}>
        <NavbarNav>
          {
            env.ACTIVE_WATCH && (
              <NavItem>
                <NavLink to="/watch/views">
                  {t('header.live')}
                </NavLink>
              </NavItem>
            )
          }
          {
            env.ACTIVE_REWIND && (
              <NavItem>
                <NavLink to="/rewind">
                  {t('header.replay')}
                </NavLink>
              </NavItem>
            )
          }
          {
            env.ACTIVE_EXPORTS && (
              <NavItem>
                <NavLink to="/exports">
                  {t('header.exports')}
                </NavLink>
              </NavItem>
            )
          }
          {env.ACTIVE_DETECTOR &&
            <NavItem>
              <NavLink to="/search">
                {t('header.investigations')}
              </NavLink>
            </NavItem>
          }
          {
            env.ACTIVE_VPI && (
              <NavItem>
                <NavLink to="/vpi">
                  {t('header.lpr')}
                </NavLink>
              </NavItem>
            )
          }
          {
            (env.ACTIVE_DETECTOR_ALERTS || env.ACTIVE_VPI_ALERTS) && (
              <NavItem>
                <NavLink to="/alerts">
                  {t('header.alerts')}
                  <AlertsCount type="search-vpi" />
                </NavLink>
              </NavItem>
            )
          }
          {
            (env.ACTIVE_SCENARIOS_SEARCH || env.ACTIVE_SCENARIOS_ALERTS) && (
              <NavItem>
                <NavLink
                  isActive={() => location.pathname.startsWith('/scenarios')}
                  to={
                    env.ACTIVE_SCENARIOS_ALERTS
                      ? '/scenarios/alerts'
                      : '/scenarios/search'
                  }
                >
                  {t('header.scenarios')}
                  <AlertsCount type="scenarios" />
                </NavLink>
              </NavItem>
            )
          }
          {
            env.ACTIVE_MAP && (
              <NavItem>
                <NavLink to="/map">
                  {t('header.map')}
                </NavLink>
              </NavItem>
            )
          }
          {
            env.ACTIVE_STATS && (
              <NavItem>
                <NavLink to="/stats">
                  {t('header.stat')}
                </NavLink>
              </NavItem>
            )
          }
          {env.ACTIVE_UPLOADS && user?.type === 'admin' && (
            <NavItem>
              <NavLink to="/uploads">
                {t('header.uploads')}
              </NavLink>
            </NavItem>
          )}
          {env.ACTIVE_CAMERAS && user?.type === 'admin' && (
            <NavItem>
              <NavLink to="/cameras">
                {t('header.cameras')}
              </NavLink>
            </NavItem>
          )}
          {env.ACTIVE_RESSOURCES && user?.type === 'admin' && (
            <NavItem>
              <NavLink to="/resources">
                {t('header.resources')}
              </NavLink>
            </NavItem>
          )}
          {env.ACTIVE_MONITORING && user?.type === 'admin' && (
            <NavItem>
              <NavLink to="/monitoring">
                {t('header.monitoring')}
              </NavLink>
            </NavItem>
          )}
        </NavbarNav>
        <LanguagesContainer>
          <Dropdown
            togglerStyle={{
              backgroundColor: "transparent",
              color: "white",
              border: "none",
              cursor: "pointer"
            }}
            toggler={
              <img style={{
                width: '25px',
                height: '25px',
              }} src={langToDisplay.find((langItem) => langItem.value === currentLanguage).img} />
            }
          >
            {
              langToDisplay.map((lang, index) => {
                return <Option
                  key={`${lang}-${index}`}
                  value={lang.value}
                  selected={currentLanguage === lang.value}
                  title={lang.availabled ? lang.tooltip : "Not available"}
                  disabled={!lang.availabled}
                  onClick={() => {
                    if (lang.availabled) {
                      changeLang(lang.value);
                      window.location.reload();
                    }
                  }} style={{
                    color: !lang.availabled ? "grey" : "",
                    cursor: lang.availabled ? "pointer" : "not-allowed",
                    display: "flex"
                  }}
                >
                  <img style={{
                    width: '25px',
                    height: '25px',
                  }} src={lang.img} />
                  <div style={{
                    margin: "auto",
                    marginLeft: "1rem",
                  }}>{lang.label}</div>
                </Option>
              })
            }
          </Dropdown>
        </LanguagesContainer>
        <Login />
      </NavbarCollapse>
    </Navbar>
  )
}

function AlertsCount({ type }) {
  const alerts = useSubscription(
    () => Promise.all([
      fetchAnprAlertsCount(),
      fetchDetectionAlertsCount(),
      fetchScenariosAlertsCount()
    ]),
    4000
  )

  let total = 0

  if (type === 'search-vpi' && alerts.data) {
    total = alerts.data[0].count + alerts.data[1].count
  }

  if (type === 'scenarios' && alerts.data) {
    total = alerts.data[2].count
  }

  if (total === 0) {
    return null
  }

  return <Notification>{total}</Notification>
}

// https://www.flaticon.com/fr/packs/countrys-flags
const langToDisplay = [
  {
    label: 'Français',
    img: '../images/fr.png',
    value: 'fr',
    tooltip: 'Français',
    availabled: true
  }, {
    label: 'English',
    img: '../images/en.png',
    value: 'en',
    tooltip: 'English',
    availabled: true
  }, {
    label: 'español',
    img: '../images/es.png',
    value: 'es',
    tooltip: 'español',
    availabled: false
  }, {
    label: 'Italiano',
    img: '../images/it.png',
    value: 'it',
    tooltip: 'Italiano',
    availabled: false
  }
]