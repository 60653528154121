const lPRSearchResult = {
    allSequences: "All sequences",
    back: "Back",
    cameras: "Cameras",
    closePane: "Close pane",
    falsePositives: "False positives",
    favorites: "Favorites",
    filterByCamera: "Filter by camera",
    filterByType: "Filter by type",
    fromXxXxXxToXxXxXx: "From xx/xx/xx to xx/xx/xx",
    hidden: "Hidden",
    highestToLowestScore: "Highest to lowest score",
    lowestToHighestScore: "Lowest to highest score",
    lPRResults: "LPR &gt; Results",
    newestToOldest: "Newest to oldest",
    noResultsFoundPerformANewSearch: "No results found. Perform a new search?",
    oldestToNewest: "Oldest to newest",
    refresh: "Refresh",
    results: "Results",
    sortBy: "Sort by",
    videoProcessingInProgress: "Video processing in progress"
}
export default lPRSearchResult