import styled from 'styled-components'
import PropTypes from 'prop-types'

const GRID_COLUMNS = 12

const CFStyle = `
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`

export const CF = styled.div`
  ${CFStyle}
`

export const Header = styled.header`
  ${CFStyle}
`

export const Section = styled.section`
  ${CFStyle}
`

export const Container = styled.div`
  ${CFStyle}
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
  text-align: ${props => props.textAlign || 'inherit'};
`

Container.propTypes = {
  textAlign: PropTypes.string
}

export const Row = styled.div`
  ${CFStyle}
  ${({ flex }) => flex && 'display: flex;'}
`

Row.propTypes = {
  flex: PropTypes.string
}

export const Columns = styled.div.attrs(props => ({
  nb: props.nb || GRID_COLUMNS,
}))`
  width: 100%;
  float: left;
  box-sizing: border-box;
  text-align: ${props => props.textAlign || 'inherit'};
  @media (min-width: 768px) {
    margin-left: 4%;
    width: ${({ nb }) => ((nb / GRID_COLUMNS)*100) - ((GRID_COLUMNS - nb)/3)}%;
    &:first-child {
      margin-left: 0;
    }
  }
`

Columns.propTypes = {
  nb: PropTypes.number,
  textAlign: PropTypes.string
}

export const Hr = styled.hr`
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  border-width: 0;
  border-top: 1px solid #e1e1e1;
  max-width: 1024px;
  margin: 2rem auto;
  padding: 0 1rem;
  width: 100%;
  @media (min-width: 400px) {
    padding: 0;
  }
`

export const Vr = styled.span`
  width: 0;
  border-left: 1px solid #e1e1e1;
  margin: 1rem;
`
