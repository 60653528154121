import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import strftime from 'strftime'

import {
  AngleDownIcon,
  ClockIcon,
  DateIcon,
  CheckIcon,
  Icon,
} from './design-system'
import { H3 } from './Typography'
import { useTranslation } from 'react-i18next'

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  font-family: 'Saira';
  width: 100%;
  transition: all 150ms ease-in-out;
  box-sizing: border-box;
  height: 50px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.primaryColor};
  }

  &:focus-within {
    border: 2px solid ${({ theme }) => theme.primaryColor};
  }

  ${Icon} {
    position: absolute;
    right: 1rem;
    width: 1.3rem;
    height: 1.3rem;
    z-index: -1;
    color: ${({ theme }) => theme.primaryColor};
  }
`

const StyledSelect = styled.select`
  background-color: transparent;
  border: none;
  padding: 1.1rem;
  padding-right: 2.4rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  color: ${({ hasSelection }) => (hasSelection ? 'black' : '#ccc')};

  option {
    color: black;
  }

  &:focus {
    outline: none;
  }
`

const Label = styled.div`
  position: absolute;
  top: -8px;
  left: 8px;
  padding: 0 0.3rem;
  font-size: 0.7rem;
  color: #999999;
  background-color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ${({ labelOverflow }) => (labelOverflow ? 'ellipsis' : '')};
  max-width: 75%;
`

export const Select = ({
  label,
  children,
  onChange,
  disabled,
  value,
  ...props
}) => {
  return (
    <InputContainer {...props}>
      {label && label !== '' && <Label>{label}</Label>}
      <StyledSelect
        value={value}
        disabled={disabled}
        hasSelection={!!value}
        onChange={(e) => onChange(e.target.value)}
      >
        {children}
      </StyledSelect>
      <AngleDownIcon />
    </InputContainer>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Select.defaultProps = {
  onChange: () => { },
  value: '',
  disabled: false,
}

const StyledInput = styled.input`
  background-color: transparent;
  box-sizing: inherit;
  border: none;
  padding: 0.9rem;
  width: 100%;
  margin: auto;

  &::-webkit-calendar-picker-indicator {
    filter: opacity(0);
    z-index: 1;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button, 
  &::-webkit-outer-spin-button {  

    opacity: 1;

  }
`

const DateTimePickerContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  column-gap: 1.5rem;
  box-sizing: border-box;
  ${StyledInput} {
    cursor: pointer;
  }
`

export const DateTimePicker = ({
  value,
  max,
  onChange,
  disabled,
  ...props
}) => {
  const { t } = useTranslation()
  const [date, setDate] = useState(strftime('%Y-%m-%d', value))
  const [time, setTime] = useState(strftime('%H:%M', value))

  const onChangeDate = (e) => {
    const value = new Date(e.target.value + 'T' + time)

    setDate(e.target.value)
    if (value instanceof Date && !isNaN(value)) {
      // setDateFormatError(false)
      onChange(value)
    }
    // else setDateFormatError(false)
  }

  const onChangeTime = (e) => {
    const value = new Date(date + 'T' + e.target.value)

    setTime(e.target.value)
    if (value instanceof Date && !isNaN(value)) {
      // setTimeFormatError(false)
      onChange(value)
    }
    // else setTimeFormatError(true)
  }

  return (
    <DateTimePickerContainer {...props}>
      <InputContainer>
        <Label>{t('investigation.date')}</Label>
        <StyledInput
          placeholder="dd-mm-yyyy"
          max={strftime('%Y-%m-%d', max)}
          disabled={disabled}
          value={date}
          type="date"
          onChange={onChangeDate}
        />
        <DateIcon />
      </InputContainer>
      <InputContainer {...props}>
        <Label>{t('investigation.time')}</Label>
        <StyledInput
          max={strftime('%H:%M', max)}
          disabled={disabled}
          value={time}
          type="time"
          onChange={onChangeTime}
        />
        <ClockIcon />
      </InputContainer>
    </DateTimePickerContainer>
  )
}

DateTimePicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

DateTimePicker.defaultProps = {
  onChange: () => { },
  value: '',
  disabled: false,
}

const TextInputContainer = styled(InputContainer)`
  ${Icon} {
    width: 1.5rem;
    height: 1.5rem;
    z-index: 1;
  }
`

const StyledTextInput = styled(StyledInput)`
  margin-right: ${({ icon }) => icon ? '2.3rem' : '0'};
  padding: 1.05rem;
  &::placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder {
    color: #999999;
  }
`

export const TextInput = React.forwardRef(
  ({ label, placeholder, value, onChange, disabled, icon, type, min, max, ...props }, ref) => {
    const InputIcon = icon

    return (
      <TextInputContainer {...props}>
        {label && label !== '' && <Label>{label}</Label>}
        <StyledTextInput
          disabled={disabled}
          value={value}
          type={type}
          min={min}
          max={max}
          ref={ref}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
        {icon && <InputIcon />}
      </TextInputContainer>
    )
  }
)

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  rows: PropTypes.string,
}

TextInput.defaultProps = {
  onChange: () => { },
  value: '',
  disabled: false,
  icon: null,
  type: 'text'
}

const TextInputMultilineContainer = styled(TextInputContainer)`
  ${Icon} {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 1;
    top: 0;
    margin-top: 1.05rem;
  }

  ${StyledTextInput} {
    resize: none;
    margin-right: 3.3rem;
  }
`

export const TextInputMultiline = ({
  label,
  placeholder,
  value,
  onChange,
  disabled,
  icon,
  rows,
  maxlength,
  ...props
}) => {
  const InputIcon = icon

  return (
    <TextInputMultilineContainer {...props}>
      {label && label !== '' && <Label>{label}</Label>}
      <StyledTextInput
        disabled={disabled}
        value={value}
        type="textarea"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        as="textarea"
        rows={rows}
        maxLength={maxlength}
      />
      {icon && <InputIcon />}
    </TextInputMultilineContainer>
  )
}

TextInputMultiline.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

TextInputMultiline.defaultProps = {
  onChange: () => { },
  value: '',
  disabled: false,
}

const ValidationTextInputButton = styled.button`
  background-color: ${({ theme }) => theme.primaryColor};
  height: 100%;
  color: #fff;
  border: none;
  position: absolute;
  height: 100%;
  padding: 0 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover};
  }

  h3 {
    margin: auto;
    font-size: 1.125rem;
  }
`

export const ValidationTextInput = ({
  button,
  disabled,
  onChange,
  placeholder,
  valid,
  value,
  onSubmit,
  ...props
}) => {
  return (
    <TextInputContainer {...props}>
      <StyledTextInput
        disabled={disabled}
        value={value}
        type="text"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
      {button && !valid && (
        <ValidationTextInputButton type="submit" onClick={onSubmit}>
          <H3>{button}</H3>
        </ValidationTextInputButton>
      )}
      {valid && <CheckIcon />}
    </TextInputContainer>
  )
}
