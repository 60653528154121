import React, { useState, useRef, useEffect, useContext } from 'react'
import EnvContext from './../contexts/EnvContext'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Link } from '../components/design-system'
import {
  faMapMarkerAlt,
  faTv,
  faStreetView,
  faExpandAlt,
  faCompressAlt,
  faEye,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faSearchPlus,
  faSearchMinus
} from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 5px;
`

const Tools = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Info = styled.div`
  padding: 0 .4rem;
  overflow: hidden;
`

const BaseOption = styled.div`
  font-size: 1.1rem;
  color: ${({ dark }) => dark ? '#fff' : '#000'};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.primaryColor};
  }

  color: ${({ theme, blocked, dark }) => (
    blocked ? theme.primaryColor : (dark ? '#fff' : '#000')
  )};
`

const CameraPosition = styled.span`
  color: #969696;
`

const WatchLink = styled(Link)`
  font-size: 1rem;
  color: #fff;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.primaryColor};
  }
`

const VideoContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`

const StyledVideo = styled.video`
  position: absolute;
  left: 50%;
  top: 50%;
  width: ${({ fullWidth }) => fullWidth ? 'auto' : '100%'};
  height: ${({ fullWidth }) => fullWidth ? 'auto' : '100%'};
  transform: translate(-50%,-50%);
`

const StyledMapContainer = styled(MapContainer)`
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const CameraOrderControl = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.primaryColor};
  margin: 0 .2rem;

  &:hover {
    cursor: pointer;
  }
`

const DateAndTimeContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PTZContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  flex: 1;
  position: relative;
  overflow: hidden;
`

const Hr = styled.div`
  width: 100%;
  height: 0;
`

const PTZControl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.primaryColor};
  }
`

const PTZZoomControl = styled(PTZControl)`
  flex: 5;
  height: 10%;
`

const PTZTopLeftControl = styled(PTZControl)`
  flex: 1;
  height: 10%;
  transform: rotate(-45deg);
`

const PTZTopControl = styled(PTZControl)`
  flex: 1;
  height: 10%;
`

const PTZTopRightControl = styled(PTZControl)`
  flex: 1;
  height: 10%;
  transform: rotate(45deg);
`

const PTZLeftControl = styled(PTZControl)`
  flex: 1;
  height: 70%;
`

const PTZCenterView = styled.div`
  display: flex;
  flex: 11;
  height: ${({ showPTZControls }) => showPTZControls ? '70%' : '100%'};
`

const PTZRightControl = styled(PTZControl)`
  flex: 1;
  height: 70%;
`

const PTZBottomLeftControl = styled(PTZControl)`
  flex: 1;
  flex-wrap: 3;
  height: 10%;
  transform: rotate(45deg);
`

const PTZBottomControl = styled(PTZControl)`
  flex: 11;
  height: 10%;
`

const PTZBottomRightControl = styled(PTZControl)`
  flex: 1;
  height: 10%;
  transform: rotate(-45deg);
`

const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const DateAndTime = () => {
  const [date, setDate] = useState(new Date())

  useInterval(() => setDate(new Date()), 1000)

  return (
    <DateAndTimeContainer>
      {date.toLocaleTimeString() + ' ' + date.toLocaleDateString()}
    </DateAndTimeContainer>
  )
}

const WebRTCPlayer = ({
  camera,
  dark,
  uncheckCamera,
  setCamerasOrder,
  camerasOrder,
  cameraOrderIndex,
  setCamerasFullWidth,
  camerasFullWidth,
  setCameraOnModal,
  hideCheck,
  hideOrderControls,
  hideModalControl
}) => {
  const [displayMap, setDisplayMap] = useState(false)
  const [blockDisplayMap, setBlockDisplayMap] = useState(false)
  const [displayControls, setDisplayControls] = useState(false)
  const [blockDisplayControls, setBlockDisplayControls] = useState(false)

  const [{ env }] = useContext(EnvContext)

  let long = -1
  let lat = -1

  if (!camera)
    return null

  if (
    camera.coordinates
    && camera.coordinates.length > 0
    && camera.coordinates.includes('/')
    && camera.coordinates[camera.coordinates.indexOf('/') + 1]
  ) {
    long = parseFloat(camera.coordinates.split('/')[0])
    lat = parseFloat(camera.coordinates.split('/')[1])
  }

  const swapArrayElements = (arr, indexA, indexB) => {
    const tmp = arr[indexA]

    arr[indexA] = arr[indexB]
    arr[indexB] = tmp

    return arr
  }

  const goFullWidth = (id, go) => {
    if (go) {
      setCamerasFullWidth([
        ...camerasFullWidth,
        id
      ])

      return
    }

    setCamerasFullWidth([
      ...camerasFullWidth.filter(cid => cid !== id)
    ])
  }

  return (
    <Container>
      <Tools>
        {
          !hideOrderControls
          && cameraOrderIndex !== 0
          && (
            <CameraOrderControl
              icon={faArrowLeft}
              onClick={
                () => setCamerasOrder([
                  ...swapArrayElements(
                    camerasOrder,
                    cameraOrderIndex - 1,
                    cameraOrderIndex
                  )
                ])
              }
            />
          )
        }
        <Info>
          {
            hideCheck ? (
              camera.name
            ) : (
              <Checkbox
                labelColor={dark ? '#fff' : '#000'}
                label={camera.name}
                checked={true}
                onChange={() => uncheckCamera(camera.id)}
                spanStyle={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            )
          }

        </Info>
        <Info style={{ padding: '.4rem 0' }}>
          <CameraPosition>
            ({cameraOrderIndex + 1})
          </CameraPosition>
        </Info>
        {
          long !== -1 && lat !== -1 && (
            <Info dark={dark}>
              <BaseOption
                dark={dark}
                blocked={blockDisplayMap || displayMap}
                onMouseEnter={() => setDisplayMap(true)}
                onMouseLeave={() => setDisplayMap(false)}
                onClick={() => setBlockDisplayMap(!blockDisplayMap)}
              >
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </BaseOption>
            </Info>
          )
        }
        <Info>
          <BaseOption
            dark={dark}
            blocked={camerasFullWidth.includes(camera.id)}
          >
            {
              camerasFullWidth.includes(camera.id) ? (
                <FontAwesomeIcon
                  icon={faCompressAlt}
                  onClick={() => goFullWidth(camera.id, false)}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faExpandAlt}
                  onClick={() => goFullWidth(camera.id, true)}
                />
              )
            }
          </BaseOption>
        </Info>
        <Info>
          <WatchLink to={`/watch?cameras=${camera.id}`} target="_blank">
            <BaseOption dark={dark}>
              <FontAwesomeIcon icon={faTv} />
            </BaseOption>
          </WatchLink>
        </Info>
        {
          camera.hasPtz && (
            <Info>
              <BaseOption
                dark={dark}
                blocked={blockDisplayControls || displayControls}
                onMouseEnter={() => setDisplayControls(true)}
                onMouseLeave={() => setDisplayControls(false)}
                onClick={() => setBlockDisplayControls(!blockDisplayControls)}
              >
                <FontAwesomeIcon icon={faStreetView} />
              </BaseOption>
            </Info>
          )
        }
        {
          !hideModalControl && (
            <Info>
              <BaseOption dark={dark}>
                <FontAwesomeIcon
                  icon={faEye}
                  onClick={() => setCameraOnModal(camera)}
                />
              </BaseOption>
            </Info>
          )
        }
        {
          !hideOrderControls
          && cameraOrderIndex !== camerasOrder.length - 1
          && (
            <CameraOrderControl
              icon={faArrowRight}
              onClick={
                () => setCamerasOrder([
                  ...swapArrayElements(
                    camerasOrder,
                    cameraOrderIndex,
                    cameraOrderIndex + 1
                  )
                ])
              }
            />
          )
        }
      </Tools>
      <PTZContainer>
        {
          (blockDisplayControls || displayControls) && (
            <>
              <PTZTopLeftControl>
                <FontAwesomeIcon icon={faArrowUp} />
              </PTZTopLeftControl>
              <PTZZoomControl>
                <FontAwesomeIcon icon={faSearchPlus} />
              </PTZZoomControl>
              <PTZTopControl>
                <FontAwesomeIcon icon={faArrowUp} />
              </PTZTopControl>
              <PTZZoomControl>
                <FontAwesomeIcon icon={faSearchMinus} />
              </PTZZoomControl>
              <PTZTopRightControl>
                <FontAwesomeIcon icon={faArrowUp} />
              </PTZTopRightControl>
              <Hr />
              <PTZLeftControl>
                <FontAwesomeIcon icon={faArrowLeft} />
              </PTZLeftControl>
            </>
          )
        }
        <PTZCenterView showPTZControls={blockDisplayControls || displayControls}>
          <VideoContainer>
            <StyledVideo
              autoPlay
              loop
              muted
              fullWidth={camerasFullWidth.includes(camera.id)}
            >
              <source
                src={`${window.location.protocol}//${window.location.hostname}:${env.NODE_PORT}/thumbnails/${camera.id}.mp4`}
                type="video/mp4"
              >
              </source>
            </StyledVideo>
            {
              (displayMap || blockDisplayMap) && (
                <StyledMapContainer
                  key={`map-${lat}-${long}`}
                  center={[lat, long]}
                  zoom={15}
                  scrollWheelZoom={false}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[lat, long]}>
                    <Popup>{camera?.name}</Popup>
                  </Marker>
                </StyledMapContainer>
              )
            }
          </VideoContainer>
        </PTZCenterView>
        {
          (blockDisplayControls || displayControls) && (
            <>
              <PTZRightControl>
                <FontAwesomeIcon icon={faArrowRight} />
              </PTZRightControl>
              <Hr />
              <PTZBottomLeftControl>
                <FontAwesomeIcon icon={faArrowDown} />
              </PTZBottomLeftControl>
              <PTZBottomControl>
                <FontAwesomeIcon icon={faArrowDown} />
              </PTZBottomControl>
              <PTZBottomRightControl>
                <FontAwesomeIcon icon={faArrowDown} />
              </PTZBottomRightControl>
              <Hr />
            </>
          )
        }
      </PTZContainer>
      <Tools>
        <Info>
          <DateAndTime />
        </Info>
      </Tools>
    </Container>
  )
}

WebRTCPlayer.propTypes = {
  hideCheck: PropTypes.bool,
  dark: PropTypes.bool,
  hideOrderControls: PropTypes.bool,
  hideModalControl: PropTypes.bool
}

WebRTCPlayer.defaultProps = {
  hideCheck: false,
  dark: false,
  hideOrderControls: false,
  hideModalControl: false
}

export default WebRTCPlayer
