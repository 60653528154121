export const signup = {
    confirmPassword: "Confirm password",
    email: "Email",
    firstName: "First name",
    lastName: "Last name",
    login: "Login",
    password: "Password",
    signUp: "Sign up"
};

export default signup;