import React from 'react'
import styled from 'styled-components'

const StyledMeter = styled.div`
  height: 5px;
	position: relative;
	background: transparent;
`
const StyledProgress = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #74ccf8;

  background-image: linear-gradient(
    center bottom,
    rgb(43,194,83) 37%,
    rgb(84,240,84) 69%
  );
  box-shadow:
    inset 0 2px 9px  rgba(255,255,255,0.3),
    inset 0 -2px 6px rgba(0,0,0,0.4);
`

const AnimatedProgress = styled.div`
  animation: move 30s linear infinite;
  content: "";
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, .3) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .3) 50%,
    rgba(255, 255, 255, .3) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;

  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 50px 50px;
    }
  }
`

export default ({ value, hideAt }) => {
  if (value >= hideAt)
    return null
  
  return (
    <StyledMeter>
      {value < hideAt &&
        <StyledProgress style={{ width: `${value}%` }}>
          <AnimatedProgress></AnimatedProgress>
        </StyledProgress>
      }
    </StyledMeter>
  )
}
