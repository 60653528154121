import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Container } from './Grid'
import { H3 } from './Typography'

const StyledContainer = styled(Container)`
  padding: 2.3rem 0 1rem !important;
  max-width: ${({ full }) => (full ? 'none' : '960px')};
  padding-top: ${({ header }) => (header ? '1.5rem' : '2.3rem')} !important;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
`

const StyledH3 = styled(H3)`
  display: flex;
  align-items: center;
`

const Icon = styled.img`
  filter: brightness(0);
  height: 1.4rem;
  margin-right: 0.8rem;
  user-select: none;
`

const Description = styled.div`
  margin-bottom: 1.5rem;
`

export const PageSection = React.forwardRef(
  ({ icon, title, description, children, ...props }, ref) => {
    return (
      <StyledContainer ref={ref} {...props} header={!!title || !!description}>
        {title && icon && (
          <StyledH3>
            <Icon src={icon} />
            {title}
          </StyledH3>
        )}
        {description && <Description>{description}</Description>}
        {children}
      </StyledContainer>
    )
  }
)

PageSection.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.node,
}

export const CriteriaWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  column-gap: 1.5rem;
  margin: auto;
`
