import React, { useContext } from 'react'
import styled from 'styled-components'

import AuthContext from '../contexts/AuthContext'

import { Link, H3 } from '../components'
import { useTranslation } from 'react-i18next'

const StyledVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`

const DashboardContainer = styled.div`
  padding: 0 4%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const StyledH3 = styled(H3)`
  text-align: center;
  color: white;
`

const StyledLink = styled(Link)`
  text-align: center;
`

const Logo = styled.img`
  margin: 0 auto;
  width: 9.375rem;
  filter: invert(100%);
  user-select: none;
`

export default () => {
  const [{ user }, { logout }] = useContext(AuthContext)
  const { t } = useTranslation();

  return (
    <>
      <StyledVideo
        autoPlay
        loop
        muted
        id="background-video"
        poster="/images/bg-poster.png"
      >
        <source
          src="/videos/bg.mp4"
          type="video/mp4"
        />
      </StyledVideo>
      <DashboardContainer>
        <Logo src="/images/neurocop-logo-only.svg" />
        <StyledH3 style={{ margin: '2rem 0' }}>
          {t('home.hello')} {user.firstname}
        </StyledH3>
        <StyledLink to="/logout">{t('home.itSNotMe')}</StyledLink>
      </DashboardContainer>
    </>
  )
}
