import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { TextInput } from '../design-system/Form'
import { SmallButton } from '../Button'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import { useTranslation } from 'react-i18next'

export default function Preview({
  image,
  layout = true,
  zones,
  loading,
  onClick,
}) {
  const { t } = useTranslation()
  const canvasRef = React.useRef()
  const [isEditZoneDisplayed, setEditZoneDisplayed] = React.useState(false)

  function showEditZone() {
    setEditZoneDisplayed(true)
  }

  function hideEditZone() {
    setEditZoneDisplayed(false)
  }

  React.useEffect(() => {
    if (!canvasRef.current) return
    const ctx = canvasRef.current.getContext('2d')
    for (let i = 0; i < zones.length; i++) {
      ctx.fillStyle = '#ef6c0055'
      ctx.strokeStyle = '#ef6c00'
      ctx.lineWidth = 1

      ctx.beginPath()
      for (let j = 0; j < zones[i].vertices.length; j++) {
        const vertex = zones[i].vertices[j]
        if (j === 0) ctx.moveTo(vertex.x, vertex.y)
        else ctx.lineTo(vertex.x, vertex.y)
      }
      ctx.closePath()
      ctx.fill()
      ctx.stroke()

      ctx.fillStyle = '#111d'
      const bary = { x: 0, y: 0 }
      let totalArea = 0
      for (let j = 0, k = 1; j < zones[i].vertices.length; j++, k++) {
        const p1 = zones[i].vertices[j]
        const p2 = zones[i].vertices[k === zones[i].vertices.length ? 0 : k]
        const area = p1.x * p2.y - p2.x * p1.y
        bary.x += (p1.x + p2.x) * area
        bary.y += (p1.y + p2.y) * area
        totalArea += area
      }
      const f = totalArea * 3
      bary.x /= f
      bary.y /= f

      ctx.beginPath()
      ctx.arc(bary.x, bary.y, 10, 0, 2 * Math.PI)
      ctx.fill()
      ctx.fillStyle = '#fff'
      ctx.fillText(i + 1, bary.x - 3, bary.y + 3)
    }
  }, [zones, canvasRef.current])

  if (loading || !image) {
    return (
      <motion.div
        layoutId={layout && 'image'}
        animate={{ background: '#000', width: 600 }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#000',
          width: 600,
          height: 400,
        }}
      >
        <FontAwesomeIcon size="2x" color="white" icon={faSpinner} spin />
      </motion.div>
    )
  }
  return (
    <>
      <div
        onClick={onClick}
        onMouseOver={onClick && (() => showEditZone())}
        onMouseOut={onClick && (() => hideEditZone())}
        style={{
          width: image.width,
          height: image.height,
          position: 'relative',
          flexShrink: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
      >
        <AnimatePresence>
          {isEditZoneDisplayed && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                enter={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  pointerEvents: 'none',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  zIndex: 1,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(0, 0, 0, 0.2)',
                }}
              />
              <SmallButton
                as={motion.button}
                initial={{
                  x: '-50%',
                  y: 30,
                  opacity: 0,
                  scale: 0.6,
                }}
                animate={{
                  y: '-50%',
                  opacity: 1,
                  scale: 1,
                }}
                exit={{
                  y: 30,
                  opacity: 0,
                  scale: 0.6,
                }}
                transition={{ type: 'spring', mass: 0.1, damping: 7 }}
                style={{
                  padding: '10px 50px',
                  background: 'rgba(0, 0, 0, 0.8)',
                  pointerEvents: 'none',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  zIndex: 2,
                }}
              >
                <FontAwesomeIcon icon={faEdit} style={{ marginRight: 10 }} />
                {t('scenario.editeZone')}
              </SmallButton>
            </>
          )}
        </AnimatePresence>
        <motion.img
          layoutId={layout && 'image'}
          animate={{
            background: '#000',
            position: 'absolute',
            width: image.width,
          }}
          src={image.src}
          style={{ width: image.width }}
        />
        <motion.canvas
          ref={canvasRef}
          width={image.width}
          height={image.height}
          style={{
            width: image.width,
            height: image.height,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          layoutId={layout && 'canvas'}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        {zones.map((zone, i) => (
          <div
            key={i}
            style={{
              marginLeft: 5,
              marginRight: 5,
              marginBottom: 10,
              flex: '1 0 25%',
            }}
          >
            <TextInput
              readOnly
              label={`${t('popUpZoneDefinitionForAScenario.zoneName')} ${i + 1}`}
              value={zone.name}
            />
          </div>
        ))}
      </div>
    </>
  )
}
