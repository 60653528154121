const crypto = require('crypto')
const url = require('url')

const rnds8 = new Uint8Array(16)
const rng = () => crypto.randomFillSync(rnds8)

const REGEX = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i
const validate = (uuid) => typeof uuid === 'string' && REGEX.test(uuid)

const byteToHex = []
for (let i = 0; i < 256; ++i) {
  byteToHex.push((i + 0x100).toString(16).substr(1));
}

const stringify = (arr, offset = 0) => {
  const uuid = (
    byteToHex[arr[offset + 0]] +
    byteToHex[arr[offset + 1]] +
    byteToHex[arr[offset + 2]] +
    byteToHex[arr[offset + 3]] +
    '-' +
    byteToHex[arr[offset + 4]] +
    byteToHex[arr[offset + 5]] +
    '-' +
    byteToHex[arr[offset + 6]] +
    byteToHex[arr[offset + 7]] +
    '-' +
    byteToHex[arr[offset + 8]] +
    byteToHex[arr[offset + 9]] +
    '-' +
    byteToHex[arr[offset + 10]] +
    byteToHex[arr[offset + 11]] +
    byteToHex[arr[offset + 12]] +
    byteToHex[arr[offset + 13]] +
    byteToHex[arr[offset + 14]] +
    byteToHex[arr[offset + 15]]
  ).toLowerCase();

  if (!validate(uuid))
    throw TypeError('Invalid UUID')

  return uuid;
}

const uuid = () => {
  const rnds = rng();

  rnds[6] = (rnds[6] & 0x0f) | 0x40;
  rnds[8] = (rnds[8] & 0x3f) | 0x80;

  return stringify(rnds);
}

const base64toBlob = (data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

const sortBy = (key) => {
  return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0)
}

const time_ms = () => {
  return Date.now()
}

const sec2time = timeInSeconds => {
  var pad = function(num, size) { return ('000' + num).slice(size * -1); },
  time = parseFloat(timeInSeconds).toFixed(3),
  hours = Math.floor(time / 60 / 60),
  minutes = Math.floor(time / 60) % 60,
  seconds = Math.floor(time - minutes * 60),
  milliseconds = time.slice(-3);

  return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) + '.' + pad(milliseconds, 3);
}

const updateUrl = (u, query = {}) => {
  const _url = url.parse(u, true)

  for (const q in query)
    _url.query[q] = query[q]
  delete _url.search

  return url.format(_url)
}

const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
)

const makeKey = length => {
  let result = ''
  const characters = (
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  )

  for (let i = 0; i < length; i++ )
    result += characters.charAt(Math.floor(Math.random() * characters.length))

  return result
}

const getAssetsRootURL = (onHttps) => {
  if (process.env.ON_CLOUD === '1')
    return 'http' + (onHttps ? 's' : '') + '://' + process.env.CDN_ASSETS_HOST

  return ''
}

module.exports = {
  base64toBlob,
  getAssetsRootURL,
  makeKey,
  sec2time,
  sleep,
  sortBy,
  time_ms,
  updateUrl,
  uuid,
}
