import header from './header'
import scenario from './scenario'
import alert from './alert'
import login from './login'
import footer from './footer'
import signup from './signup'
import home from './home'
import personalInformations from './personalInformations'
import userManagement from './userManagement'
import searchHistory from './searchHistory'
import investigation from './investigation'
import investigationResult from './investigationResult'
import LPR from './lPR'
import lPRSearchResult from './lPRSearchResult'
import alertResultAlert from './alertResultAlert'
import popUpZoneDefinitionForAScenario from './popUpZoneDefinitionForAScenario'
import resultScenario from './resultScenario'
import popUpResultOfAScenario from './popUpResultOfAScenario'
import popUpCancelScenario from './popUpCancelScenario'
import upload from './upload'

const translation = {
  translation: {
    header: { ...header },
    scenario: { ...scenario },
    alert: { ...alert },
    login: { ...login },
    footer: { ...footer },
    signup: { ...signup },
    home: { ...home },
    personalInformations: { ...personalInformations },
    userManagement: { ...userManagement },
    searchHistory: { ...searchHistory },
    investigation: { ...investigation },
    investigationResult: { ...investigationResult },
    lPR: { ...LPR },
    lPRSearchResult: { ...lPRSearchResult },
    alertResultAlert: { ...alertResultAlert },
    popUpZoneDefinitionForAScenario: { ...popUpZoneDefinitionForAScenario },
    resultScenario: { ...resultScenario },
    popUpResultOfAScenario: { ...popUpResultOfAScenario },
    popUpCancelScenario: { ...popUpCancelScenario },
    upload: { ...upload },
  },
}

export default translation
