import React from 'react'
import styled from 'styled-components'
import strftime from 'strftime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faSpinner,
  faLowVision,
  faBiohazard
} from '@fortawesome/free-solid-svg-icons'
import { PoliceBadgeIcon } from './design-system/Icons'
import Player from './Player'
import { useTranslation } from 'react-i18next'

const StyledContainer = styled.div`
  position: relative;
  margin: 10px;
  cursor: pointer;
  width: 23%;

  @media (max-width: 1280px) {
    width: 31%;
  }

  @media (max-width: 1020px) {
    width: 45%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`

const Rating = styled.div`
  background-color: ${({ confidence }) => {
    if (confidence === 3) return '#92DE42'
    if (confidence === 2) return '#FF9F00'
    return '#F61A1A'
  }};
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  border-radius: 0 0 5px 0;
  display: flex;
  align-item: center;
  padding: 0.4rem;
  column-gap: 0.3rem;
  height: 10px;

  & > * {
    height: 8px;
    width: auto !important;
  }
`

const Id = styled.div`
  background-color: #fff;
  position: absolute;
  top: 0;
  right: -2px;
  border-radius: 0 0 0 5px;
  display: flex;
  align-item: center;
  padding: 0 0.4rem;
  column-gap: 0.3rem;
  font-size: 0.8rem;
  min-height: 22px;

  &:first-child {
    align-self: center;
  }
`

const StyledSequence = styled.div`
  position: relative;
  background: #000;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 200px;
`

const StyledLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  background: #000;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Infos = styled.div`
  display: flex;
`

const Actions = styled.div`
  width: 50%;
  display: flex;
  justify-content: start;
  align-items: center;
`

const Action = styled.button`
  float: left;
  height: auto;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }
`

const Description = styled.div`
  width: 50%;
  float: right;
  font-size: 0.8rem;
  padding: 0.3rem 0;
  text-align: right;
  display: inline-grid;
`

const SequenceDescription = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Pastilla = styled.div`
  position: absolute;
  top: -15px;
  right: -15px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  z-index: 2;
  background: ${({ theme }) => theme.red};
`

function SequenceThumbnail({
  trackId,
  configId,
  configName,
  at,
  confidence,
  videos,
  poster,
  hasSeen,
  pauseAtFirstDetection,
  showControls = false,
  favorite = false,
  hidden = false,
  falsePositive = false,
  onHide = () => null,
  onAddToFavorite = () => null,
  onDeclareFalsePositive = () => null,
  onClick = () => { },
}) {

  const { t } = useTranslation()

  const getConfidenceRating = (confidence) => {
    if (confidence >= 66.66) return 3
    if (confidence >= 33.33 && confidence < 66.66) return 2
    if (confidence < 33.33) return 1
  }

  const addToFavorite = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onAddToFavorite()
  }

  const hide = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onHide()
  }

  const declareFalsePositive = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onDeclareFalsePositive()
  }

  return (
    <StyledContainer onClick={onClick}>
      <StyledSequence>
        <Player
          poster={poster + `?width=720`}
          videos={videos}
          pauseAtFirstDetection={pauseAtFirstDetection}
          showControls={showControls}
        />
      </StyledSequence>
      <Rating confidence={getConfidenceRating(confidence)}>
        {Array(getConfidenceRating(confidence))
          .fill(undefined)
          .map((_, i) => (
            <FontAwesomeIcon key={i} icon={faStar} />
          ))}
      </Rating>
      {trackId && (
        <Id>
          <div>#{trackId}</div>
        </Id>
      )}
      <Infos>
        <Actions>
          <Action onClick={addToFavorite}>
            <PoliceBadgeIcon color={favorite ? '#92DE42' : '#000'} />
          </Action>
          <Action onClick={hide}>
            <FontAwesomeIcon
              icon={faLowVision}
              color={hidden ? '#F61A1A' : '#000'}
            />
          </Action>
          <Action onClick={declareFalsePositive}>
            <FontAwesomeIcon
              icon={faBiohazard}
              color={falsePositive ? '#F61A1A' : '#000'}
            />
          </Action>
        </Actions>
        <Description>
          <SequenceDescription>
            {`${t('alertResultAlert.camera')} ${configName} #${configId}`}
          </SequenceDescription>
          <div>{strftime('%d/%m/%Y • %H:%M:%S', new Date(Number(at)))}</div>
        </Description>
      </Infos>
      {hasSeen === false && <Pastilla />}
    </StyledContainer>
  )
}

export default SequenceThumbnail
