import strftime from 'strftime'
import styled from 'styled-components'
import React from 'react'
import { P } from './Typography'
import { PageSection } from './PageSection'
import Table from './Table'
import { Modal, ModalTitle, ModalBody } from './Modal'
import { Spinner } from './design-system'
import { useFetch } from '../services/fetch'
import * as services from '../services/admin'
import { Button, SmallButton } from './Button'
import { VpiProgress } from '../pages/VPI'

const Link = styled.p`
  font-family: Blinker;
  font-size: 16px;

  color: ${({ theme }) => theme.red};
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  margin: 10px;
  &:focus {
    outline: none;
  }
`

const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  appearance: none;
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  box-sizing: border-box;

  &::after {
    box-sizing: border-box;
    content: '✓';
    text-align: center;
    font-size: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
  }

  &::before {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: white;
    border: 1px solid ${({ theme }) => theme.primaryColor};
    border-radius: 5px;
  }

  &:checked {
    &::before {
      background: ${({ theme }) => theme.primaryColor};
    }
  }
`

function AdminVpiHistory() {
  const [isOpen, setOpen] = React.useState(false)
  const [isOpenUnique, setOpenUnique] = React.useState(null)
  const tableRequest = useFetch(services.fetchAdminProcessingVpiDetections, [])

  const [selection, setSelection] = React.useState({})
  const selectionCount = Object.values(selection).filter((s) => s === true)
    .length

  const selectedRequests = Object.keys(selection).map((id) =>
    tableRequest.data?.detections.find((d) => `${d.id}` === `${id}`)
  )

  const allSelected = selectionCount === tableRequest.data?.detections.length

  function toggleCheckbox(id) {
    setSelection((prevSelection) => ({
      ...prevSelection,
      [id]: !prevSelection[id],
    }))
  }

  function toggleAll() {
    setSelection(
      allSelected
        ? {}
        : Object.fromEntries(
            tableRequest.data?.detections.map((e) => [e.id, true])
          )
    )
  }

  if (tableRequest.loading || !tableRequest.data) {
    return <Spinner />
  }

  const tableHeader = [
    {
      id: 'checkbox',
      name: <Checkbox inline bold checked={allSelected} onChange={toggleAll} />,
    },
    {
      id: 'date',
      name: 'Date',
      sort: (a, b) => new Date(+a?.at) - new Date(+b?.at),
    },
    {
      id: 'name',
      name: 'Nom de la recherche',
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      },
    },
    {
      id: 'user',
      name: 'Utilisateur',
      sort: (a, b) => {
        if (a?.firstname < b?.firstname) return -1
        if (a?.firstname > b?.firstname) return 1
        return 0
      },
    },
    {
      id: 'email',
      name: 'Email',
      sort: (a, b) => {
        if (a?.email < b?.email) return -1
        if (a?.email > b?.email) return 1
        return 0
      },
    },
    {
      id: 'start',
      name: 'Début',
      sort: (a, b) => new Date(+a?.since) - new Date(+b?.since),
    },
    {
      id: 'end',
      name: 'Fin',
      sort: (a, b) => new Date(+a?.until) - new Date(+b?.until),
    },
    {
      id: 'perimeter',
      name: 'Périmètre',
      sort: (a, b) => {
        const lengthA = (a?.cameras || '').split(',').length
        const lengthB = (b?.cameras || '').split(',').length
        return lengthA - lengthB
      },
    },
    {
      id: 'status',
      name: 'Status',
      sort: (a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      },
    },
    {
      id: 'action',
      name: 'Action',
    },
  ]

  const tableBody = tableRequest.data.detections.map((e) => {
    const numCams = (e.cameras || '').split(',').length
    return {
      ...e,
      checkbox: (
        <Checkbox
          checked={!!selection[e.id]}
          onChange={() => toggleCheckbox(e.id)}
        />
      ),
      date: formatDate(e.at),
      start: formatDate(e.since),
      end: formatDate(e.until),
      perimeter: `${numCams} caméra${numCams > 1 ? 's' : ''}`,
      user: e.firstname + ' ' + e.lastname,
      action: (
        <>
          <SmallButton
            color="red"
            hoverColor="#e00800"
            onClick={() => setOpenUnique(e.id)}
          >
            Arrêter
          </SmallButton>
          <StopDetectionModal
            isOpen={isOpenUnique === e.id}
            selectedRequests={[e]}
            onSubmit={async () => {
              await tableRequest.refetch()
              setSelection({})
              setOpenUnique(null)
            }}
            onClose={() => setOpenUnique(null)}
          />
        </>
      ),
      status: <VpiProgress searchId={e.id} />,
    }
  })

  function formatDate(date) {
    return (
      strftime('%d/%m/%Y', new Date(+date)) +
      '\n' +
      strftime('%H:%M', new Date(+date))
    )
  }

  return (
    <PageSection
      style={{ maxWidth: '100%', width: '90%' }}
      title="Recherches VPI en cours d'utilisation"
      icon="/images/search.svg"
    >
      {tableRequest.data.detections.length === 0 ? (
        <P textAlign="center">Aucune recherche n'est lancée</P>
      ) : (
        <>
          <div style={{ height: 30 }}>
            {selectionCount > 0 && (
              <Link onClick={() => setOpen(true)}>
                Arrêter les {selectionCount} recherches
              </Link>
            )}
          </div>
          <Table header={tableHeader} body={tableBody} />
          <StopDetectionModal
            isOpen={isOpen}
            selectedRequests={selectedRequests}
            onSubmit={async () => {
              await tableRequest.refetch()
              setSelection({})
              setOpen(false)
            }}
            onClose={() => setOpen(false)}
          />
        </>
      )}
    </PageSection>
  )
}

export default AdminVpiHistory

const ModalOptions = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ModalOption = styled.li`
  display: inline-block;
  margin: 0 10px 0 0;
`

function StopDetectionModal({ isOpen, onClose, onSubmit, selectedRequests }) {
  const [isLoading, setLoading] = React.useState(false)

  async function submit() {
    setLoading(true)
    await services.cancelVpiDetections({
      ids: selectedRequests.map((r) => r.id),
    })
    await onSubmit()
    setLoading(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onCloseClick={onClose}
      onBackgroundClick={onClose}
      onEscapeKeydown={onClose}
    >
      <ModalTitle>
        {selectedRequests.length === 1
          ? `Êtes-vous sûr de vouloir arrêter cette recherche ?`
          : `Êtes-vous sûr de vouloir arrêter les ${selectedRequests.length} recherches ?`}
      </ModalTitle>
      <ModalBody>
        <P>
          Souhaitez-vous vraiment annuler{' '}
          {`${
            selectedRequests.length === 1 ? 'la recherche' : 'les recherches'
          } `}
          <span style={{ fontWeight: 'bold' }}>
            {selectedRequests.map((r) => r.name).join(', ')}
          </span>
          <br />
          Cette action est irréversible.
        </P>
        <ModalOptions>
          <ModalOption>
            <Button red spin={isLoading} onClick={submit}>
              {selectedRequests.length === 1
                ? 'Arrêter la recherche'
                : 'Arrêter les recherches'}
            </Button>
          </ModalOption>
          <ModalOption>
            <Button onClick={onClose}>Fermer</Button>
          </ModalOption>
        </ModalOptions>
      </ModalBody>
    </Modal>
  )
}
