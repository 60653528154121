import React, { useState } from 'react'
import styled from 'styled-components'

import { H1 } from '../components/Typography'

const Container = styled.div`
  width: auto;
  flex: 1;
  padding: 2rem;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto;
  max-width: 1024px;
`

const StyledH1 = styled(H1)`
  color: #000;
  text-transform: none;
  margin-bottom: 0;
  margin-top: 0;
`

export default () => {
  return (
    <Container>
      <StyledContent>
        <StyledH1>Statistiques</StyledH1>
      </StyledContent>
    </Container>
  )
}